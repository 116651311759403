.omail-dark-mode {
  .omail-email-catagory-types .mat-chip-list .mat-standard-chip {
    background: #151920;
    color: #dfe3e7;
  }

  .omail-clip-block .mat-chip-list .mat-standard-chip {
    background: #151920;
    color: #dfe3e7;
  }

  ::-webkit-scrollbar-thumb {
    background: rgba(109, 116, 122, 0.5) !important;
    box-shadow: inset 0px 10px 10px rgba(255, 255, 255, 0.25);
  }

  .omail-more-dropdown {
    .dropdown-menu {
      background: #0d0f10;
      border: 1px solid #232730;

      .dropdown-item {
        color: #dfe3e7;

        &:hover {
          background: #1b1e25 !important;
        }
      }
    }
  }

  .omailsignature-modal .modal-content .modal-body .signature-info .form-group input {
    background: #1b1e25;
    border: 1px solid #232730;
    color: #dfe3e7;

    &::placeholder {
      color: #dfe3e7;
    }
  }

  .mat-dialog-container {
    background: transparent;
  }

  .omail-modal-one {
    .modal-content {
      background-color: transparent;

      .modal-header {
        background: #151920;
        border-bottom: none;

        h5 {
          color: #a0a6ab;
        }

        .btn-close {
          background: #0d0f10;
          box-shadow: 0px 4px 4px rgba(63, 89, 114, 0.2);

          img {
            filter: invert(100%) sepia(75%) saturate(0%) hue-rotate(70deg) brightness(160%) contrast(101%);
            transition: transform 0.7s ease-in-out;
          }

          &:hover {
            background-color: #e84c3d;

            img {
              filter: invert(0%) sepia(100%) saturate(0%) hue-rotate(152deg) brightness(101%) contrast(102%);
              transform: rotate(90deg);
            }
          }
        }
      }

      .modal-body {
        background: #1B1E25;
        ;
        color: #dfe3e7;
      }

      .modal-footer {
        background: #232730;
        background: #1B1E25;
      }
    }
  }

  .omail-custom-btn {
    &:disabled {
      color: #405f7c;
      background: #002950;
      cursor: no-drop;
    }
  }

  .omail-customtheam-froala-block {
    .fr-box.fr-basic .fr-toolbar {
      background: #151920;
    }

    .fr-box.fr-basic .fr-wrapper {
      background: #0D0F10;
    }

    .fr-box.fr-basic .fr-toolbar .fr-command.fr-btn {
      background: #232730;
    }

    .fr-box.fr-basic .fr-toolbar .fr-command.fr-btn svg path {
      fill: #eef1f3;
    }

    .fr-toolbar .fr-newline {
      display: none;
    }
  }

  .omailapp-mainwrap {
    input {
      color: #dfe3e7;

      &::placeholder {
        color: #dfe3e7;
      }
    }
  }

  .omail-custom-btn-outline {
    background: transparent;
  }

  .omail-mat-input {
    .mat-form-field-outline {
      color: #232730;
    }
  }

  .omail-darktheam-block {
    background: #191a1f;
  }

  /* -- Settins Page CSS Start -- */
  .omail-settings-tab-content {
    background: #0d0f10;
    border-radius: 0;

    .auto-replie {
      h4 {
        color: #6d747a;
      }

      h3 {
        color: #eef1f3;
      }

      p {
        color: #dfe3e7;
      }
    }

    .emailruleslist-table-fixed {
      .emailruleslist-table {
        border-radius: 0;

        .thead-color {
          tr {
            th {
              background: #151920;
              color: #dfe3e7;
            }
          }
        }

        .tbody-color {
          tr {
            td {
              background: #1b1e25;
              border-bottom: 1px solid #1b1e25;
              color: #eef1f3;
            }
          }
        }
      }
    }

    .edit-user {
      background: #232730;
    }

    .oesuser-profile-block {
      .setting-user-info {
        h3 {
          color: #dfe3e7;
        }
      }
    }

    .omail-setting-form {
      label {
        color: #6d747a;
      }

      .form-control {
        color: #dfe3e7;

        &:disabled {
          background: #1b1e25;
          border: 1px solid #232730;
        }
      }

      .omail-custom-select {
        background-color: #1b1e25;
        border: 1px solid #232730;
      }
    }

    .omail-storage-block {
      .storage-title {
        color: #6d747a;
      }

      .omail-storage-box {
        background: #151920;
        border: 1px solid #232730;

        .storage-info {
          .storage-cout {
            color: #fff;
          }

          .storage-text {
            color: #fff;
          }
        }
      }
    }

    .omail-user-analytics-block {
      .omail-user-analytics-box {
        background: #151920;
        border: 1px solid #232730;

        h3 {
          color: #dfe3e7;
        }

        ul {
          li {
            background: #151920;
            border: 1px solid #232730;
            border-radius: 8px;

            .list-cout {
              color: #dfe3e7;
            }

            .list-text {
              color: #a0a6ab;
            }
          }
        }
      }
    }

    .omail-cmn-pad {
      .omail-line {
        hr {
          border: 1px solid #1c1f27;
        }
      }

      .common-view {
        h3 {
          color: #6d747a;
        }
      }

      .email-tl {
        h3 {
          color: #a0a6ab;
        }
      }

      .omail-settinslistof-folders {
        .settinslistof-folders-inner {
          .folder-reset-block {
            background: #1c1f27;
            color: #eef1f3;

            .folder-name-text {
              color: #eef1f3;
            }

            .folder-resetpasscode-input {
              .form-control {
                background: #1c1f27;
                color: #dfe3e7;
              }
            }
          }
        }
      }
    }

    .omail-auto-switch {
      .readrecipt-text {
        color: #eef1f3;
      }
    }

    .email-rule-form-block {
      .rule-title {
        color: #eef1f3;
      }

      .email-rule-form {
        .form-group {
          label {
            color: #6d747a;
          }

          .form-control {
            background-color: #1b1e25;
            border: 1px solid #232730;
            color: #dfe3e7;
          }
        }

        .rule-title-text {
          color: #eef1f3;
        }

        .rule-title-sub-text {
          color: #6d747a;
        }
      }
    }

    .auto-replie .omail-autofollowup-form .omail-form-field {
      border-top: 1px solid #1c1f27;
      border-bottom: 1px solid #1c1f27;
    }

    .omail-setting-form .omail-form-field .omail-clip-block input {
      border: none !important;
    }

    .omail-setting-form .form-control {
      background-color: #1b1e25;
      border: 1px solid #232730;
      color: #dfe3e7;
    }

    .auto-replie .omail-autofollowup-form .mat-button-toggle-group-appearance-standard .mat-button-toggle-button {
      background: transparent;
      border: 1px solid #004382;
      color: #dfe3e7;
    }

    .auto-replie .omail-autofollowup-form .mat-button-toggle-group-appearance-standard .mat-button-toggle.mat-button-toggle-checked .mat-button-toggle-button {
      background: #004382;
      border: 1px solid #004382;
      color: #dfe3e7;
    }

    .auto-replie .omail-autofollowup-form .mat-button-toggle-group-appearance-standard .mat-button-toggle {
      background: transparent;
    }

    .omail-sigsettings-block .signature-info label {
      color: #6d747a;
    }

    .omail-sigsettings-block .signature-info .signature_name_input {
      background: #1b1e25;
      border: 1px solid #232730;
      color: #dfe3e7;
    }

    .omail-sigsettings-block .sig-checkbox-block label {
      color: #dfe3e7;
    }

    .omail-sigsettings-block .sig-checkbox-block label input[type="checkbox"]::after {
      border: 1.5px solid #a0a6ab;
      background: #0d0f10;
    }

    .email-rule-form-block .listof-contacts {
      border: 1px solid #232730;
    }
  }

  .omailapp-settings-wrap .omail-side-settings {
    background: #151920;
  }

  .omailapp-settings-wrap .omail-side-settings ul.sidenav-box li.list a {
    color: #a0a6ab;
  }

  .omailapp-settings-wrap .omail-side-settings ul.sidenav-box li.active a {
    background: #23272e;
    color: #029cf8;
  }

  .omailapp-settings-wrap .omail-side-settings ul.sidenav-box li.list a:hover {
    background: #1c1f27;
  }

  .omailapp-settings-wrap .omail-side-settings {
    border-color: #0d0f10;
  }

  /* -- Settins Page CSS End -- */
  /* -- Contacts Page CSS Start -- */
  .omail-contacts-main-block {
    .contacts-header-block {
      background: #0d0f10;

      .newcontactsadd-btn {
        background: #0d0f10;
      }

      .contacts-search-box .search-box .search-filter-block .form-control {
        background: #151920;
        border: 1px solid #232730;
      }

      .contacts-action-links {
        background: #151920;
        border: 1px solid #232730;
      }
    }

    .omail-list-of-contacts-block {
      background: #0d0f10;

      .pannel-main .panner-content {
        border-top: 1px solid #232730;
      }

      .pannel-main .panner-content.active {
        background: #151920;
      }

      .pannel-main .panner-content:hover {
        background: #151920;
      }

      .check-img-block .contact-pic-text {
        background: linear-gradient(0deg,
            rgba(0, 0, 0, 0.25),
            rgba(0, 0, 0, 0.25)),
          #6d747a;
      }

      .pannel-main .panner-content .contact-text h4 {
        color: #dfe3e7 !important;
      }
    }

    .contacts-left-block {
      background: #0d0f10;
      color: #eef1f3;

      .no-list-block .list-text {
        color: #eef1f3;
      }
    }

    .contacts-right-block {
      background: #0d0f10;
      color: #eef1f3;
      border-radius: 0;
    }

    .omail-v-tabs .omail-vtabs-view .omail-vtabs-view-block .omail-vtabs-view-left {
      border-right: 1px solid #232730;
    }

    .contacts-right-block .omail-tab-block .nav-tabs {
      border-bottom: 1px solid #232730;
    }

    .contacts-right-block .omail-tab-block .nav-tabs .nav-link.active {
      background: #004382;
      color: #dfe3e7;
    }

    .contacts-right-block .omail-tab-block .nav-tabs .nav-link:hover {
      background: #151920;
      color: #dfe3e7;
    }

    .contacts-right-block .omail-tab-block .nav-tabs .nav-link {
      background: #151920;
      color: #dfe3e7;
    }

    .contacts-right-block .omail-tab-block .nav-tabs .nav-link span img {
      filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(345deg) brightness(103%) contrast(103%);
    }

    .contacts-right-block .contacts-view-header {
      background: #1b1e25;
      border-bottom: 1px solid #232730;
    }

    .contacts-right-block .contacts-view-block .contacts-veiw-tab .contacts-view-form-info {
      background: #0d0f10;
    }

    .contacts-right-block .contacts-view-header .contact-smart-info .contact-pic-text {
      background: linear-gradient(0deg,
          rgba(0, 0, 0, 0.25),
          rgba(0, 0, 0, 0.25)),
        #6d747a;
    }

    .contacts-right-block .contacts-view-header .contact-smart-info .contact-text h4 {
      color: #dfe3e7;
    }

    .contacts-right-block .contacts-view-header .contact-smart-info .contact-text p {
      color: #a0a6ab;
    }

    .contacts-right-block .contacts-view-header .user-view-actions button.omail-custom-btn {
      background: #0d0f10;
    }

    .contacts-right-block .contacts-view-block .contacts-veiw-tab label {
      color: #a0a6ab;

      .text-danger {
        color: #a0a6ab;
      }
    }

    .contacts-right-block .contacts-view-block .contacts-veiw-tab .form-control:disabled {
      background: #1b1e25;
      border: 1px solid #232730;
      color: #6d747a;
    }

    .contacts-right-block .contacts-view-block .contacts-veiw-tab textarea:disabled {
      background: #1b1e25;
      border: 1px solid #232730;
      color: #6d747a;
    }

    .contacts-right-block .contacts-view-block .contacts-veiw-tab .contact-date-picker .date-input input:disabled {
      background: #1b1e25;
      border: 1px solid #232730;
      color: #6d747a;
    }

    .omail-links-email .allow-email-switch h3 {
      color: #6d747a;
    }

    .contacts-right-block .create-contact-maincontent .contact-group-user-pic-block {
      background: #232730 !important;
    }

    .contacts-right-block .contact-group-user-pic-block .contact-pic-text-block .edit-block {
      background: #0d0f10;
    }

    .contacts-right-block .contacts-view-block .contacts-veiw-tab label {
      color: #6d747a;
    }

    .contacts-right-block .contacts-view-block .contacts-veiw-tab .form-control {
      background-color: #1b1e25;
      border: 1px solid #232730;
      color: #dfe3e7;
    }

    .contacts-right-block .contacts-view-block .contacts-veiw-tab textarea {
      background: #1b1e25;
      border: 1px solid #232730;
      color: #DFE3E7;
    }

    .contacts-right-block .contacts-view-block .contacts-veiw-tab .contact-date-picker .date-input input {
      background: #1b1e25;
      border: 1px solid #232730;
      color: #dfe3e7;

      &::placeholder {
        color: #dfe3e7;
        opacity: 1;
      }

      &:-ms-input-placeholder {
        color: #dfe3e7;
      }

      &::-ms-input-placeholder {
        color: #dfe3e7;
      }
    }

    .contacts-right-block .contacts-view-header .user-view-actions button {
      background: #0d0f10;
    }

    .contacts-right-block .email-catagory-block .email-catagory-inner {
      background: #1b1e25;
    }

    .contacts-right-block .email-catagory-block .email-catagory-inner .sub-heading {
      background: #151920;
    }

    .contacts-right-block .create-group-form .add-book-btn-block .group-rule-info-text {
      background: #1c1f27;
      color: #eef1f3;
      border: 1px solid #232730;
    }

    .contacts-right-block .contact-group-user-pic-block {
      background: #232730;
    }
  }

  .omail-contacts-main-block .omail-v-tabs .omail-vtabs-sidenav {
    background: #151920;
    border-color: #232730;
  }

  .omail-contacts-main-block .omail-v-tabs .omail-vtabs-sidenav .cg-list-block li {
    background: transparent;
    color: #a0a6ab;
  }

  .omail-contacts-main-block .omail-v-tabs .omail-vtabs-sidenav .cg-list-block li.activetab {
    background: #23272e;
    color: #029cf8;
  }

  .omail-contacts-main-block .omail-v-tabs .omail-vtabs-sidenav .cg-list-block li:hover {
    background: #1c1f27;
  }

  .omail-contacts-main-block .omail-v-tabs .omail-vtabs-sidenav .omail-delete-list-one .delete-txt {
    color: #a0a6ab;
  }

  .omail-contacts-main-block .omail-v-tabs .omail-vtabs-sidenav .cg-btn-action-block .omail-btn-group {
    border: none;
  }

  .omail-contacts-main-block .omail-v-tabs .omail-vtabs-sidenav .omail-delete-list-one:hover {
    background: #1c1f27;
  }

  .omail-contacts-main-block .omail-v-tabs .omail-vtabs-sidenav .delete-content.delete-active {
    background: #23272e;
    color: #029CF8;

    .delete-txt {
      color: #029CF8;
    }
  }

  .omail-contacts-main-block .omail-v-tabs .omail-vtabs-sidenav .omail-sub-delete-layout li {
    background: transparent;

    &.activetab {
      background: #1C1F27;
    }
  }

  .omail-sub-delete-layout li {
    color: #a0a6ab;
  }

  .omail-sub-delete-layout li.activetab {
    color: #a0a6ab;
  }

  .delete-inactive {
    .cursor-pointer {
      color: #fff;
    }
  }

  .omail-contacts-main-block .contacts-right-block .contact-group-user-pic-block .contact-pic-text {
    background: #151920;
  }

  /* -- Contacts Page CSS End -- */
  /* -- MailBox Page CSS Start -- */
  .omail-mailbox-module {
    .omail-mailbox-sidenav-block {
      .omail-sub-sidebar {
        background: #101214;
      }
    }

    .omail-mailbox-split-block {
      .omailmailbox-split-left-block {
        background: #0D0F10;
      }

      .omailmailbox-split-right-block {
        background: #101214;
      }
    }

    .omail-compose-mail-block {
      background: #101214;
    }

    .omailmailbox-readmail-mainblock {
      background: #101214 !important;

      .omail-readmail-main {
        background: #101214;
      }
    }
  }

  .omail-app-header {
    background: #151920;
    border-right: 1px solid #232730;
  }

  .omail-app-header .omail-logo {
    border-bottom: 1px solid #232730;
  }

  .omail-app-header .omail-modules-link {
    border-bottom: 1px solid #232730;
  }

  .omail-mailbox-module .omail-mailbox-sidenav-block .omail-sub-sidebar .splitleftblock-partone {
    border-bottom: 1px solid #232730;
  }

  .omail-mailbox-module .omail-mailbox-split-block .omailmailbox-split-left-block {
    border-left: 1px solid #232730;
  }

  .omail-mailbox-module .omail-mailbox-sidenav-block {
    border-right: 1px solid #232730;
  }

  .as-split-gutter {
    background-color: #232730 !important;
  }

  .omail-mailbox-module .omail-mailbox-split-block .omailmailbox-split-left-block {
    border: none;
  }

  .omail-mailbox-module .omail-mailbox-sidenav-block .omail-sub-sidebar .omail-sub-sidebar-layout .omail-categories-list li.activefolder-list {
    background: #23272e;
  }

  .omail-mailbox-module .omail-mailbox-sidenav-block .omail-sub-sidebar .omail-sub-sidebar-layout .omail-categories-list li {
    color: #a0a6ab;
    background-color: transparent;
  }

  .omail-mailbox-module .omail-mailbox-sidenav-block .omail-sub-sidebar .omail-sub-sidebar-layout .omail-categories-list li:hover {
    background: #1c1f27;
  }

  .omail-mailbox-module .omail-mailbox-sidenav-block .omail-sub-sidebar .omail-sub-sidebar-layout .omail-categories-list hr {
    border: 1px solid #232730;
  }

  .omail-mailbox-module .omail-mailbox-sidenav-block .omail-sub-sidebar .omail-sub-sidebar-layout .omail-categories-list-one .folder-content.folder-inactive .folder-txt {
    color: #a0a6ab;
  }

  .omail-mailbox-module .omail-mailbox-sidenav-block .omail-sub-sidebar .omail-sub-sidebar-layout .omail-categories-list-one .folder-content.folder-active {
    background: #23272e;
  }

  .omail-mailbox-module .omail-mailbox-sidenav-block .omail-sub-sidebar .omail-sub-sidebar-layout .omail-categories-list-one li {
    background: transparent;
  }

  .omail-mailbox-module .omail-mailbox-sidenav-block .omail-sub-sidebar .omail-sub-sidebar-layout .omail-categories-list-one li .generate-folder .ge-text {
    color: #a0a6ab !important;
  }

  .omail-mailbox-module .omail-mailbox-sidenav-block .omail-sub-sidebar .omail-sub-sidebar-layout .omail-categories-list-one .omail-sub-folder-layout li.activefolder-list {
    background: #23272e;
  }

  .omail-mailbox-module .omail-mailbox-split-block .omailmailbox-split-left-block .splitleftblock-partfour .omail-mail-listblock .omail-list-empty-block {
    color: #a0a6ab;
  }

  .omail-mailbox-module .omail-mailbox-split-block .omailmailbox-split-left-block .trash-alert {
    color: #cb6b1a;
    background: #151920;
    border: 1px solid #232730;
  }

  .omail-mailbox-module .omail-mailbox-split-block .omailmailbox-split-left-block .splitleftblock-parttwo {
    background: #151920;
    border: 1px solid #232730;
  }

  .omail-mailbox-module .omail-mailbox-split-block .omailmailbox-split-left-block .splitleftblock-partone .omailsearch-refresh-block .search-block input {
    background: #151920;
    border: 1px solid #232730;
  }

  .omail-mailbox-module .omail-mailbox-split-block .omailmailbox-split-left-block .splitleftblock-partone .omailsearch-refresh-block .search-block .input-group-text {
    border-color: #232730;
    background-color: #151920;
  }

  .omail-mailbox-module .omail-mailbox-split-block .omailmailbox-split-left-block .splitleftblock-partone .omailsearch-refresh-block .search-block .input-group-text img {
    filter: invert(76%) sepia(1%) saturate(2090%) hue-rotate(164deg) brightness(85%) contrast(103%);
  }

  .omail-mailbox-module .omail-mailbox-sidenav-block .omail-sub-sidebar .sidebar-storage {
    background: #1c1f27;
    border-right: 1px solid #151920;
  }

  .omail-mailbox-module .omail-mailbox-sidenav-block .omail-sub-sidebar .sidebar-storage h5 {
    color: #6d747a;
  }

  .omail-mailbox-module .omail-mailbox-sidenav-block .omail-sub-sidebar .sidebar-storage h5 strong {
    color: #a0a6ab;
  }

  .omail-mailbox-module .omail-mailbox-split-block .omailmailbox-split-left-block .splitleftblock-partfour .omail-mail-listblock .omaillist-box {
    background: #0d0f10;
    border-color: #1c1f27;
  }

  .omail-mailbox-module .omail-mailbox-split-block .omailmailbox-split-left-block .splitleftblock-partfour .omail-mail-listblock .omaillist-box:hover {
    background-color: #151920;
    border-left: 3px solid #bee49f;
  }

  .omail-mailbox-module .omail-mailbox-split-block .omailmailbox-split-left-block .splitleftblock-partfour .omail-mail-listblock .omaillist-box .omail-userinfo-block .omaillist-inner-one .omail-user-name {
    color: #a0a6ab;
  }

  .omail-mailbox-module .omail-mailbox-split-block .omailmailbox-split-right-block .omailmailbox-readmail-mainblock .omail-readmail-main .omail-read-mail-header .omail-read-mail-headertwo {
    background: #1c1f27;
    border-color: #232730;
  }

  .omail-mailbox-module .omail-mailbox-split-block .omailmailbox-split-right-block .omailmailbox-readmail-mainblock .omail-readmail-main .omail-read-mail-header .omail-read-mail-headertwo .headertwo-left-block .read-mail-subject-text {
    color: #029cf8;
  }

  .omail-mailbox-module .omail-mailbox-split-block .omailmailbox-split-right-block .omailmailbox-readmail-mainblock .omail-readmail-main .omail-read-mail-header .omail-read-mail-headertwo .headertwo-right-block .read-mail-timedate-text {
    color: #a0a6ab;
  }

  .omail-mailbox-module .omail-mailbox-split-block .omailmailbox-split-right-block .omailmailbox-readmail-mainblock .omail-readmail-main .omail-read-mail-header .omail-read-mail-headerone {
    border-color: #232730;
  }

  .omail-mailbox-module .omail-mailbox-split-block .omailmailbox-split-right-block .omailmailbox-readmail-mainblock .omail-readmail-main .omail-read-mail-header .omail-read-mail-headerone .headerone-left-block .read-mail-user-text {
    color: #a0a6ab;
  }

  .omail-mailbox-module .omail-mailbox-split-block .omailmailbox-split-right-block .omailmailbox-readmail-mainblock .omail-readmail-main .omail-read-mail-header .omail-read-mail-headerthree {
    border-color: #232730;
  }

  .omail-mailbox-module .omail-mailbox-split-block .omailmailbox-split-left-block .splitleftblock-partfour .omail-mail-listblock .omaillist-box.activemail {
    border-left: 3px solid #37ac00;
    background-color: #151920 !important;
  }

  .omail-mailbox-module .omail-mailbox-split-block .omailmailbox-split-right-block .omailmailbox-readmail-mainblock .omail-readmail-main .omail-read-mail-body .read-mail-body-text {
    color: #dfe3e7;

    table {
      div {
        span:first-child {
          color: #DFE3E7;
        }

        span:last-child {
          color: #009CD9 !important;
        }
      }
    }
  }

  .omail-mailbox-module .omail-mailbox-split-block .omailmailbox-split-left-block .splitleftblock-partfour .omail-mail-listblock .omaillist-box .omail-user-block .maillist-rounded-block .check-img-block .contact-pic-text {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25)),
      #6d747a;
  }

  .omail-mailbox-module .omail-mailbox-split-block .omailmailbox-split-left-block .splitleftblock-partfour .omail-mail-listblock .omaillist-box .omail-user-block .maillist-rounded-block .check-img-block .contact-pic-text img {
    opacity: 0.25;
  }

  .omail-compose-mail-block .omailcomposemail-header .omailcomposemail-headerone h4 {
    color: #dfe3e7;
  }

  .omail-compose-mail-block .omailcomposemail-footer {
    border-color: #232730;
  }

  .omail-compose-mail-block .omailcomposemail-header .omailcomposemail-headertwo .omail-form-field {
    border-color: #232730;
  }

  .omail-compose-mail-block .omailcomposemail-header .omailcomposemail-headertwo .omail-form-field .omail-col-one input {
    background: transparent;
  }

  .fr-box.fr-basic .fr-element {
    color: #dfe3e7;
  }

  .omail-movetofolder-modal .selected-mail-list-block h4 {
    color: #eef1f3;
  }

  .omail-movetofolder-modal .modal-body .omail-move-create-folder {
    background: #151920;
    border: 1px solid #232730;
  }

  .omail-movetofolder-modal .modal-body .omail-move-create-folder h5 {
    color: #a0a6ab;
  }

  .omail-movetofolder-modal .selected-mail-list-block {
    background: #1b1e25;
  }

  .omail-movetofolder-modal .modal-body .omail-move-list .omail-sub-sidebar .omail-sub-sidebar-layout ul li {
    background: #0d0f10;
  }

  .omail-movetofolder-modal .modal-body .omail-move-list {
    background: #1b1e25;
  }

  .omail-movetofolder-modal .modal-body .omail-move-list .omail-sub-sidebar .omail-sub-sidebar-layout ul li .generate-folder .ge-text {
    color: #dfe3e7 !important;
  }

  .omail-movetofolder-modal .modal-body .omail-move-list .omail-sub-sidebar .omail-sub-sidebar-layout ul li.activetab {
    background: #151920 !important;
  }

  .omail-movetofolder-modal .modal-body .omail-move-list .omail-sub-sidebar .omail-sub-sidebar-layout ul li:hover {
    background: #151920 !important;
  }

  .mat-menu-panel {
    background: #0d0f10;
    border: 1px solid #232730;
    box-shadow: none !important;
  }

  .mat-menu-item {
    color: rgba(223, 227, 231, 1);
  }

  .mat-menu-item:hover:not([disabled]) {
    background: #232730;
  }

  .omail-mailbox-module .omail-mailbox-split-block .omailmailbox-split-right-block .omailmailbox-readmail-mainblock .omail-readmail-main .omail-read-mail-footer .readmailattachment-block {
    background: #151920;
  }

  .omail-mailbox-module .omail-mailbox-split-block .omailmailbox-split-right-block .omailmailbox-readmail-mainblock .omail-readmail-main .omail-read-mail-footer .readmailattachment-block .readmailattachment-body .attachment-list li {
    background: #0d0f10;
  }

  .omail-mailbox-module .omail-mailbox-split-block .omailmailbox-split-right-block .omailmailbox-readmail-mainblock .omail-readmail-main .omail-read-mail-footer .readmailattachment-block .readmailattachment-body .attachment-list li a .read-attachment-filename .file-size-text {
    color: #dfe3e7;
  }

  .omail-mailbox-module .omail-mailbox-split-block .omailmailbox-split-right-block .omailmailbox-readmail-mainblock .omail-readmail-main .omail-read-mail-header .omail-emailmorelist-dropdown .omail-morelist-header {
    background: #151920;
    color: #a0a6ab;
  }

  .omail-mailbox-module .omail-mailbox-split-block .omailmailbox-split-right-block .omailmailbox-readmail-mainblock .omail-readmail-main .omail-read-mail-header .omail-emailmorelist-dropdown .omail-morelist-header span {
    background: #0d0f10;
  }

  .omail-mailbox-module .omail-mailbox-split-block .omailmailbox-split-right-block .omailmailbox-readmail-mainblock .omail-readmail-main .omail-read-mail-header .omail-emailmorelist-dropdown .omail-morelist-header span img {
    filter: invert(97%) sepia(100%) saturate(13%) hue-rotate(196deg) brightness(103%) contrast(103%);
  }

  .omail-mailbox-module .omail-mailbox-split-block .omailmailbox-split-right-block .omailmailbox-readmail-mainblock .omail-readmail-main .omail-read-mail-header .omail-emailmorelist-dropdown .omail-clip-block {
    background: #1b1e25;
  }

  .omail-mailbox-module .omail-mailbox-split-block .omailmailbox-split-right-block .omailmailbox-readmail-mainblock .omail-readmail-main .omail-read-mail-header .omail-emailmorelist-dropdown {
    background: #0d0f10;
  }

  .omail-compose-mail-block .omailcomposemail-header .omailcomposemail-headertwo .omail-emailmorelist-dropdown .omail-morelist-header {
    background: #151920;
    color: #a0a6ab;
  }

  .omail-compose-mail-block .omailcomposemail-header .omailcomposemail-headertwo .omail-emailmorelist-dropdown {
    background: #0d0f10;
  }

  .omail-compose-mail-block .omail-smartreplay-block ul {
    background: #0d0f10;
  }

  .omail-compose-mail-block .omail-smartreplay-block ul li {
    background: #0d0f10;
    border: 1px solid #004382;
    color: #eef1f3;
  }

  /* -- MailBox Page CSS End -- */
  /* -- Calendar Page CSS Start -- */
  .omail-calendar .calendar-left {
    background: #151920;
  }

  .omail-calendar .calendar-right {
    background: #1c1f27;
  }

  .omail-calendar .leftblockcal {
    border-color: #232730;
  }

  .omail-calendar .omail-product-event-type .titleh4 {
    color: #a0a6ab;
  }

  .omail-calendar .omail-product-event-type .product-dropdown .cbtn {
    background: #151920;
    border: 1px solid #232730;
    box-shadow: 0px 1px 2px rgba(77, 64, 85, 0.1);
    color: #dfe3e7;
  }

  .omail-calendar .leftblockcal .cuborder {
    border: 1px solid #232730;
  }

  .omail-calendar .omail-product-event-type .completedbg {
    background: #232730;
  }

  .omail-calendar .viewevent-box .evnt-box .p-title {
    color: #37ac00;
  }

  .omail-calendar .viewevent-box .evnt-box .p-date-time {
    color: #a0a6ab;
  }

  .omail-calendar .viewevent-box {
    background: #232730;
  }

  .omailapp-mainwrap {
    background: #0d0f10;
    margin-right: 21px;
  }

  .omail-calendar .calendar-right .omail-tab-block .omail-appointment .event-block .event-form-block {
    background: #0d0f10;
  }

  .omail-calendar .calendar-right .omail-tab-block .omail-appointment .event-block .event-form-block .event-form-action-links {
    background: #0d0f10;
    border-top: 1px solid #1c1f27;
  }

  .omail-calendar .calendar-right .omail-tab-block .omail-appointment .event-block .event-form-block .omail-form-field {
    background: #0d0f10;
    border-top: 1px solid #1c1f27;
  }

  .omail-calendar .calendar-right .omail-tab-block .omail-appointment .event-block .event-form-block .mail-field .subject-block input {
    background: #0d0f10;
  }

  .omail-calendar .calendar-right .omail-tab-block .omail-appointment .event-block .event-form-block .mail-field {
    background: #0d0f10;
    border-top: 1px solid #1c1f27;
  }

  .omail-calendar .calendar-right .omail-tab-block .omail-appointment .event-block .event-form-block .mail-field .field-2 .location-block input {
    background: #0d0f10;
  }

  .omail-calendar .calendar-right .omail-tab-block .omail-appointment .event-block .event-duration-block {
    border-top: 1px solid #1c1f27;
    border-bottom: 1px solid #1c1f27;
  }

  .omail-calendar .calendar-right .omail-tab-block .omail-appointment .event-block .event-form-block .mail-field .field-2.reminder-field .custom-select {
    border: 1px solid #1c1f27;
    color: #dfe3e7;
  }

  .omail-calendar .calendar-right .omail-tab-block .omail-appointment .event-block .event-form-block .mail-field .subject-block input {
    color: #dfe3e7;
  }

  .omail-calendar .calendar-right .omail-tab-block .omail-appointment .event-block .event-form-block .mat-form-field-outline {
    background: #0d0f10;
  }

  .omail-dark-mode .omail-calendar .calendar-right .omail-tab-block .omail-appointment .event-block .event-form-block .mail-field .field-2 .location-block input {
    color: #dfe3e7;
  }

  .omail-calendar .calendar-right .omail-tab-block .omail-appointment .event-block .event-duration-block .event-duration-fields .ed-f-3 .timer-field .custom-select {
    background: #0d0f10;
    border: 1px solid #151920;
    color: #dfe3e7;
  }

  .omail-calendar .calendar-right .omail-tab-block .omail-appointment .event-block .event-duration-block .event-duration-fields .ed-f-4 .omail-all-days-block .all-day {
    color: #dfe3e7;
  }

  .omail-calendar .calendar-right .omail-tab-block .omail-appointment .event-block .week-days ul li label {
    background: #0d0f10;
    border: 1px solid #004382;
    color: #dfe3e7;
  }

  .omail-calendar .calendar-right .omail-tab-block .omail-appointment .event-block .week-days ul li input:checked+label {
    background: #004382;
  }

  .omail-dark-mode .omail-calendar .calendar-right .omail-tab-block .omail-appointment .event-block .event-form-block .mat-form-field-outline {
    background: #0d0f10;
    border: 1px solid #151920;
  }

  .omail-calendar .calendar-right .omail-tab-block .omail-appointment .event-block .event-form-block .mat-form-field-outline {
    color: #151920;
  }

  .omail-calendar .calendar-right .omail-tab-block .omail-appointment .event-block .event-form-block .mat-form-field-appearance-outline .mat-form-field-flex .event-icon img {
    filter: invert(97%) sepia(100%) saturate(13%) hue-rotate(196deg) brightness(103%) contrast(103%);
  }

  .omail-customtheam-froala-block .fr-box.fr-basic .fr-toolbar .fr-command[id*="videoMail"] {
    background: #37ac00 !important;
  }

  .omail-calendar .calendar-right .omail-tab-block .tab-event .nav-tabs .nav-link {
    background: #151920;
    color: #dfe3e7;
  }

  .omail-calendar .calendar-right .omail-tab-block .tab-event .nav-tabs .nav-link.active {
    background: #004382;
    color: #dfe3e7;
  }

  .omail-calendar .calendar-right .omail-tab-block .tab-event .nav-tabs .nav-link.active span img {
    filter: invert(97%) sepia(100%) saturate(13%) hue-rotate(196deg) brightness(103%) contrast(103%);
  }

  .omail-calendar .calendar-right .omail-tab-block .tab-event .nav-tabs .nav-link:hover {
    background: #004382;
    color: #dfe3e7;
  }

  .omail-event-list-one .viewevent-box {
    background: #232730 !important;
  }

  .omail-event-list-one .viewevent-box .evnt-box .p-title {
    color: #37ac00;
  }

  .omail-event-list-one .viewevent-box .evnt-box .prduct-logo {
    background: transparent;
    border: 1px solid #343943;
  }

  .omail-event-list-one .viewevent-box .evnt-box .dotsview .dropdown-menu {
    background: #0d0f10;
    color: #dfe3e7;
  }

  .omail-event-list-one .viewevent-box .evnt-box .dotsview .dropdown-menu .dropdown-item {
    color: #dfe3e7;
  }

  .omail-event-list-one .viewevent-box .evnt-box .dotsview .dropdown-menu .dropdown-item:hover {
    color: #dfe3e7;
    background: #232730;
  }

  /* -- Calendar Page CSS End -- */
  .omail-list-group-modal .contact-search-block .oesobs-search .search-icon {
    background: #151920;
  }

  .omail-list-group-modal .contact-search-block .oesobs-search .form-control {
    background: #151920;
    color: #dfe3e7;
  }

  .omail-list-group-modal .contact-search-block .contacts-selected-text {
    color: #eef1f3;
  }

  .omail-list-group-modal .addressbook-table-fixed .addressbook-table .thead-color tr th {
    background: #151920;
    color: #dfe3e7;
    border: transparent;
  }

  .omail-list-group-modal .usercheckmark-block {
    color: #dfe3e7;
  }

  .omail-list-group-modal .addressbook-table-fixed .addressbook-table .user-name {
    color: #dfe3e7;
  }

  .omail-list-group-modal .addressbook-table-fixed .addressbook-table .user-email {
    color: #dfe3e7;
  }

  .omail-list-group-modal .addressbook-table-fixed thead,
  tbody,
  tfoot,
  tr,
  td,
  th {
    color: #dfe3e7;
  }

  .omail-list-group-modal .oesomailcustom-select {
    border: 1px solid #232730;
    color: #dfe3e7;

    option {
      background-color: #0D0F10;
    }
  }

  .addressbook-table .oesomailcustom-select {
    background-color: #151920;
  }

  .oesomail-addressbook-modal .modal-content .modal-body .contact-search-block .oesobs-search .search-icon {
    background: #151920;
    border: 1px solid #232730 !important;
  }

  .oesomail-addressbook-modal .modal-content .modal-body .contact-search-block .oesobs-search .form-control {
    background: #1b1e25;
    border: 1px solid #232730 !important;
    color: #dfe3e7;
  }

  .oesomail-addressbook-modal .modal-content .modal-body .contact-search-block .contacts-selected-text {
    color: #dfe3e7;
  }

  .oesomail-addressbook-modal .modal-content .modal-body .addressbook-table-fixed thead th {
    background: #0d0f10 !important;
  }

  .oesomail-addressbook-modal .modal-content .modal-body .addressbook-table-fixed .usercheckmark-block {
    color: #dfe3e7 !important;
  }

  .oesomail-addressbook-modal .modal-content .modal-body .contact-search-block .contacts-selected-text {
    color: #dfe3e7 !important;
  }

  .omail-customtheam-froala-block .fr-box.fr-basic .fr-toolbar .fr-command[id*="speechToText"] {
    background: #37ac00 !important;
  }

  .schedulingmail-block {
    .form-group {
      .form-control {
        background: #151920;
        border: 1px solid #232730;
        color: #dfe3e7;
      }
    }
  }

  .flatpickr-calendar {
    background: #0d0f10;
    border: 1px solid #232730;
    box-shadow: none;
  }

  .flatpickr-day.selected {
    background: #004382;
    border-radius: 6px;
    color: #dfe3e7;

    &:hover {
      color: #ffffff;
    }
  }

  .flatpickr-day {
    color: #dfe3e7;

    &:hover {
      color: #000000;
    }
  }

  .flatpickr-day.flatpickr-disabled {
    color: #dfe3e7;
    opacity: 0.5;
  }

  .flatpickr-months .flatpickr-month {
    color: #dfe3e7;
  }

  .flatpickr-months .flatpickr-next-month {
    filter: invert(100%) sepia(3%) saturate(7448%) hue-rotate(155deg) brightness(106%) contrast(103%);
  }

  .flatpickr-months .flatpickr-prev-month {
    filter: invert(100%) sepia(3%) saturate(7448%) hue-rotate(155deg) brightness(106%) contrast(103%);
  }

  .flatpickr-calendar.hasTime .flatpickr-time {
    border-top: 1px solid #232730;
  }

  .flatpickr-time input {
    color: #dfe3e7;
  }

  .flatpickr-time .flatpickr-time-separator {
    color: #dfe3e7;
  }

  .flatpickr-time .flatpickr-am-pm {
    color: #dfe3e7;
  }

  .flatpickr-time .flatpickr-am-pm:hover {
    background: #1b1e25;
  }

  .flatpickr-time input:hover {
    background: #1b1e25;
  }

  .flatpickr-time input:hover {
    background: #1b1e25;
  }

  .flatpickr-time input:focus {
    background: #1b1e25;
  }

  .flatpickr-time .flatpickr-am-pm:focus {
    background: #1b1e25;
  }

  .omail-calendar .omail-product-event-type .cancelledbg {
    .p-title {
      color: #e84c3d;
    }
  }

  .omail-calendar .omail-product-event-type .completedbg {
    .p-title {
      color: #009cd9;
    }
  }

  .omail-calendar .omail-product-event-type .cancelledbg,
  .omail-calendar .omail-product-event-type .upcomingbg {
    background: #232730;
  }

  .omail-calendar .viewevent-box .evnt-box .prduct-logo {
    border: 1px solid #343943;
    background: transparent;
  }

  .omail-calendar .viewevent-box .evnt-box .dotsview .dropdown-menu {
    color: #dfe3e7;
  }

  .omail-calendar .viewevent-box .evnt-box .dotsview .dropdown-menu .dropdown-item {
    color: #dfe3e7;
  }

  .omail-calendar .viewevent-box .evnt-box .dotsview .dropdown-menu .dropdown-item:hover,
  .omail-calendar .viewevent-box .evnt-box .dotsview .dropdown-menu .dropdown-item:focus {
    background: #232730;
  }

  .omail-dark-mode .omail-calendar .viewevent-box .evnt-box .dotsview .dropdown-menu {
    background: #1b1e25;
  }

  .omail-calendar .calendar-right .show-day-strip {
    background: #232730;
    color: #dfe3e7;
  }

  .omail-calendar .calendar-right .show-day-strip h3,
  .omail-calendar .calendar-right .show-day-strip .h3 {
    color: #dfe3e7;
  }

  .omail-event-calendar .short-view-cal .s-v-cal-text {
    color: #dfe3e7;
  }

  .omail-calendar .calendar-right .day-view-block .cal-day-view .cal-week-view .cal-time-events {
    border: none;
  }

  .omail-calendar .calendar-right .day-view-block .cal-day-view .cal-week-view .cal-time-events .cal-day-columns .cal-day-column .cal-hour {
    background: transparent;
  }

  .omail-calendar .calendar-right .day-view-block .cal-day-view .cal-week-view .cal-time-events .cal-day-columns .cal-day-column .cal-hour.cal-hour-odd {
    background: transparent;
  }

  .omail-event-calendar .cal-week-view .cal-time-events .cal-day-columns:not(.cal-resize-active) .cal-hour-segment:hover {
    background: #232730;
  }

  .omail-event-calendar .cal-week-view .cal-time-events .cal-day-columns:not(.cal-resize-active) .cal-hour-segment:hover {
    background: #232730;
  }

  .omail-event-calendar .week-view-block .cal-week-view .cal-day-headers .cal-header {
    background: #1c1f27;
  }

  .omail-event-calendar .week-view-block .cal-week-view .cal-day-headers .cal-header b {
    color: #dfe3e7;
  }

  .omail-event-calendar .week-view-block .cal-week-view .cal-day-headers .cal-header.cal-weekend span {
    color: #dfe3e7;
  }

  .omail-event-calendar .week-view-block .cal-week-view {
    background: none;
  }

  .omail-event-calendar .cal-week-view .cal-hour-odd {
    background: transparent;
  }

  .omail-event-calendar .week-view-block .cal-week-view .cal-day-headers .cal-header span {
    color: #dfe3e7;
  }

  .omail-event-calendar .omail-month-view .cal-month-view .cal-header .cal-cell {
    background: #1c1f27;
    color: #dfe3e7;
  }

  .omail-event-calendar .omail-month-view .cal-month-view .cal-days .cal-cell-row .cal-day-cell:nth-of-type(odd) {
    background: #0d0f10;
  }

  .omail-event-calendar .omail-month-view .cal-month-view .cal-days .cal-cell-row .cal-day-cell:nth-of-type(even) {
    background: #0d0f10;
  }

  .omail-event-calendar .omail-month-view .cal-month-view {
    background: transparent;
  }

  .omail-event-calendar .cal-month-view .cal-cell-row:hover {
    background: #232730;
  }

  .omail-event-calendar .omail-month-view .cal-month-view .cal-days .cal-cell-row .cal-day-cell .cal-cell-top .cal-day-number {
    color: #dfe3e7;
  }

  .cal-week-view .cal-all-day-events .cal-event,
  .cal-week-view .cal-time-events .cal-event {
    background: #232730;
    border: 1px solid #232730;
  }

  .omail-event-calendar .cal-week-view * {
    color: #dfe3e7;
  }

  .omail-mailbox-module .omail-mailbox-split-block .omailmailbox-split-right-block .omailmailbox-readmail-mainblock .omail-readmail-main .omail-read-mail-header .omail-read-mail-headerfour .read-mail-scheduled-text {
    color: #cb6b1a;
  }

  .omail-compose-mail-block .omailcomposemail-header .omailcomposemail-headertwo .omail-form-field .omail-col-one input {
    color: #dfe3e7;
  }

  .omailapp-settings-wrap .omail-settings-tab-content .omail-setting-form .event-more-mail-list .omail-morelist-header {
    background: #151920;
    color: #a0a6ab;
  }

  .omailapp-settings-wrap .omail-settings-tab-content .omail-setting-form .omail-emailmorelist-dropdown {
    background: #151920;
    color: #a0a6ab;
  }

  input[type="checkbox"]:before {
    background: #151920;
  }

  .omail-welcontact-pic-text {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25)),
      #6d747a;
    border: 1px solid #232730;
  }

  .omail-create-folder-modal .modal-content .modal-body .create-folder-modal .form-group .form-control {
    background: #0d0f10;
    border: 1px solid #232730;
    color: #a0a6ab;
  }

  .omail-create-folder-modal .modal-content .modal-body .create-folder-modal .form-group label {
    color: #dfe3e7;
  }

  .omail-create-folder-modal .modal-content .modal-body .create-folder-modal .omail-folder-form .folder-text .passcode-checkbox .passcode-text {
    color: #dfe3e7;
  }

  .mat-checkbox-frame {
    border: 1.5px solid #a0a6ab;
  }

  .omail-create-folder-modal .modal-content .modal-body .create-folder-modal .omail-folder-form .folder-input input {
    background: #0d0f10;
    border: 1px solid #232730;
  }

  .omail-create-folder-modal .modal-content .modal-body .create-folder-modal .ng-select-container {
    background: #0d0f10;
    border: 1px solid #232730;
    color: #a0a6ab;
  }

  .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected,
  .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked {
    background: #0d0f10;
    border: 1px solid #232730;
    color: #dfe3e7;
  }

  .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
    background: #0d0f10;
    border: 1px solid #232730;
    color: #dfe3e7;
  }

  .ng-dropdown-panel.ng-select-bottom {
    border: 1px solid #232730;
  }

  .omail-compose-mail-block .omailcomposemail-header .omailcomposemail-headertwo .omail-form-field .omail-col-one .attachment-list ul li {
    color: #dfe3e7;
  }

  .omail-mailbox-module .omail-mailbox-split-block .omailmailbox-split-right-block .omailmailbox-readmail-mainblock .omail-readmail-main .omail-read-mail-footer .readmailattachment-block .readmailattachment-body .attachment-list li a .read-attachment-filename {
    color: #dfe3e7;
  }

  .omail-dark-mode .omail-mailbox-module .omail-mailbox-split-block .omailmailbox-split-right-block .omailmailbox-readmail-mainblock .omail-readmail-main .omail-read-mail-footer .readmailattachment-block .readmailattachment-body .attachment-list li a .read-attachment-filename .file-size-text {
    color: #a0a6ab;
  }

  .cg-pic-dw-block {
    background: #0d0f10;
    border: 1px solid #0d0f10;
  }

  .cg-pic-dw-block .dropdown-item {
    border-bottom: 1px solid #232730;
    color: #dfe3e7;
  }

  .cg-pic-dw-block .dropdown-item:hover {
    background: #232730;
  }

  .omail-passCodeModal-modal .modal-content .modal-body .create-folder-modal .omail-folder-form .folder-input input {
    background: #0d0f10;
    border: 1px solid #232730;
    color: #dfe3e7;
  }

  .omail-dark-mode .omail-event-list-one .viewevent-box {
    background: #232730 !important;
  }

  .omail-calendar .omail-month-view .cal-days .omail-event-list-one .viewevent-box .evnt-box .prduct-logo {
    border: 1px solid #343943;
    background: #232730;
  }

  .omail-event-list-one {
    .evnt-box .p-title {
      color: #37ac00;
    }
  }

  .omail-event-list-one .completedbg {
    .evnt-box .p-title {
      color: #009cd9;
    }
  }

  .omail-event-list-one .cancelledbg {
    .evnt-box .p-title {
      color: #e84c3d;
    }
  }

  .omail-popover-block {
    background: #0d0f10;
    border: 1px solid #232730;
    color: #dfe3e7;
    padding: 0;

    .card-body {
      background: #0d0f10;
      color: #dfe3e7;

      .text-truncate {
        color: #dfe3e7;
      }

      .media-img {
        border: 1px solid #343943;
        background: #232730;
      }
    }
  }

  .omail-event-list-one {
    .viewevent-box {
      .popover {
        background: #0d0f10;
        border: 1px solid #343943;
        filter: none;

        .omail-popover-block {
          border: 0;

          .title {
            color: #dfe3e7;
          }
        }
      }
    }
  }

  .omail-calendar .calendar-right .day-view-block .cal-day-view .cal-week-view .cal-time-events .cal-day-columns .cal-day-column .cal-hour {
    border-top: 1px solid #232730;
  }

  .omail-calendar .calendar-right .day-view-block .cal-day-view .cal-week-view .cal-time-events .cal-day-columns .cal-day-column .cal-hour mwl-calendar-week-view-hour-segment:first-child {
    border-bottom: 1px dashed #232730;
  }

  .omail-event-calendar .week-view-block .cal-day-columns {
    border: 1px solid #232730;
  }

  .omail-event-calendar .week-view-block .cal-week-view .cal-day-column {
    border: 1px solid #232730;
  }

  .omail-event-calendar .week-view-block .cal-hour {
    border-top: 1px solid #232730;
  }

  .omail-event-calendar .week-view-block .cal-hour mwl-calendar-week-view-hour-segment:first-child {
    border-bottom: 1px dashed #232730;
  }

  .omail-event-calendar .cal-month-view .cal-days .cal-cell-row {
    border-bottom: 1px solid;
    border-bottom-color: #1C1F27;
  }

  .omail-event-calendar .omail-month-view .cal-month-view .cal-days .cal-cell-row .cal-day-cell.cal-today .cal-day-number {
    color: #DFE3E7 !important;
  }

  .omail-event-calendar {
    background-color: #0D0F10;
  }

  .omail-dark-mode .omail-event-calendar .cal-month-view .cal-cell-row:hover {
    background: transparent;
  }

  .omail-event-calendar .omail-month-view .cal-month-view .cal-days .cal-cell-row .cal-day-cell:hover {
    background-color: #232730 !important;
  }

  .omail-event-calendar .week-view-block .cal-hour:last-child {
    border-bottom: 1px solid #232730;
  }

  .omail-calendar .calendar-right .day-view-block .cal-day-view .cal-week-view .cal-time-events .cal-day-columns .cal-day-column .cal-hour:last-child {
    border-bottom: 1px solid #232730;
  }

  .omail-contacts-main-block .omail-v-tabs .omail-vtabs-sidenav .cg-list-block {
    border-top: 1px solid #1C1F27;
  }

  .omail-dark-mode .omail-mailbox-module .omail-mailbox-split-block .omailmailbox-split-right-block .omailmailbox-readmail-mainblock .omail-readmail-main .omail-read-mail-body .read-mail-body-text table div {
    color: #DFE3E7 !important;
  }

  .omail-dark-mode .omail-mailbox-module .omail-mailbox-split-block .omailmailbox-split-right-block .omailmailbox-readmail-mainblock .omail-readmail-main .omail-read-mail-body .read-mail-body-text table div span {
    color: #DFE3E7 !important;
  }

  .omail-dark-mode .omail-mailbox-module .omail-mailbox-split-block .omailmailbox-split-right-block .omailmailbox-readmail-mainblock .omail-readmail-main .omail-read-mail-body .read-mail-body-text table div p {
    color: #DFE3E7 !important;
    background-color: #0D0F10 !important;
  }

  .omail-dark-mode .omail-mailbox-module .omail-mailbox-split-block .omailmailbox-split-right-block .omailmailbox-readmail-mainblock .omail-readmail-main .omail-read-mail-body .read-mail-body-text table div pre {
    background-color: #0D0F10 !important;
    border: none !important;
  }

  .omail-dark-mode .omail-mailbox-module .omail-mailbox-split-block .omailmailbox-split-right-block .omailmailbox-readmail-mainblock .omail-readmail-main .omail-read-mail-body .read-mail-body-text table div pre code {
    background-color: #0D0F10 !important;
    color: #DFE3E7 !important;
  }

  .omail-dark-mode .omail-compose-mail-block .omailcomposemail-footer {
    background: #0D0F10;
  }

  .omail-compose-mail-block .omailcomposemail-header .omailcomposemail-headerone {
    border-color: #232730;
  }

  .mat-datepicker-content {
    background-color: #0D0F10;
    color: rgba(256, 256, 256, 1);
  }

  .mat-calendar-body-cell-content {
    border-radius: 6px;
  }

  .mat-calendar-table-header th {
    text-align: center;
    padding: 10px 0px;
  }

  .mat-calendar-table-header {
    .mat-calendar-table-header-divider {
      display: none;
    }
  }

  .calendar-left-side {
    .mat-calendar-content {
      .mat-calendar-table-header {
        th {
          padding: 0;

          span {
            color: #fff;
          }
        }
      }

      .mat-calendar-table .mat-calendar-body-cell-content {
        color: #DFE3E7;
      }
    }
  }

  .mat-calendar-body-selected {
    background: #004382;
  }

  .mat-calendar-body-cell-content {
    color: #DFE3E7;
  }

  .mat-calendar-body-disabled>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    color: rgb(223, 227, 231, 0.5);
  }

  .mat-calendar-arrow {
    fill: rgba(223, 227, 231, 0.5);
  }

  .mat-calendar-table-header,
  .mat-calendar-body-label {
    color: rgb(223, 227, 231, 1);
  }

  .mat-datepicker-toggle,
  .mat-datepicker-content .mat-calendar-next-button,
  .mat-datepicker-content .mat-calendar-previous-button {
    color: rgb(223, 227, 231, 1);
  }

  .omail-settinslistof-folders .notfolder-list {
    color: #a0a6ab;
    border-top: 1px solid #232730;
    border-bottom: 1px solid #232730;
  }

  .omail-settinslistof-folders .notfolder-list p {
    color: #a0a6ab;
  }

  .omail-contacts-main-block .contacts-right-block .contact-group-user-pic-block .contact-pic-text .show.dropdown .edit-block img {
    filter: brightness(0) invert(0);
  }

  .omailapp-settings-wrap .omail-settings-tab-content .omail-setting-form .allow-calendar {
    border-bottom: 1px solid #1c1f27;
  }

  .omail-list-group-modal .contact-search-block .oesobs-search {
    border: 1px solid #1c1f27 !important;
  }

  .omail-dark-mode tr {
    border: 1px solid #1c1f27 !important;
  }

  .oesomail-addressbook-modal .modal-content .modal-body .contact-search-block .oesobs-search {
    border: 1px solid #1c1f27 !important;
  }

  .oesomail-addressbook-modal .modal-content .modal-body .addressbook-table-fixed tr td {
    border: 1px solid #1c1f27 !important;
    background-color: #1B1E25;
    border-bottom: 1px solid #232730 !important;
    color: #DFE3E7;
  }

  .omail-mailbox-module .omail-mailbox-sidenav-block .omail-sub-sidebar .omail-sub-sidebar-layout .omail-categories-list li.activefolder-list {
    color: #029CF8;
  }

  .omail-dark-mode .omail-mailbox-module .omail-mailbox-sidenav-block .omail-sub-sidebar .omail-sub-sidebar-layout .omail-categories-list li:hover {
    background: #23272e;
  }

  .omail-calendar .omail-product-event-type .product-dropdown .dropdown-menu {
    background-color: #0D0F10;
  }

  .omail-calendar .omail-product-event-type .product-dropdown .item {
    color: #DFE3E7;
  }

  .omail-calendar .omail-product-event-type .product-dropdown .item:hover {
    background-color: #1C1F27;
  }

  .omail-calendar .omail-product-event-type .titleh5 {
    color: #A0A6AB;
  }

  .omail-mat-autocomplete {
    border: 1px solid #1B1E25 !important;
    background: #1B1E25;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  }

  .omail-mat-autocomplete .mat-option {
    background: #151920 !important;
    color: #DFE3E7 !important;
  }

  .omail-customtheam-froala-block .fr-box.fr-basic .fr-toolbar .fr-command.fr-active {
    background: #2c97d4;
  }

  .omail-more-lineksmail+* .cdk-overlay-pane .mat-menu-panel .mat-menu-content:not(:empty) button.mat-menu-item {
    background-color: #0D0F10;
    color: #DFE3E7;
  }

  .omail-more-lineksmail+* .cdk-overlay-pane .mat-menu-panel .mat-menu-content:not(:empty) button.mat-menu-item:hover {
    background-color: #1C1F27;
  }

  span.flatpickr-weekday {
    color: #DFE3E7;
  }

  .flatpickr-current-month .numInputWrapper span.arrowUp:after {
    border-bottom-color: rgba(255, 255, 255, 0.9);
  }

  .flatpickr-current-month .numInputWrapper span.arrowDown:after {
    border-top-color: rgba(255, 255, 255, 0.9);
  }

  .flatpickr-time .numInputWrapper span.arrowUp:after {
    border-bottom-color: #DFE3E7;
  }

  .flatpickr-time .numInputWrapper span.arrowDown:after {
    border-top-color: #DFE3E7;
  }

  .omail-list-of-contacts-block .contacts-rounded-block .check-img-block .contact-pic-text img {
    opacity: 0.25;
  }

  .omail-dark-mode .omail-list-of-contacts-block .contacts-rounded-block .check-img-block .contact-pic-text .cgpic-img {
    opacity: 1;
  }

  .mail-list-dark {
    opacity: 0.25;
  }

  .omail-create-folder-modal .modal-content .modal-body .create-folder-modal .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
    background-color: #1b1e25;
  }

  .omail-create-folder-modal .modal-content .modal-body .create-folder-modal .ng-dropdown-panel .ng-dropdown-panel-items .ng-option,
  .omail-create-folder-modal .modal-content .modal-body .create-folder-modal .ng-dropdown-panel.ng-select-bottom {
    border-color: #232730;
    color: #DFE3E7;
  }

  .omail-calendar .calendar-right .omail-tab-block .omail-appointment .event-block .event-form-block .omail-form-field .omail-col-one input {
    background: #0d0f10;
    color: #DFE3E7;
  }

  .fr-toolbar .fr-more-toolbar {
    background: #090909;
  }

  .fr-command.fr-btn+.fr-dropdown-menu .fr-dropdown-wrapper .fr-dropdown-content ul.fr-dropdown-list {
    background: #151920;
    color: #DFE3E7;
  }

  .fr-command.fr-btn+.fr-dropdown-menu .fr-dropdown-wrapper .fr-dropdown-content ul.fr-dropdown-list li a.fr-active {
    background: #1b1e25;
    color: #DFE3E7;
  }

  .fr-desktop .fr-command:hover:not(.fr-table-cell),
  .fr-desktop .fr-command:focus:not(.fr-table-cell),
  .fr-desktop .fr-command.fr-btn-hover:not(.fr-table-cell),
  .fr-desktop .fr-command.fr-expanded:not(.fr-table-cell) {
    background: #1b1e25;
    color: #DFE3E7;
  }

  .omail-calendar .calendar-right .omail-tab-block .omail-appointment .event-attachment-block {
    border-top: 1px solid #1c1f27;
  }

  .omail-calendar .calendar-right .omail-tab-block .omail-appointment .event-block .event-form-block hr {
    border-top: 1px solid #1c1f27;
  }

  .omail-calendar .calendar-right .omail-tab-block .omail-appointment .event-attachment-block ul li .attach-file-info {
    color: #DFE3E7;
  }

  .omail-calendar .calendar-right .omail-tab-block .omail-appointment .event-attachment-block ul li button {
    img {
      filter: invert(96%) sepia(0%) saturate(0%) hue-rotate(143deg) brightness(105%) contrast(106%);
    }
  }

  .omail-calendar .calendar-right .omail-tab-block .omail-appointment .event-attachment-block ul li .attach-icon img {
    filter: invert(96%) sepia(0%) saturate(0%) hue-rotate(143deg) brightness(105%) contrast(106%);
  }

  .omail-mailbox-module .omail-mailbox-split-block .omailmailbox-split-right-block .omailmailbox-readmail-mainblock .omail-readmail-main .omail-read-mail-body .read-mail-body-text p span {
    background-color: transparent !important;
    color: #DFE3E7 !important;
  }

  .omail-mailbox-module .omail-mailbox-split-block .omailmailbox-split-right-block .omailmailbox-readmail-mainblock .omail-readmail-main .omail-read-mail-body .read-mail-body-text p a {
    background-color: transparent !important;
  }

  .omail-replymail-froala p span {
    background-color: transparent !important;
    color: #DFE3E7 !important;
  }

  .omail-replymail-froala p a {
    background-color: transparent !important;
  }

  .omail-mailbox-module .omail-mailbox-split-block .omailmailbox-split-left-block .splitleftblock-partfour .omail-mail-listblock .omaillist-box.newMailText .omaillist-inner-two .omail-subject-name {
    color: #029CF8 !important;
  }

  .omail-mailbox-module .omail-mailbox-split-block .omailmailbox-split-left-block .splitleftblock-partfour .omail-mail-listblock .omaillist-box.newMailText .omaillist-inner-one .omail-user-name {
    color: #DFE3E7 !important;
  }

  .oesomail-addressbook-modal .modal-content .modal-body .addressbook-table-fixed thead th {
    background: #151920 !important;
  }

  .omail-customtheam-froala-block .fr-toolbar .fr-command.fr-btn.fr-open:not(:hover):not(:focus):not(:active) {
    background: #464C59;
  }

  .omail-addgroupincomp-Modal .contact-group-user-pic-block {
    background-color: #1B1E25;
    border-radius: 12px;
  }

  .omail-addgroupincomp-Modal .contact-group-user-pic-block .contact-pic-text-block .edit-block {
    background: #0D0F10;
  }

  .omail-addgroupincomp-Modal .modal-body label {
    color: #6D747A;
    font-size: 12px;
  }

  .omail-addgroupincomp-Modal .modal-body .form-control {
    background-color: #1B1E25;
    border-color: #232730;
    color: #A0A6AB;
  }

  .omail-addgroupincomp-Modal .modal-body label {
    color: #DFE3E7;
  }

  .omail-compose-group-modal .modal-body .add-book-btn-block .group-rule-info-text {
    background-color: #1C1F27;
    border-color: #151920;
    color: #EEF1F3;
  }

  .omail-addgroupincomp-Modal .email-catagory-block .email-catagory-inner {
    background-color: #1B1E25;
    border-color: #1B1E25;
  }

  .omail-addgroupincomp-Modal .email-catagory-block .email-catagory-inner .sub-heading {
    background-color: #151920;
  }

  .omail-addgroupincomp-Modal .email-catagory-block .email-catagory-inner .sub-heading h6 {
    color: #006BCE;
  }

  .oesomail-addressbook-modal .modal-content .modal-body .contact-search-block .oesobs-search .search-icon {
    background-color: #151920;
  }

  .omail-calendar .leftblockcal .d-w-m-tab .cbtn:hover {
    color: #EEF1F3;
  }

  .omail-calendar .leftblockcal .d-w-m-tab .cbtn.active {
    background-color: #004382;
    color: #EEF1F3;
  }

  .omail-contacts-main-block .omail-v-tabs .omail-vtabs-sidenav .cg-list-block li.activetab span {
    color: #029CF8;
  }

  .omail-mailbox-module .omail-mailbox-sidenav-block .omail-sub-sidebar .omail-sub-sidebar-layout .omail-categories-list .trash-inactive .omail-trash-list li.activefolder-list {
    background: #23272e;
  }

  .auto-date-icon {
    img {
      &:hover {
        filter: invert(30%) sepia(90%) saturate(4433%) hue-rotate(187deg) brightness(108%) contrast(98%);
      }
    }
  }

  .omailapp-settings-wrap .omail-settings-tab-content .auto-replie .omail-autofollowup-form .mat-button-toggle-group-appearance-standard .mat-button-toggle-button .mat-button-toggle-label-content {
    &:hover {
      color: #0288D9;
    }
  }

  .omail-autofup-froala-block {
    .fr-box.fr-basic .fr-wrapper {
      background: #1B1E25;
    }

    .fr-box .fr-second-toolbar {
      background: #1B1E25;
      border: none;
    }

    .fr-box.fr-basic .fr-wrapper {
      border: none;
    }

    .fr-box.fr-basic .fr-toolbar {
      border: none;
    }
  }

  .omail-signature-froala-block {
    .fr-box.fr-basic .fr-wrapper {
      background: #1B1E25;
    }

    .fr-box .fr-second-toolbar {
      background: #1B1E25;
      border: none;
    }

    .fr-box.fr-basic .fr-wrapper {
      border: none;
    }

    .fr-box.fr-basic .fr-toolbar {
      border: none;
    }
  }

  .omail-autoreply-froala-block {
    .fr-box.fr-basic .fr-wrapper {
      background: #1B1E25;
    }

    .fr-box .fr-second-toolbar {
      background: #1B1E25;
      border: none;
    }

    .fr-box.fr-basic .fr-wrapper {
      border: none;
    }

    .fr-box.fr-basic .fr-toolbar {
      border: none;
    }
  }

  .omail-customtheam-froala-block .fr-box.fr-basic .fr-wrapper p>* {
    background-color: transparent !important;
    color: #dfe3e7 !important;
  }

  .omail-dark-mode .fr-box.fr-basic .fr-element p span>* {
    background-color: transparent !important;
    color: #dfe3e7 !important;
  }

  .omail-dark-mode .fr-box.fr-basic .fr-element div>* {
    background-color: transparent !important;
    color: #dfe3e7 !important;
  }

  .omail-customtheam-froala-block .fr-box.fr-basic .fr-wrapper .fr-element>* {
    background-color: transparent !important;
    color: #dfe3e7 !important;
  }

  .omail-compose-mail-block .omailcomposemail-header .omailcomposemail-headertwo .omail-emailmorelist-dropdown .event-more-mail-list .omail-clip-block {
    background: #1b1e25;
  }

  .omail-dark-mode .omail-mailbox-module .omail-mailbox-split-block .omailmailbox-split-right-block .omailmailbox-readmail-mainblock .omail-readmail-main .omail-read-mail-header .omail-emailmorelist-dropdown .omail-morelist-header span {
    &:hover {
      background-color: #E84C3D;

      img {
        filter: invert(6%) sepia(8%) saturate(631%) hue-rotate(155deg) brightness(90%) contrast(100%);
      }
    }
  }

  .omail-calendar .calendar-right .omail-tab-block .omail-appointment .calemail-more-list-block .omail-emailmorelist-dropdown .omail-morelist-header {
    background: #151920;
    color: #a0a6ab;
  }

  .omail-calendar .calendar-right .omail-tab-block .omail-appointment .calemail-more-list-block .omail-emailmorelist-dropdown .omail-clip-block {
    background: #1b1e25;
  }

  .omail-calendar .calendar-right .omail-tab-block .omail-appointment .calemail-more-list-block .omail-emailmorelist-dropdown {
    background: #1b1e25;
  }

  .omailapp-settings-wrap .omail-settings-tab-content .omail-setting-form .omail-emailmorelist-dropdown .omail-clip-block {
    background: #1b1e25;
  }

  .omailmailbox-readmail-mainblock {
    .omail-pre-loader-mail-list {
      background-color: #1c1f27;
    }
  }

  .omail-readmailchain-block {
    border-left: 2px solid #7ECEFE;
    background-color: #1B1E25;
  }

  .omail-mat-autocomplete .mat-option:hover {
    background: #232730 !important;
  }

  .omail-mailbox-module .omail-mailbox-sidenav-block .omail-sub-sidebar .omail-sub-sidebar-layout .omail-categories-list-one .folder-content .folder-plush-icon {
    background-color: #232730;
  }

  .fr-view strong {
    color: #dfe3e7 !important;
  }

  .fr-view article {
    color: #dfe3e7 !important;
    background-color: transparent !important;
    background: transparent !important;
  }

  .omail-mailbox-module .omail-mailbox-split-block .omailmailbox-split-right-block .omailmailbox-readmail-mainblock .omail-readmail-main .omail-read-mail-body .read-mail-body-text p {
    background-color: transparent !important;
  }

  .flatpickr-calendar.arrowTop:before {
    border-bottom-color: #0d0f10;
  }

  .flatpickr-calendar.arrowTop:after {
    border-bottom-color: #0d0f10;
  }

  .omail-mailbox-module .omail-mailbox-split-block .omailmailbox-split-left-block .splitleftblock-partthree .read-mail-pagenation .received-block .dropdown-menu {
    background-color: #0D0F10;
  }

  .omail-mailbox-module .omail-mailbox-split-block .omailmailbox-split-left-block .splitleftblock-partthree .read-mail-pagenation .received-block .dropdown-menu .dropdown-item {
    color: #DFE3E7;
  }

  .omail-mailbox-module .omail-mailbox-split-block .omailmailbox-split-left-block .splitleftblock-partthree .read-mail-pagenation .received-block .dropdown-menu .dropdown-item:hover {
    background-color: #1C1F27;
  }

  .omail-mailbox-module .omail-mailbox-split-block .omailmailbox-split-left-block .splitleftblock-partthree .read-mail-pagenation .received-block .dropdown-menu .dropdown-item.disabled {
    color: #A3A3A3;
  }

  .omail-dark-mode .omail-settings-tab-content .omail-setting-form .timecustom-select {
    background: #0d0f10 !important;
    border: 1px solid #232730 !important;
    color: #dfe3e7 !important;
  }

  .omail-dark-mode .omail-settings-tab-content .omail-setting-form .form-control {
    background: transparent;

    option {
      background-color: #0D0F10;
    }
  }

  .omail-calendar .calendar-left .mat-calendar-content tr {
    border: 1px solid transparent !important;
  }

  .calendar-left-side {
    .daySelected td {
      background-color: #004382;

      &:first-child {
        border-radius: 4px 0 0 4px;
      }

      &:last-child {
        border-radius: 0 4px 4px 0;
      }
    }

    .mat-calendar-content .mat-calendar-table .daySelected .mat-calendar-body-cell-content {
      color: #DFE3E7;
    }

  }

  .omail-calendar .calendar-left-side .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: #006BCE !important;
    border-color: transparent;
  }

  .calendar-left-side .daySelected td {
    border-top: transparent;
  }

  .omail-calendar .calendar-left-side .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    color: #006BCE;
  }

  .omail-calendar .calendar-left-side .mat-calendar-body-active {
    &:hover {
      .mat-calendar-body-today {
        color: #DFE3E7 !important;
      }
    }
  }

  .calendar-left-side {
    .calendar-left {
      .mat-calendar {
        .mat-calendar-header {
          background-color: #232730;
          border-radius: 3px;

          button {
            color: #DFE3E7;
          }
        }
      }
    }
  }

  .omail-calendar .mat-calendar .mat-calendar-header .mat-calendar-controls svg {
    fill: #DFE3E7;
  }

  .calendar-left-side .daySelected td {
    border-top: inherit !important;
  }

  .omail-dark-mode .calendar-left-side .daySelected td button {
    border: 1px solid #029CF8;
    border-right: 0;
    border-left: 0;
  }

  .omail-dark-mode .calendar-left-side .daySelected td {
    &:first-child {
      button {
        border-left: 1px solid #029CF8;
        border-radius: 4px 0 0 4px;
      }
    }

    &:last-child {
      button {
        border-right: 1px solid #029CF8;
        border-radius: 0 4px 4px 0;
      }
    }
  }

  .omail-calendar .calendar-left-side .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: #232730;
  }

  .omail-calendar .calendar-left-side .mat-calendar-body-selected {
    background-color: #232730;
    color: #006BCE !important;
  }

  .omail-calendar .calendar-left-side .daySelected {
    .mat-calendar-body-selected {
      background-color: #006BCE !important;
      color: #DFE3E7 !important;
    }
  }

  .omail-selected-list-block .selected-list-inner .omail-selected-list-main {
    background: url(../../../assets/omail/images/contacts/selected-listcontacts-icon_dark.svg) no-repeat center center;
  }

  .omail-selected-maillist-main {
    background: url(../../../assets/omail/images/mailbox/mail-circledark-icon.svg) no-repeat center center;
  }

  .omail-selected-list-block .selected-list-inner h5 {
    color: #EEF1F3;
  }

  .omail-selected-list-block .selected-list-inner button {
    color: #DFE3E7;
  }

  .omail-c-trash {
    background: url(../images/obsomailcontacts/newcon/Darkmode_del-empty-contacts.svg) no-repeat center center;
  }

  .omail-c-gtrash {
    background: url(../images/obsomailcontacts/newcon/Darkmode_del-empty-group.svg) no-repeat center center;
  }

  .omail-c-fav {
    background: url(../images/obsomailcontacts/newcon/Darkmode-empty-favorites.svg) no-repeat center center;
  }

  .omail-selected-list-block .selected-list-inner .omail-selected-list-main.omail-mulselected-group {
    background: url(../images/obsomailcontacts/newcon/Darkmode_del-empty-group.svg) no-repeat center center;
  }

  .omail-selected-list-block {
    background: #101214;
  }

  .omailapp-settings-wrap .omail-settings-tab-content .oesuser-profile-block .show-pic .omail-settings-profile-pic {
    background: url(../images/obssettings/darkmode_SettingsProfilepic.svg) no-repeat center center;
  }

  .omail-date-autofup {
    border-bottom: 1px solid #1c1f27;
  }

  .omailapp-settings-wrap .omail-settings-tab-content .omail-setting-form .omail-mat-input .mat-form-field-outline {
    color: #151920;
  }

  .omail-date-autofup .timeselect-autore {
    border-color: #151920 !important;
  }

  .omail-drag-selected-mails {
    background-color: #232730;
    border-color: #6D747A;
    color: #EEF1F3;
  }

  .omail-addgroupincomp-Modal {
    .modal-content {
      .modal-body {
        background: #232730;
      }
    }
  }

  .omail-contacts-main-block .contacts-right-block .contact-group-user-pic-block .contact-pic-text {
    background: url(../images/obsomailcontacts/newcon/Darkmode_del-empty-contacts.svg) no-repeat center center;
  }

  .omail-addgroupincomp-Modal .contact-group-user-pic-block .contact-pic-text {
    background: url(../images/obsomailcontacts/newcon/Darkmode_del-empty-contacts.svg) no-repeat center center;
  }

  .omail-contactsgroup-sendmail-loader {
    background-color: #1B1E25;
  }

  .flatpickr-day.today,
  .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
  .mat-calendar-body-today.mat-calendar-body-selected {
    color: #DFE3E7;
    border-color: #004382;
    background-color: #004382;
  }

  .flatpickr-day:hover {
    background: #DFF3FF;
    border-color: #DFF3FF;
    border-radius: 6px;
  }

  .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    color: #000000;
  }

  .omail-listofmailids-count-block {
    background-color: #1b1e25;
  }

  .omail-inputtimer-field .omail-input-time {
    border: 1px solid #1c1f27;
  }

  .omail-inputtimer-field .omail-timeicon-list {
    background-color: #0D0F10;
    border: 1px solid #232730;
    box-shadow: none;
  }

  .omail-inputtimer-field .omail-timeicon-list li span {
    color: #DFE3E7;
  }

  .omail-inputtimer-field .omail-timeicon-list li:hover {
    background-color: #232730;
  }

  .omail-calendar .calendar-right .omail-tab-block .omail-appointment .event-block .event-form-block .event-info-textarea .omail-customtheam-froala-block .fr-second-toolbar {
    background-color: #0D0F10;
  }

  .omail-mailbox-module .omail-mailbox-split-block .omailmailbox-split-right-block .omailmailbox-readmail-mainblock .omail-readmail-main .omail-read-mail-header .omail-read-mail-headerone .headerone-rigth-block button {
    color: #006BCE;

    &:hover {
      background-color: transparent !important;
      color: #0288D9;
    }
  }

  .omail-more-lineksmail+* .cdk-overlay-pane hr {
    color: #1C1F27;
    opacity: 1;
  }

  .omail-listofmailids-count-block .omail-listofmailids-count-inner {
    border: 1px solid #232730;
    background: #151920;
    color: #CB6B1A;
  }

  .omail-timeicon-list {
    position: relative !important;
    left: -81px;
    border: 1px solid #232730;
    background: #0D0F10;
    padding: 8px;
    max-height: 289px;
    min-height: 289px;
    overflow-y: auto;
    min-width: 99px;
    box-shadow: none;

    &::-webkit-scrollbar {
      width: 4px !important;
    }

    &::-webkit-scrollbar-thumb {
      background: #DFF3FF;
      box-sizing: border-box;
      box-shadow: inset 0px 10px 10px rgba(255, 255, 255, 0.25);
      border-radius: 7px;
    }

    .dropdown-item {
      cursor: pointer;

      &:hover {
        background-color: #151920;
      }

      span {
        color: #DFE3E7;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
      }
    }
  }

  .omail-app-header .omail-modules-link .omail-contact-icon img {
    filter: invert(1) sepia(9%) saturate(2101%) hue-rotate(179deg) brightness(92%) contrast(92%);
  }

  .omail-app-header .omail-modules-link .omail-user-hover {
    opacity: 0;
  }

  .omail-app-header .omail-modules-link ul li:nth-child(3):hover .omail-contact-icon .omail-user-hover {
    opacity: 1;
  }

  .omail-app-header .omail-modules-link ul li:nth-child(3).active:hover img {
    filter: invert(1);
  }

  .omail-app-header .omail-modules-link ul li:hover {
    border-bottom: 3px solid #004382;
  }

  .omail-app-header .omail-modules-link ul li.active:hover {
    border-bottom: 3px solid #006BCE;
  }

  .omail-mailbox-module .omail-mailbox-split-block .omailmailbox-split-right-block .omailmailbox-readmail-mainblock .omail-readmail-main .omail-read-mail-header .omail-read-mail-headerfour {
    background-color: #1C1F27;
  }

  .omail-dark-mode .omail-mailbox-module .omail-mailbox-split-block .omailmailbox-split-right-block .omailmailbox-readmail-mainblock .omail-readmail-main .omail-read-mail-header .omail-read-mail-headerfour .read-mail-scheduled-text {
    color: #CB6B1A;
  }
}
