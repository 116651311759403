:root,
[data-otn-theme='light'] {
    --otrim-white: #fff;
    --otrim-filter: invert(0);
    --otrim-filter2: invert(0) brightness(1) saturate(1);
    --otrim-text-color: #384455;
    --otrim-primary-color: #084753;
    --otrim-text-link: #133A92;
    --otrim-btnoutline-bg: transparent;
    --otrim-btn-bg: linear-gradient(290.98deg, #007289 27.72%, #123A92 85.16%);
    --otrim-hover-btn-bg: linear-gradient(290.98deg, #007289 27.72%, #123A92 85.16%);
    --otrim-border-color: #084753;
    --otrim-border: #E4E2E8;
    --otrim-border2: #f4f4f4;
    --otrim-title3: #06152B;

    --otrim-body-bg: #E4ECF9;
    --otrim-obs-sidemenu-logo: #f9f9f9;
    --otrim-obs-sidemenu-bg: #f0f0f0;
    --otrim-sidemenu-bg: #f1f1f1;
    --otrim-sidemenulist-bg: #fff;
    --otrim-sidemenulist-color: #1F4393;
    --otrim-header-bg: linear-gradient(90deg, #FFFFFF 0%, #015391 49%, #FFFFFF 100%);

    --otrim-breadcrumb-bg: #fff;
    --otrim-breadcrumb-item: #4236f19f;
    --otrim-breadcrumb-item-active: #4136F1;

    --otrim-innercard-bg: #F7F7F7;
    --otrim-innercard-header-bg: linear-gradient(290.98deg, #007289 27.72%, #123A92 85.16%);
    --otrim-card-bg: #fff;
    --otrim-card-border: #f4f4f4;
    --otrim-created-link-card: #F7F9FF;
    --otrim-card2: #BBDBE3;
    --otrim-card2-border: #82C7D8;

    --otrim-status-link1: #133A92;
    --otrim-status-bg1: rgba(19, 56, 144, 0.1);
    --otrim-status-link2: #01718A;
    --otrim-status-bg2: rgba(1, 113, 138, 0.1);
    --otrim-status-link3: #13572A;
    --otrim-status-bg3: rgba(19, 87, 42, 0.1);

    --otrim-input-bg: #fff;
    --otrim-inputsearch-bg: #e9ecef;
    --otrim-label: #313131;
    --otrim-input-border: #8FA1C9;
    --otrim-input-check-bgcolor: #1DA1F2;
    --otrim-placeholder-color: #616F81;
    --otrim-input-color: #161819;
    --otrim-input-btn-disabled-bg: #efefef;

    --otrim-table-bg: #ffffff;
    --otrim-table-head-bg: linear-gradient(290.98deg, #007289 27.72%, #123A92 85.16%);
    --otrim-table-text-color: #173545;
    --otrim-table-border: #D8E8FF;
    --otrim-table-odd: #fff;
    --otrim-table-even: #F7F9FF;
    --otrim-table-footer-bg: rgba(28, 160, 242, 0.12);

    --otrim-tooltip-bg: #DEE8FF;
    --otrim-tooltip-border: #1F4393;
    --otrim-tooltip-color: #1F4393;

    --otrim-themebtn-bg: #ffffff;
    --otrim-themebtn-color: #133A92;
}

[data-otn-theme='dark'] {
    --otrim-text-link: #578FFF;
    --otrim-text-color: #fff;
    --otrim-filter: invert(1);
    --otrim-filter2: invert(1) brightness(2) saturate(0);
    --otrim-btnoutline-bg: #161819;
    --otrim-border-color: #084753;
    --otrim-border: #4F608459;
    --otrim-border2: #4F608459;
    --otrim-title3: #fff;

    --otrim-body-bg: #161819;
    --otrim-obs-sidemenu-logo: #242529;
    --otrim-obs-sidemenu-bg: #161819;
    --otrim-sidemenu-bg: #242529;
    --otrim-sidemenulist-bg: #161819;
    --otrim-sidemenulist-color: #fff;
    --otrim-header-bg: #161819;

    --otrim-breadcrumb-bg: #242529;
    --otrim-breadcrumb-item: #EBEAFF;
    --otrim-breadcrumb-item-active: #fff;

    --otrim-innercard-header-bg: #313745;
    --otrim-innercard-bg: #161819;
    --otrim-card-bg: #242529;
    --otrim-card-border: #4F608459;
    --otrim-created-link-card: #161819;
    --otrim-card2: #161819;
    --otrim-card2-border: #4F608459;

    --otrim-status-link1: #fff;
    --otrim-status-bg1: #133890;
    --otrim-status-link2: #fff;
    --otrim-status-bg2: #01718a;
    --otrim-status-link3: #fff;
    --otrim-status-bg3: #13572a;

    --otrim-input-bg: #161819;
    --otrim-inputsearch-bg: #161819;
    --otrim-label: #f1f1f1;
    --otrim-input-border: #4F608459;
    --otrim-input-check-bgcolor: #1DA1F2;
    --otrim-placeholder-color: #616F81;
    --otrim-input-color: #d4d4d4;
    --otrim-input-btn-disabled-bg: #1a2744;

    --otrim-table-bg: #161819;
    // --otrim-table-bg2: #0a162fe5;
    --otrim-table-head-bg: #4F60844D;
    --otrim-table-text-color: #e4e4e4;
    --otrim-table-border: #4F608459;
    --otrim-table-odd: #161819;
    --otrim-table-even: #242529;
    --otrim-table-footer-bg: #4F6084;

    --otrim-tooltip-bg: #242529;
    --otrim-tooltip-border: #4F608459;
    --otrim-tooltip-color: #fff;

    --otrim-themebtn-bg: linear-gradient(290.98deg, #007289 27.72%, #123A92 85.16%);
    --otrim-themebtn-color: #ffffff;
}

html[data-otn-theme] {
    body {
        background-color: var(--otrim-body-bg) !important;

        .main-layout {
            header {
                background: var(--otrim-header-bg);
            }

            .obs-main-layout .obs-sidebar {
                background: var(--otrim-obs-sidemenu-bg);
            }

            .obs-main-content.mobileMenuOpen,
            .obs-main-layout .obs-main-content,
            .obs-main-layout .obs-sidebar {

                .obs-menus {

                    .sidebar-products-list li,
                    .sidebar-products-list-fixed li {
                        &:not(:last-child) {
                            margin-bottom: 1px;
                        }

                        a {
                            background: var(--otrim-sidemenulist-bg);
                            border-bottom: 1px solid var(--otrim-border);
                            margin-right: 0;
                            border-right: 0;

                            @media only screen and (max-width: 767px) {
                                padding-top: 10px;
                            }

                            .title {
                                color: var(--otrim-text-color);
                            }

                            &.active,
                            &:hover {
                                background: var(--otrim-hover-btn-bg);

                                .icon {
                                    background: var(--otrim-white) !important;
                                }

                                .title {
                                    color: var(--otrim-white);
                                }
                            }

                            .icon {
                                width: 48px;
                                height: 48px;
                                border-radius: 6px;
                                margin-bottom: 0;
                                background: var(--otrim-card-bg);
                                border-color: var(--otrim-border2);
                            }

                            .hover-title {
                                top: 22px;
                            }

                            &.active .logopimg {
                                filter: none;
                            }
                        }
                    }
                }
            }

            .header-right {
                .credits-field .credits-content {
                    small {
                        color: var(--otrim-text-color) !important;
                    }
                }

                .widget-fields li,
                .cart-profile,
                .custom-dropdown.dropdown-menu {
                    background: var(--otrim-card-bg) !important;
                    color: var(--otrim-text-color);

                    .dropdown-item {
                        color: var(--otrim-text-color);

                        img {
                            filter: var(--otrim-filter);
                        }

                        &:hover,
                        &:focus,
                        &:active {
                            color: var(--otrim-text-color);
                            background: var(--otrim-btn-bg);
                        }
                    }
                }
            }
        }
    }
}

.otrim-cursior-pointer {
    cursor: pointer;
}

.border,
.border-top,
.border-right,
.border-bottom,
.border-left {
    border-color: var(--otrim-border) !important;
}

.vr {
    background-color: var(--otrim-border);
    opacity: 1;
}

small,
.small {
    font-size: 0.75em !important;
}

@media (min-width: 768px) {
  .position-md-absolute {
    position: absolute;
  }  
}
.form-help {
    display: block;
    line-height: 1;
    margin-top: 5px;
}

/* BREADCRUMB */
.otrim-text-link {
    color: var(--otrim-text-link);
}

.otrim-font-family-poppins {
    font-family: 'poppins', sans-serif !important;
}

.otrim-font-family-cursive {
    font-family: cursive, sans-serif !important;
}

/* OTRIM BUTTONS */
.btn {
    // transition: 0.1s linear ease-in-out;

    &.btn-otrim-theme {
        font-size: 14px;
        cursor: pointer;
        color: var(--otrim-white);
        border-radius: 10px;
        padding: 0.565rem 1rem;
        background: var(--otrim-btn-bg);
        box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 8%);
        border: none;
        background-size: 150% 100%;
        transition: .2s ease-in-out;

        &:hover,
        &:focus,
        &.active {
            color: var(--otrim-white);
            background-color: transparent;
            box-shadow: 0 0.25rem 0.35rem rgba(0, 0, 0, 18%);
            background-position: 100% 50%;
        }

        &:not(:first-child) {
            margin-left: 0.65rem;
        }
    }

    &.btn-otrim-theme-outline {
        font-size: 14px;
        cursor: pointer;
        border-radius: 10px;
        padding: 0.45rem 1rem;
        color: var(--otrim-text-color);
        background: var(--otrim-btnoutline-bg);
        border: 1px solid var(--otrim-border-color);
        background-size: 150% 100%;
        transition: .2s ease-in-out;

        &:hover,
        &:focus,
        &.active {
            color: var(--otrim-white);
            background-color: transparent;
            background: var(--otrim-btn-bg);
            border: 1px solid transparent;
            background-position: 100% 50%;
        }

        &:not(:first-child) {
            margin-left: 0.65rem;
        }
    }
}

.ngb-dp-weekday {
    color: #0d6efd !important;
    font-style: normal !important;
}

/* RANGEDATEPICKER */
.custom-day {
    text-align: center;
    padding: 0.185rem 0.25rem;
    display: inline-block;
    height: 2rem;
    width: 2rem;
}

.custom-day.focused {
    background-color: #e6e6e6;
}

.custom-day.range,
.custom-day:hover {
    background-color: rgb(2, 117, 216);
    color: white;
}

.custom-day.faded {
    background-color: rgba(2, 117, 216, 0.5);
}

.otrim_tooltip.tooltip {
    opacity: 1;
    z-index: 1090;

    .tooltip-inner {
        font-size: var(--clamp14);
        font-weight: 600;
        color: var(--otrim-tooltip-color);
        background: var(--otrim-tooltip-bg) !important;
        border: 1px solid var(--otrim-tooltip-border) !important;
        max-width: 250px;

        .share-list {
            display: flex;
            align-items: center;
            justify-content: space-between;

            a {
                color: #1F4393 !important;
                display: flex;

                &:not(:last-child) {
                    margin-right: 0.02rem;
                }
            }
        }
    }

    &.bs-tooltip-top .tooltip-arrow,
    .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow {
        &::before {
            width: 1px;
            content: "";
            z-index: -1;
            border-top-color: var(--otrim-tooltip-border);
            border-bottom: none;
        }

        &::after {
            box-sizing: content-box;
            position: absolute;
            border: 6px solid transparent;
            height: 0;
            width: 1px;
            content: "";
            z-index: -1;
            border-width: 6px;
            bottom: 9px;
            top: -2px;
            border-top-color: var(--otrim-tooltip-bg);
        }
    }


    &.bs-tooltip-end .tooltip-arrow,
    .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow {
        &::before {
            width: 1px;
            content: "";
            z-index: -1;
            border-right-color: var(--otrim-tooltip-border);
            border-left: none;
        }

        &::after {
            box-sizing: content-box;
            position: absolute;
            border: 6px solid transparent;
            height: 0;
            width: 1px;
            content: "";
            z-index: -1;
            border-width: 6px;
            // left: 9px;
            right: -2px;
            border-right-color: var(--otrim-tooltip-bg);
        }
    }

    &.bs-tooltip-bottom .tooltip-arrow,
    .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow {
        &::before {
            width: 1px;
            content: "";
            z-index: -1;
            border-bottom-color: var(--otrim-tooltip-border);
            border-top: none;
        }

        &::after {
            box-sizing: content-box;
            position: absolute;
            border: 6px solid transparent;
            height: 0;
            width: 1px;
            content: "";
            z-index: -1;
            border-width: 6px;
            // top: 9px;
            bottom: -2px;
            border-bottom-color: var(--otrim-tooltip-bg);
        }
    }

    &.bs-tooltip-start .tooltip-arrow,
    .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow {
        &::before {
            width: 1px;
            content: "";
            z-index: -1;
            border-left-color: var(--otrim-tooltip-border);
            border-right: none;
        }

        &::after {
            box-sizing: content-box;
            position: absolute;
            border: 6px solid transparent;
            height: 0;
            width: 1px;
            content: "";
            z-index: -1;
            border-width: 6px;
            right: 9px;
            left: -2px;
            border-left-color: var(--otrim-tooltip-bg);
        }
    }
}

.otrima_modal {
    z-index: 9999 !important;

    .otrim_tooltip.tooltip {
        opacity: 1;
        z-index: 9999;

        .tooltip-inner {
            font-size: var(--clamp14);
            font-weight: 600;
            color: var(--otrim-tooltip-color);
            background: var(--otrim-tooltip-bg) !important;
            border: 1px solid var(--otrim-tooltip-border) !important;
            max-width: 250px;
        }
    }

    &.maxWidthNone {
        max-width: none;
    }

    .modal-content {
        position: relative;
        border-radius: 1rem;
        color: var(--otrim-text-color);
        background-color: var(--otrim-breadcrumb-bg);
        border: none;
        z-index: 999;

        .modal-header {
            color: var(--otrim-white);
            background: var(--otrim-table-head-bg);
            border-color: var(--otrim-border2);
            border-top-left-radius: 1rem;
            border-top-right-radius: 1rem;

            .btn-close {
                background-image: none;
                display: flex;
                align-items: center;
                color: var(--otrim-white);

                i.far.fa-times {
                    color: var(--otrim-white);
                }
            }
        }

        i.far.fa-times {
            color: var(--otrim-text-color);
        }

        *>.table-responsive {
            border-radius: 0.75rem;
            border: 1px solid var(--otrim-table-border);
            scrollbar-width: auto !important;

            &::-webkit-scrollbar-thumb {
                background: #AEC3E4 !important;
                border-radius: 5px;
                width: 5px !important;
            }

            &::scrollbar-thumb {
                background: #AEC3E4 !important;
                border-radius: 5px;
                width: 5px !important;
            }

            &::-webkit-scrollbar {
                width: 5px !important;
                height: 5px;
            }
        }

        .table {
            vertical-align: baseline;
            border-collapse: separate;
            border-spacing: 0px;
            width: 100%;
            border-radius: 1rem;
            min-width: 100%;
            margin-bottom: 0;
            background: var(--otrim-table-bg);

            thead {
                display: table;
                width: 100%;
                table-layout: fixed;
                vertical-align: middle;
                background: var(--otrim-table-head-bg);
                z-index: 1;

                tr {
                    display: table;
                    width: 100%;
                    table-layout: fixed;
                    color: var(--otrim-white);
                    border-radius: 12px 12px 0 0;

                    th {
                        height: 3.5rem;
                        font-weight: 600;
                        font-size: 14px;
                        color: var(--otrim-white);
                        background: transparent;
                        border: none;

                        &:first-child {
                            border-top-left-radius: 12px;
                            padding-left: 20px;
                        }

                        &:last-child {
                            border-top-right-radius: 12px;
                            padding-right: 20px;
                        }
                    }

                    &.space {
                        width: 1px;
                        height: 1px;
                        display: block;
                        margin-left: calc(20.3333px) !important;
                        margin-top: calc(7.53333px) !important;
                    }
                }
            }

            tbody {
                display: block;
                max-height: 350px;
                border: none;
                overflow: hidden overlay;
                box-shadow: 4px 4px 10px rgba(67, 127, 236, 0.1);

                &::-webkit-scrollbar-thumb {
                    background: #E4ECF9 !important;
                    border-radius: 5px;
                    width: 5px !important;
                }

                &::scrollbar-thumb {
                    background: #E4ECF9 !important;
                    border-radius: 5px;
                    width: 5px !important;
                }

                &::-webkit-scrollbar {
                    width: 5px !important;
                    height: 5px;
                }

                tr {
                    display: table;
                    width: 100%;
                    table-layout: fixed;

                    td {
                        border: none;
                        color: var(--otrim-text-color);
                        padding: 1rem 0.65rem;
                        font-size: 14px;
                        position: relative;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        cursor: default;
                        background-color: transparent;

                        &:first-child {
                            border-top-left-radius: 12px;
                            border-bottom-left-radius: 12px;
                            padding-left: 22px;
                        }

                        &:last-child {
                            border-top-right-radius: 12px;
                            border-bottom-right-radius: 12px;
                            padding-right: 22px;
                        }

                        .action-list {
                            display: flex;
                            align-items: center;

                            a {
                                color: #1F4393;

                                &:not(:last-child) {
                                    margin-right: 0.65rem;
                                }
                            }
                        }
                    }

                    &:nth-of-type(odd) {
                        background: var(--otrim-table-odd);
                    }

                    &:nth-of-type(even) {
                        background: var(--otrim-table-even);
                    }
                }
            }
        }

        .o-trim_pagination .pagination {
            .page-item {

                .page-link {
                    color: var(--otrim-text-color);
                    background-color: var(--otrim-input-bg);
                    border-color: var(--otrim-border-color);
                }

                &.disabled {
                    .page-link {
                        cursor: none;
                    }

                    &:hover .page-link,
                    &.active .page-link {
                        background: linear-gradient(290.98deg, #00728983 27.72%, #123b928e 85.16%);
                        border-color: #00728970;
                    }
                }

                &:hover .page-link,
                &.active .page-link {
                    color: #fff;
                    background: linear-gradient(290.98deg, #007289 27.72%, #123A92 85.16%);
                    border-color: #007289;
                }
            }
        }

        .form-control {
            font-size: var(--clamp14);
            height: clamp(40px, 2.032vw, 40px);
            border-radius: 10px !important;
            color: var(--otrim-input-color) !important;
            border-color: var(--otrim-input-border) !important;
            background: var(--otrim-input-bg) !important;

            &.otrim-border-theme {
                border: 1px solid var(--otrim-input-border);
            }

            &::placeholder {
                color: var(--otrim-placeholder-color) !important;
                opacity: 1;
            }

            &::-ms-input-placeholder {
                color: var(--otrim-placeholder-color) !important;
            }

            &:-ms-input-placeholder {
                color: var(--otrim-placeholder-color) !important;
            }

            &::-webkit-calendar-picker-indicator {
                filter: invert(0.5);
            }

            &:focus,
            &:focus-visible {
                outline: none;
                box-shadow: 0 0 0 0.1rem #8743ff33;
            }
        }
    }
}

@media (min-width: 1200px) and (max-width: 1400px) {
    .otrim_daterange_picker .input-group.rightALign .md-drppicker.shown.double {
        right: -185px !important;
    }
}

@media (min-width: 1440px) and (max-width: 1600px) {
    .otrim_daterange_picker .input-group.rightALign .md-drppicker.shown.double {
        right: -160px !important;
    }
}

.otrim_daterange_picker {
    .custom-filter {
        max-width: 100%;

        .form-control {
            font-size: var(--clamp14);
            height: clamp(40px, 2.032vw, 40px);
            border-radius: 10px !important;
            color: var(--otrim-input-color) !important;
            border-color: var(--otrim-input-border) !important;
            background: var(--otrim-input-bg) !important;

            &.otrim-border-theme {
                border: 1px solid var(--otrim-input-border);
            }

            &::placeholder {
                color: var(--otrim-placeholder-color) !important;
                opacity: 1;
            }

            &::-ms-input-placeholder {
                color: var(--otrim-placeholder-color) !important;
            }

            &:-ms-input-placeholder {
                color: var(--otrim-placeholder-color) !important;
            }

            &::-webkit-calendar-picker-indicator {
                filter: invert(0.5);
            }

            &:focus,
            &:focus-visible {
                outline: none;
                box-shadow: 0 0 0 0.1rem #8743ff33;
            }
        }

        .right-icon {
            color: #1F4393;
            height: 30px;
            width: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0.45rem;
            z-index: 9;
            background: none;
            box-shadow: none;
            border: transparent;
            border-radius: 10px !important;
            transition: all 0.1s ease;
        }

    }

    .input-group.left_Align .md-drppicker.shown.double {
        left: 0 !important;
        right: auto !important;
    }

    .input-group .md-drppicker {
        top: 46px !important;
        left: auto !important;
        right: 0 !important;
        background-color: var(--otrim-card-bg);

        &.shown.double {
            top: 48px !important;
            padding: 6px 6px 60px 6px !important;
        }

        .ranges ul li {

            &:hover button,
            button.active {
                color: #fff !important;
                background: linear-gradient(290.98deg, #007289 27.72%, #123A92 85.16%);
                border-radius: 12px;
            }

            &:hover {
                border-radius: 1rem;
            }
        }

        th {
            color: #1F4393;
            text-transform: capitalize;
        }

        td.active {
            color: #fff !important;
            background: linear-gradient(290.98deg, #007289 27.72%, #123A92 85.16%);
        }

        td.off {
            color: #1F4393;
        }

        .buttons_input .btn {
            font-size: 14px;
            text-transform: capitalize;
            border-radius: 10px;
            padding: 0.15rem 1rem;

            &:first-child {
                color: #084753;
                background: transparent;
                border: 1px solid #084753;

                svg {
                    color: #084753;
                }

                &:hover,
                &:focus,
                &.active {
                    color: #fff;
                    background-color: transparent;
                    background: linear-gradient(290.98deg, #123A92 27.72%, #007289 85.16%);
                    border: 1px solid #084753;

                    svg {
                        color: #fff;
                    }
                }
            }

            &:last-child {
                color: #fff;
                background: linear-gradient(290.98deg, #007289 27.72%, #123A92 85.16%);
                box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 8%);
                border: 1px solid transparent;
                transition: all 0.1s ease;

                &:hover,
                &:focus,
                &.active {
                    color: #fff;
                    background-color: transparent;
                    background: linear-gradient(290.98deg, #123A92 27.72%, #007289 85.16%);
                    box-shadow: 0 0.25rem 0.35rem rgba(0, 0, 0, 18%);
                }
            }
        }
    }

    .md-drppicker td.available:hover,
    .md-drppicker th.available:hover {
        color: var(--otrim-white) !important;
        background: var(--otrim-hover-btn-bg);
        border-color: transparent;
        border-radius: 6px;
    }

    .md-drppicker td.in-range.available {
        color: var(--otrim-white) !important;
        background: var(--otrim-hover-btn-bg);
        border-color: transparent;
    }

    .md-drppicker td.off {
        color: var(--otrim-text-color) !important;
        background-color: var(--otrim-border);
    }

    .md-drppicker option.disabled,
    .md-drppicker td {
        opacity: 1;

        &.disabled {
            text-decoration: none !important;
        }
    }

    .md-drppicker .calendar-table {
        background-color: var(--otrim-card-bg);
        border: 1px solid var(--otrim-border)
    }

    .md-drppicker .calendar {

        td,
        th {
            min-width: 25px;
            font-size: 14px;
        }

        td.available.off {
            color: var(--otrim-text-link);
        }

        td.available {
            color: var(--otrim-text-color);
        }
    }

    @media (min-width: 992px) {
        .md-drppicker.double {
            width: auto;
        }
    }
}

.custom-dropdown-ng-select {
    .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
        &.ng-option-marked {
            color: #fff;
            background: #1F4393;
        }
    }

    .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected,
    .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked {
        color: #fff;
        background: #2757be;
    }
}

.custom-ng-select {
    .ng-dropdown-panel.ng-select-multiple {
        .ng-dropdown-panel-items .ng-option {
            &.ng-option-marked {
                color: #fff;
                background: #1F4393;
            }
        }
    }
}

.introjs-tooltip.otrim_tour_guide {
    background-color: transparent;
    box-shadow: none;
    margin-bottom: 2rem;

    .introjs-tooltiptext {
        color: #fff;
        font-size: var(--clamp16);
        text-shadow: 2px 2px 6px #000;
        font-family: cursive;
        padding: 15px 0px;
        line-height: 28px;

        img.bottom {
            position: absolute;
            top: -64px;
            right: 0px;
            transform: rotateY(0deg) rotateX(30deg);
            color: var(--otrim-text-link);
        }
    }

    &.introjs-top-middle-aligned,
    &.introjs-top-left-aligned {
        img.bottom {
            position: absolute;
            top: auto;
            bottom: -100px;
            transform: rotateY(30deg) rotateX(0deg);
        }
    }

    .introjs-tooltipbuttons {
        border-top: none;
        padding: 0 0 10px;
        display: flex;
        gap: 0.85rem;

        .introjs-button {
            color: #fff;
            border-radius: 10px;
            padding: 0.45rem 1rem;
            background: linear-gradient(290.98deg, #007289 27.72%, #123A92 85.16%);
            box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 8%);
            border: none;
            transition: all 0.1s ease;

            &:hover,
            &:focus,
            &.active {
                color: #fff;
                background-color: transparent;
                background: linear-gradient(290.98deg, #123A92 27.72%, #007289 85.16%);
                box-shadow: 0 0.25rem 0.35rem rgba(0, 0, 0, 18%);
            }
        }
    }

    .introjs-skipbutton {
        left: 0;

        span {
            color: var(--white-color);
            font-size: var(--clamp16);
        }
    }

    .introjs-arrow {
        display: none !important;
    }
}

.otrim_heighlight_box {
    background: none;
    backdrop-filter: blur(6px);
    box-shadow: 0px 0px 0px 0px, rgb(33 33 33 / 80%) 0px 0px 0px 5000px !important;
}