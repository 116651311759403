:root {
    --onet-dark-bg-primary: #081827;
    --onet-dark-bg-secondary: #122333;
    --onet-dark-bg-hovered: #182b3c;
    --onet-dark-border-color: #243C51;
    --onet-primary-color: #034C53;
    --onet-secondary-color: #f3f3f3;
    --onet-label-color: #1e2730;
    --onet-text-dark-color: #5E989D;
    --onet-text-primary-color: #ffffff;
    --onet-text-secondary-color: #729B9F;
    --onet-black-color: #000000;
    --onet-white-color: #ffffff;
    --onet-btn-color: #dedede;
    --onet-btn-secondary-color: #345763;
    --onet-blue-color: #006FFF;
    --onet-light-blue: #ceddc9;
    --onet-light-green: #D8FCD8;
    --onet-banner-green: #E8FAEB;
    --onet-grey-color: #21c93842;
    --onet-link-color: #FF5D5D;
    --onet-darkgrey-color: #047a14;
    --onet-lightgrey-color: #21c93821;
    --onet-font-family: font-family: 'Poppins', sans-serif !important;
}

.onet-dark-mode {
    background: var(--onet-dark-bg-primary) !important;
    background-color: var(--onet-dark-bg-primary) !important;

    .logo,
    #affiliate-id {
        pointer-events: none !important;
    }

    ////////////// side border css ////////////
    .main-layout .obs-main-layout .obs-sidebar,
    .sidebar-products-list li a,
    .sidebar-products-list-fixed li a {
        background: #0f1722 !important;
        border-bottom: 1px solid #1a2b3a !important;

    }

    .main-layout .obs-main-layout .obs-sidebar .sidebar-products-list li:not(:last-child),
    .main-layout .obs-main-layout .obs-sidebar .sidebar-products-list-fixed li:not(:last-child) {
        border-bottom: 1px #1a2b3a solid !important;
    }

    .main-layout>header {
        background: #0f1722 !important;
        box-shadow: 0px 1px 3px #5038f41a !important;
    }

    .main-layout .obs-main-layout .obs-main-content .sub-sidebar .mobile-main-menu {
        background: #0f1722 !important;
    }

    .sidebar-products-list li a.active,
    .sidebar-products-list-fixed li a.active {
        background: var(--onet-dark-bg-secondary) !important;
        box-shadow: none !important;
    }

    ul.widget-fields {
        border-bottom: transparent !important;

        li {
            border: 0px solid transparent !important;

            .list-btn {
                background-color: #171f33 !important;
            }
        }
    }

    .sidebar-products-list li a .title,
    .sidebar-products-list-fixed li a .title {
        color: var(--white-color) !important;
    }

    .widget-fields li,
    .cart-profile,
    .custom-dropdown.dropdown-menu {
        background: #0a0e19 !important;
    }

    .goog-te-gadget-simple {
        background-color: #0a0e19 !important;
    }

    ul.widget-fields {
        li.credits-field .credits-content-right {
            small {
                color: #c5c6c7 !important;
            }

            h5 {
                color: #fff !important;
            }
        }

        li .list-content .user-ids-btn strong {
            color: #fff !important;
        }

        li.link-copy-list .invite-btn .custom-btn {
            background: #0a0e19 !important;
            color: #fff !important;
        }

    }

    .header-right .cart-profile .profile-dropdown-details {
        color: #fff !important;
    }

    .header-right .cart-profile .profile-dropdown::before {
        color: #fff !important;
    }

    .custom-dropdown {
        a {
            color: #bbb !important;

            &:hover {
                background: #326088 !important;
            }

            .span img {
                filter: brightness(0) invert(1) !important;
            }
        }
    }

    .onetMobile_headerTop {
        box-shadow: none !important;
    }

    .send-dropdown-menu .main-tabs .nav-pills,
    .dropdown .dropdown-menu.show {
        background: #1c3f5d !important;
        color: #cccccc !important;
        border: 1px solid transparent !important;
    }

    .send-dropdown-menu .sendinvite-slide {
        .slick-slider .slick-slide .send-template {
            background: #0e1722 !important;
            color: #cccccc !important;

            .template-card .template-spacing .send-body {
                .custom-btn.custom-btn-outline {
                    background: #7595AE !important;
                }

                .custom-btn {
                    background: #45B549 !important;
                }
            }

            .template-card .template-spacing .send-body p {
                color: #cccccc !important;
            }
        }

        .select-layout {
            color: #cccccc !important;
        }
    }

    .send-dropdown-menu {
        .dropdown-menu-content {
            .invitation-product-dropdown .mat-form-field {
                background-color: transparent !important;
            }

            .dropdown-content .invitation-product-dropdown .mat-select-arrow {
                filter: brightness(10) !important;
                position: absolute;
                right: 0;
            }
        }

        .mat-select {
            background-color: transparent !important;

            .mat-select-value-text {
                background-color: transparent !important;
            }
        }

        .example-chip-list {
            background-color: transparent !important;

            .mat-input-element {
                background-color: transparent !important;
            }
        }
    }

    .header-right {
        .cart-profile:hover {
            background: #1c3f5d !important;
        }

        ul.widget-fields li.user-ids-field:hover {
            background: #1c3f5d !important;
            transition: 0.5s;
        }

        .widget-fields li.link-copy-list .invite-btn .custom-btn {
            background: #28292e !important;
            color: #ccc !important;

            &:hover {
                background-color: #1c3f5d !important;
            }
        }

        .widget-fields li .list-content {
            p {
                color: #ccc !important;
            }

            h6 {
                color: #808186 !important;
            }
        }
    }

    ////////////// side border css end /////////////





    .mat-autocomplete-panel {
        background: var(--onet-dark-bg-primary) !important;

        .mat-option {
            color: var(--onet-white-color) !important;

            &.mat-selected:not(.mat-active):not(:hover) {
                background: var(--onet-dark-bg-primary) !important;
            }
        }

        .inviteFrnds span {
            color: var(--onet-text-dark-color) !important;
        }
    }

    .chips-block {
        mat-chip-list {
            .mat-chip {
                background-color: var(--onet-dark-bg-primary) !important;
                color: var(--onet-white-color) !important;

                .mat-chip-remove .mat-icon {
                    // border: 2px solid var(--onet-white-color) !important;
                    color: var(--onet-white-color) !important;
                }

                .mutual_thumbs {
                    align-items: center;
                }
            }
        }
    }

    .mat-autocomplete-panel .mat-option .mat-chip.mat-standard-chip {
        background-color: var(--onet-dark-bg-primary) !important;
        color: var(--onet-white-color) !important;

        .mat-chip-remove .mat-icon {
            // border: 2px solid var(--onet-white-color) !important;
            color: var(--onet-white-color) !important;
        }
    }

    .mat-menu-panel {
        background-color: var(--onet-dark-bg-primary) !important;

        .notif-more button {
            color: var(--onet-text-secondary-color) !important;
        }

        .mat-menu-content .mobileList-buttons {
            background-color: var(--onet-dark-bg-primary) !important;
            color: var(--onet-white-color) !important;
        }
    }

    .mat-menu-panel.notify-drop-menu {
        background: var(--onet-dark-bg-secondary) !important;

        &>div {
            &::before {
                border-bottom: 12px solid var(--onet-dark-bg-secondary) !important;
            }
        }

        .notify-scroll button {
            border-top: 1px solid;
        }

        .notif-more button {
            color: var(--onet-text-secondary-color) !important;
        }

        .notify_text {
            color: var(--onet-text-secondary-color) !important;
        }

        .notify-scroll {
            button a .notTxt.unread {
                color: var(--onet-white-color) !important;

                .dur {
                    color: var(--onet-text-secondary-color) !important;
                }
            }

            button:hover {
                background-color: var(--onet-dark-bg-hovered) !important;
            }
        }

        button a .userStatus.active {
            background-color: var(--bs-primary) !important;
        }
    }

    .onet-main-layout {
        .fr-command.fr-btn {
            background-color: #7a7a7a !important;
        }

        .mat-menu-panel {
            background-color: var(--onet-dark-bg-primary) !important;

            .notif-more button {
                color: var(--onet-text-secondary-color) !important;
            }
        }

        .mat-menu-panel.notify-drop-menu {
            background: var(--onet-dark-bg-secondary) !important;

            &>div {
                &::before {
                    border-bottom: 12px solid var(--onet-dark-bg-secondary) !important;
                }
            }

            .notify-scroll button {
                border-top: 1px solid;
            }

            .notif-more button {
                color: var(--onet-text-secondary-color) !important;
            }

            .notify_text {
                color: var(--onet-text-secondary-color) !important;
            }

            .notify-scroll {
                button a .notTxt.unread {
                    color: var(--onet-white-color) !important;

                    .dur {
                        color: var(--onet-text-secondary-color) !important;
                    }
                }

                button:hover {
                    background-color: var(--onet-dark-bg-hovered) !important;
                }
            }
        }

        .mat-select-panel-wrap .mat-select-panel::-webkit-scrollbar-thumb,
        .notify-drop-menu .notify-scroll::-webkit-scrollbar-thumb {
            background-color: #081827 !important;
        }




        &.mat-menu-panel {
            background-color: var(--onet-dark-bg-primary) !important;

            .mat-menu-content button.more {
                color: var(--onet-white-color) !important;
            }

            button.mat-menu-item {
                color: var(--onet-text-secondary-color) !important;

                &:hover {
                    background: #eeeeee08 !important;
                }
            }
        }

        .edit-thumbs .mat-chip.mat-standard-chip {
            background-color: #00000070 !important;
            color: var(--onet-text-secondary-color) !important;
        }

        .edit-thumbs .mutual_thumbs .mat-chip-remove {
            color: var(--onet-text-secondary-color) !important;
        }
    }


    .onet-dark-mode-inn {
        body {
            background-color: var(--onet-dark-bg-primary) !important;
            color: var(--onet-white-color);

        }

        .body_layout,
        .main_nav,
        .main_nav_inn,
        .menu-list,
        .msg-noti-access,
        .top-right-nav {
            background-color: var(--onet-dark-bg-primary) !important;

            .mat-card {
                background-color: var(--onet-dark-bg-secondary) !important;
            }
        }

        .btn-fill {
            border: 1px solid transparent !important;
        }

        input,
        .form-select,
        .mat_select,
        .mat-select,
        .mat-form-field,
        textarea,
        .form-select option {
            -webkit-background-color: var(--onet-dark-bg-hovered) !important;
            -webkit-border-color: var(--onet-dark-border-color) !important;
            -webkit-color: var(--onet-text-secondary-color) !important;
            -moz-background-color: var(--onet-dark-bg-hovered) !important;
            -moz-border-color: var(--onet-dark-border-color) !important;
            -moz-color: var(--onet-text-secondary-color) !important;
            -o-background-color: var(--onet-dark-bg-hovered) !important;
            -o-border-color: var(--onet-dark-border-color) !important;
            -o-color: var(--onet-text-secondary-color) !important;
            background-color: var(--onet-dark-bg-hovered) !important;
            border-color: var(--onet-dark-border-color) !important;
            color: var(--onet-text-secondary-color) !important;
        }

        // .form-select, .mat-select {
        //     background-image: url(/assets/onet/img/dropdown-arrow-dark.svg) !important;
        // }
        .mat-form-field-hide-placeholder .mat-select-placeholder {
            color: #ccc !important;
        }

        .mat-icon img,
        .form-select-event::after {
            background-color: transparent !important;
            border: none !important;
            filter: contrast(0.5) !important;
        }

        .mat-select-arrow {
            visibility: hidden !important;
        }



        .whats-your-mind.box-layout,
        .recommend-wrapper {
            background-color: var(--onet-dark-bg-secondary) !important;
        }

        .feature-notifies {
            .notifies_card {
                .tags {
                    background-color: var(--onet-dark-bg-secondary) !important;
                }
            }
        }

        .recomm-friends .no-data h3 {
            color: var(--onet-white-color) !important;
        }

        .left-wrapper {
            .left-wrapper-inn {
                .bg {
                    background-color: var(--onet-dark-bg-secondary) !important;

                    .capt {
                        background-color: var(--onet-dark-bg-secondary) !important;
                        border-bottom: 1px solid var(--onet-dark-border-color) !important;

                        small img {
                            filter: brightness(0) invert(1);
                        }
                    }
                }
            }
        }

        .right-nav {
            .side-wraps {
                background-color: var(--onet-dark-bg-secondary) !important;

                .capt {
                    border-bottom: none !important;
                    color: var(--onet-white-color) !important;

                    .viewAll-span {
                        color: var(--onet-white-color) !important;

                        img {
                            filter: brightness(0) invert(1);
                        }
                    }
                }

                .capt,
                .list-inns {
                    background-color: var(--onet-dark-bg-secondary) !important;
                }

                .list-inns {
                    border-radius: unset !important;

                    .list-tags {
                        h6 {
                            color: var(--onet-white-color) !important;
                        }

                        p,
                        a {
                            color: var(--onet-text-secondary-color) !important;
                        }
                    }
                }

                .cal-wrap {
                    background-color: var(--onet-dark-bg-secondary) !important;

                    .calendar-wrap {
                        background-color: var(--onet-dark-bg-secondary) !important;
                        border-bottom: unset !important;

                        .btn-group {
                            background-color: var(--onet-dark-bg-hovered) !important;
                        }
                    }

                    .btn-group h3 {
                        color: var(--onet-white-color) !important;
                    }
                }
            }
        }

        .calendar-wrap {
            background-color: var(--onet-dark-bg-secondary) !important;
        }

        .events-calendar {
            .cal-month-view {
                .cal-cell-row {
                    .cal-cell:hover {
                        background-color: #fafafa00 !important;
                    }

                    &:hover {
                        background-color: #fafafa00 !important;
                    }
                }

                .cal-days {
                    background-color: var(--onet-dark-bg-hovered) !important;
                    border-color: transparent !important;
                }

                .cal-day-cell.cal-today {
                    background-color: transparent !important;
                }

                .cal-day-number {
                    color: #ffffff !important;
                }
            }
        }

        .gm-user {
            .gm-user-inn {
                box-shadow: none !important;
                background-color: #5b5b5b77 !important;

                .prof-name {
                    background-color: var(--onet-dark-bg-secondary) !important;

                    .prof-name-inn {
                        h6 {
                            color: var(--onet-white-color) !important;
                        }

                        small {
                            color: var(--onet-text-secondary-color) !important;
                        }

                        img {
                            filter: brightness(0) saturate(100%) invert(56%) sepia(41%) saturate(328%) hue-rotate(136deg) brightness(92%) contrast(91%) !important;
                        }
                    }
                }
            }

            .public-user-inn {
                .public-name-wrap {
                    background-color: var(--onet-dark-bg-secondary) !important;

                    .public-mutual-friends {
                        h6 {
                            color: var(--onet-white-color) !important;
                        }

                        label {
                            color: var(--onet-text-dark-color) !important;
                        }
                    }

                    .more-acts {
                        background-color: var(--onet-dark-bg-primary) !important;
                    }
                }
            }
        }

        .box-layout {
            .d-flex.justify-content-between p {
                color: var(--onet-text-dark-color) !important;
            }

            background-color: var(--onet-dark-bg-secondary) !important;
            box-shadow: unset !important;

            mat-card-header {
                border-bottom: var(--onet-dark-bg-secondary) !important;

                .mat-card-title {
                    color: var(--onet-white-color) !important;
                }

                .mat-card-subtitle,
                .privacy-wrap span {
                    color: var(--onet-text-secondary-color) !important;
                }
            }

            .more-acts {
                background-color: var(--onet-dark-bg-primary) !important;
                border-radius: 4px !important;
            }

            .mat-card-content {
                color: var(--onet-text-secondary-color) !important;
            }
        }

        .onet-sidemenu {
            ul {
                li {
                    span.title {
                        color: var(--onet-text-secondary-color) !important;
                    }

                    &.active,
                    &:hover {
                        span.title {
                            color: var(--onet-white-color) !important;
                            box-shadow: 0px 3px 0px 0px var(--onet-white-color) !important;
                        }
                    }

                    &.myMenu-out0 .nav-icn {
                        border: none !important;
                    }
                }
            }

            .sidenav-inn .get_more .get_more_btn {
                color: var(--onet-text-secondary-color) !important;
            }
        }

        .post-share-links {
            ul {
                li {
                    a {
                        background: transparent !important;
                    }

                    .circle-c {
                        img {
                            background-color: transparent !important;
                            filter: brightness(2) contrast(0.5) !important;
                        }

                        &.like-icn {
                            div {
                                img {
                                    filter: none !important;
                                }
                            }
                        }
                    }

                    .like-icn.circle-c {

                        &.like-icn {
                            div {
                                img.liked-icn {
                                    filter: none !important;
                                }
                            }
                        }
                    }

                    span {
                        color: var(--onet-text-secondary-color) !important;

                        a {
                            color: var(--onet-text-secondary-color) !important;
                        }
                    }

                    a:hover .actve_comm_inn,
                    .shareIcn:hover {
                        background-color: transparent !important;
                        color: var(--onet-text-secondary-color) !important;
                    }

                }
            }

        }

        .custom-search-input {
            background-color: var(--onet-dark-bg-hovered) !important;
            box-shadow: none !important;
            border: 1px solid var(--onet-dark-border-color) !important;

            .input-group-btn button {
                background-color: var(--onet-dark-bg-hovered) !important;
            }
        }

        .noData {
            background-color: var(--onet-dark-bg-secondary);
        }

        .fr-toolbar,
        .fr-box.fr-basic .fr-wrapper,
        .fr-second-toolbar {
            background-color: var(--onet-dark-bg-hovered) !important;
        }

        .fr-box.fr-basic {
            border: none !important;
            background-color: var(--onet-dark-bg-hovered) !important;
            border-radius: 10px !important;
        }

        .addstory {
            .createSerach {
                .createPost_wrap {
                    .create-event-btns .icn label {
                        background-color: var(--onet-dark-bg-hovered) !important;

                        img {
                            filter: brightness(50) contrast(10);
                        }

                        &.active {
                            background-color: var(--onet-primary-color) !important;

                            &:hover {
                                background-color: var(--onet-primary-color) !important;
                            }
                        }

                        &:hover {
                            background-color: #54626f !important;
                        }
                    }

                    .upload_blk .custom-dropzone,
                    .createPodcast .upload_blk {
                        background-color: var(--onet-dark-bg-hovered) !important;
                        border: 1px solid #20374b !important;
                        color: var(--onet-white-color) !important;

                        .uploadwrap {
                            label img {
                                filter: invert(1);
                            }

                            img {
                                filter: invert(1);
                            }

                            div .thumb_img {
                                filter: invert(0);
                            }

                            h6 {
                                color: var(--onet-white-color) !important;
                            }
                        }

                        .thumb-inn-audio {
                            color: var(--onet-text-dark-color) !important;

                            ngx-dropzone-remove-badge {
                                opacity: 1;
                                top: 12px;
                                right: 0px;
                                background-color: var(--onet-dark-bg-primary) !important;

                                svg line {
                                    stroke: var(--onet-primary-color) !important;
                                }
                            }
                        }

                        .addaudio {
                            .ad h6 {
                                color: var(--onet-white-color);
                            }

                            .ad small {
                                color: var(--onet-text-dark-color);
                            }

                            .audio-upload-wrap {
                                img {
                                    filter: brightness(0) saturate(100%) invert(90%) sepia(41%) saturate(328%) hue-rotate(136deg) brightness(92%) contrast(91%) !important;
                                }
                            }
                        }
                    }

                    .createPost .add-media .uploaded-thumbs {
                        .thumb-inn {
                            background-color: var(--onet-dark-bg-primary) !important;
                        }

                        .uploadwrap {
                            background-color: var(--onet-dark-bg-primary) !important;

                            img {
                                filter: brightness(2) !important;
                            }
                        }
                    }
                }

                .form-fields .fr-box.fr-inline {
                    background-color: var(--onet-dark-bg-hovered) !important;
                    border-color: var(--onet-dark-border-color) !important;
                    color: var(--onet-text-secondary-color) !important;
                }

                .post-profile .post-name h3 {
                    color: var(--onet-white-color) !important;
                }
            }

            .termsAndConditions {
                a {
                    color: var(--onet-white-color) !important;
                }
            }

        }

        .notify-drop .notif-icn,
        .msg-wrap button .msg-icn,
        .onet-guide,
        .dark-mode {
            background-color: var(--onet-dark-bg-hovered) !important;
        }

        .recommend-wrapper {
            .recommend-tabs {
                box-shadow: none !important;
                background-color: var(--onet-dark-bg-primary) !important;

                .active button {
                    color: var(--onet-white-color) !important;
                    box-shadow: inset 0px -5px 0px 0px var(--onet-primary-color);
                }
            }

            .recommend-content {
                .recomm-card {
                    .tags {
                        h3 {
                            color: var(--onet-white-color) !important;
                        }

                        small {
                            color: var(--onet-text-secondary-color) !important;
                        }
                    }
                }
            }
        }

        .feature-notifies .notifies_card {
            background-color: var(--onet-dark-bg-secondary) !important;
        }

        .addstory .pp {
            background-color: var(--onet-primary-color) !important;
            border: 1px solid transparent;
            border-radius: 5px !important;

            a {
                color: var(--white-color) !important;
            }

            select {
                filter: brightness(10) contrast(50) !important;
                background-color: transparent !important;
            }
        }

        // .left-wrapper{
        .left-wrapper-inn,
        .left-wrapper-inn-friend {
            .bg {
                background-color: var(--onet-dark-bg-secondary) !important;

                .capt {
                    background-color: var(--onet-dark-bg-secondary) !important;
                    border-bottom: 1px solid var(--onet-dark-border-color) !important;

                    h6,
                    small {
                        color: var(--onet-white-color) !important;
                    }
                }

                .capt-inn {
                    color: var(--onet-white-color) !important;

                    small {
                        color: var(--onet-text-secondary-color) !important;
                    }
                }
            }
        }

        // }
        .media-tags-group {
            .media-tags {
                a span {
                    color: var(--onet-white-color) !important;
                }
            }

            li .icn img {
                filter: brightness(10) contrast(0.5) !important;
            }
        }

        .post_text p {
            color: var(--onet-text-secondary-color) !important;
        }

        .newsFeedsBlog-view .card {
            background-color: var(--onet-dark-bg-secondary) !important;

            .card-body .cards-wrap .card {
                background-color: var(--onet-dark-bg-hovered) !important;

                h6 {
                    p {
                        color: var(--onet-white-color) !important;
                    }
                }

                p {
                    color: var(--onet-text-secondary-color) !important;
                }

                .card-body {
                    background-color: var(--onet-dark-bg-hovered) !important;
                }
            }
        }

        .textExtend_froala p {
            color: var(--onet-text-secondary-color) !important;
        }

        .scroll-inn,
        .left-wrapper-inn {
            &:hover {
                &::-webkit-scrollbar-thumb {
                    background: #2b4257 !important;
                }
            }
        }

        .fr-toolbar .fr-command.fr-btn svg path {
            fill: var(--onet-dark-bg-secondary) !important;
        }

        .mat-select-value-text {
            color: var(--onet-white-color) !important;
        }

        .carousel .nav-btn {
            border-color: #5E989D !important;
            box-shadow: 0px 2px 2px #0000005e !important;
            background-color: #034c538c !important;

            img {
                filter: brightness(10) contrast(0.5) !important;
            }
        }

        .posted_comment,
        .reply_posted_comment {
            background-color: transparent !important;

            h6 {
                color: var(--onet-white-color) !important;
            }

            p {
                color: var(--onet-text-secondary-color) !important;
            }

            .whenTo {
                color: var(--onet-text-secondary-color) !important;
            }

            span {
                color: var(--onet-white-color) !important;
            }
        }

        .comment_width .posted_comment div p span {
            background-color: transparent !important;
            color: var(--onet-text-dark-color) !important;
        }

        .sub_comments {
            .bg-border {
                background-color: var(--onet-dark-bg-primary) !important;
                border-color: var(--onet-dark-bg-hovered) !important;

                .digi,
                a {
                    color: var(--onet-text-secondary-color) !important;
                }
            }

            span {
                color: var(--onet-white-color) !important;
                font-size: 12px !important;

                a {
                    h4 {
                        color: var(--onet-white-color) !important;
                    }
                }
            }
        }

        .custom-search-input {

            input,
            .mat-form-field,
            .mat-select {
                color: var(--onet-text-secondary-color) !important;
                background: transparent !important;
            }

            .mat-select-arrow {
                visibility: visible !important;
                border-top-color: var(--onet-text-dark-color) !important;
            }
        }

        .edit-cover .more-acts {
            background-color: transparent !important;
            border-radius: unset !important
        }

        .edit-profile,
        .edit-cover {
            .more-acts {
                button {
                    background-color: var(--onet-dark-bg-primary) !important;
                    box-shadow: 0px 1px 2px 0px #0000005e !important;
                }

                .more {
                    background-color: var(--onet-dark-bg-primary) !important;
                    box-shadow: 0px 1px 2px 0px #0000005e !important;
                }
            }
        }

        .u-name {
            color: var(--onet-text-secondary-color) !important;
        }

        .hidePost {
            p {
                color: var(--onet-text-secondary-color) !important;
            }

            span {
                color: var(--onet-white-color) !important;
            }

            .icn {
                filter: brightness(10);
            }

            .mat-card-title {
                small {
                    color: var(--onet-text-secondary-color) !important;
                }
            }

            .user-prof {
                .u-name {
                    color: var(--onet-text-secondary-color) !important;
                }

                .privacy-wrap {
                    color: var(--onet-text-secondary-color) !important;

                    span {
                        color: var(--onet-text-secondary-color) !important;
                    }
                }
            }
        }

        .default-mode {
            background-color: var(--onet-dark-bg-hovered) !important;

            img {
                filter: invert(48%) sepia(13%) saturate(3207%) hue-rotate(130deg) brightness(65%) contrast(70%);
            }
        }

        .bg-white {
            background-color: var(--onet-dark-bg-secondary) !important;
        }

        .lik-dropdown:hover .lik-dropdown-content {
            background-color: var(--onet-dark-bg-primary) !important;
            border-color: var(--onet-dark-bg-secondary) !important;
            box-shadow: none !important;
        }

        .img-grid .o-img.x-one {
            border: none !important;
            background: var(--onet-dark-bg-secondary) !important;
        }

        .tab-wrapper {
            .nav {
                border-bottom: 1px solid var(--onet-dark-border-color) !important;

                @media screen and(max-width:768px) {
                    border-bottom: none !important;
                }

                button.active,
                button:hover {
                    color: #ffffff !important;
                    box-shadow: inset 0px -3px 0px 0px #ffffff !important;
                }

                .moduleTitle {
                    color: #ffffff !important;
                }
            }

        }

        .single_template,
        .podcast-view {
            .card {
                background-color: var(--onet-dark-bg-secondary) !important;

                .inner-card {
                    background-color: var(--onet-dark-bg-secondary) !important;
                }

                .card-body .cards-wrap .card,
                .card-body .cards-wrap .card .card-body {
                    background-color: var(--onet-dark-bg-hovered) !important;
                }

                .card-body .cards-wrap .card .card-body {
                    h6 {
                        color: var(--onet-white-color) !important;

                        p {
                            color: var(--onet-white-color) !important;
                        }
                    }
                }

                p {
                    color: var(--onet-text-secondary-color) !important;
                }

                .blogPostText {
                    p {
                        color: var(--onet-text-secondary-color) !important;
                    }
                }
            }
        }

        .public_main_row {
            background-color: var(--onet-dark-bg-secondary) !important;
            border: 1px solid var(--onet-dark-bg-secondary) !important;

            h6 {
                color: var(--onet-white-color) !important;

                span {
                    color: var(--onet-white-color) !important;
                }
            }

            .card_wrap_main {
                background: var(--onet-dark-bg-primary) !important;
                border: 1px solid var(--onet-dark-bg-primary) !important;
            }

            .card-wrap {
                .more-acts {
                    button {
                        background-color: var(--onet-dark-bg-primary) !important;
                    }
                }

                .card {
                    background-color: var(--onet-dark-bg-primary) !important;

                    .prof_capts {
                        background-color: var(--onet-dark-bg-primary) !important;
                        background: var(--onet-dark-bg-primary) !important;

                        h5 {
                            color: var(--onet-white-color) !important;
                        }

                        label {
                            color: var(--onet-text-secondary-color) !important;
                        }
                    }
                }
            }
        }

        .main_frnds_heading {
            h5 {
                color: var(--onet-white-color) !important;
            }
        }

        .card-col {
            .card_wrap_main {
                border: 1px solid var(--onet-dark-bg-secondary) !important;
            }
        }

        .date_devider {
            color: var(--onet-white-color) !important;
        }

        .allBlogs {
            h5 {
                color: var(--onet-white-color) !important;
            }

            .custom-search-input {
                background-color: var(--onet-dark-bg-hovered) !important;
            }
        }

        .schedulePost-dropbox.scheduletooltip,
        .tagfriends-wrap.tagfriendstooltip {
            box-shadow: none !important;
            background-color: var(--onet-dark-bg-hovered) !important;

            .schedulePost-inn,
            .tag_friends_inn {
                color: var(--onet-text-secondary-color) !important;

                .custom-search-input .srch img {
                    filter: brightness(3);
                }

                p {
                    color: var(--onet-text-secondary-color) !important;
                }

                label {
                    color: var(--onet-text-secondary-color) !important;
                }
            }

            .schedulePost-inn {
                .form {
                    .schedule-postTimer {
                        img {
                            filter: invert(1) !important;
                        }
                    }

                    mat-datepicker-toggle {
                        button {
                            svg {
                                filter: invert(1) !important;
                            }
                        }
                    }
                }
            }

            .tag_friends {
                color: var(--onet-text-secondary-color) !important;

                .tag_suggestions {
                    h6 {
                        color: var(--onet-white-color) !important;
                    }

                    .lis {
                        border-bottom: 1px solid #e3e3e347 !important;

                        .check-wrap {
                            span {
                                color: #cbcbcb !important;
                            }

                            .round {
                                input[type=checkbox]:checked+label {
                                    background-color: var(--onet-primary-color) !important;
                                }

                                label {
                                    background-color: transparent !important;
                                    border: 1px solid #fff !important;
                                }
                            }
                        }
                    }
                }
            }
        }

        .main-card .stick-head {
            box-shadow: 0px 2px 4px -2px #0000007a !important;
        }

        .notifications-wrap .card {
            background-color: var(--onet-dark-bg-secondary) !important;
        }

        .checkmark {
            border: 1px solid var(--onet-text-secondary-color) !important;

            &:after {
                left: 4px !important;
                top: 1px !important;
            }
        }

        @media screen and (max-width: 767.98px) {
            .onetMobile_headerTop {
                background-color: var(--onet-dark-bg-primary) !important;
            }
        }

        .my_media .right-wrapper .myMedia-card .card {
            border-color: var(--onet-dark-bg-primary) !important
        }

        .friends-search {
            .inn {
                .globalSearch-title h4 {
                    color: var(--onet-white-color) !important;

                    span {
                        color: var(--onet-text-dark-color) !important;
                    }
                }

                .card {
                    background-color: var(--onet-dark-bg-hovered) !important;

                    &.globalSearch-people,
                    &.globalSearch-club {
                        h5 {
                            color: var(--white-color) !important;
                        }
                    }

                    .globalSearch-peopledetails,
                    .globalSearch-peopledetails {
                        h5 {
                            color: var(--onet-white-color) !important;
                        }

                        p {
                            span {
                                color: var(--onet-text-secondary-color) !important;
                            }
                        }
                    }

                    .card-body ul li h6 {
                        color: var(--onet-white-color) !important;
                    }

                    .card-body ul li p,
                    .card-body ul li p span {
                        color: var(--onet-text-dark-color) !important;
                    }
                }
            }
        }

        .recommend_clubs .default_img {
            background-color: var(--onet-dark-bg-hovered) !important;
        }

        .message-blog,
        .msg_wrap .right-pannel .card .card_scroll,
        .searchList ul:hover,
        .proffesional-info-view .inn_scroll {
            &::-webkit-scrollbar-thumb {
                background: #607082 !important;
            }
        }

        .request-group {
            ul {
                li {
                    background: var(--onet-dark-bg-hovered) !important;
                    &:hover, &.active{
                        background-color: var(--onet-primary-color) !important;
                    }
                    .r-icn {
                        filter: brightness(10);
                    }

                    label {
                        color: var(--white-color) !important;

                        span {
                            color: var(--onet-text-secondary-color) !important;
                        }
                    }
                }
            }
        }

        .play-icn,
        .img-grid .o-img .vedioPlay::before {
            background: url(../../../assets/onet/icons/play-dark.svg) !important;
            background-color: var(--onet-primary-color) !important;
            background-repeat: no-repeat !important;
            background-position: center !important;
        }

        .play-icn {
            height: 50px;
            width: 50px;

            img {
                display: none !important;
            }
        }

        .lik-dropdown-content .tool_tip .tooltiptext::after,
        .emoji_tool_tip .tooltiptext::after {
            border-color: var(--onet-dark-border-color) transparent transparent transparent !important;
        }

        // .cover-bg .c_img img{filter: invert(1);}
        .multiple_template {
            .txt {
                color: var(--onet-text-secondary-color) !important;
            }
        }

        .no_data_block {
            h6 {
                color: #fff !important;
            }
        }

        .post_text a {
            text-decoration: underline !important;
        }

        .blogPostText a {
            color: var(--onet-text-secondary-color) !important;
        }
        .noDataProfImg{
            h4, h6{color: #fff !important;}
        }


















        //R Added Styles start
        //Events Module CSS
        .createPost_wrap {
            .createEvents {
                .searchList {
                    background-color: var(--onet-dark-bg-primary) !important;

                    &::-webkit-scrollbar-thumb {
                        background: #7b7b7b !important;
                    }

                    ul {
                        background-color: var(--onet-dark-bg-primary) !important;

                        li {
                            color: var(--onet-white-color);
                        }
                    }
                }

                form div small.ms-1 {
                    color: #dc3545 !important;
                }

                .form-select-event .form-select {
                    background-image: none !important;
                }
            }
        }

        .create_event {
            .searchList {
                background-color: var(--onet-dark-bg-primary) !important;

                &::-webkit-scrollbar-thumb {
                    background: #7b7b7b !important;
                }

                ul {
                    background-color: var(--onet-dark-bg-primary) !important;

                    li {
                        color: var(--onet-white-color);
                    }
                }
            }

            form div small.ms-1 {
                color: #dc3545 !important;
            }
        }

        .border_top {
            border-color: var(--onet-dark-border-color) !important;
        }

        .public-profile-section {
            .prof-content {
                .friendRequest .friendRequest-inn {
                    background-color: var(--onet-dark-bg-secondary) !important;
                    box-shadow: unset !important;

                    .inc img {
                        filter: brightness(0) saturate(100%) invert(23%) sepia(11%) saturate(4413%) hue-rotate(143deg) brightness(93%) contrast(98%);
                    }

                    .txt h6 {
                        color: var(--onet-white-color) !important;
                    }

                    .txt p {
                        color: var(--onet-text-dark-color) !important;
                    }

                    .btn-outline {
                        background: var(--onet-text-secondary-color) !important;
                        color: var(--onet-primary-color) !important;
                    }
                }

                .privacy_height {
                    h6 {
                        color: var(--onet-white-color);
                    }

                    div {
                        color: var(--onet-text-dark-color);
                    }
                }

                .grid-view .card {
                    background-color: var(--onet-dark-bg-primary) !important;

                    .card-body {

                        label,
                        h5 {
                            color: var(--onet-white-color) !important;
                        }

                        p {
                            color: var(--onet-text-dark-color) !important;
                        }

                        a {
                            color: var(--bs-primary) !important
                        }
                    }
                }

                .grid-view .card .card-footer {
                    background-color: var(--onet-dark-bg-primary) !important;
                    padding: 0px 20px 25px !important;

                    img {
                        filter: brightness(10) contrast(0.5) !important;
                    }

                    span {
                        color: var(--onet-text-dark-color) !important;
                    }
                }
            }
        }


        .events-tab-wrapper,
        .inner-tab-wrapper {
            border-bottom: 1px solid var(--onet-dark-border-color) !important;

            .moduleTitle {
                color: var(--onet-white-color) !important;
            }

            ::ng-deep {
                .nav-tabs {
                    .nav-item.show {
                        .nav-link {
                            background-color: transparent;
                            color: #FFF !important;
                            border-bottom: 3px solid #fff !important;
                        }
                    }

                    .nav-item {
                        .nav-link.active {
                            background-color: transparent;
                            color: #FFF !important;
                            border-bottom: 3px solid #fff !important;
                        }
                    }
                }
            }
        }

        .events-tab-wrapper .nav-tabs {

            .nav-link.active,
            .nav-link:hover {
                color: #ffffff !important;
                border-bottom: 3px solid #ffffff !important;
            }
        }

        .allEvents {
            &.bg-white {
                background-color: var(--onet-dark-bg-secondary) !important;

                h4 {
                    color: var(--onet-white-color) !important;
                }

                h5 {
                    color: var(--onet-white-color) !important;
                }

                .d-flex.justify-content-between {
                    a {
                        color: var(--onet-white-color) !important;

                        .img-fluid {
                            filter: brightness(0) invert(1);
                        }
                    }
                }

                .custom-search-input {
                    border: 1px solid #404f5c !important;
                    background-color: var(--onet-dark-bg-hovered) !important;

                    .inner-group {
                        button {
                            padding: 4px 14px !important;
                        }

                        input {
                            color: #fff;
                        }
                    }
                }

                .select_category_wrap {
                    .mat-form-field-appearance-fill {
                        .mat-form-field-flex {
                            background-color: #034c53;
                            border: 1px solid #034c53;

                            .filer-icon {
                                filter: brightness(0) invert(1);
                            }

                            .mat-select {
                                opacity: 0;
                            }
                        }
                    }
                }

                .card {
                    background-color: var(--onet-dark-bg-primary) !important;

                    .card-body {
                        .name_strip {
                            color: var(--onet-white-color);
                        }

                        .name_strip-p {
                            color: var(--onet-text-secondary-color);
                        }

                        .location-created {
                            span {
                                color: var(--onet-text-secondary-color);

                                img {
                                    filter: brightness(0) saturate(100%) invert(61%) sepia(14%) saturate(613%) hue-rotate(137deg) brightness(93%) contrast(90%);
                                }
                            }
                        }
                    }
                }
            }

            .bg-white {
                background-color: var(--onet-dark-bg-secondary) !important;
            }

            .card-body .row .btn_wrapper button.btn-fill {
                border: 1px solid var(--onet-primary-color) !important;
            }
        }

        .my-events {

            .card-body .row .btn_wrapper button.btn-fill {
                border: 1px solid var(--onet-primary-color) !important;
            }

            .myEvents-new {
                .card {
                    background: var(--onet-dark-bg-secondary) !important;
                }

                .tab-wrapper {
                    .nav {
                        border-bottom: none !important;

                        button {
                            color: var(--onet-text-secondary-color) !important;

                            &:hover,
                            &.active {
                                box-shadow: none !important;

                                a {
                                    color: var(--onet-white-color) !important;
                                    border-bottom: 3px solid var(--onet-white-color) !important;
                                }
                            }
                        }
                    }

                    .snooztime_span {
                        background-color: #081827 !important;

                        img {
                            filter: brightness(0) saturate(100%) invert(61%) sepia(14%) saturate(613%) hue-rotate(137deg) brightness(93%) contrast(90%);
                        }
                    }

                    .more-acts {
                        button.more {
                            background-color: #081827 !important;

                            img {
                                filter: brightness(0) saturate(100%) invert(61%) sepia(14%) saturate(613%) hue-rotate(137deg) brightness(93%) contrast(90%);
                            }
                        }
                    }
                }

                .about_card {
                    .card {
                        background: var(--onet-dark-bg-secondary) !important;
                        border-top: 1px solid var(--onet-dark-border-color) !important;

                        .card-body {
                            p {
                                color: var(--onet-text-secondary-color) !important;
                            }

                            h6 {
                                color: var(--onet-white-color) !important;
                            }

                            .publicPrivateIcons {
                                img {
                                    filter: brightness(0) saturate(100%) invert(61%) sepia(14%) saturate(613%) hue-rotate(137deg) brightness(93%) contrast(90%);
                                }
                            }

                            .myEventsInvite-cancel {
                                .btn-outline {
                                    background: transparent !important;
                                    color: var(--onet-text-secondary-color) !important;
                                    border: 1px solid var(--onet-text-secondary-color) !important;
                                }
                            }

                        }
                    }

                    &.guest_wrap {
                        .card {
                            .card-body {

                                .hosts_block,
                                .guests_block,
                                .guests_block_rows {
                                    border-color: var(--onet-dark-border-color) !important;

                                    h5 {
                                        color: var(--onet-text-dark-color) !important;
                                    }
                                }

                                label {
                                    color: var(--onet-text-dark-color) !important;
                                }
                            }

                            .card-footer {
                                background-color: var(--onet-dark-bg-secondary) !important;
                            }
                        }
                    }
                }
            }

        }

        .myeventNoContentData {
            .no_data_available {
                background-color: var(--onet-dark-bg-secondary) !important;
                box-shadow: unset !important;

                h5 {
                    color: var(--white-color) !important;
                }
            }

            .txt {
                color: var(--onet-text-dark-color) !important;
            }
        }

        .events-dashBoard-wrapper {
            .birthdays-wrap {
                .card {
                    background-color: var(--onet-dark-bg-secondary) !important;

                    .card-header {
                        background-color: var(--onet-dark-bg-secondary) !important;

                        h5 {
                            color: var(--onet-white-color);
                        }

                        span {
                            color: var(--onet-text-dark-color) !important;
                        }
                    }

                    .card-body {
                        .blue_card {
                            background: var(--onet-dark-bg-secondary) !important;
                            box-shadow: 0px 0px 0px;
                            border: 1px solid #1E384E !important;

                            p,
                            .blue_color,
                            .fw-light,
                            a {
                                color: var(--onet-text-dark-color) !important;
                                width: fit-content !important;
                            }

                            .bday-icn {
                                width: 30px !important;
                            }

                            .msg_box {
                                .msg_inp {
                                    background-color: var(--onet-dark-bg-hovered) !important;
                                    border: 1px solid #1E384E !important;

                                    input {
                                        color: var(--onet-white-color);
                                    }
                                }
                            }

                            .birthDayWishesCard {
                                .birthDayWishesCardImg {
                                    background-color: var(--onet-dark-bg-hovered) !important;

                                    .close-btn.c_pointer {
                                        background-color: #cbcbcb;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .calendar-wrap {
                .btn-group.eventsCalendarBtns {
                    border: 1px solid var(--onet-dark-border-color) !important;

                    .btn-plain {
                        background-color: transparent !important;
                        color: var(--onet-text-dark-color);

                        img {
                            filter: brightness(0) invert(1) !important;
                        }

                        &.active,
                        &:hover {
                            color: var(--white-color);
                            background-color: var(--onet-primary-color) !important;

                            img {
                                filter: brightness(0) invert(1) !important;
                            }
                        }
                    }
                }

                .btn-outline-secondary {
                    border-color: transparent !important;

                    &:hover {
                        background-color: transparent !important;
                    }

                    h3 {
                        color: var(--onet-white-color);
                    }
                }

                .btn-group {
                    .btn-none img {
                        filter: brightness(0) saturate(100%) invert(67%) sepia(12%) saturate(1175%) hue-rotate(136deg) brightness(81%) contrast(83%);
                    }
                }
            }

            .cal-month-view .cal-days,
            .cal-month-view .cal-days .cal-cell-row {
                border-color: var(--onet-dark-border-color) !important;
            }

            .tab-content .eventCalendar-view .cal-header .cal-cell {
                background-color: var(--onet-dark-bg-primary) !important;
                color: var(--onet-white-color) !important;
                border-color: var(--onet-dark-border-color) !important;
                text-align: center;
            }

            .tab-content .eventCalendar-view .cal-days .cal-cell-row .cal-day-cell,
            .tab-content .eventCalendar-view .cal-days .cal-day-column .cal-day-cell,
            .tab-content .eventCalendar-view .cal-days .cal-events-row .cal-day-cell,
            .tab-content .eventCalendar-view .cal-day-columns .cal-cell-row .cal-day-cell,
            .tab-content .eventCalendar-view .cal-day-columns .cal-day-column .cal-day-cell,
            .tab-content .eventCalendar-view .cal-day-columns .cal-events-row .cal-day-cell,
            .tab-content .eventCalendar-view .cal-all-day-events .cal-cell-row .cal-day-cell,
            .tab-content .eventCalendar-view .cal-all-day-events .cal-day-column .cal-day-cell,
            .tab-content .eventCalendar-view .cal-all-day-events .cal-events-row .cal-day-cell {
                background-color: var(--onet-dark-bg-hovered) !important;
                border-color: var(--onet-dark-border-color) !important;

                .cell-totals .calendarEvent-details .calendarEvent-span .calendarEvent-spanText {
                    color: #015391;
                }

                .cell-totals .calendarEvent-details .calendarEvent-span small {
                    color: #333333;
                }
            }

            .tab-content .eventCalendar-view .cal-days .cal-cell-row .cal-cell-top .cal-day-number,
            .tab-content .eventCalendar-view .cal-days .cal-day-column .cal-cell-top .cal-day-number,
            .tab-content .eventCalendar-view .cal-days .cal-events-row .cal-cell-top .cal-day-number,
            .tab-content .eventCalendar-view .cal-day-columns .cal-cell-row .cal-cell-top .cal-day-number,
            .tab-content .eventCalendar-view .cal-day-columns .cal-day-column .cal-cell-top .cal-day-number,
            .tab-content .eventCalendar-view .cal-day-columns .cal-events-row .cal-cell-top .cal-day-number,
            .tab-content .eventCalendar-view .cal-all-day-events .cal-cell-row .cal-cell-top .cal-day-number,
            .tab-content .eventCalendar-view .cal-all-day-events .cal-day-column .cal-cell-top .cal-day-number,
            .tab-content .eventCalendar-view .cal-all-day-events .cal-events-row .cal-cell-top .cal-day-number {
                color: var(--onet-white-color) !important;

                .calendarEvent-details .calendarEvent-span .calendarEvent-spanText {
                    color: #015391;
                }

                .calendarEvent-details .calendarEvent-span small {
                    color: #333333;
                }
            }

            .tab-content .eventCalendar-view .cal-days .cal-cell-row .cell-totals .calendarEvent-details .btn-view-more,
            .tab-content .eventCalendar-view .cal-days .cal-day-column .cell-totals .calendarEvent-details .btn-view-more,
            .tab-content .eventCalendar-view .cal-days .cal-events-row .cell-totals .calendarEvent-details .btn-view-more,
            .tab-content .eventCalendar-view .cal-day-columns .cal-cell-row .cell-totals .calendarEvent-details .btn-view-more,
            .tab-content .eventCalendar-view .cal-day-columns .cal-day-column .cell-totals .calendarEvent-details .btn-view-more,
            .tab-content .eventCalendar-view .cal-day-columns .cal-events-row .cell-totals .calendarEvent-details .btn-view-more,
            .tab-content .eventCalendar-view .cal-all-day-events .cal-cell-row .cell-totals .calendarEvent-details .btn-view-more,
            .tab-content .eventCalendar-view .cal-all-day-events .cal-day-column .cell-totals .calendarEvent-details .btn-view-more,
            .tab-content .eventCalendar-view .cal-all-day-events .cal-events-row .cell-totals .calendarEvent-details .btn-view-more {
                img {
                    filter: brightness(0) invert(1);
                }
            }

            .tab-content .eventCalendar-view .cal-days .cal-cell-row .cell-totals .calendarEvent-details .calendarEvent-span,
            .tab-content .eventCalendar-view .cal-days .cal-day-column .cell-totals .calendarEvent-details .calendarEvent-span,
            .tab-content .eventCalendar-view .cal-days .cal-events-row .cell-totals .calendarEvent-details .calendarEvent-spanText,
            .tab-content .eventCalendar-view .cal-day-columns .cal-cell-row .cell-totals .calendarEvent-details .calendarEvent-span,
            .tab-content .eventCalendar-view .cal-day-columns .cal-day-column .cell-totals .calendarEvent-details .calendarEvent-span,
            .tab-content .eventCalendar-view .cal-day-columns .cal-events-row .cell-totals .calendarEvent-details .calendarEvent-span,
            .tab-content .eventCalendar-view .cal-all-day-events .cal-cell-row .cell-totals .calendarEvent-details .calendarEvent-span,
            .tab-content .eventCalendar-view .cal-all-day-events .cal-day-column .cell-totals .calendarEvent-details .calendarEvent-span,
            .tab-content .eventCalendar-view .cal-all-day-events .cal-events-row .cell-totals .calendarEvent-details .calendarEvent-span {
                .calendarEvent-spanText {
                    color: #015391;
                }

                small {
                    color: #333333;
                }
            }

            .tab-content .eventCalendar-view .cal-day-columns .cal-day-column {
                border-color: var(--onet-dark-border-color) !important;

                .cal-hour {
                    background-color: var(--onet-dark-bg-hovered) !important;

                    mwl-calendar-week-view-hour-segment {
                        background-color: var(--onet-dark-bg-hovered) !important;
                    }
                }

            }

            .tab-content .eventCalendar-view .cal-week-view {
                border-color: var(--onet-dark-border-color) !important;

                .cal-time-label-column,
                .cal-hour-odd {
                    background-color: var(--onet-dark-bg-primary) !important;
                    border-color: var(--onet-dark-border-color) !important;
                }

                .cal-time-events {
                    border-color: var(--onet-dark-border-color) !important;
                }

                .cal-all-day-events {
                    border-color: var(--onet-dark-border-color) !important;

                    .cal-events-row {
                        background-color: var(--onet-dark-bg-primary) !important;
                    }
                }
            }

            .tab-content .eventCalendar-view .cal-week-view .cal-day-headers,
            .tab-content .eventCalendar-view .cal-week-view .cal-header.cal-today {
                background-color: var(--onet-dark-bg-primary) !important;
                color: var(--onet-white-color) !important;
                border-color: var(--onet-dark-border-color) !important;

                b {
                    font-weight: 400;
                }

                span {
                    color: var(--onet-text-dark-color) !important;
                    opacity: 1 !important;
                }

                .cal-header {
                    border-color: var(--onet-dark-border-color) !important;

                    &:hover {
                        background-color: var(--onet-dark-bg-hovered) !important;
                    }
                }
            }

            .tab-content .eventCalendar-view .cal-week-view .cal-hour-segment.cal-after-hour-start .cal-time,
            .tab-content .eventCalendar-view .cal-week-view .cal-time {
                background-color: var(--onet-dark-bg-hovered) !important;
                border-right-color: var(--onet-dark-border-color) !important;
            }

            .tab-content .eventCalendar-view .cal-week-view .cal-hour-segment.cal-after-hour-start .cal-time {
                color: transparent;
            }

            .tab-content .eventCalendar-view .cal-week-view mwl-calendar-week-view-hour-segment,
            .tab-content .eventCalendar-view .cal-week-view .cal-hour-segment {
                color: var(--onet-white-color) !important;
                border-bottom-color: var(--onet-dark-border-color) !important;

                &:hover {
                    background-color: var(--onet-dark-bg-hovered) !important;
                }
            }

            .myCalendarEvents-rightStrip {
                background-color: var(--onet-dark-bg-hovered) !important;

                h3 {
                    color: var(--onet-white-color) !important;
                }

                .myCalendarEvents-rightStripRow {
                    h5 {
                        color: var(--onet-text-dark-color) !important;
                    }

                    &.cancelled-Events h5 {
                        color: var(--onet-text-dark-color) !important;
                    }

                    span {
                        color: var(--onet-white-color) !important;
                        text-decoration: none !important;
                    }

                    .myCalendarEvents-rightStripItem {
                        background-color: var(--onet-dark-bg-primary) !important;
                        box-shadow: 0px 0px 0px;

                        .media-img {
                            width: 50px;
                            height: 50px;
                        }

                        .media-body {
                            .title {
                                color: var(--onet-white-color);
                            }

                            ul {
                                background-color: var(--onet-dark-bg-primary) !important;

                                li {
                                    color: var(--onet-text-dark-color) !important;
                                }
                            }
                        }
                    }
                }
            }

            .tab-content {
                .eventsCalendar-status {
                    background-color: var(--onet-dark-bg-secondary) !important;

                    .tab-wrapper {
                        ul {
                            background: transparent;
                            border: 1px solid var(--onet-dark-border-color) !important;

                            button {
                                color: var(--onet-text-dark-color) !important;
                                border-right: 1px solid var(--onet-dark-border-color);

                                &.btn-upcoming.active,
                                &.btn-completed.active,
                                &.btn-cancelled.active,
                                &.btn-upcoming:hover,
                                &.btn-completed:hover,
                                &.btn-cancelled:hover {
                                    background: var(--onet-primary-color) !important;
                                    color: var(--white-color) !important;
                                    box-shadow: unset !important;
                                }
                            }

                        }
                    }

                    .calendar-date-field {
                        border: 1px solid var(--onet-dark-bg-secondary) !important;
                        background-color: var(--onet-dark-bg-secondary) !important;

                        .custom-filter {
                            &.date-field {
                                background: var(--onet-dark-bg-secondary) !important;
                                color: var(--onet-text-dark-color) !important;
                                border: 1px solid var(--onet-dark-border-color) !important;
                            }

                            .right-icon {
                                background: transparent !important;

                                i {
                                    color: var(--onet-text-dark-color) !important;
                                }
                            }

                            input {
                                background: var(--onet-dark-bg-hovered) !important;
                                color: var(--onet-text-dark-color) !important;
                                border: 1px solid var(--onet-dark-border-color) !important;

                                ::placeholder {
                                    color: var(--onet-text-dark-color) !important;
                                }

                                :-ms-input-placeholder {
                                    color: var(--onet-text-dark-color) !important;
                                }

                                ::-ms-input-placeholder {
                                    color: var(--onet-text-dark-color) !important;
                                }
                            }

                            ::placeholder {
                                color: var(--onet-text-dark-color) !important;
                            }

                            :-ms-input-placeholder {
                                color: var(--onet-text-dark-color) !important;
                            }

                            ::-ms-input-placeholder {
                                color: var(--onet-text-dark-color) !important;
                            }

                            .md-drppicker {
                                .calendar-table {
                                    border: 1px solid var(--onet-primary-color) !important;
                                    background-color: var(--onet-dark-bg-secondary) !important;
                                }

                                th,
                                td {
                                    color: #ffffff;
                                    font-weight: 400;

                                    &:hover {
                                        background-color: var(--onet-primary-color) !important;
                                    }
                                }

                                th.available.prev,
                                th.available.next {
                                    filter: brightness(0) invert(1);
                                }

                                td.off,
                                td.off.end-date,
                                td.off.in-range,
                                td.off.start-date {
                                    background-color: #000 !important;
                                    color: var(--primary-color) !important;
                                }

                                td.active {
                                    background-color: var(--primary-color) !important;
                                }

                                td.in-range {
                                    background-color: var(--onet-primary-color) !important;
                                    border-color: transparent;
                                    color: var(--onet-white-color) !important;
                                }

                                .buttons_input {
                                    button {
                                        background-color: var(--onet-primary-color) !important;
                                        color: var(--onet-white-color) !important;
                                        border: 1px solid var(--onet-primary-color) !important;
                                        margin: 0px !important;
                                    }

                                    button.btn-default {
                                        color: var(--onet-primary-color) !important;
                                        background-color: var(--onet-dark-bg-primary) !important;
                                        border: 1px solid var(--onet-dark-border-color) !important;
                                        margin-right: 10px !important;

                                        svg {
                                            color: #034c53 !important;
                                        }
                                    }
                                }
                            }
                        }

                        .md-drppicker {
                            background-color: var(--onet-dark-bg-primary);

                            .ranges ul li {
                                &:hover {
                                    background-color: var(--onet-primary-color);
                                }

                                button {
                                    color: var(--onet-white-color) !important;

                                    &.active {
                                        background-color: var(--onet-primary-color);
                                    }
                                }
                            }
                        }
                    }

                    .eventsCalendar-statusRow {
                        .eventsCalendar-statusCard {
                            background: var(--onet-dark-bg-primary);
                            border: 1px solid var(--onet-dark-border-color);
                            box-shadow: 0px 0px 0px;

                            .eventsCalendar-img {
                                span {
                                    width: 100%;
                                }
                            }

                            .eventsCalendar-content {
                                .eventsStatusDateMonth {
                                    background-color: var(--onet-dark-bg-hovered) !important;

                                    span {
                                        color: var(--onet-white-color);
                                    }
                                }

                                div {
                                    span {
                                        color: var(--onet-text-dark-color);

                                        img {
                                            filter: brightness(0) saturate(100%) invert(61%) sepia(14%) saturate(613%) hue-rotate(137deg) brightness(93%) contrast(90%);
                                        }
                                    }

                                    p {
                                        color: var(--onet-text-dark-color);
                                    }
                                }

                                h5 {
                                    span {
                                        color: var(--onet-white-color) !important;
                                    }

                                    label {
                                        background-color: var(--onet-dark-bg-hovered) !important;
                                    }
                                }
                            }
                        }

                        .no-activites {
                            color: var(--onet-white-color) !important;
                        }
                    }
                }
            }

        }

        .event-create {
            h4 {
                color: var(--onet-white-color) !important;
            }

            background-color: var(--onet-dark-bg-secondary);

            .events_default_img {
                background-color: var(--onet-dark-bg-hovered) !important;

                .camera_icon img {
                    filter: brightness(0) invert(1);
                }

                .camera_icon h6 {
                    color: var(--onet-white-color);
                }
            }

            .form-control,
            select {
                background-color: var(--onet-dark-bg-hovered) !important;
                background: var(--onet-dark-bg-hovered) !important;
                border: 1px solid var(--onet-dark-border-color) !important;
                color: var(--onet-text-dark-color) !important;
            }

            .custom-search-input {
                background-color: var(--onet-dark-bg-hovered) !important;
                border: 1px solid var(--onet-dark-border-color) !important;

                .form-control {
                    background-color: transparent !important;
                    border-color: transparent !important;
                }
            }

            .date_filelds .form-select::before {
                background-color: var(--onet-dark-bg-hovered) !important;
            }

        }

        //My Media Module CSS
        .my_media {
            .tab-wrapper {
                .card {
                    border-color: var(--onet-dark-border-color) !important;
                }

                .moduleTitle {
                    color: var(--onet-white-color) !important;
                }

                .myMedia-tabs li.active label,
                .myMedia-tabs li:hover label {
                    color: var(--onet-white-color) !important;
                    box-shadow: 0px 3px 0px 0px var(--onet-white-color) !important;
                }
            }

            .right-wrapper {
                .card {
                    background-color: var(--onet-dark-bg-secondary);

                    h5 {
                        color: var(--onet-white-color) !important;
                    }

                    .mymedia_photo {
                        border: 1px solid var(--onet-dark-border-color);
                        box-shadow: unset;

                        .more-acts .more {
                            background-color: var(--onet-dark-bg-primary) !important;
                            opacity: 1 !important;
                        }
                    }

                }
            }
        }

        .myMedia-viewPost {
            .more-acts {
                background-color: transparent !important;

                .more {
                    background-color: var(--onet-dark-bg-primary) !important;
                }
            }
        }

        //Podcasts Module CSS
        .podcasts-tabsdiv {
            .tab-wrapper {
                .nav {
                    border-bottom: none !important;
                }
            }

            .tab-wrapper ul button.active,
            .tab-wrapper ul button:hover {
                box-shadow: unset !important;

                a {
                    border-bottom: none !important;
                    color: var(--onet-white-color) !important;
                    box-shadow: inset 0px -3px 0px 0px #ffffff !important;

                }
            }

            .tab-wrapper .podcast-create {
                .btn-fill {
                    background-color: transparent !important;
                    color: var(--onet-text-dark-color) !important;
                    border: 1px solid var(--onet-text-secondary-color) !important;

                    img {
                        filter: brightness(0) saturate(100%) invert(67%) sepia(12%) saturate(1175%) hue-rotate(136deg) brightness(81%) contrast(83%);
                    }
                }

                .btn-outline {
                    background-color: var(--onet-primary-color) !important;
                    color: var(--onet-white-color) !important;
                    border: 1px solid var(--onet-primary-color) !important;

                    img {
                        filter: none !important;
                    }
                }
            }
        }

        .all-podcast {
            .row_pcast {
                background-color: var(--onet-dark-bg-secondary) !important;

                .heading-wrap {
                    h5 {
                        color: var(--onet-white-color) !important;
                    }

                    .podcat_viewall a {
                        background-color: var(--onet-dark-bg-secondary);
                        color: var(--onet-white-color);
                    }
                }

                .cards-wrap {
                    .card {
                        background: var(--onet-dark-bg-hovered);

                        .image_main {
                            .save-img {
                                .podCost-likeReport {

                                    .savedPodcost11,
                                    .reportpdIcon {
                                        background: var(--onet-primary-color) !important;

                                        img {
                                            filter: brightness(0) saturate(100%) invert(61%) sepia(14%) saturate(613%) hue-rotate(137deg) brightness(93%) contrast(90%);
                                        }
                                    }
                                }
                            }
                        }

                        .card-body {
                            background: var(--onet-dark-bg-primary);

                            .playbtn-duration {
                                .playbtn-duration-bg {
                                    background-color: var(--onet-primary-color) !important;
                                    border-radius: 50%;

                                    img {
                                        filter: brightness(0) saturate(100%) invert(61%) sepia(14%) saturate(613%) hue-rotate(137deg) brightness(93%) contrast(90%);
                                    }
                                }

                                p {
                                    color: var(--onet-text-dark-color) !important;
                                    text-align: center;
                                }

                                .podcast-audio-progress .mat-progress-spinner svg {
                                    stroke: var(--onet-white-color) !important;

                                    circle {
                                        stroke: var(--onet-white-color) !important;
                                    }
                                }
                            }

                            h6 {
                                color: var(--onet-white-color) !important;
                            }

                            p,
                            .date_block span {
                                color: var(--onet-text-dark-color) !important;
                            }

                            .date_block img {
                                filter: brightness(0) saturate(100%) invert(61%) sepia(14%) saturate(613%) hue-rotate(137deg) brightness(93%) contrast(90%) !important;
                            }
                        }
                    }
                }
            }

            .all-podcasts-main,
            .catgory-overviewAll {
                .row_pcast {
                    background-color: var(--onet-dark-bg-secondary) !important;

                    .heading-wrap {
                        h5 {
                            color: var(--onet-white-color) !important;
                        }

                        .podcat_viewall a {
                            background-color: var(--onet-dark-bg-secondary);
                            color: var(--onet-white-color);
                        }
                    }

                    .cards-wrap {
                        .card {
                            background: var(--onet-dark-bg-hovered);

                            .image_main {
                                .save-img {
                                    .podCost-likeReport {

                                        .savedPodcost11,
                                        .reportpdIcon {
                                            background: var(--onet-primary-color) !important;

                                            img {
                                                filter: brightness(0) saturate(100%) invert(61%) sepia(14%) saturate(613%) hue-rotate(137deg) brightness(93%) contrast(90%);
                                            }
                                        }
                                    }
                                }
                            }

                            .card-body {
                                background: var(--onet-dark-bg-primary) !important;

                                .playbtn-duration {
                                    .playbtn-duration-bg {
                                        background-color: var(--onet-primary-color) !important;
                                        border-radius: 50%;

                                        img {
                                            filter: brightness(0) saturate(100%) invert(61%) sepia(14%) saturate(613%) hue-rotate(137deg) brightness(93%) contrast(90%);
                                        }
                                    }

                                    p {
                                        color: var(--onet-text-dark-color) !important;
                                        text-align: center;
                                    }

                                    .podcast-audio-progress .mat-progress-spinner svg {
                                        stroke: var(--onet-white-color) !important;

                                        circle {
                                            stroke: var(--onet-white-color) !important;
                                        }
                                    }
                                }

                                h6 {
                                    color: var(--onet-white-color) !important;
                                }

                                p,
                                .date_block span {
                                    color: var(--onet-text-dark-color) !important;
                                }

                                .date_block img {
                                    filter: brightness(0) saturate(100%) invert(61%) sepia(14%) saturate(613%) hue-rotate(137deg) brightness(93%) contrast(90%) !important;
                                }
                            }
                        }
                    }
                }
            }
        }

        .podcast-view-wrapper {
            .comments_card {
                box-shadow: unset !important;
                background-color: var(--onet-dark-bg-secondary) !important;

                span a {
                    color: var(--onet-white-color) !important;
                }
            }

            background-color: var(--onet-dark-bg-secondary) !important;

            .play-wrapper-right {
                background-color: var(--onet-dark-bg-primary) !important;

                .title span {
                    color: var(--onet-white-color) !important;
                }

                .releted_block {
                    background-color: var(--onet-dark-bg-primary) !important;
                    margin-top: 0px;

                    .play-inns {
                        // margin-top: 13px;
                        margin-bottom: 0px;

                        &:hover {
                            background-color: transparent !important;
                        }

                        .tags_thumbs {
                            h4 {
                                color: var(--onet-white-color) !important;
                                font-weight: 400 !important;
                                font-size: var(--clamp14) !important;
                            }

                            p {
                                color: var(--onet-text-dark-color) !important;
                            }
                        }
                    }
                }
            }

            .play-wrapper-left {
                .playBox {
                    .audio-content {

                        .play-btns,
                        .valumeBtn {
                            button {
                                span {
                                    img {
                                        filter: brightness(0) saturate(100%) invert(61%) sepia(14%) saturate(613%) hue-rotate(137deg) brightness(93%) contrast(90%) !important;
                                    }
                                }
                            }
                        }

                        .range-block {
                            .tracks span {
                                color: var(--onet-text-dark-color) !important;
                            }
                        }
                    }

                    .podcast-audio-titles {
                        .title_tags {
                            p {
                                color: var(--onet-text-dark-color) !important;
                            }
                        }

                        .podcast-audio-icons {
                            ul {
                                li {
                                    a {
                                        img {
                                            filter: brightness(0) saturate(100%) invert(61%) sepia(14%) saturate(613%) hue-rotate(137deg) brightness(93%) contrast(90%) !important;
                                        }
                                    }

                                    span {
                                        color: var(--onet-text-dark-color);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .my-podcast-wrap {
            .my-podcast-module {
                background-color: var(--onet-dark-bg-secondary) !important;

                .heading-wrap {
                    a {
                        background-color: var(--onet-dark-bg-secondary) !important;
                        color: var(--onet-white-color) !important;
                    }

                    h5 {
                        color: var(--onet-white-color) !important;
                    }

                    .podcat_viewall a {
                        background-color: var(--onet-dark-bg-secondary) !important;
                        color: var(--onet-white-color) !important;
                    }
                }

            }

            .cards-wrap {
                position: relative;

                .card {
                    background-color: var(--onet-dark-bg-hovered) !important;
                    border: 1px solid var(--onet-dark-border-color);

                    .image_main {
                        .save-img {
                            .podCost-likeReport {

                                .savedPodcost11,
                                .reportpdIcon,
                                .deletepdIcon {
                                    background: var(--onet-primary-color) !important;

                                    img {
                                        filter: brightness(0) saturate(100%) invert(61%) sepia(14%) saturate(613%) hue-rotate(137deg) brightness(93%) contrast(90%);
                                    }
                                }
                            }
                        }
                    }

                    .card-body {
                        background-color: var(--onet-dark-bg-primary) !important;

                        .playbtn-duration {
                            .playbtn-duration-bg {
                                background-color: var(--onet-primary-color) !important;
                                border-radius: 50% !important;

                                img {
                                    filter: brightness(0) saturate(100%) invert(61%) sepia(14%) saturate(613%) hue-rotate(137deg) brightness(93%) contrast(90%);
                                }
                            }

                            p {
                                color: var(--onet-text-dark-color) !important;
                                text-align: center;
                            }

                            .podcast-audio-progress .mat-progress-spinner svg {
                                stroke: var(--onet-white-color) !important;

                                circle {
                                    stroke: var(--onet-white-color) !important;
                                }
                            }
                        }

                        h6 {
                            color: var(--onet-white-color) !important;
                        }

                        p,
                        .date_block span {
                            color: var(--onet-text-dark-color) !important;
                        }

                        .date_block img {
                            filter: brightness(0) saturate(100%) invert(61%) sepia(14%) saturate(613%) hue-rotate(137deg) brightness(93%) contrast(90%) !important;
                        }
                    }
                }
            }
        }

        .my-channels-wrapper {
            background-color: var(--onet-dark-bg-secondary) !important;

            .left-card-wrpper {
                .card {
                    .card-header {
                        background: var(--onet-dark-bg-secondary) !important;

                        h5 {
                            color: var(--onet-white-color) !important;

                            .myChannel-count {
                                color: var(--onet-text-dark-color) !important;
                            }
                        }
                    }

                    .card-body {
                        background: var(--onet-dark-bg-secondary) !important;

                        ul {
                            li {

                                &:hover,
                                &.active {
                                    background: var(--onet-dark-bg-secondary) !important;
                                }

                                label {
                                    color: var(--onet-white-color) !important;
                                }
                            }
                        }
                    }
                }
            }

            .podcast-view-wrapper {
                padding: 0px 0px;
                margin: 0px 0px 30px;
                margin-top: 0px;

                .channel_cover {
                    .channel_descript {
                        background-color: var(--onet-dark-bg-secondary) !important;
                        border: 1px solid var(--onet-dark-border-color);
                        padding: 1rem;

                        h5 {
                            color: var(--onet-white-color) !important;
                        }

                        p {
                            color: var(--onet-text-dark-color) !important;
                        }
                    }
                }

                .row_pcast {
                    background-color: var(--onet-dark-bg-secondary) !important;

                    .cards-wrap {
                        position: relative;

                        .card {
                            background-color: var(--onet-dark-bg-hovered) !important;
                            border: 1px solid var(--onet-dark-border-color);

                            .image_main {
                                .save-img {
                                    .podCost-likeReport {

                                        .savedPodcost11,
                                        .reportpdIcon,
                                        .deletepdIcon {
                                            background: var(--onet-primary-color) !important;

                                            img {
                                                filter: brightness(0) saturate(100%) invert(61%) sepia(14%) saturate(613%) hue-rotate(137deg) brightness(93%) contrast(90%);
                                            }
                                        }
                                    }
                                }
                            }

                            .card-body {
                                background-color: var(--onet-dark-bg-primary) !important;

                                .playbtn-duration {
                                    .playbtn-duration-bg {
                                        background-color: var(--onet-primary-color) !important;
                                        border-radius: 50% !important;

                                        img {
                                            filter: brightness(0) saturate(100%) invert(61%) sepia(14%) saturate(613%) hue-rotate(137deg) brightness(93%) contrast(90%);
                                        }
                                    }

                                    p {
                                        color: var(--onet-text-dark-color) !important;
                                        text-align: center;
                                    }

                                    .podcast-audio-progress .mat-progress-spinner svg {
                                        stroke: var(--onet-white-color) !important;

                                        circle {
                                            stroke: var(--onet-white-color) !important;
                                        }
                                    }
                                }

                                h6 {
                                    color: var(--onet-white-color) !important;
                                }

                                p,
                                .date_block span {
                                    color: var(--onet-text-dark-color) !important;
                                }

                                .date_block img {
                                    filter: brightness(0) saturate(100%) invert(61%) sepia(14%) saturate(613%) hue-rotate(137deg) brightness(93%) contrast(90%) !important;
                                }
                            }
                        }
                    }
                }
            }
        }

        .create-channel {
            h5 {
                color: var(--onet-white-color) !important;
            }

            .upload_photo {
                background-color: var(--onet-dark-bg-primary);
                border: 1px solid var(--onet-dark-border-color);

                .uploadwrap label img {
                    filter: brightness(0) saturate(100%) invert(67%) sepia(12%) saturate(1175%) hue-rotate(136deg) brightness(81%) contrast(83%);
                }

                .uploadwrap h6 {
                    color: var(--onet-white-color) !important;
                }
            }

            .upload_blk {
                background-color: var(--onet-dark-bg-primary);
                border: 1px solid var(--onet-dark-border-color) !important;

                .custom-dropzone {
                    background-color: var(--onet-dark-bg-hovered) !important;
                    border: 1px dashed var(--onet-dark-border-color) !important;
                    box-shadow: unset !important;
                    border-radius: 8px !important;

                    h6 {
                        color: var(--onet-white-color) !important;
                    }

                    label img {
                        filter: brightness(0) saturate(100%) invert(67%) sepia(12%) saturate(1175%) hue-rotate(136deg) brightness(81%) contrast(83%);
                    }
                }

                .addaudio .ad h6 {
                    color: var(--onet-white-color) !important;
                }

                .addaudio .ad small {
                    color: var(--onet-text-dark-color) !important;
                }

                .addaudio {
                    .uploaded-thumbs {
                        color: var(--onet-text-dark-color) !important;

                        ngx-dropzone-preview ngx-dropzone-remove-badge {
                            opacity: 1;
                            background: var(--onet-dark-bg-secondary) !important;
                            color: var(--onet-primary-color) !important;

                            svg line {
                                stroke: var(--onet-primary-color) !important;
                            }
                        }
                    }

                    .h-100.bg-transparent {
                        background-color: #034c53 !important;
                        border-radius: 50%;
                        width: 35px;
                        height: 35px !important;
                        justify-content: center;
                        display: flex;
                        align-items: center;

                        img {
                            filter: brightness(0) saturate(100%) invert(90%) sepia(41%) saturate(328%) hue-rotate(136deg) brightness(92%) contrast(91%) !important;
                        }
                    }
                }
            }

            .termsConditions span {
                color: var(--onet-text-dark-color) !important;
            }
        }

        .audio-upload {
            h5 {
                color: var(--onet-white-color) !important;
            }

            .upload_blk {
                background-color: var(--onet-dark-bg-primary);
                border: 1px solid var(--onet-dark-border-color) !important;

                .custom-dropzone {
                    box-shadow: unset;
                    border-color: var(--onet-dark-border-color) !important;

                    h6 {
                        color: var(--onet-white-color) !important;
                    }

                    label img {
                        filter: brightness(0) saturate(100%) invert(67%) sepia(12%) saturate(1175%) hue-rotate(136deg) brightness(81%) contrast(83%);
                    }
                }
            }

            .addaudio {
                .uploaded-thumbs {
                    color: var(--onet-text-dark-color) !important;

                    ngx-dropzone-preview ngx-dropzone-remove-badge {
                        opacity: 1;
                        background: var(--onet-dark-bg-primary) !important;
                        color: var(--onet-primary-color) !important;

                        svg line {
                            stroke: var(--onet-primary-color) !important;
                        }
                    }
                }
            }

            .termsConditions span {
                color: var(--onet-text-dark-color) !important;
            }

            .addaudio {
                background-color: var(--onet-dark-bg-hovered) !important;
                border-color: var(--onet-dark-border-color) !important;

                .ad h6 {
                    color: var(--onet-white-color) !important;
                }

                .ad small {
                    color: var(--onet-text-dark-color) !important;
                }

                .uplploadAudioIcon {
                    background-color: var(--onet-primary-color) !important;

                    img {
                        filter: brightness(0) saturate(100%) invert(90%) sepia(41%) saturate(328%) hue-rotate(136deg) brightness(92%) contrast(91%) !important;
                    }
                }

                .bg-transparent {
                    background-color: var(--onet-primary-color) !important;
                }
            }
        }

        .schedulepost-wrap {
            .search_icon {
                top: 7px !important;
                right: 8px;
                // height: 25px;
                filter: brightness(0) saturate(100%) invert(67%) sepia(12%) saturate(1175%) hue-rotate(136deg) brightness(81%) contrast(83%);
            }

            .schedulePostDate {
                .mat-form-field-appearance-fill {
                    .mat-form-field-flex {
                        background: var(--onet-dark-bg-secondary) !important;
                        border: 1px solid var(--onet-dark-border-color) !important;

                        button {
                            .mat-datepicker-toggle-default-icon {
                                filter: brightness(0) saturate(100%) invert(67%) sepia(12%) saturate(1175%) hue-rotate(136deg) brightness(81%) contrast(83%);
                            }
                        }
                    }
                }
            }

            .schelude_post_wrap {
                border: 1px solid var(--onet-dark-bg-secondary);
                background-color: var(--onet-dark-bg-secondary) !important;

                .main-card {
                    .table-responsive {
                        border: 1px solid transparent !important;
                    }

                    table {
                        tr {
                            td {
                                background-color: var(--onet-dark-bg-primary) !important;
                                color: var(--onet-text-dark-color) !important;
                                border-bottom: 1px solid var(--onet-dark-border-color) !important;

                                .action-btns {
                                    button {
                                        background: var(--onet-dark-bg-secondary) !important;

                                        img {
                                            filter: brightness(0) saturate(100%) invert(67%) sepia(12%) saturate(1175%) hue-rotate(136deg) brightness(81%) contrast(83%);
                                        }
                                    }
                                }
                            }
                        }

                        .bg-white {
                            background-color: var(--onet-dark-bg-primary) !important;
                        }
                    }

                }

                ul.pagination li a {
                    background-color: var(--onet-dark-bg-primary) !important;
                    color: var(--onet-white-color) !important;
                    border-color: var(--onet-dark-border-color) !important;
                }

                ul.pagination li.active a {
                    background-color: var(--onet-primary-color) !important;
                }
            }
        }

        .edit-schedule-post {
            &.main-card {
                .privacy_wrap {
                    select {
                        border: 1px solid var(--onet-dark-border-color) !important;
                        background-color: var(--onet-dark-bg-primary) !important;
                        color: var(--onet-white-color) !important;
                        padding: 6px 6px;
                    }
                }

                .editSchedulePostPhotos {
                    .media-tags a .icn img {
                        filter: brightness(0) saturate(100%) invert(67%) sepia(12%) saturate(1175%) hue-rotate(136deg) brightness(81%) contrast(83%) !important;
                    }

                    .media-tags a span {
                        color: var(--onet-white-color);
                    }
                }

                .editTagfriends .tagFrndBlog a label .icn {
                    filter: brightness(0) saturate(100%) invert(67%) sepia(12%) saturate(1175%) hue-rotate(136deg) brightness(81%) contrast(83%) !important;
                }

                .editTagfriends .tagFrndBlog a label span {
                    color: var(--onet-white-color) !important;
                }
            }
        }

        .noData-reportpost {
            .no_data_available {
                background-color: var(--onet-dark-bg-secondary) !important;
                box-shadow: unset !important;

                h5 {
                    color: var(--onet-white-color) !important;
                }

                .txt {
                    color: var(--onet-text-dark-color) !important;
                }
            }
        }

        .msg-left-content {
            .moduleTitleDiv {
                border-color: var(--onet-dark-border-color) !important;

                .moduleTitle {
                    color: var(--onet-white-color) !important;
                }
            }

            .messages {
                .container-fluid {
                    .left-nav {
                        .card {
                            background: var(--onet-dark-bg-secondary);
                            border-radius: 8px;
                            // overflow: hidden;

                            .card-header .custom-search-input {
                                border-radius: 8px;
                            }

                            .card-header .custom-search-input .input-group-btn button img {
                                filter: brightness(0) saturate(100%) invert(67%) sepia(12%) saturate(1175%) hue-rotate(136deg) brightness(81%) contrast(83%) !important;
                            }

                            .card-body {
                                h4 {
                                    color: var(--onet-white-color) !important;
                                }

                                .start_conversation {
                                    ul {
                                        li {
                                            background-color: var(--onet-dark-bg-hovered) !important;
                                            box-shadow: 1px 0px 1px 0px #e7e7e7b0 !important;
                                            &:hover, &.active{
                                                background-color: var(--onet-primary-color) !important;
                                            }
                                            label {
                                                color: var(--onet-white-color) !important;

                                                span {
                                                    color: var(--onet-text-dark-color) !important;
                                                }
                                            }
                                        }
                                    }
                                }

                                .no-conversations {
                                    background-color: var(--onet-dark-bg-secondary) !important;
                                    padding: 25px 0px;

                                    h2 {
                                        color: var(--onet-white-color) !important;
                                    }
                                }
                            }
                        }
                    }
                }

                .msg_wrap {
                    .right-pannel {
                        background-color: var(--onet-dark-bg-secondary) !important;
                        border-radius: 8px;

                        .card {
                            background-color: var(--onet-dark-bg-secondary) !important;

                            .card-header {
                                border-radius: 8px 8px 0px 0px;
                                background: var(--onet-dark-bg-secondary) !important;
                                border-color: var(--onet-dark-border-color) !important;

                                label span {
                                    color: var(--onet-white-color) !important;
                                }
                                .mob_back{
                                    i{
                                        filter: brightness(0.5) invert(1) !important;
                                    }
                                }
                            }

                            .card-body {
                                .sender_mainwrap .sender_msg p {
                                    background-color: var(--onet-dark-bg-primary) !important;
                                    color: var(--onet-white-color) !important;

                                    &::after {
                                        border-left: 50px solid var(--onet-dark-bg-primary) !important;
                                    }
                                }

                                .send_msg_time {
                                    color: var(--onet-text-dark-color) !important;
                                }

                                .receiver_msg {
                                    position: relative;
                                    top: -5px;

                                    p {
                                        background-color: var(--onet-dark-bg-primary) !important;
                                        color: var(--onet-white-color) !important;

                                        &::after {
                                            border-right: 50px solid var(--onet-dark-bg-primary) !important;
                                        }
                                    }
                                }

                                .receive_msg_time {
                                    color: var(--onet-text-dark-color) !important;
                                }
                            }

                            .card-footer {
                                // background-color: transparent !important;
                                // border: 1px solid var(--onet-dark-border-color) !important;

                                .msg_box .msg_inp {
                                    input {
                                        color: var(--onet-text-dark-color) !important;
                                    }

                                    span .more img {
                                        filter: brightness(0) saturate(100%) invert(67%) sepia(12%) saturate(1175%) hue-rotate(136deg) brightness(81%) contrast(83%);
                                    }

                                    span .send-button img {
                                        filter: brightness(1) invert(0);
                                    }
                                }
                            }

                            .card_scroll {
                                border-bottom: var(--onet-dark-border-color) !important;
                            }
                        }
                    }
                }

                @media screen and (max-width: 575px) {
                    .mob-responce .custom-search-input-mob {
                        border: 1px solid var(--onet-dark-border-color) !important;
                        background-color: var(--onet-dark-bg-hovered) !important;
                    }

                    .container-fluid .left-nav .card-body ul li {
                        border-bottom: 1px solid var(--onet-dark-bg-secondary) !important;
                    }
                }

            }

            .onet-right-wrapper {
                .msg-right {
                    background-color: var(--onet-dark-bg-secondary) !important;
                    border-radius: 8px;

                    .nodata {
                        color: var(--onet-white-color);
                    }

                    .message-wrap {
                        .message-blog {
                            background-color: var(--onet-dark-bg-secondary) !important;

                            .img-about-section {
                                background-color: var(--onet-dark-bg-secondary) !important;
                            }

                            .about-me,
                            .photos,
                            .videos,
                            .Mutual-frds {
                                background-color: var(--onet-dark-bg-secondary) !important;

                                ul {
                                    background-color: var(--onet-dark-bg-secondary) !important;
                                }
                            }

                            .about-me {
                                p.info {
                                    color: var(--white-color) !important;
                                }

                                p {
                                    color: var(--onet-text-dark-color) !important;
                                }

                                h4 {
                                    color: var(--white-color) !important;
                                }
                            }

                            .profile-blcok ul li {
                                span {
                                    color: var(--onet-text-dark-color) !important;
                                }

                                span:first-child {
                                    color: var(--onet-white-color) !important;
                                }
                            }

                            .imgcl h4,
                            h6 {
                                color: var(--onet-white-color) !important;
                            }

                            .aclass {
                                color: #2692FF !important;
                                font-size: var(--clamp14);
                                margin-top: 0px !important;
                            }

                            .photos-blcok-one span {
                                color: var(--onet-text-dark-color) !important;
                            }
                        }
                    }
                }
            }
        }

        .selectCoverPhotos .main-card {
            .title-capts h4 {
                color: var(--onet-white-color) !important;
            }

            .card {
                background-color: var(--onet-dark-bg-hovered);
                border: 1px solid var(--onet-dark-border-color) !important;

                .image_main {
                    background-color: var(--onet-dark-bg-hovered);
                    border: 1px solid var(--onet-dark-border-color) !important;
                }
            }

        }

        .btn-outline {
            background: transparent !important;
            color: var(--onet-text-secondary-color) !important;
            border: 1px solid var(--onet-text-secondary-color) !important;
        }


        //R Added Styles end

















        /* S Added Styles Start*/
        .tab_wrapper {
            .mat-tab-header {
                background-color: var(--onet-dark-bg-primary) !important;
                border-bottom: 1px solid var(--onet-dark-bg-secondary);
            }

            .main-content .card-wrap .card .prof_capts {
                background-color: var(--onet-dark-bg-primary) !important;
            }

            .main-content .horiz_view .card {
                background-color: var(--onet-dark-bg-primary) !important;
                border: 1px solid #1E384E !important;
            }

            .main-content .card-wrap .card .img_width {
                border-radius: 10px 10px 0px 0px !important;
                border-bottom: 1px solid #1e384e2b !important;
            }

            .main-content .horiz_view .card .prof_capts h5 {
                color: var(--onet-text-primary-color) !important;
            }

            .main-content .card-wrap .card .friend_block label {
                color: var(--onet-text-secondary-color) !important;
            }

            .main-content .title-capts h4 {
                color: var(--onet-text-primary-color) !important;
            }

            .main-content .title-capts h4 small {
                color: var(--onet-text-primary-color) !important;
            }
        }

        .moduleTitleDiv {
            h4 {
                color: var(--onet-text-primary-color) !important;
            }
        }

        .main-card {
            background-color: var(--onet-dark-bg-secondary) !important;
            border: 1px solid transparent !important;

        }

        .blogs-tab-wrapper.main-card,
        .allBlogs.main-card {
            .custom-search-input .input-group-btn button {
                background-color: #ffffff00 !important;
            }
        }

        .clubs_tab_wrapper .main-card {
            background-color: var(--onet-dark-bg-secondary) !important;
            border: 1px solid transparent !important;
        }

        .tab-group .main-content .card-wrap .card .more-acts .more {
            background: var(--onet-dark-bg-secondary) !important;
        }

        .tab-group .main-content .card-wrap .card {
            background-color: var(--onet-dark-bg-primary) !important;
            border: 1px solid #1E384E !important;
        }

        .tab-group .main-content .card-wrap .card .prof_capts .name_strip {
            color: var(--onet-text-primary-color) !important;
        }

        .friends-right-wrap {
            .friends-right-inn {
                background-color: var(--onet-dark-bg-secondary) !important;

                h4 {
                    color: var(--onet-text-primary-color) !important;
                }
            }
        }

        .friends-right-wrap .right_friends_content h4 {
            color: var(--onet-text-secondary-color) !important;
        }

        .friend_content .custom-search-input {
            background-color: var(--onet-dark-bg-secondary) !important;
            box-shadow: none !important;
            border: 1px solid var(--onet-text-secondary-color) !important;

            .form-control {
                color: var(--onet-text-secondary-color) !important;
            }
        }

        .tab-group .main-content .title-capts a {
            color: var(--onet-text-primary-color) !important;

            img {
                filter: brightness(0) invert(1);
            }
        }

        .custom-search-input .inner-group button img {
            filter: brightness(7) contrast(0.4) !important;
        }

        .title-capts .mat-form-field-flex {
            background-color: var(--onet-dark-bg-secondary) !important;
            border: 1px solid var(--onet-text-secondary-color) !important;
        }

        .friends-right-wrap .right_friends_item_btn a {
            color: var(--onet-text-primary-color) !important;

            img {
                filter: brightness(0) invert(1);
            }
        }

        ::ng-deep .mat-select-arrow {
            color: #fff !important;
        }

        .tab_wrapper .mat-tab-label.mat-tab-label-active .mat-tab-label-content {
            color: var(--onet-white-color) !important;
            border-bottom: 3px solid var(--onet-white-color) !important;
        }

        .friends-sort-wrap mat-select::placeholder {
            color: var(--onet-white-color) !important;
        }

        .no_data_block {
            background-color: var(--onet-dark-bg-secondary) !important;
        }

        .main-content .card {
            border: 1px solid #1E384E !important;
            background: var(--onet-dark-bg-primary) !important;

            .name_strip {
                color: var(--onet-white-color) !important;
            }
        }

        .clubs_count {
            color: var(--onet-white-color) !important;
        }

        .allClubs a {
            color: var(--onet-white-color) !important;

            img {
                filter: brightness(0) invert(1);
            }
        }

        .clubs_block .members_count {
            background: var(--onet-primary-color) !important;
            color: var(--onet-white-color) !important;
            filter: none !important;
        }

        .create_club .main-card .img-upload-block {
            background: var(--onet-dark-bg-primary) !important;
            border: 1px solid #1E384E !important;
        }

        .img-upload-block img {
            filter: brightness(0) invert(1);
        }

        .img-upload-block h6 {
            color: var(--onet-white-color) !important;

            small {
                color: var(--onet-text-secondary-color) !important;
            }
        }

        .invite_friends .custom-search-input {
            background-color: var(--onet-dark-bg-primary) !important;
            border: #1E384E !important;
        }

        .create_club .main-card .form-control,
        .create_club .main-card .form-select {
            border: 1px solid #1E384E !important;
            background-color: var(--onet-dark-bg-primary) !important;
            color: var(--onet-text-secondary-color) !important;
        }

        .create_club .custom-search-input .form-control {
            border: none !important;
        }

        .create_club .custom-search-input .inner-group {
            border: 1px solid #1E384E !important;
            border-radius: 10px !important;
        }

        .club-content-inn .card .card-body {
            background-color: var(--onet-dark-bg-secondary) !important;
            margin: 0 !important;

            .club_name h3 {
                color: var(--onet-white-color) !important;
            }
        }

        .club-content-inn .box-layout .more-acts {
            background-color: transparent !important;
        }

        .club-content-inn .openText h2 {
            color: var(--onet-white-color) !important;
        }

        .club_members .main-card .inn-card .card-body h5 {
            color: var(--onet-white-color) !important;
        }

        .club_members .main-card .main-head h5 {
            color: var(--onet-white-color) !important;

            span {
                color: var(--onet-white-color) !important;
            }
        }

        .club_members .main-card .inn-card .card-body h6 {
            color: var(--onet-white-color) !important;
        }

        .club_members .main-card .inn-card .members_card .card {
            background-color: var(--onet-dark-bg-secondary) !important;

            h5 {
                color: var(--onet-white-color) !important;
            }
        }

        .club_more button img {
            background-color: var(--onet-dark-bg-primary) !important;
        }

        .club_members .inn-card .card_block .more-acts img {
            background: var(--onet-dark-bg-primary) !important;
        }

        .view-all a {
            color: var(--onet-white-color) !important;

            img {
                filter: brightness(0) invert(1) !important;
            }
        }

        .club-members .members-main-card {
            background-color: var(--onet-dark-bg-secondary) !important;

            h5 {
                color: var(--onet-white-color) !important;
            }
        }

        .club-members .members-main-card .members-inn-card {
            background: var(--onet-dark-bg-primary) !important;
            border: 1px solid #1E384E !important;
            box-shadow: none !important;

            h5 {
                color: var(--onet-text-secondary-color) !important;
            }

            h6 {
                color: var(--onet-white-color) !important;
            }
        }

        .left-wrapper .left-wrapper-inn .bg .capt-inn small {
            color: var(--onet-white-color) !important;
        }

        .left-wrapper .left-wrapper-inn .bg .capt-inn .txt {
            color: var(--onet-text-secondary-color) !important;
        }

        .left-wrapper .left-wrapper-inn .bg .capt-inn .text-desc {
            color: var(--onet-white-color) !important;
        }

        .profile-main-wrapper .profile-right-content .profile-right-wrapper {
            background-color: var(--onet-dark-bg-secondary) !important;

            label {
                color: #fff !important;
            }
        }

        .profile-main-wrapper .profile-left-wrapper {
            background-color: var(--onet-dark-bg-secondary) !important;
            color: var(--onet-white-color) !important;
        }

        .profile-view .form-control,
        .profile-view .form-select,
        .education-view .form-control,
        .education-view .form-select,
        .proffesional-info-view .form-control,
        .proffesional-info-view .form-select,
        .address-view .form-control,
        .address-view .form-select {
            background-color: var(--onet-dark-bg-hovered) !important;
            color: var(--onet-text-secondary-color) !important;
            border: transparent !important;
        }

        .profile-view .date_wrap .mat-form-field-appearance-fill .mat-form-field-infix,
        .education-view .date_wrap .mat-form-field-appearance-fill .mat-form-field-infix,
        .proffesional-info-view .date_wrap .mat-form-field-appearance-fill .mat-form-field-infix,
        .address-view .date_wrap .mat-form-field-appearance-fill .mat-form-field-infix {
            background-color: var(--onet-dark-bg-hovered) !important;
            color: var(--onet-text-secondary-color) !important;
            border: transparent !important;
        }

        .about-view .card {
            background-color: var(--onet-dark-bg-hovered) !important;
            color: var(--onet-text-secondary-color) !important;
            border: transparent !important;
        }

        .profile-right-wrapper .form-control,
        .form-selct {
            background-color: var(--onet-dark-bg-hovered) !important;
            color: var(--onet-text-secondary-color) !important;
            border: transparent !important;
        }

        .date_wrap .mat-form-field-appearance-fill .mat-form-field-infix {
            background-color: var(--onet-dark-bg-hovered) !important;
            color: var(--onet-text-secondary-color) !important;
            border: transparent !important;
        }

        .profile-info-right .card .img-crop-wrap {
            background-color: var(--onet-dark-bg-primary) !important;
        }

        .privacy-wrap .ng-select.ng-select-single .ng-select-container {
            color: var(--onet-white-color);
            background: var(--onet-primary-color) !important;
            border: none !important;
        }

        .privacy-wrap img {
            // filter: brightness(0) invert(1) !important;
            filter: brightness(0) saturate(100%) invert(67%) sepia(12%) saturate(1175%) hue-rotate(136deg) brightness(81%) contrast(83%) !important;
            //background-color: var(--onet-dark-bg-primary) !important;
            //color: var(--onet-white-color) !important;
        }

        .privacy-wrap .ng-dropdown-panel {
            background-color: transparent !important;
        }

        .privacy-view img {
            filter: brightness(0) invert(1) !important;
        }

        .privacy-view label {
            color: var(--onet-white-color) !important;
        }

        .profile-main-wrapper .profile-right-content .profile-right-wrapper h6 {
            color: var(--onet-white-color) !important;
        }

        .date_wrap .mat-input-element {
            color: var(--onet-text-secondary-color) !important;
        }

        .delete-profile-wrap label {
            color: var(--onet-text-secondary-color) !important;
        }

        .professional-information label,
        .profile-main-wrapper .form-check-label,
        .date_wrap input::placeholder,
        .profile-right-wrapper input::placeholder,
        .date_wrap .mat-datepicker-toggle,
        .mat-datepicker-content .mat-calendar-next-button,
        .mat-datepicker-content .mat-calendar-previous-button,
        .mat-select-wrap .mat-form-field-label,
        .mat-select-wrap .mat-select-value-text {
            color: var(--onet-text-secondary-color) !important;
        }

        .profile-info-left .mat-select,
        .mat-select-wrap .mat-select {
            background-color: transparent !important;
        }

        .date_wrap input,
        .date_wrap .mat-form-field {
            background-color: transparent !important;
        }

        .date_wrap .mat-form-field-appearance-fill .mat-form-field-flex {
            background-color: var(--onet-dark-bg-secondary) !important;
        }

        .heading-wrap h5,
        .allBlogs h5 {
            color: var(--onet-white-color) !important;
        }

        .grid-view .card,
        .grid-view .card .blog_body .card-footer {
            background-color: var(--onet-dark-bg-primary) !important;
        }

        .grid-view .card .blog_body .card-body label.name_label {
            color: var(--onet-white-color) !important;

            span {
                color: var(--onet-white-color) !important;
            }
        }

        .grid-view .card .blog_body .card-body label,
        .grid-view .card .blog_body .card-body h5>p {
            color: var(--onet-white-color) !important;
        }

        .grid-view .card .blog_body .card-body .card-text p,
        .grid-view .card .blog_body .card-body .card-text p font {
            color: var(--onet-text-secondary-color) !important;
        }

        .grid-view .card .blog_body .card-footer img {
            filter: brightness(10) contrast(0.5) !important;
        }

        .grid-view .card .blog_body .card-footer span {
            color: var(--onet-text-secondary-color) !important;
        }

        .drafted_blogs .blog_card {
            border: transparent !important;
        }

        .card-footer .btn-two {
            background-color: var(--onet-text-secondary-color) !important;

            a {
                color: var(--onet-white-color) !important;
            }
        }

        .create_blog .main-card .img-upload-block,
        .createBlog-Titlediv .fr-box {
            background: var(--onet-dark-bg-primary) !important;
            border: 1px solid #1E384E !important;
            color: var(--onet-text-dark-color) !important;
        }

        .create_blog .fr-box.fr-basic {
            background: var(--onet-dark-bg-primary) !important;
            border: 1px solid #1E384E !important;
        }

        .create_blog {

            .fr-toolbar,
            .fr-box.fr-basic .fr-wrapper,
            .fr-second-toolbar {
                background: var(--onet-dark-bg-primary) !important;
            }
        }

        .create_blog .main-card h5 {
            color: var(--onet-white-color) !important;
        }

        .draft_link a {
            color: var(--onet-text-secondary-color) !important;
        }

        .allBlogs .custom-search-input,
        .my-drafts .custom-search-input {
            background-color: var(--onet-dark-bg-primary) !important;
            border: 1px solid #1E384E !important;
        }

        .custom-search-input .inner-group input::placeholder {
            color: var(--onet-text-secondary-color) !important;
        }

        // .tab-wrapper ul button.active a{
        //     border-bottom: 3px solid var(--onet-white-color) !important;
        //     color: var(--onet-white-color) !important;
        // }
        .grid-view .card .blog_body .card-body a {
            color: #2692FF !important;
        }

        .view-blog .main-card label {
            color: var(--onet-text-secondary-color) !important;
        }

        .view-blog .main-card .blog_desc p {
            color: var(--onet-text-dark-color) !important;
        }

        .view-blog .main-card h2>p {
            color: var(--onet-white-color) !important;
        }

        .view-blog .more_icon {
            background: var(--onet-dark-bg-primary) !important;
        }

        .social-btns .lik-dropdown .like-icn img,
        .blog_icons button img {
            filter: brightness(10) contrast(0.5) !important;
        }

        .social-btns .like-icn.circle-c img {
            filter: none !important;
        }

        .social-btns .icn label {
            color: var(--onet-text-secondary-color) !important;
        }

        .social-btns .dropdown.dropup a {
            color: var(--onet-text-secondary-color) !important;
        }

        .social-btns .icn>div {
            color: var(--onet-text-secondary-color) !important;
        }

        .grid-view .card {
            border: 1px solid #1E384E !important;
        }

        .social-btns .reaction_count {
            color: var(--onet-text-secondary-color) !important;
        }

        .blog_comments_wrap {
            background: var(--onet-dark-bg-secondary) !important;
            border-radius: 10px;
        }

        .schelude_post_wrap {
            border: 1px solid transparent !important;
        }

        .schelude_post_wrap table tr td {
            border-bottom: 1px solid var(--onet-dark-bg-secondary) !important;
        }

        .schelude_post_wrap .bg-white {
            background-color: var(--onet-dark-bg-primary) !important;
        }

        .schelude_post_wrap .table-responsive {
            border: 1px solid transparent !important;
        }

        .schedulePostDate .mat-form-field {
            background-color: transparent !important;
        }

        .schedulepost-wrap .schedulePostDate .mat-form-field-appearance-fill .mat-form-field-flex,
        .schedulepost-wrap .searchInput input {
            background: var(--onet-dark-bg-hovered) !important;
            border: 1px solid #1E384E !important;
        }

        .schedulePostDate ::placeholder,
        .schedulePostDate input {
            background-color: var(--onet-dark-bg-hovered) !important;
            ;
        }

        .schedulePostDate .mat-date-range-input-separator {
            color: var(--onet-text-secondary-color) !important;
        }

        .schelude_post_wrap table tr td {
            background-color: var(--onet-dark-bg-primary) !important;
            color: var(--onet-text-secondary-color) !important;
        }

        .schelude_post_wrap table tr td .action-btns button {
            background: var(--onet-dark-bg-hovered) !important;
            border: 1px solid #1E384E !important;
        }

        .media-tags li .icn img,
        .tagFrndBlog .icn img {
            filter: brightness(10) contrast(0.5) !important;
        }

        .media-tags a span,
        .editTagfriends .tagFrndBlog a label span {
            color: var(--onet-white-color) !important;
        }

        .edit-schedule-post .media_uploads {
            border: 1px solid #182B3C !important;
        }

        .edit-schedule-post .add-media .custom-dropzone {
            background-color: #182B3C !important;
            border: 1px solid #182B3C !important;
        }

        .edit-schedule-post .add-media .uploaded-thumbs .uploadwrap {
            background-color: var(--onet-dark-bg-primary) !important;
        }

        .edit-thumbs .mutual_thumbs .mat-chip-remove {
            color: var(--onet-text-secondary-color) !important;
            opacity: 1 !important;
        }

        .edit-thumbs .mat-chip.mat-standard-chip {
            background-color: var(--onet-dark-bg-primary) !important;
            color: var(--onet-text-secondary-color) !important;
        }

        .club-content-inn .card .card-body .privacy_wrap {
            background-color: var(--onet-primary-color) !important;
            color: var(--onet-white-color) !important;
            border: 1px solid transparent !important;

            img {
                filter: brightness(10) contrast(0.5) !important;
            }
        }

        .friends-sort-wrap .mat-select {
            background-color: transparent !important;
        }

        .no_data_block {
            background-color: transparent !important;
        }

        .editTag_friends .tag_friends .custom-search-input .w-100.position-relative.d-flex {
            border: 1px solid transparent !important;
        }

        .tagFrndBlog .dropdown-menu.show {
            background-color: var(--onet-dark-bg-hovered) !important;
            color: var(--onet-text-secondary-color) !important;
        }

        .about-view .card label {
            color: var(--onet-white-color) !important;
        }

        .about-view .card p {
            color: var(--onet-text-secondary-color) !important;
        }

        .delete_icn {
            background-color: var(--onet-primary-color) !important;
            border: 1px solid var(--onet-text-secondary-color) !important;

            img {
                filter: brightness(10) contrast(0.5) !important;
            }
        }

        .notify-warp .tab-wrapper,
        .notify-warp .tab-wrapper ul button {
            background-color: transparent !important;
        }

        .notify-warp .tab-wrapper .nav {
            border-bottom: none !important;
        }

        .all-notifications .tab-wrapper ul button.active {
            border-bottom: none !important;
        }

        .all-notifications .tab-wrapper ul button {
            margin: 0px 15px !important;
        }

        .notify-warp .tab-wrapper h3 {
            color: var(--onet-white-color) !important;
        }

        .notifications-wrap .main-card .list-head .list-group-item {
            label {
                color: var(--onet-white-color) !important;
            }

            background-color: var(--onet-dark-bg-secondary) !important;

            .mobileList-btns .mobileListmore {
                background-color: var(--onet-dark-bg-primary) !important;
            }
        }

        .all-notifications .main-card .list-head .list-group-item .list-btns button {
            color: var(--onet-text-secondary-color) !important;
        }

        .notifications-wrap .card .card-body .notify-content p {
            color: var(--onet-white-color) !important;
        }

        .notifications-wrap .card .card-body .notify-content label {
            color: var(--onet-text-secondary-color) !important;
        }

        .all-notifications .tab-wrapper {
            border-bottom: 1px solid var(--onet-text-secondary-color) !important;
        }

        .notifications-wrap .card .card-body ul li {
            border-bottom: 0.6px solid #243C51 !important;
        }

        .main-card .stick-head {
            border-bottom: 0.6px solid #243C51 !important;
            box-shadow: none !important;
        }

        .notifications-wrap .card {
            background-color: transparent !important;
        }

        .notifications-wrap .card .card-body .notify-content {
            .report_notify .report_read {
                background-color: var(--onet-dark-bg-primary) !important;
                box-shadow: 2px 2px 2px 0px var(--onet-dark-bg-primary) !important;
                border: 1px solid var(--onet-dark-bg-primary) !important;

                h5 {
                    color: var(--onet-white-color) !important;
                }

                p {
                    color: var(--onet-text-dark-color) !important;
                }
            }
        }

        .schedulepost-wrap .schelude_post_wrap .main-card table tr {
            border-bottom: 0.6px solid #243C51 !important;
        }

        .schedulepost-wrap .schelude_post_wrap .main-card table tr:last-child {
            border-bottom: none !important;
        }

        .schedulepost-wrap .schelude_post_wrap .main-card table tr td {
            border-bottom: none !important;
        }

        .mat-select-wrap .mat-select-arrow {
            visibility: visible !important;
            color: var(--onet-text-secondary-color) !important;
        }

        .mat-select-wrap .mat-select-trigger {
            height: 30px !important;
        }

        .mat-select-wrap .mat-form-field {
            padding: 0px 13px !important;
        }

        .mat-select-wrap .mat-form-field-label {
            font-size: var(--clamp14) !important;
        }

        .date_wrap mat-label,
        .mat-stroked-button.mat-accent.mat-button-disabled {
            color: var(--onet-text-secondary-color) !important;
        }

        .tab_wrapper .mat-tab-label.mat-tab-label .mat-tab-label-content:hover {
            font-weight: normal !important;
            border-bottom: 3px solid var(--onet-white-color) !important;
            color: var(--onet-white-color) !important;
        }

        .profile-main-wrapper .profile-left-wrapper ul li img {
            filter: brightness(0) saturate(100%) invert(53%) sepia(37%) saturate(383%) hue-rotate(136deg) brightness(97%) contrast(84%);
        }

        .education-view .inn_scroll .outer-card,
        .proffesional-info-view .inn_scroll .outer-card,
        .address-view .inn_scroll .outer-card {
            border: 1px solid #1E384E !important;
        }

        .education-wrap form,
        .add-professional-information form {
            border: 1px solid #1E384E !important;
            padding: 10px;
            margin-top: 15px;
            border-radius: 8px;
            background: var(--onet-dark-bg-primary);
        }

        .education-wrap .date_wrap .mat-form-field-appearance-fill .mat-form-field-flex {
            background: var(--onet-dark-bg-primary) !important;
        }

        .education-wrap .btn-wrap,
        .professional-information .btn-wrap {
            margin-top: 3rem !important;
        }

        .date_wrap mat-label {
            color: var(--onet-text-secondary-color) !important;
        }

        .clubs_tab_wrapper .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
        .mat-tab-header-pagination-chevron {
            border-color: var(--onet-text-secondary-color) !important;
        }

        .tab-wrapper ul button.active,
        .tab-wrapper ul button:hover {
            border-bottom: none !important;
        }

        .date_wrap .mat-icon-button.mat-button-disabled.mat-button-disabled {
            color: var(--onet-text-secondary-color) !important;
        }

        .tool_tip .tooltiptext {
            background: var(--onet-dark-border-color) !important;
            color: #fff !important;

            ul {
                li {
                    span {
                        color: var(--white-color) !important;
                    }
                }
            }
        }

        .tool_tip .tooltiptext::after {
            border-color: transparent transparent var(--onet-dark-border-color) transparent !important;
        }

        .title-capts p {
            color: var(--onet-text-secondary-color) !important;
        }

        .friends-sort-wrap {
            .mat-select-arrow {
                visibility: visible !important;
            }
        }

        .start_conversation .msg_count {
            background-color: #FF5E3A !important;
        }

        .members-inn-card .more-acts button {
            background: var(--onet-dark-bg-secondary) !important;
        }

        .form_label {
            label {
                color: var(--onet-white-color) !important;
            }
        }

        .edit-cover .more-acts .more img,
        .edit-profile .more-acts .more img {
            filter: brightness(0) saturate(100%) invert(64%) sepia(31%) saturate(311%) hue-rotate(137deg) brightness(87%) contrast(83%);
        }

        .edit-cover .more-acts .more {
            background-color: var(--onet-dark-bg-primary) !important;

            img {
                filter: brightness(0) saturate(100%) invert(64%) sepia(31%) saturate(311%) hue-rotate(137deg) brightness(87%) contrast(83%);
            }
        }

        .user_camera {
            background-color: var(--onet-dark-bg-primary) !important;
            box-shadow: 0px 1px 2px 0px #0000005e !important;

            img {
                filter: brightness(0) saturate(100%) invert(64%) sepia(31%) saturate(311%) hue-rotate(137deg) brightness(87%) contrast(83%);
            }
        }

        .profile-info-right .card .prof_default_img {
            background-color: var(--onet-dark-bg-primary) !important;

            img {
                filter: brightness(0) saturate(100%) invert(57%) sepia(63%) saturate(239%) hue-rotate(136deg) brightness(86%) contrast(83%);
            }
        }

        .club_members .main-card .main-head h5,
        .club_members .main-card .inn-card {
            border-bottom: 1px solid var(--onet-dark-border-color) !important;
        }

        .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected,
        .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked {
            background-color: var(--onet-dark-bg-primary) !important;
            color: var(--onet-text-secondary-color) !important;

        }

        .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
            background-color: var(--onet-dark-bg-secondary) !important;
            color: var(--onet-text-secondary-color) !important;
        }

        .edit-schedule-post h6 {
            color: var(--onet-white-color) !important;
        }

        .friend_content .custom-search-input .input-group-btn button {
            background-color: transparent !important;
        }
        .msg_wrap .right-pannel .card .card-footer .req_msg{
            color:var(--white-color) !important;
        }

        /* S Added Styles End */



        .addstory .pp select option {
            background-color: var(--onet-dark-bg-hovered) !important;
            border-color: var(--onet-dark-border-color) !important;
            color: var(--onet-text-secondary-color) !important;
        }

        // ADD CODE TILL ABOVE    //
    }

    // .form-select, .createEvents .cat_search .mat-select {
    //     background-image: url(/assets/onet/img/dropdown-arrow-dark.svg) !important;
    //     background-repeat: no-repeat !important;
    //     background-position: right 0.75rem center;
    // }

    input,
    .form-select,
    .mat_select,
    .mat-select,
    .mat-select .mat-select-value-text,
    .mat-select option,
    .mat-form-field,
    textarea,
    .form-select option,
    select option,
    .addstory .pp select,
    .addstory .pp select option {
        -webkit-background-color: var(--onet-dark-bg-hovered) !important;
        -webkit-border-color: var(--onet-dark-border-color) !important;
        -webkit-color: var(--onet-text-secondary-color) !important;
        -moz-background-color: var(--onet-dark-bg-hovered) !important;
        -moz-border-color: var(--onet-dark-border-color) !important;
        -moz-color: var(--onet-text-secondary-color) !important;
        -o-background-color: var(--onet-dark-bg-hovered) !important;
        -o-border-color: var(--onet-dark-border-color) !important;
        -o-color: var(--onet-text-secondary-color) !important;
        background-color: var(--onet-dark-bg-hovered) !important;
        border-color: var(--onet-dark-border-color) !important;
        color: var(--onet-text-secondary-color) !important;

    }

    input,
    .form-select,
    .mat_select,
    .mat-select,
    .mat-select .mat-select-value-text,
    .mat-select option,
    .mat-form-field,
    textarea,
    .form-select option,
    select option,
    .addstory .pp select,
    .addstory .pp select option {
        -webkit-background-color: var(--onet-dark-bg-hovered) !important;
        -webkit-border-color: var(--onet-dark-border-color) !important;
        -webkit-color: var(--onet-text-secondary-color) !important;
        -moz-background-color: var(--onet-dark-bg-hovered) !important;
        -moz-border-color: var(--onet-dark-border-color) !important;
        -moz-color: var(--onet-text-secondary-color) !important;
        -o-background-color: var(--onet-dark-bg-hovered) !important;
        -o-border-color: var(--onet-dark-border-color) !important;
        -o-color: var(--onet-text-secondary-color) !important;
        background-color: var(--onet-dark-bg-hovered) !important;
        border-color: var(--onet-dark-border-color) !important;
        color: var(--onet-text-secondary-color) !important;
    }

    .media-tags-group .media-post-btn {
        .homeCreateposTypeMat {
            background-color: transparent !important;

            .mat-form-field-wrapper {
                .mat-form-field-underline {}

                .mat-form-field-flex {
                    .mat-form-field-infix {
                        .mat-select {
                            background-color: transparent !important;
                            background-image: url(/assets/onet/img/dropdown-arrow-dark.svg) !important;
                            background-repeat: no-repeat;
                            background-position: right 0.25rem center;

                            .mat-select-value-text {
                                background-color: transparent !important;

                                mat-select-trigger {
                                    color: var(--onet-white-color) !important;

                                    img {
                                        filter: brightness(0.5) invert(1);
                                    }
                                }
                            }
                        }
                    }

                    .mat-select-arrow-wrapper {
                        .mat-select-arrow {
                            display: block !important;
                        }
                    }

                }
            }
        }
    }

    .no-birthdays {
        color: var(--onet-text-dark-color) !important;
    }
    .blocked_msg{
        color: var(--white-color) !important;
        a{
            color:var(--onet-text-secondary-color) !important;
            text-decoration: underline !important;
        }
      }
      .user-block-content{
        a{
            color:var(--white-color) !important;
            text-decoration: underline !important;
        }
      }
      .blocked_user_name{
        color:var(--white-color) !important;
      }
      .blocked_usr_msg{
        p{
            color:var(--white-color) !important;
        }
      }
      .msg_wrap .right-pannel .card .card-footer{
        border-top: 0.5px solid var(--onet-dark-bg-hovered) !important;
      }
      .msg_wrap .right-pannel .card .card-footer .req_msg{
        border-bottom: 0.5px solid var(--onet-dark-bg-hovered) !important;
      }
      .blocked_profile h5,.blocked_profile h6{color:var(--white-color) !important;}
      .msg_wrap .right-pannel .card .card-footer{background-color: var(--onet-dark-bg-secondary) !important;}
      .msg-left-content .messages .msg_wrap .right-pannel .card .card-footer{background-color: var(--onet-dark-bg-secondary) !important;}
}