
@media screen and (max-width: 1024px){
    .my_media .left-wrapper ul li .img{
        display: none !important;
    }
}


// iPad Air
@media screen and (max-width: 820px){
    .profile-tab-wrapper{
        margin:0 0.25rem !important;
    }
    .profile-tab-wrapper .nav button a{
        padding: 7px !important;
    }
    .my_media{
        .left-wrapper{
            .card{
                margin-bottom: 15px;;
            ul{
                display: flex;
                li{
                    padding:5px 5px !important;
                    .img{
                        display:none;
                    }
                    label{
                        margin-left:5px;
                    }
                }
            }
        }
        }
    }
    .top-view-profile {
        margin-left: 1rem !important;
        margin-right: 1rem !important;
    }
    .user_profile{
        .tab_space4{
            margin-left:0rem !important;
            margin-right:0rem !important;
        }
        .tab_space3{
            margin-left:0rem !important;
            margin-right:0rem !important;
        }
    }
    .allEvents{
        .custom-search-input {
            width:200px !important;
        }
    }

}


// iPad mini
@media screen and (max-width: 768px){

    .allBlogs .card .card-footer{
        padding: 10px 10px !important;
    }
}

@media screen and (max-width: 540px){
    .img-grid {
        .o-img{
            // height: 110px !important;
            &.one{height: 250px !important;}
        }

    }
    .addstory {
        .createSerach{
        display: block !important;
        .srch{
            width: 100% !important;
        }
        }
    }

    .my_media{
        .right-wrapper{
             .card{
                h5{
                    margin-left: 10px !important;
                }
                 .card-body{
                    padding: 0px 10px !important;
                 }
                }
            }
        }
        .top-view-profile{
            margin-left: 0rem;
            margin-right: 0rem;
            .cover-bg{
                min-height:100px !important;
            }
            .profile-img{
                min-width: 100px!important;
                max-width: 100px !important;
                min-height: 100px !important;
                max-height: 100px !important;
            }
            .prof_thumb{
                margin-top: -50px !important;
        }
    }

.add-info{
    .card_columns {
        column-count: 1 !important;
        .card_radius{
            margin-bottom:1rem !important
        }
    }
}
.image-upload{
    img{
        position: relative !important;
    // bottom: 25px !important;
    }
}
.user_profile{
    .tab_space4{
        margin-left:0rem !important;
        margin-right:0rem !important;
    }

}

.top-view-profile {
    margin-left: 0rem !important;
    margin-right: 0rem !important;
}
.allClubs,.myclubs,.clubs_requested,.recommend_clubs,.clubs_joined{
    h5{
        font-size: 16px !important;
    }
}
.clubs_block{
    // display:inherit !important;
    .w_70{
        width:100% !important;
    }
}

.no_data_img{
    width: 100% !important;
}
.notifications-wrap{
    .card{
        .card-header{
            padding: 10px 1rem !important;
            .tab-wrapper{
                ul{
                    button{
                        margin-right: 10px !important;
                        a{
                            padding: 5px 10px!important;
                        }
            }
        }
    }
}
}
}
.notifications-wrap .card .card-body ul li{
    padding: 10px 10px 10px 25px !important;
}
.userStatus.active{
    // left:12px !important;
    width:7px !important;
    height:7px !important
}
.profile-tab-wrapper{
    margin: 0 7px 5px 7px !important;
    .nav{
        button{
            padding: 0 !important;
            a{
                padding: 5px 10px !important;
                font-size:13px !important;
            }
        }
    }
}
// .prof-content{
//     .col-auto{
//         width:50% !important;
//     }
// }
}


@media screen and (max-width: 414px){
    .onet-container {
        margin: auto 0rem !important;
    }
    .tab_wrapper{
        margin-left:0px !important;
        margin-right:0px !important;
    }
    .events-tab-wrapper ul li a{
        font-size:12px !important;
    }
    .events_default_img .camera_icon img{
        top:12% !important;
    }

    .allBlogs .card .card-footer{
        padding:10px !important;
    }
    .events-img {
        top: -45px !important;
        right: 7px !important;
        width: 15px !important;
      }
    //   .prof-content{
    //     .col-auto{
    //         width:100% !important;
    //     }
    // }
    .onet-main-layout .lik-dropdown:hover .lik-dropdown-content{
        width: 290px !important;
    }
    .emj-icn .img_width, .lik-dropdown img.img_width {
        width: 25px !important;
        height: 25px !important;
    }

}

@media screen and (max-width: 375px){
    .events_default_img .camera_icon img{
        top:17% !important;
    }
}



// Galaxy
@media screen and (max-width: 280px){

}