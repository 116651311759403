:root {
    --white-color: #fff;
    --main-color: #1B3999;
    --link-color: #2E87DF;
    --link-color2:#0E60FF;
    --blue-color:#1B3999;
    --orange-color: #FE9900;
    --orange-color2: #EE7F00;
    --light-orange-color: #FFF9F1;
    --green-color: #01D167;
    --green-color2: #20BF30;
    --header-bg-color: #F1F8FE;
    --light-grey-color: #F6F9FC;
    --clamp14: clamp(12px, 0.72916vw, 14px);
    --clamp16: clamp(14px, 0.8333vw, 16px);
    --clamp18: clamp(16px, 0.9375vw, 18px);
    --clamp24: clamp(16px, 1.25vw, 24px);
    --clamp26: clamp(18px, 1.3541vw, 26px);
    --clamp28: clamp(20px, 1.4583vw, 28px);
    --clamp36: clamp(24px, 1.875vw, 36px);
    --clamp48: clamp(25px, 2.5vw, 48px);
    --clamp56: clamp(30px, 2.916vw, 56px);
    --clamp70: clamp(54px, 3.6458vw, 70px);
    --font-family: "Poppins", sans-serif;
}