@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import './responsive.scss';
@import "/src/assets/onet/css/onet-dark-style.scss";

:root {
  --onet-primary-color: #034c53;
  --onet-secondary-color: #f3f3f3;
  --onet-label-color: #1e2730;
  --onet-text-color: #0c8c98;
  --onet-title-color: #2E3744;
  --onet-black-color: #000000;
  --onet-white-color: #ffffff;
  --onet-btn-color: #dedede;
  --onet-blue-color: #1f4393;
  --onet-light-blue: #ceddc9;
  --onet-light-green: #D8FCD8;
  --onet-banner-green: #E8FAEB;
  --onet-grey-color: #21c93842;
  --onet-link-color: #ff5e3a;
  --onet-darkgrey-color: #047a14;
  --onet-lightgrey-color: #21c93821;
  --onet-link-blue-color: #2692FF;
  --onet-font-family: font-family: 'Poppins', sans-serif !important;


}
.onet-dark-mode .cdk-overlay-container,
.onet-light-mode .cdk-overlay-container {
  z-index: 9999 !important;
}


.w-large.show .modal-dialog {
  min-width: 800px !important;

  @media screen and (max-width:1366px) {
    min-width: 700px !important;
  }

  @media screen and (max-width:575px) {
    min-width: 95% !important;
  }
}

.events_modal.show .modal-dialog {
  min-width: 1000px !important;

  @media screen and (max-width:1366px) {
    min-width: 900px !important;
  }

  @media screen and (max-width:575px) {
    min-width: 95% !important;
  }
}


.onet-main-layout {

  .cdk-overlay-pane {
    transform: translate(0px, 0) !important;
    min-width: auto !important;
  }

  &.mat-menu-panel {
    min-height: 40px !important;
  }

  // label::after {
  // content: attr(data-end) ;
  // color: red ;
  // }

  html,
  body,
  applet,
  object,
  iframe,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  blockquote,
  pre,
  a,
  abbr,
  acronym,
  address,
  big,
  cite,
  code,
  del,
  dfn,
  em,
  img,
  ins,
  kbd,
  q,
  s,
  samp,
  small,
  strike,
  sub,
  sup,
  tt,
  var,
  b,
  u,
  i,
  center,
  dl,
  dt,
  dd,
  ol,
  ul,
  li,
  fieldset,
  form,
  label,
  legend,
  table,
  caption,
  tbody,
  tfoot,
  thead,
  tr,
  th,
  td,
  article,
  aside,
  canvas,
  details,
  embed,
  figure,
  figcaption,
  footer,
  header,
  hgroup,
  menu,
  nav,
  output,
  ruby,
  section,
  summary,
  time,
  mark,
  audio,
  video {
    margin: 0;
    padding: 0;
    border: 0;
  }

  /* HTML5 display-role reset for older browsers  */
  article,
  aside,
  details,
  figcaption,
  figure,
  footer,
  header,
  hgroup,
  menu,
  nav,
  section {
    display: block;
  }

  body {
    height: 100vh;
    font-family: 'Poppins', sans-serif !important;
    top: 0 !important;
    font-size: var(--clamp16);
    scroll-behavior: smooth;
  }

  p {
    font-size: var(--clamp16);
    word-break: break-word;
  }

  a,
  a:hover {
    color: #0375d3;
  }

  ol,
  ul {
    list-style: none;
  }

  blockquote,
  q {
    quotes: none;
  }

  blockquote:before,
  blockquote:after,
  q:before,
  q:after {
    content: '';
    content: none;
  }

  .mat-menu-item {
    font-family: "Poppins", sans-serif !important;
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  body.modal-open {
    height: 100vh;
    overflow-y: hidden;
  }

  button {
    &:focus {
      outline: none !important;
      box-shadow: none !important;
    }
  }

  .onet-container {
    margin: auto 10px;
  }

  .pointer {
    cursor: pointer;
  }

  .modal-header {
    border: 0px solid transparent !important;
    top: 0;
    transition: all 0.2s;
    position: absolute;
    width: 100%;
    left: 0;
    right: 0;
    z-index: 9;

    &:hover {
      opacity: 0.8;
    }

    h5 {
      margin-left: 20px;
    }

    button {
      border: 0;
      background: transparent;
      padding: 0;
      margin: 0;
      right: 0px;
      top: 0px;
      z-index: 99;
      position: relative;

      img {
        width: auto !important;
      }
    }

    //   .btn-close{
    //     border: 1px solid var(--blue-color);
    //     color: var(--blue-color);
    //     padding: 0.3rem !important;
    //     border-radius:50%;
    //     box-shadow:none;
    //     font-size: 0.5rem !important;
    //     opacity:1;
    //     &:hover{opacity: 0.7 !important;}
    //     &:focus{
    //      box-shadow: none;
    //     }
    // }
  }

  .model-hdr {
    position: relative !important;
    border-bottom: 1px solid #ddd !important;
    display: flex;
    justify-content: space-between;

    img {
      cursor: pointer;
    }

    button {
      border: none;
      background: transparent;
    }

    h5 {
      font-size: var(--clamp20);
      color: #000000;
    }
  }



  .gallery-post-modal {
    .modal-dialog {
      display: flex;
      align-items: center;
      height: 100% !important;
      margin: auto !important;
    }
  }

  .modal-content {
    border: none !important;
    border-radius: 10px !important;
    padding: 15px !important;
  }

  .preview-wrap {
    border-radius: 5px !important;
    background-color: #fff;

    .carousel-inner .carousel-item {
      float: none !important;
      margin: auto !important;
      transition: all 0s !important;
      // width: fit-content !important;
      height: calc(100vh - 55px);
      padding: 20px;
      position: relative;

      .image {
        transform: translate(-50%, -50%);
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;

        img {
          object-fit: scale-down;
          z-index: 5;
          position: relative;
          width: auto !important;
          height: 100% !important;
          max-width: 100%;
          margin: auto;
        }
      }

      .video {
        width: 100%;
        height: 100%;
        transform: translate(-50%, -50%);
        position: absolute;
        top: 50%;
        left: 50%;
        align-items: center;
        display: flex;
        background-color: #b5b5b5;
      }

      .blurBgImg {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        filter: blur(10px) brightness(0.6) drop-shadow(0px 4px 4px #000);
        z-index: 0;
        opacity: 0.5;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
      }

    }
  }

  .img-blurred {
    .blurBgImg-wrap {
      position: relative;
      height: -webkit-fill-available;
      z-index: 1;

      .blurBgImg {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        filter: blur(10px) brightness(0.6) drop-shadow(0px 4px 4px #000);
        z-index: 0;
        opacity: 0.5;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
      }
    }

    .bg-image img {
      object-fit: contain;
      z-index: 1;
      position: absolute;
      max-width: 100%;
      margin: auto;
      top: 0;
      left: 0;
      right: 0;
    }

  }


  /*  LOGINS FORM START */
  .login-bg {
    display: flex;
    width: 100%;
    background: url(../../../assets/onet/images/onet-bg.jpg) no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    min-height: 100vh;
    height: 100%;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 8px;
    }

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgb(77 77 77 / 35%);
    }

    &::-webkit-scrollbar-thumb {
      background: #a5a5a5;
      outline: none;
      border-radius: 5px;
    }

    &.signup {
      display: inline-block !important;
    }

    &>div {
      align-self: center;
    }

  }

  .login-form-bg {
    width: 100%;
    margin: auto;
    background-color: var(--white-color);
    border-radius: 6px;

    form {
      .form-group {
        label {
          color: #0A2477;
          font-weight: 400;
        }
      }
    }

    .form-control,
    .form-select {
      border: 1px solid #7688C2 !important;
      border-radius: 0.25rem !important;
    }

    .form-select {
      color: #6e6e6e;
    }

    .form-check-input {
      width: 1em;
      height: 1em;
      border: 1px solid #1f4393;
    }
  }

  .login-bg h1 {
    font-size: 1.2rem;
    color: var(--blue-color);
    font-weight: 500;
  }


  .txt-lnk {
    color: var(--blue-color) !important;
  }

  .txt-lnk:hover {
    text-decoration: none;
    color: #3562c4;
  }

  .f-siz {
    font-size: .9em;
  }

  .mrg-dic {
    margin-top: -30px;
  }

  .img-fit img {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important;
    opacity: 1 !important;
  }

  .custom-dropzone {
    height: auto !important;
    padding: 10px !important;
  }

  .ng2-pdf-viewer-container {
    height: 300px !important;
    width: 100% !important;
  }

  .cdk-global-overlay-wrapper,
  .cdk-overlay-container {
    z-index: 99999 !important;
  }

  .mat-form-field-infix {
    border-top: 0 !important;
  }

  // .mat-tooltip {
  //     background-color: #5f7c8a;
  //     font-size: var(--clamp14);
  // }

  .mat-datepicker-close-button {
    position: absolute !important;
  }

  .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
    background-color: orangered;
  }

  .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
    background-color: #04455f;
  }

  // .modal {
  //   z-index: 5040 !important;
  // }

  // .modal-backdrop {
  //   z-index: 5040 !important;
  // }

  .otp_wrapper .wrapper {
    font: inherit !important;
    min-height: inherit !important;
    overflow: inherit !important;
    background: inherit !important;
  }

  .otp-input {
    width: 35px !important;
    height: 35px !important;
    font-size: var(--clamp14) !important;
    margin: 0px 12px 0px 0px !important;
  }

  .otp_wrapper .wrapper .otp-input:focus {
    border-color: #04a3ff !important;
    outline: none;
  }


  /* ng-otp overlapping wrapper */

  a,
  a:hover,
  a:focus,
  a:active {
    text-decoration: none;
    outline: none;
    cursor: pointer;
  }

  ul {
    padding: 0;
    margin: 0;
  }

  ul li {
    list-style-type: none;
  }

  h1,
  h2,
  h3,
  h4 {
    font-weight: 600;
  }

  h3 {
    font-size: var(--clamp24);
  }

  h4 {
    font-size: var(--clamp18);
  }

  #loader {
    position: absolute;
  }

  .btn-primary,
  .btn-primary:focus,
  .btn-primary:active {
    background-color: #28abb9 !important;
    border-color: #28abb9 !important;
    padding: 3px 10px;
  }

  .block {
    background: #fff;
    width: 100%;
    border-radius: 15px;
    padding: 15px;

  }

  .wrapper {
    font: 15px/24px "Poppins", sans-serif;
    min-height: 100vh;
    overflow-x: hidden;
    background: #2d6187;
  }


  /******** Main Content **********/

  .main_content {
    width: calc(100% - 80px);
    left: 80px;
    position: relative;
    height: 100vh;
    background: #f6f6f6;
    border-radius: 50px 0 0 50px;
  }

  .main_content>div {
    width: 100%;
  }

  .sidebar_maximize .main_content {
    width: calc(100% - 200px);
    left: 200px;
  }


  /******** Middle Content **********/

  .middle_content {
    width: calc(100% - 360px);
    /* max-width: 1100px; */
    margin: 0 auto;
    left: -130px;
    position: relative;
  }

  .middle_content.minimize {
    left: 130px;
  }

  @media screen and (max-width: 767px) {
    .wrapper {
      background: #fff;
    }

    .main_content {
      width: 100%;
      left: 0;
      top: 0;
    }

    .middle_content {
      width: 100%;
      left: 0;
      position: relative;
    }
  }

  @media screen and (min-width: 768px) {
    .main_content {
      overflow-y: auto;
      overflow-x: hidden;
    }
  }

  @media screen and (min-width: 768px) and (max-width: 991px) {
    .middle_content.minimize {
      left: 110px;
    }

    .middle_content {
      width: calc(100% - 300px);
    }
  }

  .fit_img {
    height: 100%;
    width: 100%;
    object-position: center;
    object-fit: cover;
    background-position: 50%;
    background-size: cover;
  }

  .mark_ht {
    font-weight: bold;
    background-color: yellow;
    padding: 0px 4px;
  }

  .goog-text-highlight {
    background-color: inherit !important;
    box-shadow: inherit !important;

  }

  .goog-logo-link {
    display: none !important;
  }

  .goog-te-gadget {
    color: transparent !important;
  }

  .goog-te-banner-frame.skiptranslate {
    display: none !important;
  }

  .card_left span span img {
    display: block;
    width: 100% !important;
    height: 112px;
    margin-bottom: 20px;
  }

  .dropdown-toggle::after {
    display: none !important;
  }

  a {
    text-decoration: none;
    // color: #1c6adb;
    //  text-decoration: underline !important;
  }

  a:hover {
    text-decoration: none;
  }

  textarea {
    resize: none;
    width: 100%;
    border-radius: 4px;
    background: #f6f9fc;
    border: 1px solid #dbdbdb;
    height: 70px;
  }

  ul {
    margin-bottom: 0 !important;
  }

  li {
    list-style: none;
  }

  /*** 2.0 - Global Styles ***/

  body {
    font-family: 'Poppins', sans-serif !important;
    font-size: var(--clamp16) !important;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: 'Poppins', sans-serif !important;
  }

  .box-shadow {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .08);
    -moz-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .08);
    -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .08);
  }

  .button-bg {
    background: var(--onet-btn-color);
  }

  .button-bg:hover {
    background: #04b95d;
  }

  .bg-hash {
    background: #F6F9FC;
  }

  .bg-light-blue.posted_comment,
  .bg-light-gray.reply_posted_comment {
    background-color: #fff;
  }

  .reply_posted_comment {
    span {
      font-weight: 400;
      font-family: var(--font-family) !important;
      font-size: var(--clamp16);
    }
  }

  .bg-light-blue {
    background: #e8faeb6b;
  }

  .bg-light-gray {
    background: rgba(206, 221, 200, 0.32);
  }

  .bg-blue {
    background: #2E87DF;
  }

  .bg-orange {
    background: #FE9900;
  }

  .bg-lighten-blue {
    background: var(--onet-banner-green);
  }

  .bg-light-pink {
    background: #FFF6FD;
  }

  .bg-light-yellow {
    background: #FFF9F1;
  }

  .text-green {
    color: var(--onet-btn-color) !important;
  }

  .border-green {
    border: 1px solid var(--onet-btn-color);
  }

  .color-blue {
    color: #0A2477 !important;
  }

  .mat-body,
  .mat-body-1,
  .mat-typography {
    font-family: 'Poppins', sans-serif !important;

  }

  .story-input::placeholder {
    color: #C6D5F2;
  }

  .story-input:focus {
    box-shadow: none;
  }

  .dropdown-menu {
    /* min-width: 236px !important; */
    min-width: 205px !important;
    inset: 22px auto auto 0px !important;
    box-shadow: 0px 5px 8px #0000004D;
    border-radius: 8px;
  }


  // .post-share-links {
  //     margin: 0 -15px -15px -15px;
  // }

  .update {
    border: 1px solid #0000001A;
  }

  .more {
    border: 0px;
    background-color: transparent;

    .mat-menu-panel {
      font-family: "Poppins", sans-serif !important;
    }
  }

  .recommended-friends .carousel-control-next,
  .recommended-friends .carousel-control-prev {
    top: 38% !important;
    opacity: 1 !important;

    background: var(--onet-btn-color) !important;
    width: 52px !important;
    height: 52px !important;
    border-radius: 50% !important;
    border-radius: 50% !important;
    border: 2px solid #fff !important;
    box-shadow: 0px 3px 6px #00000029 !important;
  }

  .recommended-friends .carousel-control-next {
    right: -15px !important;
  }

  .recommended-friends .carousel-control-prev {
    left: -15px !important;
  }

  .action-btn .outline-btn {
    border: 1px solid var(--onet-btn-color);
    border-radius: 21px;
    color: var(--onet-btn-color) !important;
  }

  .action-btn .bg-green {
    background: var(--onet-btn-color);
    border-radius: 21px;
    color: var(--white-color) !important;
  }

  /*  Multilanguage Styles*/
  .goog-te-combo {
    background: none;
    border: 0;
    max-width: 46px;
  }

  .trans_elm_div {
    width: 100px !important;
  }



  .form-control:focus,
  .form-select {
    box-shadow: none !important;
  }


  /*Media Queries */

  @media (min-width:1200px) and (max-width: 1399px) {

    .photos-videos a {
      margin-right: 12px !important;
    }

    .side-menu ul li,
    .photos-videos a {
      line-height: 48px !important;
    }

    .password_eye {
      top: 34px !important;
    }
  }

  .mat-menu-panel {
    min-width: 112px;
    max-width: 312px;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    max-height: calc(100vh - 48px);
    border-radius: 4px !important;
    outline: 0;
    min-height: 40px !important;
    margin-top: 15px;

    @media screen and (min-width: 991px) {
      overflow: initial !important;
    }
  }

  .mat-menu-panel:before {
    content: "";
    position: absolute;
    right: 5px;
    top: 5px;
    border-radius: 20px !important;
    border-bottom: 10px solid #aaa;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    z-index: 11;

    @media screen and (min-width: 991px) {
      overflow: initial !important;
    }
  }

  .mat-menu-panel:after {
    content: "";
    position: absolute;
    right: 5px;
    top: 5px;
    border-bottom: 10px solid #fff;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    z-index: 11;

    @media screen and (min-width: 991px) {
      overflow: initial !important;
    }
  }

  .sharePost-panel {
    &:before {
      right: auto !important;
      left: 5px;
    }

    &:after {
      right: auto !important;
      left: 5px;
    }

    button {
      .img_width {
        width: 17px;
        margin-right: 7px;
      }
    }
  }

  // news feed css start
  .sub_comments {
    span {
      font-family: var(--font-family) !important;
    }

    .bg-border {
      background-color: #f3f3f3;
      border: 1px solid #e5e5e5 !important;
      padding: 0 5px;
      border-radius: 15px;
      line-height: 22px;

      .icn {
        img {
          width: 13px;
        }
      }

      .digi,
      a {
        font-size: var(--clamp14) !important;
      }
    }
  }

  .whenTo {
    font-weight: 300 !important;
    color: #979797;
    font-size: var(--clamp14) !important;
  }

  .reply-btn {
    font-family: var(--font-family) !important;
    font-weight: 500 !important;
    cursor: pointer;
  }

  .lik-dropdown {
    position: relative;
    display: inline-block;
  }

  .lik-dropbtn {
    color: white;
    padding: 16px;
    font-size: 16px;
    border: none;
  }

  .lik-dropdown-content {
    display: none;
    position: absolute;
    // top: -200px;
    top: -25px;
    background-color: transparent;
    // border: 1px solid #b1c6f5;
    z-index: 9;
    // min-width: 300px;
    // min-width: 14em;
    // height: 200px;
    // overflow-y: scroll;
    // overflow-x: hidden;
  }

  .lik-dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
  }

  .lik-dropdown-content a:hover {
    background-color: #ddd;
  }

  // .lik-dropdown:hover .lik-dropdown-content {display: flex;}
  .lik-dropdown:hover .lik-dropdown-content {
    display: inline-block;
    background: #fff;
    border-radius: 20px;
    width: 350px;
    top: -50px;
    left: -5px;
    padding: 5px;
    border: 1px solid #f2f2f2;
    box-shadow: 0px 0px 8px rgb(142 142 142 / 40%);
  }

  /* width */
  .lik-dropdown-content::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  .lik-dropdown-content::-webkit-scrollbar-track {
    background: #bfdefa;
  }

  /* Handle */
  .lik-dropdown-content::-webkit-scrollbar-thumb {
    background: #2d4cb1;
  }

  /* Handle on hover */
  .lik-dropdown-content::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  .emj-icn img {
    // min-width: 20px;
    width: 25px;
    height: 25px;
    display: inline-grid;
    margin: .2rem .2rem;
  }

  .lst-dw-img img {
    max-width: 40px;
    height: 40px;
  }

  .box-layout {
    border-radius: 30px;
    background: #fff;
    box-shadow: 0px 0px 8px rgba(142, 142, 142, 0.05);
    margin-bottom: 1rem;
    position: relative;

    // border: 2px solid #ccedd7;
    .mat-card-header {
      .mat-card-title {
        font-size: var(--clamp16) !important;
        color: var(--onet-title-color) !important;
        // cursor:pointer;
        font-family: var(--font-family) !important;
        cursor: pointer;
      }
    }

    .mat-card-subtitle,
    .mat-card-content {
      font-size: var(--clamp14) !important;
      font-family: var(--font-family) !important;
    }
  }


  // mat-card-title, h1, h2, h3,h4, h5, h6, p:first-letter{
  //   text-transform: capitalize;
  // }
  // model box start

  .drk-blu {
    color: #1F4393;
  }

  .modal-header {
    .cls-siz {
      font-size: 0.7rem !important;
      padding: 0.3rem 0.3rem !important;
    }
  }

  // model box end

  // news feed css end

  ::ng-deep .mat-card {
    font-family: var(--font-family) !important;
  }

  .invalid-feedback {
    color: #e91e63;
  }

  .invalid-feedback {
    display: block;
  }

  .custom-control-input:checked~.custom-control-label::before {
    border-color: #E91E63;
    background-color: #E91E63;
  }

  .custom-control-input:focus~.custom-control-label::before {
    box-shadow: none;
  }

  .btn-fill {
    background-color: var(--onet-primary-color) !important;
    color: #fff !important;
  }

  .btn-outline {
    background: #fff !important;
    color: var(--onet-primary-color) !important;
    border: 1px solid var(--onet-primary-color) !important;
  }

  .btn {
    font-size: var(--clamp16) !important;
    line-height: 1.4 !important;
    border-radius: 5px;
    transition: all 0.2s;
    margin: auto 2px;
    font-family: var(--font-family) !important;
    padding: 8px 15px;

    @media screen and (max-width:1366px) {
      padding: 8px 10px;
      font-size: var(--clamp14) !important;
    }

    &:hover {
      opacity: 0.8;
    }
  }

  .btn-sm {
    padding: 0.3rem 0.5rem !important;
    font-size: 0.8rem !important;
    border-radius: 0.25rem !important;
  }

  .btn.disabled,
  .btn:disabled,
  fieldset:disabled .btn {
    pointer-events: auto !important;
    cursor: not-allowed !important;
  }

  .mat-drawer .main-logo,
  .mat-drawer .gm-user {
    display: none;
  }

  .sidemenu {
    width: 58px;
    margin-left: 0rem;
    box-shadow: 4px 1px 6px -7px #565656 inset;

    .sidenav-inn {
      overflow: auto;
      height: calc(100vh - 80px);

      &::-webkit-scrollbar {
        width: 3px;

        @media screen and (max-width: 1024px) {
          width: 3px;
        }
      }

      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
      }

      &::-webkit-scrollbar-thumb {
        background: #62a5e7;
        outline: none;
        border-radius: 5px;

        @media screen and (max-width: 1024px) {
          background: #62a5e7a1;
        }
      }

      ul {
        margin-left: 2px;
        margin-top: 10px;
      }
    }

    .media {
      justify-content: center;
    }
  }

  .media {
    span {
      display: none;
    }
  }

  @media screen and (max-width: 1024px) {
    .sidemenu {
      margin-left: 0rem !important;

      .media {
        justify-content: center;
      }
    }

    .mat-drawer {
      width: 60px;

      span {
        display: none;
      }

      ul {
        li {
          margin-left: 0rem !important;
          justify-content: center;

          .nav-icn {
            margin-right: 0px !important;
          }
        }
      }
    }
  }

  .sidemenu {
    ul {
      li {
        margin-left: 0rem !important;
        justify-content: center;

        .nav-icn {
          margin-right: 0px !important;
        }
      }
    }
  }

  .mat-drawer.mat-drawer-opened {
    .sidemenu {
      @media screen and (max-width: 1024px) {
        width: auto !important;

        ul {
          li {
            margin-left: auto !important;
            justify-content: start !important;

            span {
              display: block !important;
            }

            .nav-icn {
              margin-right: 10px;
            }
          }
        }

      }

      ul {
        li {
          // margin-left: 1rem !important;
          justify-content: flex-start;

          .nav-icn {
            margin-right: 10px !important;
          }
        }
      }
    }
  }

  .round_pic {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    flex-shrink: 0;
    object-fit: cover;
  }

  .fw-bold {
    font-weight: 600 !important;
  }

  .cke_chrome {
    border-color: rgb(204, 204, 204) !important;
    margin: 10px 0 !important;
    border-radius: 2px !important;
  }

  .tool_tip {
    position: relative;
    cursor: pointer;
    display: inline-block;

    &:hover {
      .tooltiptext {
        visibility: visible;
        padding: 5px 10px !important;
      }
    }

    .tooltiptext {
      visibility: hidden;
      margin-top: 5px;
      margin-left: -30px;
      padding: 0px 10px !important;
      color: #034c53;
      background-color: #e0e9ea;
      line-height: 1.5;
      min-width: max-content;
      text-align: left;
      border-radius: 6px;
      position: absolute;
      z-index: 1;
      font-size: var(--clamp13) !important;

      @media screen and(max-width:1199px) {
        margin-left: auto;
        left: auto;
        right: 0px;
      }
    }
  }

  ::ng-deep .drop-sm {
    button {
      line-height: 35px !important;
      height: 35px !important;

      &:hover {
        background-color: #FFF9F1 !important;
      }
    }
  }

  .mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
    display: none !important;
  }

  .custom-loader {
    position: absolute;
    z-index: 999999999;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
  }

  .mt-25 {
    margin-top: 25px;
  }

  .mt-50 {
    margin-top: 50px;
  }

  .mt-100 {
    margin-top: 100px;
  }

  .mt-150 {
    margin-top: 150px;
  }

  .mt-200 {
    margin-top: 200px;
  }

  .mat-menu-content:not(:empty) {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }

  .mat-menu-item:hover:not([disabled]) {
    background: #eeeeee;
  }

  button.mat-menu-item {
    height: fit-content;
    line-height: 40px !important;
  }

  .modal-header {
    h5 {
      // color: var(--onet-text-color) !important;
      font-size: var(--clamp20) !important;
      position: relative;
      z-index: 1;
      color: #2E3744 !important;
      font-weight: 600;
    }

    img {
      cursor: pointer;
      // filter: brightness(200%) sepia(150%) hue-rotate(50deg) saturate(500%);
      // filter: brightness(30);
      width: 27px;
    }

    button {
      border: 0;
      background: transparent;
      padding: 0;
      margin: 0;
    }
  }

  ::ng-deep .create-post-popup .modal-dialog {
    min-width: 700px !important;
    height: 90%;
    width: 100%;
    justify-content: center;
  }

  ::ng-deep .edit-post-modal .modal-dialog {
    min-width: 700px !important;
    height: 90%;
    width: 100%;
    justify-content: center;
  }

  .delete-modal {
    margin-top: 150px !important;
  }

  .cke_resizer_ltr {
    display: none;
  }


  .delete-modal {
    .modal-dialog {
      min-width: 320px !important;

      @media screen and (max-width:1440px) {
        min-width: 340px !important;
      }
    }

  }

  @keyframes slideFlip {
    0% {
      left: 50px;
      top: 10px;
    }

    50% {
      transform: rotateY(90deg);
    }

    100% {
      left: 250px;
      top: 10px;
    }
  }

  .form-signup,
  .forget-pwd {
    animation: 1s ease-out 0s slideFlip;
  }


  @keyframes slideInFromRight {
    0% {
      transform: translateX(100%);
    }

    100% {
      transform: translateX(0);
    }
  }

  .lets-connect,
  .form-signin {
    animation: 1s ease-out 0s slideInFromRight;
  }

  // .signup{
  //   @media screen and (max-width:1440px){
  //     overflow: auto !important;
  //   }
  // }
  .login-bg {
    overflow: hidden;
  }

  .log_capts {
    text-align: center;
    position: relative;
    right: 90px;

    @media screen and (max-width:1440px) {
      right: 30px;
    }

    @media screen and (max-width:1366px) {
      right: 60px;
    }

    @media screen and (max-width:1200px) {
      right: 0px;
    }

    @keyframes slideInFromLeft {
      0% {
        transform: translateX(-100%);
      }

      70% {
        transform: translateX(0);
      }

      100% {
        transform: translateX(0);
      }
    }

    .capts {
      animation: 2s ease-out 0s 1 slideInFromLeft;
      margin-bottom: 4rem;

      @keyframes slideUpDownA {

        0%,
        100% {
          transform: scale(1);
        }

        50% {
          transform: scale(1.15);
        }
      }

      h2 {
        animation: slideUpDownA 1s alternate;
        animation-delay: 1.5s;
        color: #0A2477;
        font-weight: 700;
        font-size: var(--clamp36);
        margin-bottom: 1.5rem;
      }

      p {
        font-weight: 600;
        color: #0A2477;
        font-size: var(--clamp20);

        @media screen and (max-width:1200px) {
          font-size: var(--clamp18);
        }
      }
    }

    .anim_icns {
      display: flex;

      .icn {
        border-radius: 100%;
        display: inline-block;
        width: 90px;
        height: 90px;
        background: #fff;
        line-height: 90px;
        text-align: center;
        box-shadow: 0px 5px 10px 0px #8b8b8b8f;
        margin-right: 30px;

        @media screen and (max-width:1366px) {
          width: 70px;
          height: 70px;
          line-height: 65px;
          margin-right: auto;
        }

        img {
          width: 90px;
          height: 90px;
          margin: auto;

          @media screen and (max-width:1366px) {
            width: 70px;
            height: 70px;
          }
        }
      }

      .commentt,
      .share {
        position: relative;
        top: 30px;

        img {
          width: 50px;
          height: 50px;

          @media screen and (max-width:1366px) {
            width: 30px;
            height: 30px;
          }
        }
      }

      .circle {
        position: relative;
        top: -30px;

        img {
          border-radius: 100%;
        }
      }

    }
  }


  @keyframes slideUpDown {

    0%,
    100% {
      transform: translateY(-20px);
    }

    50% {
      transform: translateY(0px);
    }
  }

  .icn_top1 {
    animation: slideUpDown 2s alternate infinite;
    animation-delay: 0.5s;
  }



  @keyframes slideUpDown {

    0%,
    100% {
      transform: translateY(0);
    }

    50% {
      transform: translateY(-20px);
    }
  }

  .icn_top2 {
    animation: slideUpDown 2s alternate infinite;
    animation-delay: 0.75s;
  }


  @keyframes slideUpDown {

    0%,
    100% {
      transform: translateY(0);
    }

    50% {
      transform: translateY(-20px);
    }
  }

  .icn_top3 {
    animation: slideUpDown 2s alternate infinite;
    animation-delay: 1s;
  }


  @keyframes slideUpDown {

    0%,
    100% {
      transform: translateY(0);
    }

    50% {
      transform: translateY(-20px);
    }
  }

  .icn_top4 {
    animation: slideUpDown 2s alternate infinite;
    animation-delay: 1.25s;
  }


  @keyframes slideUpDown {

    0%,
    100% {
      transform: translateY(0);
    }

    50% {
      transform: translateY(-20px);
    }
  }

  .icn_top5 {
    animation: slideUpDown 2s alternate infinite;
    animation-delay: 1.5s;
  }






  @keyframes slideDownUp {

    0%,
    100% {
      transform: translateY(0);
    }

    50% {
      transform: translateY(20px);
    }
  }

  .icn_bott1111 {
    animation: slideDownUp 3s linear infinite;
  }

  // .cke_button__smiley{background: #bfd8ed99 !important;
  //   border: 1px solid #b7b7b7b3 !important;
  //   cursor: pointer !important;
  //   border-radius: 4px !important;}

  .cke_inner {
    .cke_contents {
      height: 140px !important;
    }
  }

  .grayFont {
    color: #ababab;
    font-weight: normal;
    font-weight: 600;
    font-style: italic;
  }

  .globe_chat {
    display: flex;
    position: fixed;
    align-items: center;
    bottom: 10px;
    left: 250px;
    z-index: 999;
    cursor: move;

    @media screen and (min-width:540px) and (max-width:1024px) {
      left: 40px;
    }

    // @media screen and (max-width:1600px){
    //   right:350px;
    // }
    // @media screen and (max-width:1440px){
    //   right:320px;
    // }
    // @media screen and (max-width:1366px){
    //   right:290px;
    // }
    // @media screen and (max-width:1280px){
    //   right:270px;
    // }
    // @media screen and (max-width:1200px){
    //   right:250px;
    // }
    // @media screen and (max-width:1024px){
    //   right:320px;
    // }
    &:hover {
      .btns {
        display: block;
        margin-left: -30px;

        .btns-inn {
          // opacity:1;
          display: flex;
          z-index: -1;
          transition: all .3s ease-out;
        }
      }
    }

    .icon-globe {

      // position: absolute;
      // left: -30px;
      // margin-top: 90px;
      // margin-bottom: 15px;
      // margin-left: 40px;
      img {
        width: 60px;
        // border-radius: 50%;
        // box-shadow: -2px 0px 2px 1px #626262d4;
        // background: #78a8ca;
      }
    }

    .btns {
      display: none;

      .btns-inn {
        // opacity:0;
        position: absolute;
        top: 30px;
        z-index: -1;
        padding: 7px 10px;
        padding-left: 35px;
        background: var(--onet-primary-color);
        border-radius: 20px;
        box-shadow: 0px 2px 10px 2px #0000005e;
        overflow: hidden;

        &::after {
          position: absolute;
          content: "";
          background-image: url(/assets/onet/images/sidenav-icons/sidenav-patch.png);
          background-repeat: no-repeat;
          width: 160px;
          background-size: cover;
          right: 0;
          z-index: 1;
          top: 0;
          bottom: 0;
        }

        button {
          border: transparent;
          background: #027b24;
          margin: auto 5px;
          border-radius: 50%;
          width: 30px;
          height: 30px;
          box-shadow: 1px 1px 3px 0px #ffffff91;
          z-index: 2;

          &:hover {
            opacity: 0.7;
          }

          img {
            width: 20px;
            padding: 3px;
            transition: all 0.4s;
            filter: brightness(10);
          }

          &:hover {
            transition: all 0.4s;

            img {
              transform: rotate(360deg);
            }
          }
        }
      }
    }
  }

  // Language css
  .select-lang {
    @media screen and (max-width: 640px) {
      margin-right: 20px;
    }

    @media screen and (max-width: 480px) {
      margin-right: -15px;
    }

    .trans_elm_div {
      display: block;
      min-width: 160px !important;
      height: 30px;
      line-height: 30px;
      overflow: hidden;
      border: 1px solid #B2CFE9;
      border-radius: 20px;
      padding: 0px 5px;
      position: relative;
      cursor: pointer;
      background: #fff;

      @media screen and (max-width:1600px) {
        min-width: 160px !important;
      }

      &::after {
        position: absolute;
        content: '';
        border-top: solid 7px #747474;
        border-left: solid 5px transparent;
        border-right: solid 5px transparent;
        top: 11px;
        right: 10px;
      }
    }

    .goog-te-gadget {
      position: relative;
      color: transparent;

    }

    select {
      outline: none;
      font-weight: 400;
      border: none;
      box-shadow: inset 0px -5px 5px rgba(255, 255, 255, 0.15),
        inset 0px 10px 10px rgba(255, 255, 255, 0.15);
      border-radius: 4px;
      background-color: transparent !important;
      font-size: var(--clamp14);
      --webkit-appearance: none;
      --moz-appearance: none;
      padding: 0 15px;

      option {
        color: var(--main-color) !important;
      }
    }
  }

  .select-lang {
    @media screen and (max-width: 460px) {
      position: absolute;
      top: 5px;
      left: 17px;
    }

    @media screen and (max-width: 280px) {
      left: auto;
    }

    select {
      box-shadow: none !important;
    }

    .goog-text-highlight {
      background-color: inherit !important;
      box-shadow: inherit !important;
    }

    .goog-logo-link {
      display: none !important;
    }

    .goog-te-gadget {
      color: transparent !important;
    }

    .goog-te-banner-frame.skiptranslate {
      display: none !important;
    }

    .goog-te-combo {
      background: none;
      border: 0;
      padding: 0 0.5em;
      position: relative;
      z-index: 9;
      max-height: 20px;
      line-height: 20px;
    }
  }

  // End Language css
  input,
  select {
    font-size: var(--clamp16) !important;
  }

  .create-tagged-friends-popup.tag-inn {
    background: #00000073 !important;
  }

  .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
    border-color: var(--onet-primary-color) !important;
  }

  .mat-radio-button.mat-accent .mat-radio-inner-circle {
    background-color: var(--onet-primary-color) !important;
  }

  .mat-radio-outer-circle {
    border-color: var(--onet-primary-color) !important;
  }

  .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple {
    background-color: var(--onet-primary-color) !important;
  }


  .cat_items .tool_tip {
    position: relative !important;
    display: inline-block !important;

    &:hover {
      .tooltiptext {
        visibility: visible !important;
      }
    }

    .tooltiptext {
      visibility: hidden !important;
      background-color: #e0e9ea !important;
      text-align: center !important;
      line-height: 2 !important;
      border-radius: 5px !important;
      bottom: 40px !important;
      top: auto !important;

      &::after {
        top: 100% !important;
        left: 30% !important;
        bottom: auto !important;
        border-color: #48547B transparent transparent transparent !important;
      }
    }
  }

  .schedulePost {
    .tool_tip {
      .icn {
        background-color: var(--onet-btn-color);
        padding: 5px;
        line-height: 27px;
        margin-left: 1px;
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
      }

      .tooltiptext {
        bottom: -38px !important;
        margin-left: -82px !important;
        background: #fff9f1 !important;
        color: rgba(0, 0, 0, .87);
        opacity: 1 !important;
        font-size: var(--clamp16) !important;
        line-height: 2;
        cursor: pointer;

        &::after {
          top: -27% !important;
          left: 85% !important;
          border-color: transparent transparent #fff9f1 transparent !important;
        }
      }
    }
  }

  .textdotted {
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
  }


  .w-250 {
    width: 250px !important;
  }

  .w-95 {
    width: 95% !important;
  }

  .mw-25 {
    max-width: 25% !important;
  }

  .mw-50 {
    max-width: 50% !important;
  }

  .mw-75 {
    max-width: 75% !important;
  }

  .mw-100 {
    max-width: 100% !important;
  }

  .w-md-100 {
    @media screen and (max-width:767.98px) {
      width: 100% !important;
    }
  }

  .w-sm-100 {
    @media screen and (max-width:575.98px) {
      width: 100% !important;
    }
  }

  .c-scroll .mat-tab-body-wrapper {
    height: calc(100vh - 220px);
    overflow-y: auto;
    overflow-x: hidden;
    display: block;
    margin-top: 1rem;

    @media screen and (max-width:540px) {
      overflow-y: overlay !important;

      &::-webkit-scrollbar {
        width: 0px !important;
      }
    }

    &::-webkit-scrollbar {
      width: 8px;
    }

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
    }

    &::-webkit-scrollbar-thumb {
      background: #b4bcc9;
      outline: none;
      border-radius: 5px;
    }

    .mat-tab-body.mat-tab-body-active {
      margin-right: 10px;

      @media screen and (max-width:540px) {
        margin-right: 0 !important;
      }
    }
  }

  .createSch.date-wrap {
    .mat-datepicker-toggle {
      button {
        position: absolute;
        right: 0;
        top: -4px;

        .mat-datepicker-toggle-default-icon {
          width: 20px !important;
        }
      }
    }
  }


  // Froal custom icons removed
  // #specialCharacters-1, #undo-1, #redo-1, #insertLink-1, #backgroundColor-1{
  //   display: none !important;
  // }
  .fr-command.fr-btn {
    height: 25px !important;
    background-color: #ceddc927 !important;

    svg {
      margin: 0px 5px !important;
      width: 18px !important;
    }
  }

  .fr-popup {
    .fr-command.fr-btn {
      height: auto !important;
    }
  }

  // .fr-box.fr-basic{
  //   overflow: hidden;
  // }
  // .fr-toolbar.fr-top{
  //   border-radius: 0;
  // }
  .post-share-links {
    .fr-box.fr-basic .fr-element {
      min-height: 40px !important;
      padding: 0 15px;
    }

    .fr-wrapper.show-placeholder .fr-placeholder {
      margin-top: 0px !important;
      padding-top: 10px !important;
    }
  }

  .textExtend_froala p {
    margin-top: 5px !important;
    word-break: break-word;
  }

  .posted_comment,
  .reply_posted_comment {
    span {
      font-family: var(--font-family) !important;
    }

    p {
      font-size: var(--clamp16) !important;
      font-family: "Poppins", sans-serif !important;
      margin-top: 5px;
      font-weight: 400;
      word-break: break-word;
      color: #67696B;

      @media screen and (max-width:767px) {
        font-size: 12px !important;
      }

      em {
        font-weight: 600;
      }
    }

    p {
      img {
        width: 110px !important;

        @media screen and (max-width:990px) {
          width: 70px !important;
        }
      }
    }

    h6 {
      font-weight: 600;
      font-size: var(--clamp16);
      cursor: pointer;
    }
  }

  .reply-btn {
    font-size: var(--clamp16);

    &:hover {
      opacity: 0.9;
    }
  }

  .mat-form-field-appearance-legacy .mat-form-field-wrapper {
    padding-bottom: 0 !important;
  }

  .fr-emoticon-img {
    background-repeat: no-repeat !important;
    display: inline-block !important;
    width: 25px;
    height: 20px;
  }

  .post_text {
    font-size: var(--clamp16) !important;

    p {
      display: block;
      font-size: var(--clamp16) !important;

      span {
        display: block;
        background-repeat: no-repeat !important;
      }

      em {
        font-weight: 600;
      }
    }
  }

  .smily-tool-tip {
    width: 25px;
    font-size: 13px !important;

    .tooltiptext {
      visibility: hidden;
      background-color: #48547B;
      opacity: 0.5;
      min-width: max-content;
      color: #fff;
      text-align: left;
      padding: 3px 1px !important;
      border-radius: 6px;
      position: absolute;
      z-index: 999;
      margin-left: 0;
      line-height: 2;
      font-size: var(--clamp12) !important;

      &::after {
        border: none;
      }
    }
  }

  ul.pagination {
    li {
      &.page-item:not(:first-child) .page-link {
        margin-left: 3px !important;
      }

      a {
        padding: 2px 10px !important;
        text-align: center !important;
        font-size: var(--clamp20) !important;
      }
    }
  }

  .textExtend_froala {
    .fr-wrapper {
      .fr-element.fr-view {
        overflow: auto !important;
        max-height: 160px !important;
        padding-top: 2px !important;
        min-height: 100px;

        &::-webkit-scrollbar {
          width: 7px;

          @media screen and (max-width:640px) {
            width: 0;
          }
        }

        &::-webkit-scrollbar-track {
          box-shadow: inset 0 0 6px #4d4d4d18;
        }

        &::-webkit-scrollbar-thumb {
          background: #cfcfcf;
          outline: none;
          border-radius: 5px;
        }
      }
    }

    span {
      font-weight: 400 !important;
      font-family: var(--font-family) !important;
    }
  }

  .edt-cmt {
    .fr-wrapper {
      .fr-element.fr-view {
        min-height: 20px;
        padding-bottom: 0;
      }
    }
  }

  .main-card {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    margin-top: 24px;
  }
  .onet-left-wrapper-frnds{
    position: sticky !important;
    top: 80px;
    height: 100%;
    margin-top: 90px;
    @media screen and(max-width:991px) {
      margin-right: 0px;
      overflow-x: hidden;
    }

    @media screen and (max-width:767px) {
      width: 0px;
      display: none;
    }
  }
  .onet-left-wrapper {
    width: 312px;
    position: absolute;
    left: 0;
    margin-right: 0px;
    top: 15px;
    // z-index: 9;
    z-index: 8;
    height: 100%;

    @media screen and (max-width: 1536px) {
      width: 290px;
    }

    @media screen and (max-width:1366px) {
      width: 260px;
    }

    @media screen and(max-width:991px) {
      margin-right: 0px;
      overflow-x: hidden;
    }

    @media screen and (max-width:767px) {
      width: 0px;
      display: none;
    }
  }

  .onet-right-wrapper {
    width: 312px;
    // position: absolute;
    // right: 0;
    margin-left: -312px;
    //  top: 25px;
    height: 100%;

    @media screen and (max-width: 1536px) {
      width: 290px;
      margin-left: -290px;
    }

    @media screen and (max-width:1366px) {
      width: 260px;
      margin-left: -260px;
    }

    @media screen and(max-width:991px) {
      margin-left: -260px;
      overflow-x: hidden;
    }

    @media screen and (max-width: 1199px) {
      width: 260px;
    }

    @media screen and (max-width:860px) {
      width: 0px;
      display: none;
    }
  }

  .mat_select {
    font-family: var(--font-family) !important;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    padding: 5px 0px;
    color: #212529;
    font-weight: 500;
  }

  .schedulePost-dropbox {
    .schedulePost_inn {
      button {
        min-width: 400px !important;
        max-width: 400px !important;

        .schedulePost_inn {
          .btns {
            display: flex !important;
            align-items: center !important;
          }
        }
      }
    }
  }

  .secondary-btn {
    border-radius: 10px;
    background: #f3f3f3;
    border: 1px solid #dedede;
    color: var(--onet-title-color);
    font-size: var(--clamp14) !important;
    padding: 8px 15px;

    &:hover {
      color: var(--onet-title-color);
      opacity: 0.8;
    }
  }


  // GRID IMAGE/VIDEO CSS  //////////////////////////////
  .img-grid {
    .o-img {
      width: 50%;
      height: 350px;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      position: relative;
      padding: 5px;
      cursor: pointer;
      transition: 0.2s all ease-in-out;
      overflow: hidden;

      @media screen and (max-width:1366px) {
        height: 300px;
      }

      @media screen and (max-width:767px) {
        width: 100%;
      }

      &:last-child {
        &::before {
          content: '';
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          margin: auto;
        }
      }

      &:hover {
        opacity: 0.9;
      }

      img,
      video {
        width: 100%;
        object-fit: cover;
        height: 100%;
        border-radius: 10px;
        box-shadow: 0 0 5px -2px #6f6f6f;
      }

      .vedioPlay {
        position: relative;
        height: 100%;
        transition: all .3s;
        border-radius: 10px;
        overflow: hidden;
        margin: 0px auto;
        text-align: center;

        &::before {
          transition: all .3s;
          content: "";
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          z-index: 9;
          width: 45px;
          height: 45px;
          margin: auto;
          background: url('../../../../src/assets/onet/icons/play.svg');
          background-repeat: no-repeat;
          background-color: #fff;
          border-radius: 50%;
          background-position: center;
        }

        &::after {
          content: "";
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          background-color: rgb(12, 12, 12);
          opacity: 0.5;
        }

        &:hover {
          transition: all .3s;

          &::before {
            transform: scale(1.1);
            transition: all .3s;
          }
        }
      }

      video {
        height: 100% !important;

        .controls progress {
          display: block;
          width: 100%;
          height: 81%;
          margin-top: 0.125rem;
          border: none;
          color: #0095dd;
          -moz-border-radius: 2px;
          -webkit-border-radius: 2px;
          border-radius: 2px;
        }
      }

      .more_img {
        position: absolute;
        font-size: var(--clamp48);
        left: 5px;
        right: 5px;
        top: 5px;
        bottom: 5px;
        margin: auto;
        z-index: 1;
        text-align: center;
        color: #fff;
        line-height: 4;
        font-family: var(--font-family) !important;

        @media screen and (max-width:1366px) {
          line-height: 5;
        }

        @media screen and (max-width:991px) {
          font-size: var(--clamp70);
          line-height: 2.5;
        }

        @media screen and (max-width:767px) {
          font-size: var(--clamp70);
          line-height: 2.5;
        }

        &::before {
          content: '';
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          margin: auto;
          background-color: #000;
          opacity: 0.6;
          z-index: -1;
          border-radius: 10px;

          @media screen and (max-width:991px) {
            background: radial-gradient(#000000c4, transparent);
            background-color: #10d3ba;
          }
        }

        span {
          position: absolute;
          width: 30%;
          height: 30%;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          color: #fff;
          margin: auto;

        }
      }
    }

    .o-img .blurBgImg,
    .o-img .blurBgImgTag {
      display: none;
    }

    .o-img.x-one {
      width: 100% !important;
      height: 350px;
      background: #fff;
      border-radius: 10px;
      display: flex;
      align-items: center;
      padding: 0;
      border: 1px solid #ebebeb;

      @media screen and (max-width:1366px) {
        height: 300px;
      }

      @media screen and (max-width:540px) {
        height: 100% !important;
      }

      .blurBgImg {
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        filter: blur(10px) brightness(0.6) drop-shadow(0px 4px 4px #000);
        z-index: 0;
        opacity: 0.5;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        object-fit: cover !important;
      }

      img {
        height: 100% !important;
        width: 100% !important;
        max-width: 100%;
        margin: auto;
        object-fit: contain !important;
      }

      .blurBgImgTag {
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        filter: blur(10px) brightness(0.6) drop-shadow(0px 4px 4px #000);
        z-index: 0;
        opacity: 0.5;
        width: 100% !important;
        height: 100% !important;
        object-fit: cover !important;
      }

      img,
      video {
        height: auto;
        display: block;
        position: relative;
      }

      .vedioPlay {
        position: relative;

        &::before {
          content: "";
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          z-index: 9;
          width: 50px;
          height: 50px;
          margin: auto;
          background: url('../../../../src/assets/onet/icons/play.svg');
          background-repeat: no-repeat;
          background-color: #fff;
          border-radius: 50%;
          background-position: center;
        }

        &::after {
          content: "";
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          background-color: rgb(12, 12, 12);
          opacity: 0.5;
        }

        video {
          border-radius: 10px !important;
        }
      }
    }

    .o-img.xxx-two {
      height: 175px;

      @media screen and (max-width:1366px) {
        height: 150px;
      }

      @media screen and (max-width:767px) {
        height: 260px;
        width: 50%;
      }
    }

    .o-img.xxx-three {
      height: 175px;
      position: absolute;
      bottom: 0;
      right: 0;

      @media screen and (max-width:1366px) {
        height: 150px;
      }

      @media screen and (max-width:767px) {
        height: 260px;
        width: 50%;
      }
    }

    .o-img.xxxx-two,
    .o-img.xxxxx-two {
      height: 175px;

      @media screen and (max-width:1366px) {
        height: 150px;
      }

      @media screen and (max-width:767px) {
        width: 50%;
        height: 260px;
      }
    }

    .o-img.xxxx-three,
    .o-img.xxxxx-three {
      height: 175px;
      position: absolute;
      bottom: 0;
      right: 25%;
      width: 25%;

      @media screen and (max-width:1366px) {
        height: 150px;
      }

      @media screen and (max-width:767px) {
        position: initial;
        width: 50%;
        height: 130px;
      }
    }

    .o-img.xxxx-four,
    .o-img.xxxxx-four {
      height: 175px;
      position: absolute;
      bottom: 0;
      right: 0;
      width: 25%;

      @media screen and (max-width:1366px) {
        height: 150px;
      }

      @media screen and (max-width:767px) {
        width: 50%;
        height: 130px;
      }
    }

  }

  // GRID IMAGE/VIDEO CSS //////////////////////////////

  .play-icn {
    transition: all 0.2s;
    position: absolute;
    cursor: pointer;
    z-index: 9;
    box-shadow: 0px 0px 40px -3px #000;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    padding: 10px;
    border-radius: 50%;

    &:hover {
      transition: all 0.2s;
      box-shadow: 0px 0px 20px 2px #000;
      border-radius: 50%;
    }
  }

  .nowrap {
    white-space: nowrap;
  }

  .text-dot-limit {
    -webkit-line-clamp: 2;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    word-break: break-word;
    font-family: var(--font-family) !important;
    max-height: 45px;
    line-height: 22px;
  }

  .text-dot-limit-oneLine {
    -webkit-line-clamp: 1 !important;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    word-break: break-word;
    font-family: var(--font-family) !important;
    // height: 23px;
    line-height: 22px;
  }

  .write-your-story,
  .textExtend_froala {
    &>div {
      border: 1px solid #ccc;
      border-radius: 10px;
      font-family: var(--font-family) !important;
      font-weight: 500 !important;
    }
  }

  .fr-toolbar .fr-newline {
    display: none;
  }

  .fr-second-toolbar,
  .fr-box.fr-basic .fr-wrapper,
  .fr-toolbar {
    border: none !important;
  }

  .fr-box.fr-basic .fr-wrapper {
    border-radius: 10px !important;
  }

  // .textExtend_froala{
  //   .fr-toolbar{display: none;}
  //   .fr-wrapper:focus + .fr-toolbar{
  //     display: block !important;
  //   }
  // }
  .onet-right-content {
    width: 100%;
    padding-left: 312px;

    @media screen and (max-width: 1536px) {
      padding-left: 290px;
    }

    @media screen and (max-width:1366px) {
      padding-left: 260px;
    }

    @media screen and (max-width:1199px) {
      padding-left: 0px;
      position: relative;
      z-index: 9;
    }
  }

  .onet-left-content {
    width: 100%;
    padding-right: 312px;

    @media screen and (max-width: 1536px) {
      padding-right: 290px;
    }

    @media screen and (max-width:1366px) {
      padding-right: 260px;
    }

    @media screen and (min-width:992px) and (max-width:1199) {
      margin-top: 56px;
    }

    @media screen and (max-width:1199px) {
      padding-right: 0px;
      position: relative;
      z-index: 2;
    }
  }

  .onet-left-content-inn {
    position: relative;
    top: 0;
  }

  .onet-public-left-content {
    width: 100%;
    padding-right: 312px;
    padding-left: 312px;

    @media screen and (max-width: 1536px) {
      padding-left: 290px;
      padding-right: 290px;
    }

    @media screen and (max-width:1366px) {
      padding-right: 260px;
      padding-left: 260px;
    }

    @media screen and (max-width: 1199px) {
      padding-right: 0;
    }

    @media screen and (max-width: 640px) {
      padding-left: 0;
    }

    .onet-public-left-content-inn {
      margin: 0 1.5rem;

      @media screen and (max-width:1199px) {
        margin: 0 1rem;
        margin-right: 0;
      }

      @media screen and (max-width: 640px) {
        margin: 0 0rem;
      }
    }

    .onet-right-wrapper {
      top: 80px !important;
      right: 0;
    }
  }

  .frnd-profile {
    height: 200px !important;
    top: 0 !important;

    @media screen and(max-width:1199px) {
      height: 164px !important;
      margin-top: 0px !important;
    }

    .c_img {
      height: 200px !important;

      @media screen and(max-width:1199px) {
        height: 164px !important;
      }

      .coverPhoto_middleImg {
        height: 200px !important;

        @media screen and(max-width:1199px) {
          height: 164px !important;
        }

        img {
          width: 100%;
          height: auto;
          object-fit: cover;
          object-position: center;
        }
      }
    }
  }

  .cover-bg-inner {
    height: 200px !important;
    top: 0 !important;

    @media screen and(max-width:1199px) {
      height: 140px !important;
    }

    @media screen and(max-width:767px) {
      height: 0px !important;
    }

    .gm-user .gm-user-inn .profile-wrap .profile-pic {
      width: 170px !important;
      height: 170px !important;
      margin-bottom: 10px !important;

      @media screen and (max-width: 1199px) {
        width: 100px !important;
        height: 100px !important;
        margin: auto !important;
      }
    }

    .gm-user {
      .gm-user-inn {
        @media screen and (max-width: 1199px) {
          height: 140px !important;
        }
      }
    }

    .c_img {
      height: 200px !important;

      @media screen and(max-width:1199px) {
        height: 140px !important;
      }

      .coverPhoto_middleImg {
        height: 200px !important;

        @media screen and(max-width:1199px) {
          height: 140px !important;
        }

        img {
          width: 100%;
          height: auto;
          object-fit: cover;
          object-position: center;
        }
      }
    }
  }



  .privacy-wrap .ng-select {
    font-size: 12px !important;
    width: auto !important;
    outline: none !important;
    font-family: var(--font-family) !important;

    .ng-value {
      font-size: 12px !important;
      font-family: var(--font-family) !important;
    }
  }

  .removeCard {
    position: absolute;
    right: 10px;
    top: 10px;
    padding: 3px;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s;

    img {
      opacity: 1;
      width: 17px;
    }

    &:hover {
      background: var(--onet-primary-color) !important;

      img {
        opacity: 1;
        filter: brightness(20.5) saturate(0.5) contrast(20.5);
      }
    }
  }

  .ndImg {
    text-align: center;
    margin: 0px auto;
    padding: 20px 0;

    .nodata_img {
      max-width: 40%;
    }
  }

  @media screen and(min-width:2560px) {
    .body_layout {
      width: 1920px;
      margin: 0px auto;
    }

  }

  .c-pointer {
    cursor: pointer;
  }

  .card-col {
    width: 25%;

    @media screen and (max-width:1599px) {
      width: 33.33%;
    }

    @media screen and (max-width:767.98px) {
      width: 50%;
    }

    @media screen and (max-width:540px) {
      width: 100%;
    }
  }

  .card-col-inn {
    width: 33.33%;

    @media screen and (max-width:1199px) {
      width: 50%;
    }

    @media screen and (max-width:640px) {
      width: 50%;
    }

    @media screen and (max-width:540px) {
      width: 100%;
    }
  }

  .mat-elevation-z5 {
    box-shadow: none !important;
  }

  .tab_wrapper {
    .mat-tab-header {
      border-bottom: 1px solid #ccc;
      border-radius: 0px;
      background-color: #f1f1ef;
      padding: 0;
      border-top: 0;
      color: #707070;
      position: relative;
      top: 0;
      z-index: 2;
      height: 75px;

      .moduleTitle {
        font-weight: 500;
        font-size: var(--clamp24);
        color: var(--onet-title-color);

        @media screen and (max-width:1199px) {
          margin-left: 10px;
        }

        @media screen and (max-width:575.98px) {
          margin-left: 0px;
        }
      }

      @media screen and (max-width:1199px) {
        margin-left: 0px;
      }

      @media screen and (max-width:767px) {
        margin-top: 3rem;
      }
    }

    .friends_bodyContent {
      .mat-tab-header {
        @media screen and (max-width:991px) {
          margin-top: 3rem;
        }
      }
    }
  }

  .inner-tab-wrapper,
  .events-tab-wrapper {
    border-bottom: 1px solid #b5b5b5;
    border-radius: 0px;
    background-color: transparent;
    border-top: 0;
    color: #707070;
    position: relative;
    top: 0;
    z-index: 2;
    margin-left: 335px;
    padding: 10px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 75px;

    .moduleTitle {
      font-weight: 400;
      font-size: var(--clamp24);
      color: var(--onet-title-color);
      margin-left: 0px;
      margin-top: 25px;

      @media screen and (max-width:1199px) {
        margin-left: 10px;
      }

      @media screen and (max-width:575.98px) {
        margin-left: 0px;
      }
    }

    @media screen and (max-width: 1536px) {
      margin-left: 310px;
    }

    @media screen and (max-width:1366px) {
      margin-left: 275px;
    }

    @media screen and (max-width:1199px) {
      margin-left: 0px;
    }

    @media screen and (max-width:767px) {
      display: block;
      margin-top: 3rem;
    }
  }

  .scrollMobile {
    @media screen and (max-width:767px) {
      flex-wrap: nowrap;
      overflow-x: auto;

      .col-auto.card-col {
        width: 65%;
        margin-bottom: 5px;
      }
    }

    @media screen and (max-width:575px) {
      .col-auto.card-col {
        width: 85%;
      }
    }

  }


  @media screen and (max-width: 767px) {
    .moduleTitleDiv {
      top: 20px;
      left: 20px;
    }

    .create_btn {
      top: 10px;
      right: 7px;
    }
  }

  .pp select {
    padding: 0.375rem 1.25rem 0.375rem 0.75rem !important;
    background-position: right 0.3rem center !important;
  }

  .rev-arrow {
    transform: rotate(180deg);
  }

  .bg-white {
    border-radius: 8px;
  }

  .section-wrapper-inn {
    max-width: 1320px;
    margin: auto;

    // @media screen and (max-width:1792px) {
    //   max-width: 1300px;
    // }

    // @media screen and (max-width:1680px) {
    //   max-width: 1300px;
    // }

    // @media screen and (max-width:1536px) {
    //   max-width: 1250px;
    // }

    @media screen and (max-width:1440px) {
      // max-width: 1200px;
      margin-left: 1rem;
      margin-right: 1rem;
    }

    // @media screen and (max-width:1366px) {
    //   max-width: 1150px;
    // }

    // @media screen and (max-width:1280px) {
    //   max-width: 1100px;
    // }

    @media screen and (max-width:1199px) {
      max-width: initial;
      // margin: 1rem 1rem 0;
    }

    @media screen and (max-width:767px) {
      margin-top: 4rem;
    }
  }

  .home_loads {
    overflow: auto;
    height: calc(100vh - 77px);
  }

  .section-wrapper.friend-section-wrapper {
    .left-wrapper {
      top: -10px !important;
    }
  }

  .mat-card-avatar {
    width: 52px !important;
    height: 52px !important;

    @media screen and(max-width:767px) {
      width: 35px !important;
      height: 35px !important;
    }
  }

  .check-box {
    position: relative;

    input {
      opacity: 0;
    }

    .checkmark {
      position: absolute;
      top: 3px;
      left: 0;
      height: 15px;
      width: 15px;
      border: 1px solid #ccc;
      border-radius: 3px;

      &:after {
        content: "";
        position: absolute;
        display: none;
        left: 5px;
        top: 2px;
        width: 5px;
        height: 9px;
        border: solid white;
        border-width: 0 1px 1px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }
    }

    input:checked~.checkmark {
      background-color: var(--onet-primary-color);
      border: none;
    }

    input:checked~.checkmark:after {
      display: block;
    }
  }

  .form_label {
    label {
      font-size: 12px;
      color: #2E3744;
      font-weight: 400;
      margin-top: 15px;
      line-height: 18px;
    }
  }

  .audio-upload,
  .create-channel {
    .form_label {
      label {
        margin-top: 0px;
      }
    }
  }

  .hide_caret {
    .mat-select-arrow {
      display: none !important;
    }

    .mat-form-field-label {
      top: 25px;
      left: 10px;
      color: #383838;
    }
  }

  .media-tags-group .media-post-btn .homeCreateposTypeMat .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-infix .mat-select .mat-select-value-text mat-select-trigger {
    font-family: var(--font-family) !important;
    // font-size: var(--clamp16) !important;
  }



  .blue-color {
    color: var(--onet-link-blue-color) !important
  }

  .text-decoration-underline {
    text-decoration: underline !important;
  }

  .post_text a {
    text-decoration: underline !important;
    color: var(--onet-link-blue-color) !important
  }

 

.custom-search-input{
  button{
    i{color: #999;}
  }
}












  // PLEASE ADD CSS ABOVE ONLY
}


.collapseMenu {
  .body_layout {
    padding-left: 0 !important;
  }
} 

@media screen and (max-width:1024px) {
    .cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
        opacity: 1 !important;
    }
}