@import '~bootstrap/scss/bootstrap';
//@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import '~ngx-toastr/toastr';
@import './assets/o-trim/otrim-common-styles';
@import './assets/o-tracker/otracker-common-style';
@import url("../src/assets/css/theme-setting.scss");
@import '../node_modules/angular-calendar/css/angular-calendar.css';


:root {
  --font-family: "Poppins", sans-serif;
  --primary-color: #2D7EC1;
  --secondary-color: #CBE8FE;
  --primary-btn-bg: transparent linear-gradient(270deg,
      var(--primary-color) 0%,
      var(--secondary-color) 100%) 0% 0% no-repeat padding-box;
  --text-color: #0F1419;
  --text-muted-color: #969696;
  --link-color: #125df2;
  --header-text-color: #463742;
  --header-border-color: #f1f1f1;
  --card-border-color: #d7d3e0;
  --black-color: #000000;
  --white-color: #ffffff;
  --error-color: #e61800;
  --body-bg: #EFF3F4;
  --bg-color1: #8743ff33;
  --input-bg: #EEF7FF;
  --table-head-bg: #DEF1FF;
  --input-height: 42px;
  --input-height-lg: 42px;
  --input-height-sm: 40px;
  --body-content-bg: #f7f7f7;
  --success-bg: #1ad5984d;
  --success-text-color: #45B549;
  --pending-bg: #217efd4d;
  --pending-text-color: #217efd;
  --failed-bg: #ea3a3d4d;
  --failed-text-color: #ff2f33;
  --font-weght-medium: 500;
  --font-weght-bold: 600;
  --uppercase: uppercase;
  --border-radius1: 8px;
  --border-radius2: 10px;
  --border-radius3: 16px;
  --border-color-ticket-from: #eae9ed;
  --arrow-btn-bg: #dccafc;
  --btn-outline-bg: #f1ebff80;
  --table-even-color: #F8F8F8;
  --voilet-color: #7b41fd;
  --title-color: #015391;
  --oes-secondary-color: #E8F5FD;
  --oes-text-color: #616B80;
  --btn-primary-color: #2A76B4;
  --oes-title-color: #064B8B;
  --oes-table-color: #2E3744;
  --back-btn-bg: #015391;

  --clamp12: clamp(10px, 0.625vw, 12px);
  --clamp13: clamp(11px, 0.677vw, 13px);
  --clamp14: clamp(12px, 0.72916vw, 14px);
  --clamp15: clamp(13px, 0.78125vw, 15px);
  --clamp16: clamp(14px, 0.8333vw, 16px);
  --clamp18: clamp(15px, 0.9375vw, 18px);
  --clamp20: clamp(16px, 1.0416vw, 20px);
  --clamp22: clamp(16px, 1.14583vw, 22px);
  --clamp24: clamp(18px, 1.25vw, 24px);
  --clamp26: clamp(20px, 1.3541vw, 26px);
  --clamp28: clamp(22px, 1.4583vw, 28px);
  --clamp30: clamp(20px, 1.5625vw, 30px);
  --clamp32: clamp(24px, 1.6666vw, 32px);
  --clamp34: clamp(25px, 1.77083vw, 34px);
  --clamp36: clamp(25px, 1.875vw, 36px);
  --clamp38: clamp(26px, 1.97916vw, 38px);
  --clamp40: clamp(25px, 2.083vw, 40px);
  --clamp42: clamp(25px, 2.1875vw, 42px);
  --clamp44: clamp(25px, 2.2916vw, 44px);
  --clamp46: clamp(36px, 2.39583vw, 46px);
  --clamp48: clamp(30px, 2.5vw, 48px);
  --clamp60: clamp(35px, 3.125vw, 60px);
  --clamp78: clamp(40px, 4.0625vw, 78px);
}

html,
body {
  // height: 100vh !important;
  // top: 0 !important;
  // min-height: inherit !important;
  // overflow: hidden;
  // overflow-y: auto;
  scroll-behavior: smooth;
  scrollbar-width:none;
}

* {
  //scrollbar-width: none !important;
}

::-webkit-scrollbar {
  width: 0;
  height: 0;
}

body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  min-height: 100%;
  background-color: var(--body-bg) !important;
}

ol,
ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

a {
  text-decoration: none !important;
  color: inherit;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 0;
}

.text-error {
  color: var(--error-color);
  font-size: var(--clamp12);

  &.search-error {
    position: absolute;
    bottom: -20px;
    font-size: 12px;
    left: 12px;
  }
}



.link-color {
  color: var(--link-color);
}

.cursor-pointer {
  cursor: pointer;
}

.primary-color {
  color: var(--primary-color) !important;
}

.border-bottom {
  border-bottom: 1px solid #f1f1f1;
}

.hide-arrow.dropdown-toggle {
  &::after {
    display: none;
  }
}

//Form fields styles start
@mixin placeholder {
  ::-webkit-input-placeholder {
    @content;
    color: #00000085;
  }

  :-moz-placeholder {
    @content;
    color: #00000085;
  }

  ::-moz-placeholder {
    @content;
    color: #00000085;
  }

  :-ms-input-placeholder {
    @content;
    color: #00000085;
  }
}

input::-ms-reveal {
  display: none;
}

.form-control:focus,
.form-control:focus-visible,
.form-select:focus {
  box-shadow: none;
  border-color: var(--primary-color);
}


.custom-input {
  background: var(--input-bg);
  color: var(--text-color);
  font-size: var(--clamp14);
  border-radius: 6px;
  border: none;
  font-weight: 400;
  width: 100%;
  height: var(--input-height);

  @media screen and (max-width: 1400px) {
    height: var(--input-height-lg);
  }

  @media screen and (max-width: 992px) {
    height: var(--input-height-sm);
  }

  &::placeholder {
    opacity: 0.5;
  }

  &:focus {
    background: var(--input-bg);
  }
}

.custom-select {
  background: url(../src/assets/images/dropdown-arrow1.svg) no-repeat var(--input-bg);
  background-position: 94% center;
  cursor: pointer;
  color: var(--title-color);
  font-size: var(--clamp14);
  border-radius: var(--border-radius2);
  border: none;
  font-weight: 400;
  width: 100%;
  height: var(--input-height);
  border: 2px solid #EFF3F4;
  // min-width: 220px;

  @media screen and (max-width: 1400px) {
    height: var(--input-height-lg);
  }

  @media screen and (max-width: 992px) {
    height: var(--input-height-sm);
  }

  &::placeholder {
    opacity: 0.5;
  }

  &:focus {
    background: url(../src/assets/images/dropdown-arrow1.svg) no-repeat var(--input-bg);
    background-position: 94% center;
    outline: none;
    box-shadow: 0px 0px 0px;
    border: 1px solid var(--primary-color);
  }

  &.text-muted {
    cursor: initial;
    color: var(--text-color);
  }
}

@mixin search_props {
  position: relative;
  min-width: 230px;
  width: 100%;

  @media screen and (max-width:767px) {
    max-width: 100%;
  }

  &:focus-visible {
    border: none !important;
    box-shadow: none !important;
    outline: none;
  }

  input {
    padding-right: 44px;
    padding-left: 12px;
    background: var(--input-bg);
    border: none;
    width: 100%;
    max-width: 100%;
    border-radius: var(--border-radius2) !important;
    color: var(--text-color);
    height: var(--input-height);
    caret-color: var(--primary-color);
    background: #EEF7FF 0% 0% no-repeat padding-box;
    border: 1px solid #00000000;
    border-radius: 10px;

    @media screen and (max-width: 1400px) {
      height: var(--input-height-lg);
    }

    @media screen and (max-width: 992px) {
      height: var(--input-height-sm);
    }

    &:focus,
    &:focus-visible {
      background: #EEF7FF 0% 0% no-repeat padding-box;
      box-shadow: none;
      outline: 1px solid var(--primary-color);
      border: none;
    }
  }

  @include placeholder {
    color: var(--black-color) !important;
    opacity: 0.4;
    font-size: 14px;
  }

  .right-icon {
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translateY(-50%);
    background: var(--primary-color);
    box-shadow: none;
    outline: none;
    border: none;
    color: var(--white-color);
    height: 32px;
    font-size: var(--clamp14);
    width: 32px;
    z-index: 4;
    border-radius: var(--border-radius2) !important;
    cursor: pointer;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    padding-top: 2px;
  }

}

.custom-search {
  @include search_props;

  &.close-icon {
    input {
      padding-right: 75px;
    }
  }
}

.custom-filter {
  @include search_props;


  &.close-icon {
    input {
      padding-right: 75px;
    }
  }

  .left-icon {
    position: absolute;
    left: 10px;
    top: 50%;
    z-index: 2;
    transform: translateY(-50%);
    padding-top: 2px;
    color: var(--text-color);
    opacity: 0.4;
  }

  &.date-field {
    font-size: 14px !important;
    min-width: 250px !important;

    input {
      font-size: 14px !important;
    }

  }
}

// custom-tooltip styles start
.obs-custom-tooltip .tooltip-inner {
  background-color: var(--primary-color);
  font-size: 12px;
  line-height: 16px;
}

.obs-custom-tooltip.bs-tooltip-end .tooltip-arrow::before {
  border-right-color: var(--primary-color);
}

.obs-custom-tooltip.bs-tooltip-start .tooltip-arrow::before {
  border-left-color: var(--primary-color);
}

.obs-custom-tooltip.bs-tooltip-top .tooltip-arrow::before {
  border-top-color: var(--primary-color);
}

.obs-custom-tooltip.bs-tooltip-bottom .tooltip-arrow::before {
  border-bottom-color: var(--primary-color);
}

// custom-tooltip styles end


.mat-table {
  font-family: var(--font-family);
}

// scrollbar-width: thin !important;
.table-responsive {


  &::-webkit-scrollbar {
    background-color: transparent;
    height: 6px;
  }
}

.table-responsive:hover {
  &::-webkit-scrollbar {
    height: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, .2)
  }
}


.custom-dropdown2 {
  .btn-view {
    background: var(--input-bg);
    border: none;
    border-radius: 8px !important;
    color: var(--primary-color);
    height: var(--input-height);
    padding-left: 24px;
    padding-right: 15px;

    .fa {
      padding: 0 5px 0 10px;
      color: var(--primary-color);
      font-size: var(--clamp18);
    }

    @media screen and (max-width: 1400px) {
      height: var(--input-height-lg);
    }

    @media screen and (max-width: 992px) {
      height: var(--input-height-sm);
    }

    &:focus {
      background: var(--input-bg);
    }
  }
}

.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background: linear-gradient(94deg, #015391 0%, #2D7EC1 100%);
}

//Form fields styles end

// Buttons styles start
.custom-btn.btn,
.custom-btn {
  background-color: var(--title-color);
  border-radius: var(--border-radius2);
  color: var(--white-color);
  font-size: var(--clamp16);
  font-weight: 500;
  text-align: center;
  min-width: 120px;
  padding: 14px 14px 12px;
  outline: none;
  border: 1px solid var(--title-color);
  display: inline-flex;
  justify-content: center;
  align-items: center;
  min-height: var(--input-height);
  line-height: 18px;

  i {
    margin-right: 12px;
  }

  &:hover,
  &.active,
  &:focus,
  &:focus-visible {
    color: var(--white-color);
  }

  &.green-btn {
    background-color: #45B549;
    border: 1px solid #45B549;
  }
}

.custom-btn-outline {
  background: var(--white-color);
  border-radius: var(--border-radius2);
  color: #666666;
  font-size: var(--clamp16);
  font-weight: 500;
  text-align: center;
  min-width: 100px;
  padding: 14px 14px 12px;
  outline: none;
  border: 1px solid #2D7EC1;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  min-height: var(--input-height);
  line-height: 18px;

  i {
    color: var(--primary-color);
    margin-right: 12px;
  }
}


.addfavorite-fill {
  .fa {
    color: #ff009b !important;
  }
}

.addfavorite-outline {
  .fal {
    color: #ff99d7 !important;
  }
}

.back-btn {
  background: linear-gradient(129deg, #015391 0%, #2D7EC1 100%);
  width: 35px;
  height: 35px;
  border-radius: 6px;
  cursor: pointer;
  color: var(--white-color);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  border: none;

  &:hover {
    color: var(--white-color);
  }
}

.animation-btn {
  color: #fff;
  position: relative;
  background: #012670;
  font-size: var(--clamp16);
  text-transform: capitalize;
  padding: 13px 50px 13px 13px;
  border-radius: 33px;
  border: 1px solid #1d9cf0;
  overflow: hidden;
  transition: all 0.3s ease 0s;
  z-index: 1;
  min-height: 60px;

  &:before {
    content: '';
    background: #1d9cf0;
    width: 100%;
    height: 100%;
    border-radius: 33px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    transition: all 0.3s ease;
  }

  span {
    transition: all 0.4s ease-in;
  }

  .icon {
    width: 30px;
    transform: translateY(-50%);
    position: absolute;
    top: 50%;
    left: calc(100% - 40px);
    transition: all 0.3s ease 0s;
  }

  &:hover {
    color: #fff;
    padding: 13px 13px 13px 50px;
    border-radius: 33px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    border: 1px solid #FFFFFF99;
    background-color: #0C4B8C;

    span {
      // animation: blurText 0.3s ease 0s;
    }

    .icon {
      left: 10px;
    }

    &:before {
      background: #60bcfa61;
      width: 50px;
      border-top-right-radius: 50px;
      border-bottom-right-radius: 50px;
      box-shadow: 0 0 20px #569ff7;
    }
  }

  @keyframes blurText {
    0% {
      filter: blur(0px);
    }

    50% {
      filter: blur(6px);
    }

    100% {
      filter: blur(0px);
    }
  }
}

// Buttons styles start

// Scrollbar styles start
@mixin scrollbar {
  &::-webkit-scrollbar {
    width: 0;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--bg-color1);
    box-sizing: border-box;
    box-shadow: inset 0px 10px 10px rgba(255, 255, 255, 0.25);
    border-radius: 3px;
  }
}

.sub-sidebar,
.obs-sidebar {
  @include scrollbar;
}

// Scrollbar styles end

//headers styles start
.obs-title2 {
  font-size: var(--clamp24);
  color: var(--title-color);
  font-weight: 600;

  span {
    color: var(--success-text-color);
    display: inline-block;
  }
}

.obs-title3 {
  font-size: var(--clamp20);
  color: var(--title-color);
  font-weight: 600;
}

.obs-title4 {
  font-size: var(--clamp18);
  color: var(--title-color);
  font-weight: 600;
}


.obs-title5 {
  font-size: var(--clamp18);
  color: var(--title-color);
  font-weight: 500;
}

//headers styles end

.obs-inside-page {
  background-color: var(--white-color);
  border-radius: 16px;
  padding: 24px;

  // height: 100%;
  @media (max-width: 991px) {
    padding: 16px;
  }

  .page-header {
    padding-bottom: 16px;
    border-bottom: 1px solid var(--header-border-color);
    margin-bottom: 20px;
  }
}

// mat date picker css start
.input-group {
  .md-drppicker {
    &.shown.double {
      top: 60px !important;
      padding: 15px 30px 60px 15px;
      display: flex;
      overflow: inherit;
      left: auto !important;
      right: 0 !important;

      @media screen and (max-width:651px) {
        display: inherit;
      }
    }

    .buttons_input {
      display: flex;
      justify-content: flex-end;

      .btn {
        background: var(--white-color);
        border-radius: 6px;
        color: var(--primary-color);
        font-size: var(--clamp16);
        font-weight: 500;
        min-width: 100px;
        padding: 1px 8px;
        border: 1px solid var(--primary-color);
        color: var(--text-color);

        @media screen and(max-width:768px) {
          font-size: 12px !important;
        }

        &.btn-default {
          margin-right: 8px;
          color: var(--primary-color);
        }

        svg {
          width: 20px;
          position: relative;
          top: -5px;
          color: #015391;
        }

        &:last-child {
          background-color: var(--primary-color);
          color: var(--white-color);
          text-align: center;
        }
      }
    }

    .ranges {
      ul {
        li {
          button {
            color: var(--of-main-text-color);
            white-space: nowrap;

            &.active {
              background-color: var(--primary-color);
              border-radius: 8px;
            }
          }

          &:hover {
            border-radius: 8px;
          }
        }
      }
    }

    th {
      color: var(--primary-color);

      &.prev,
      &.next {
        filter: sepia(100%) hue-rotate(190deg) saturate(460%);
      }
    }

    td {
      color: var(--text-color);

      &.off {
        color: var(--primary-color);
        opacity: 0.5;
      }

      &.active {
        background-color: var(--primary-color);
      }
    }

    .buttons {
      text-align: right;
      margin: 0 5px 5px 0;
      position: absolute;
      bottom: 10px;
      right: 10px;
    }
  }
}

// mat date picker css end
// table wrapper start
.table-wrapper {
  width: 100%;
  font-size: var(--clamp16);
  color: var(--db-tittle);

  table {
    width: 100%;
    margin-bottom: 0;

    thead {
      background: var(--table-head-bg);
      border-radius: var(--border-radius2) !important;
      -webkit-border-radius: var(--border-radius2);
      -moz-border-radius: var(--border-radius2);
      vertical-align: middle;

      tr {

        th:first-child {
          border-top-left-radius: var(--border-radius2);
          border-bottom-left-radius: var(--border-radius2);
          // padding-left: 16px !important;
        }

        th:last-child {
          border-top-right-radius: var(--border-radius2);
          border-bottom-right-radius: var(--border-radius2);
        }

        th {
          background: var(--table-head-bg);
          font-size: var(--clamp15);
          font-weight: 600;
          border-bottom-width: 0px;
          padding: 0 0.5em;
          border-radius: 0px;
          line-height: 20px;
          color: #015391;
        }
      }
    }

    tbody {
      color: var(--label-color);

      tr {
        background-color: var(--table-row-color);
        height: 52px;

        &:nth-child(even) {
          background-color: var(--table-even-color);
        }

        td {
          vertical-align: middle;
          border-bottom-width: 0px;
          padding: 0 0.5em;
          color: var(--oes-table-color);
          font-size: var(--clamp15);
          font-weight: normal;
          background-color: white;

          &:last-child {
            vertical-align: middle;
          }

          @mixin actionBtnProps {
            font-size: var(--clamp13);
            font-weight: 500;
            background-color: transparent;
            position: relative;
            padding-left: 22px;

            &::before {
              position: absolute;
              content: '';
              left: 0;
              top: 50%;
              transform: translateY(-50%);
              height: 20px;
              width: 20px;
              background-size: 13px;
              background-position: center;
              background-repeat: no-repeat;
            }
          }

          button {
            border: none;

            &.success-full-btn {
              color: #00AA0D;
              @include actionBtnProps;

              &::before {
                background-image: url(../src/assets/images/dashboard/success-icon.svg);
              }
            }

            &.pending-full-btn {
              color: #d59351;
              @include actionBtnProps;

              &::before {
                background-image: url(../src/assets/images/dashboard/pending-icon.svg);
              }
            }

            &.failed-full-btn {
              color: #E85B49;
              @include actionBtnProps;

              &::before {
                background-image: url(../src/assets/images/dashboard/rejected-icon.svg);
              }
            }

            &.expired-full-btn {
              color: #E85B49;
              @include actionBtnProps;




              &::before {
                background-image: url(../src/assets/images/dashboard/expired-icon.svg);
              }
            }

            &.registred-full-btn {
              color: #00AA0D;
              @include actionBtnProps;

              &::before {
                background-image: url(../src/assets/images/dashboard/success-icon.svg);
              }
            }
            &.refunded-full-btn {
              //color: #2D7EC1;
              color: #00AA0D;
              @include actionBtnProps;

              &::before {
                //background-image: url(../src/assets/images/dashboard/refunded.svg);
                background-image: url(../src/assets/images/dashboard/success-icon.svg);
              }
            }


          }

          .trans-id-arrow {
            border-radius: 12px;
            background: #e3ffe2;
            display: inline-table;
            width: 20px;
          }

        }

        &:hover {
          td {
            background-color: var(--input-bg) !important;
          }

          box-shadow: 0px 12px 24px #36363612;
        }
      }
    }
  }

  table.mat-table{
    mat-header-row, mat-footer-row, th.mat-header-cell, td.mat-cell, td.mat-footer-cell {
      border-bottom-color: transparent !important;
      border-bottom-style: none !important;
    }

    th, td{
      outline: none;
      &:focus-visible{
        outline: none;
      }
    }
  }
}

// table wrapper end


.mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: var(--body-bg);
  border-radius: var(--border-radius1);
}

.mat-form-field-label {
  color: var(--black-color);
}

.pre-loader {
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 99999;
  background: transparent;
  color: var(--primary-color);
  display: flex;
  justify-content: center;
  overflow: hidden;
  left: 300px;
  top: 128px;
  @media (max-width:991px) {
    left:0;
    top: 0;
  }

  &-box {
    height: 100px;
    width: 100px;
    display: flex;
    position: fixed;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    background-color: var(--white-color);
    border-radius: 8px;
    box-shadow: 0px 0px 5px #eee;
    top: 40%;
    left: 50%;

    .spinner-border {
      color: var(--primary-color);
      width: 40px;
      height: 40px;
      border-width: 4px;
    }
  }

}




.mat-tooltip {
  color: #fff !important;
  padding: 15px;
  background: #453963;
}

ngx-intl-tel-input {
  width: 100%;

  .iti {
    width: 100%;

    .iti__flag-container {
      left: 45px;
    }

    input.custom {
      width: 100%;
      height: 50px;
      border-radius: 12px;
      padding-left: 100px;
      border: none;

      &:focus-visible {
        border: none;
      }
    }
  }
}

.otp {
  margin-top: 5px;

  .ng-otp-input-wrapper {
    display: flex;
    justify-content: space-between;

    .otp-input {
      border-radius: 8px;
      border: none;

      &:focus-visible {
        outline: none;
      }
    }
  }
}


// chatbot intro js custom styles
.introjs-tooltip.chat-tour-guide {
  background-color: transparent;
  box-shadow: none;

  .introjs-tooltiptext {
    color: #fff;
    font-size: var(--clamp16);
    text-shadow: 2px 2px 6px #000;
    font-family: cursive;
    padding: 15px 0px;
    line-height: 28px;
  }

  .introjs-tooltipbuttons {
    border-top: none;
    // display: flex;
    padding: 0 0 10px;

    .introjs-button {
      background: var(--primary-btn-bg);
      border-radius: var(--border-radius2);
      color: var(--white-color);
      font-size: var(--clamp14);
      font-weight: 500;
      text-align: center;
    }

    .introjs-prevbutton {
      margin-right: 5px;
    }
  }

  .introjs-skipbutton i {
    font-family: "Font Awesome 5 Pro" !important;
    color: #f00;
    background-color: var(--white-color);
    width: 25px;
    height: 25px;
    border-radius: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: var(--clamp16);
  }

  .introjs-arrow {
    display: none !important;
  }

}

// chatbot intro js custom styles end

// obs dashboard intro js custom styles start



.introjs-tooltip.obs-tour-guide {
  background-color: var(--white-color);
  box-shadow: none;
  padding: 10px 20px;
  border-radius: 15px;

  @media screen and (min-width:1200px){
background-color:transparent;
  }

  @media screen and(max-width:992px) {
    padding: 0 24px;
  }

  .introjs-tooltiptext {
    color:var(--title-color);
    font-size: var(--clamp16);
    text-shadow: none;
    font-family: poppins;
    padding: 15px 0px;
    //line-height: 28px;
    font-weight: 600;
    @media screen and (min-width:1200px) {
      color: var(--white-color);
      font-size: var(--clamp22);
      text-shadow: 2px 2px 6px #000;
      font-family: cursive;
      line-height: 28px;
    }
  }

  .introjs-tooltipbuttons {
    border-top: none;
    // display: flex;
    padding: 0 0 10px;

    .introjs-button {
      background: var(--title-bg);
      border-radius: 15px;
      color: var(--white-color);
      font-size: var(--clamp14);
      font-weight: 500;
      text-align: center;
      padding: 6px 16px;
      font-family: poppins;
      @media screen and (min-width:1200px) {
        background: var(--primary-btn-bg);
    border-radius: var(--border-radius2);
    color: var(--white-color);
    font-size: var(--clamp14);
    font-weight: 500;
    text-align: center;
    padding: 6px 16px;
      }
      &.introjs-prevbutton{
        @media screen and (max-width:1199px) {
          background: var(--title-bg);
          color: var(--title-color);
          border-color:#ccc;
        }
        
      }
      @media screen and (max-width:1199px) {
        &.introjs-nextbutton{
          background: var(--title-color);
          color: var(--white-color);
          border: 0;
      }
      }
      
    }

    .introjs-prevbutton {
      margin-right: 5px;
    }
  }

  .introjs-skipbutton i {
    font-family: "Font Awesome 5 Pro" !important;
    color: var(--white-color);
    background-color: var(--title-color);
    width: 25px;
    height: 25px;
    border-radius: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: var(--clamp16);

    @media screen and (min-width:1199px) {
      background-color: var(--white-color);
      color: red;
    }
  }

  .introjs-arrow {
    display: none !important;
  }

}

// obs dashboard intro js custom styles end



.links-layout {
  width: 100%;
  max-width: 1024px;
  margin: 25px auto;

  .tab-header {
    // background: #4529E8;
    color: #fff;
    min-height: 210px;
    align-items: center;
    display: flex;
    padding: 35px;
    background: linear-gradient(94deg, #015391 0%, #2D7EC1 100%);
    background-size: cover;
    background-position: center;

    @media screen and (max-width: 767px) {
      min-height: 100px;
    }
  }

  .tab-body-content {
    background-color: #fff;
    padding: 20px 25px;

    h4 {
      font-size: var(--clamp18);
      color: var(--title-color);
      font-weight: 500;
      margin-bottom: 6px;

      &:not(:nth-of-type(1)) {
        margin-top: 20px;
      }
    }

    p {
      color: var(--text-color);
      font-size: var(--clamp16);
      line-height: 26px;
    }
  }

  .mat-tab-group {
    border: none;
    background: transparent;
    margin-bottom: 2px;

    .mat-tab-labels {
      .mat-tab-label {
        background-color: #fff;
        color: var(--primary-color);
        font-size: var(--clamp16);
        font-weight: 600;
        border: 1px solid var(--primary-color);
        border-radius: 4px;
        margin-right: 2px;

        &.mat-tab-label-active {
          background: linear-gradient(94deg, #015391 0%, #2D7EC1 100%);
          color: var(--white-color);
          opacity: 9;
        }
      }
    }
  }
}

.c1{
  color: #F58932 !important;
}


body .wsc-badge--small:not(:hover):not(.wsc-badge--focused) {
  display: none !important;
}


.mat-table thead th .mat-sort-header-arrow{
  position: relative;
  opacity: 1 !important;
  transform: none !important;
  height: 16px !important;
  width: 20px !important;
  &::after{
    content: "\f0dc";
    font-family: "Font Awesome 5 Pro";
    position: absolute;
    left: 4px;
    top: -2px;
    color: #015391;
    font-weight: 600;
    font-size: 16px;
  }
  .mat-sort-header-stem, .mat-sort-header-indicator{
    display: none !important;
  }
}

//To disable the div
.disabledDiv {
  pointer-events: none;
  opacity: 0.8;
}

// Safari browser input focus issue
input::-webkit-contacts-auto-fill-button {
  visibility: hidden;
  display: none !important;
  pointer-events: none;
  position: absolute;
  right: 0;
}

// disable mac safari password default key icon
input::-webkit-textfield-decoration-container {
  visibility: hidden;
}

// All products styles imported here. Please write the styles above this line


// OES header google translater css start
.translated-rtl,  .translated-ltr{
  body{
      margin-top: 0 !important;
      top: 0!important;
  }

    .skiptranslate {
        .skiptranslate{
        display: none !important;
        }
    }
  }

  .VIpgJd-ZVi9od-aZ2wEe-wOHMyf{
    display: none !important;
  }


.skiptranslate {
    .skiptranslate{
      display: none !important;
    }
}


html[lang="auto"]{
  body{
    top:0 !important;
  }
}
  // OES header google translater css end


  // html[data-obs-theme='light'], html[data-obs-theme='dark'] {
  //   .cdk-overlay-container{
  //     z-index: 1 !important;
  //   }
  // }


@import url("../src/assets/css/obs-common-styles.scss");
 

@import "/src/assets/omail/css/omail-styles.scss";
@import "/src/assets/onet/css/onet-styles.scss";



