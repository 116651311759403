:root,
[data-trc-theme='light'] {
  --color-scheme: light;
  --menu-toggle: #8340FA;
  --menu-toggle-bg: #ffffff;
  --asidemenu-collepse-icon: #1F4393;
  --logo-text: #483AA7;
  --text-white: #ffffff;
  --text-dark: #2A3F51;
  --aside-menu-bg: #f1f1f1;
  --obsT_primary: #1DA1F2;
  --obsT_body: #E4ECF9;
  --obsT_color: #FFFFFF;
  --text-color: #173545;
  --text-color2: #616F81;
  --breadcrumb-bg: #ffffff;
  --section-card-bg: #ffffff;
  --btn-gb-color: #CBEBFF;
  --border-color: #E0E0E0;
  --border-color-50: #EBEAFF;
  --obsT_title: #1F4393;
  --obsT_subtitle: #0A2477;
  --cohort-tooltip: #fff;

  --input-bg-color: #F8F8FF;
  --label-color: #313131;
  --input-border-color: #F8F8FF;
  --input-check-color: #dadde1;
  --input-check-bgcolor: #1DA1F2;
  --placeholder-color: #616F81;
  --create-campaign-placeholder-color: #0A2477;
  --input-color: #000;
  --input-btn-disabled-bg: #efefef;
  --form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
  --map-bg: #def3ff;
  --map-color: #fff;

  --card-bg: #ffffff;
  --card-bg-secondary: #EDF1FF;
  --card-bg-third: #f8f8ff;
  --card-bg-four: #F3F5FF;
  --card-header-bg: #E8F3FF;
  --card-border-color: #e1eaf5;

  --table-bg: #ffffff;
  --table-bg2: #DCF2FD;
  --table-head-bg: #e3f3fd;
  --table-text-color: #173545;
  --table-border: #D8E8FF;
  --table-footer-bg: #e3f3fd;

  --modal-header: #e3f3fd;
  --modal-border-color: #D8E8FF;
  --modal-body: #fff;

  --userpath: #C8EBFF;
  --realtime1: #D6FFDC;
  --realtime2: #FFE2E2;
  --realtime3: #EDFFDB;
  --realtime4: #FFE2E2;

  --obsT_danger: #E8524A;
  --obsT_success: #01D167;
  --obsT_warning: #FEC600;
  --obsT_link: #136CDE;
  --filterObject: invert(0);
  --obst-mode-light: inline-block;
  --obst-mode-dark: none;
}

[data-trc-theme='dark'] {
  --color-scheme: dark;
  --menu-toggle: #1DA1F2;
  --menu-toggle-bg: #0a162f;
  --logo-text: #fff;
  --asidemenu-collepse-icon: #1DA1F2;
  --aside-menu-bg: #1A2744;
  --obsT_body: #0a162f;
  --obsT_color: #1A2744;
  --text-color: #f5f5f5;
  --text-color2: #f0f0f0;
  --breadcrumb-bg: #1A2744;
  --section-card-bg: #1A2744;
  --btn-gb-color: #2a354a;
  --border-color: #4F6084;
  --border-color-50: #4F608480;
  --obsT_title: #fff;
  --obsT_subtitle: #fff;
  --label-color: #f1f1f1;
  --cohort-tooltip: #1DA1F2;

  --input-bg-color: #0a162f;
  --input-border-color: #0a162f;
  --input-check-color: #fff;
  --input-check-bgcolor: #1DA1F2;
  --placeholder-color: #A3A3A3;
  --create-campaign-placeholder-color: #1DA1F2;
  --input-color: #d4d4d4;
  --input-btn-disabled-bg: #1a2744;
  --form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23dee2e6' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
  --map-bg: #1a2744;
  --map-color: #6D8FD9;

  --card-bg: #0a162f;
  --card-bg-secondary: #0a162f;
  --card-bg-third: #0a162f;
  --card-bg-four: #1A2744;
  --card-header-bg: #354875;
  --card-border-color: #4f608480;

  --table-bg: #0a162f;
  --table-bg2: #0a162fe5;
  --table-head-bg: #354875;
  --table-text-color: #e4e4e4;
  --table-border: #4f608480;
  --table-footer-bg: #1A2744;

  --userpath: #1a2744;
  --realtime1: #89D996;
  --realtime2: #1A2744;
  --realtime3: #1A2744;
  --realtime4: #E0A0A0;

  --modal-header: #354875;
  --modal-border-color: #4f608480;
  --modal-body: #1A2744;
  --filterObject: invert(1);
  --obst-mode-light: none;
  --obst-mode-dark: inline-block;
}

.widget-fields {
  border-color: var(--border-color-50) !important;
}

.widget-fields li,
.cart-profile,
.custom-dropdown.dropdown-menu {
  background: var(--card-bg) !important;
  color: var(--obsT_title);
  border-color: var(--border-color-50) !important;

  .dropdown-item {
    color: var(--text-color);

    img {
      filter: var(--filterObject);
    }

    &:hover,
    &:focus,
    &:active {
      color: var(--text-color);
      background: var(--obsT_primary);
    }
  }
}

[data-trc-theme='dark'] {
  .main-layout {
    header {
      background: var(--aside-menu-bg);
    }

    .obs-main-layout .obs-main-content,
    .obs-main-layout .obs-sidebar {
      background-color: var(--menu-toggle-bg);

      .obs-menus {

        .sidebar-products-list li,
        .sidebar-products-list-fixed li {
          &:not(:last-child) {
            border-bottom: none;
            margin-bottom: 0px !important;
          }

          a {
            background: var(--aside-menu-bg);
            border-bottom: 1px solid var(--border-color-50);
            margin-right: 0;

            @media only screen and (max-width: 767px) {
              padding-top: 10px;
            }

            .title {
              color: var(--text-color);
            }

            &.active,
            &:hover {
              background: #8743FF;

              .icon {
                background: var(--text-white) !important;
              }
            }

            .icon {
              width: 48px;
              height: 48px;
              background: var(--card-bg);
              border-radius: 6px;
              margin-bottom: 0;
              border-color: var(--border-color-50);

              img {
                filter: none;
              }
            }

            .hover-title {
              top: 22px;
            }
          }
        }
      }
    }


    .header-right {

      .widget-fields li,
      .cart-profile,
      .custom-dropdown.dropdown-menu {
        background: var(--card-bg) !important;
        color: var(--obsT_title);

        .dropdown-item {
          color: var(--text-color);

          img {
            filter: var(--filterObject);
          }

          &:hover,
          &:focus,
          &:active {
            color: var(--text-color);
            background: var(--obsT_primary);
          }
        }
      }

      .select-language .goog-te-gadget-simple {
        background-color: var(--card-bg) !important;
        color: var(--obsT_title);
        background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAC8AAAAVCAMAAADhGEdVAAAAAXNSR0IB2cksfwAAAVBQTFRFAVORAVORAVORAVORAVORAVORAVORAVORAVORAVORAVORAVORAVORAVORAVORAVORAVORAVORAVORAVORAVORAVORAVORAVORAVORAVORAVORAVORAVORAVORAVORAVORAVORAVORAVORAVORAVORAVORAVORAVORAVORAVORAVORAVORAVORAVORAVORAVORAVORAVORAVORAVORAVORAVORAVORAVORAVORAVORAVORAVORAVORAVORAVORAVORAVORAVORAVORAVORAVORAVORAVORAVORAVORAVORAVORAVORAVORAVORAVORAVORAVORAVORAVORAVORAVORAVORAVORAVORAVORAVORAVORAVORAVORAVORAVORAVORAVORAVORAVORAVORAVORAVORAVORAVORAVORAVORAVORAVORAVORAVORAVORAVORhqV2+AAAAHB0Uk5T5P/zCQDLf70YlnuXJfk3GSFAbYEn0Owac4AfLGtPmDMwcea3qP4tW3S1kz0kAw8eE0r1/FWQmuq/+qUHQZTnw1BIRE5fWkuLHeLFFNNU2A1F+N5HWUP2n8D7ydoCTTzxrgXwckyk97Y4QjIBO7mhbG3cKmAAAAFQSURBVHicjdJXU8JAFAXgHHJFFBFWsAAiKqAiiBpUwC723gsW7BXL/39zEwIkQIAzk9lN5svN7s0KArQxiVQ7AprMpTTDUte3aO5aG/BWeWiztee9vYH6DsY64GTM1ZC3Uqe62S7uu3vcajyG9b2s18e/0Ofnvr/YqoEa6x8MUDA0pOzXz5QMY8SwPpETYfRRoT+jEaKo1o951UlsXKk/MSkhPmU39NOYUUZXIiTXTwKpWQkI573onyvz83Es8CG6iCW5/rJ7xUP2tHlV8WsSTOt6TxspbNLWNnZE9X+p4X7Xtrd/YDrUezo6hvUESZH0xw2wnMJHZ4iUeTq/AAJ8ncLlVSGJzDVubu+U19LlnrL3D49yP0tPgk/PvPYLXt3BKp7yJ1Hr36R3ogg+6BNflZ4qPDIOotw3v7LUkP9hxRieh9L0V9+pv3o+xnTJVfX/jL4z6W8B9cMAAAAASUVORK5CYII=');
      }
    }
  }
}

[data-trc-theme='light'] {
  .main-layout {

    .obs-main-layout .obs-main-content,
    .obs-main-layout .obs-sidebar {
      .obs-menus {

        .sidebar-products-list li,
        .sidebar-products-list-fixed li {
          &:not(:last-child) {
            border-bottom: none;
            margin-bottom: 0px !important;
          }

          a {
            margin-right: 0;
            background: #fff;
            border-bottom: 1px solid var(--aside-menu-bg);

            @media only screen and (max-width: 767px) {
              padding-top: 10px;
            }


            &.active,
            &:hover {
              background: #8743FF;

              .icon {
                background: var(--text-white) !important;
              }
            }

            .icon {
              width: 48px;
              height: 48px;
              background: var(--card-bg);
              border-radius: 6px;
              margin-bottom: 0;
              border-color: var(--border-color-50);

              img {
                filter: none;
              }
            }
          }
        }
      }
    }
  }
}