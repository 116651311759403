/*
  ********************************
  ********************************
  * Omail App Mailbox,Calendar,
  * Contacts and Settings  Start
  ********************************
  ********************************
*/
/*
  *****************************
  * Omail App Common CSS Start
  *****************************
*/
.omail-custom-btn {
  background: #006BCE;
  border-radius: 6px;
  color: #fff;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  border: none;
  padding: 10px 24px;
  cursor: pointer;
  margin: 0;

  &:hover {
    background: #0288D9;
  }

  &:disabled {
    background: #BFE7FE;
    cursor: no-drop;
  }
}

.omail-custom-btn-outline {
  background: #FFFFFF;
  border: 1px solid #006BCE;
  border-radius: 6px;
  color: #006BCE;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  padding: 10px 24px;
  cursor: pointer;
  margin: 0;

  &:hover {
    background: #F0F9FF;
  }

  &:disabled {
    color: #7ECEFE;
    border: 1px solid #7ECEFE;
    cursor: no-drop;
  }
}

.omail-auto-switch {
  .readrecipt-text {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #003B93;
  }

  .mat-slide-toggle-label {
    .mat-slide-toggle-bar {
      background-color: transparent;
      height: 20px;
      width: 32px;
      border-radius: 12px;
      border: 1px solid #A3A3A3;
    }
  }

  .mat-slide-toggle-thumb-container {
    top: 1px;
    left: 2px;

    .mat-slide-toggle-thumb {
      height: 16px;
      width: 16px;
      border-radius: 50%;
      display: block;
      background: #A3A3A3;
    }

    .mat-slide-toggle {
      .mat-checked {
        .mat-slide-toggle-thumb {
          background-color: #0288D9;
        }
      }
    }
  }

  .mat-slide-toggle.mat-checked {
    .mat-slide-toggle-bar {
      background-color: #0288D9;
      border: 1px solid #0288D9;
    }
  }

  .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
    background-color: #fff;
  }

  .mat-slide-toggle.mat-checked {
    .mat-slide-toggle-thumb-container {
      top: 1px;
      left: -2px;
    }
  }
}

/* Tool tip CSS Start */
.snack-bar-failure {
  color: #ffffff !important;
  background-color: #ec6262 !important;
}

.snack-bar-success {
  color: #ffffff !important;
  background-color: #31b477 !important;
  white-space: pre-wrap !important;
}

snack-bar-container {
  button {
    color: #ffffff !important;
  }
}

/* Tool tip CSS End */
.mat-dialog-container {
  padding: 0 !important;
  box-shadow: none !important;
  border-radius: 8px !important;
}

/* Custom Tool tip CSS Start */
.omail-link-copy {
  margin-bottom: 5px !important;

  @media screen and (max-width: 640px) {
    display: none;
  }

  .tooltip-inner {
    background: #003B93;
    border-radius: 4px;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #FFFFFF;
  }

  .tooltip-arrow {
    &::before {
      border-top-color: #003B93;
    }
  }
}

.omail-link-copy-left {
  .tooltip-arrow {
    &::before {
      border-top-color: transparent;
      border-left-color: #003B93;
    }
  }
}

/* Custom Tool tip CSS End */
.omail-list-text1 {
  display: none !important;
}

// omail-calendar-css-start//
.omail-event-calendar {
  .cal-month-view {
    .cal-header {
      text-align: center;
      font-weight: bolder;

      .cal-cell {
        padding: 5px 0;
        overflow: hidden;
        -o-text-overflow: ellipsis;
        text-overflow: ellipsis;
        display: block;
        white-space: nowrap;
      }
    }

    .cal-days {
      border: 1px solid;
      border-bottom: 0;
      border-color: #ECECEC;

      .cal-cell-row {
        border-bottom: 1px solid;
        border-bottom-color: #ECECEC;
      }
    }

    .cal-cell-top {
      min-height: 78px;
      -webkit-box-flex: 1;
      -ms-flex: 1;
      flex: 1;
    }

    .cal-cell-row {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;

      &:hover {
        background-color: transparent;
      }

      .cal-cell {
        &:hover {
          background-color: #F5FBFF;
        }
      }
    }

    .cal-cell {
      float: left;
      -webkit-box-flex: 1;
      -ms-flex: 1;
      flex: 1;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-align: stretch;
      -ms-flex-align: stretch;
      align-items: stretch;

      .cal-event {
        pointer-events: all !important;
      }
    }

    .cal-day-cell {
      min-height: 100px;

      &:not(:last-child) {
        border-right: 1px solid;
        border-right-color: #e1e1e1;
      }
    }

    .cal-day-badge {
      margin-top: 18px;
      margin-left: 10px;
      display: inline-block;
      min-width: 10px;
      padding: 3px 7px;
      font-size: 12px;
      font-weight: 700;
      line-height: 1;
      text-align: center;
      white-space: nowrap;
      vertical-align: middle;
      border-radius: 10px;
      background-color: #b94a48;
      color: #fff;
    }

    .cal-day-number {
      font-size: 1.2em;
      font-weight: 400;
      opacity: 0.5;
      margin-top: 15px;
      margin-right: 15px;
      float: right;
      margin-bottom: 10px;
    }

    .cal-events {
      flex: 1;
      align-items: flex-end;
      margin: 5px 0;
      line-height: 10px;
      flex-wrap: wrap;
      display: flex;
      margin-right: 5px;

      @media screen and (max-width: 1440px) {
        position: relative;
        left: -8px;
        margin-top: 0;
      }

      @media screen and (max-width: 360px) {
        left: 0px;
      }
    }

    .cal-event {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      display: inline-block;
      margin: 2px;
      background-color: #1e90ff;
      border-color: #d1e8ff;
      color: #fff;
    }

    .cal-day-cell.cal-in-month.cal-has-events {
      cursor: pointer;
    }

    .cal-day-cell.cal-out-month {
      .cal-day-number {
        opacity: 0.1;
        cursor: default;
      }
    }

    .cal-day-cell.cal-today {
      .cal-day-number {
        font-size: 1.9em;
        color: #fff;
      }

      border-top: 3px solid #F87405 !important;
      background-color: #F5FBFF;
      border-radius: 0 !important;
    }

    .cal-open-day-events {
      padding: 15px;
      color: #fff;
      background-color: #555;
      -webkit-box-shadow: inset 0 0 15px 0 rgba(0, 0, 0, 0.5);
      box-shadow: inset 0 0 15px 0 rgba(0, 0, 0, 0.5);

      .cal-event {
        position: relative;
        top: 2px;
      }
    }

    .cal-out-month {
      .cal-day-badge {
        opacity: 0.3;
      }

      .cal-event {
        opacity: 0.3;
      }
    }

    .cal-draggable {
      cursor: move;
    }

    .cal-drag-active {
      * {
        pointer-events: none;
      }
    }

    .cal-event-title {
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }

    background-color: #fff;

    .cal-cell.cal-has-events.cal-open {
      background-color: #ededed;
    }

    .cal-day-cell.cal-weekend {
      .cal-day-number {
        color: #8b0000;
      }
    }

    .cal-day-cell.cal-drag-over {
      background-color: #e0e0e0 !important;
    }
  }

  .cal-week-view {
    * {
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
    }

    .cal-day-headers {
      display: -webkit-box;
      display: -ms-flexbox;
      -js-display: flex;
      display: flex;
      padding-left: 70px;
      border: 1px solid;
      border-color: #e1e1e1;
      border-top: 0;

      .cal-header {
        -webkit-box-flex: 1;
        -ms-flex: 1;
        flex: 1;
        text-align: center;
        padding: 5px;

        &:not(:last-child) {
          border-right: 1px solid;
          border-right-color: #e1e1e1;
        }

        &:first-child {
          border-left: 1px solid;
          border-left-color: #e1e1e1;
        }

        &:hover {
          background-color: #ededed;
        }
      }

      span {
        font-weight: 400;
        opacity: 0.5;
      }

      .cal-drag-over {
        background-color: #ededed;
      }
    }

    .cal-day-column {
      -webkit-box-flex: 1;
      -ms-flex-positive: 1;
      flex-grow: 1;
      border-left: solid 1px;
      border-left-color: #e1e1e1;
    }

    .cal-event {
      font-size: 12px;
      border: 1px solid;
      overflow: hidden;
      -o-text-overflow: ellipsis;
      text-overflow: ellipsis;
      white-space: nowrap;
      background-color: #d1e8ff;
      border-color: #1e90ff;
      color: #1e90ff;
    }

    .cal-time-label-column {
      width: 70px;
      height: 100%;
    }

    .cal-current-time-marker {
      position: absolute;
      width: 100%;
      height: 2px;
      z-index: 2;
      background-color: transparent;
    }

    .cal-all-day-events {
      border: solid 1px;
      border-top: 0;
      border-bottom-width: 3px;
      padding-top: 3px;
      position: relative;
      border-color: #e1e1e1;

      .cal-day-columns {
        height: 100%;
        width: 100%;
        display: -webkit-box;
        display: -ms-flexbox;
        -js-display: flex;
        display: flex;
        position: absolute;
        top: 0;
        z-index: 0;
      }

      .cal-events-row {
        position: relative;
        height: 31px;
        margin-left: 70px;
      }

      .cal-event-container {
        display: inline-block;
        position: absolute;
      }

      .cal-event-container.resize-active {
        z-index: 1;
        pointer-events: none;
      }

      .cal-event {
        padding: 0 5px;
        margin-left: 2px;
        margin-right: 2px;
        height: 28px;
        line-height: 28px;
      }

      .cal-starts-within-week {
        .cal-event {
          border-top-left-radius: 5px;
          border-bottom-left-radius: 5px;
        }
      }

      .cal-ends-within-week {
        .cal-event {
          border-top-right-radius: 5px;
          border-bottom-right-radius: 5px;
        }
      }

      .cal-time-label-column {
        display: -webkit-box;
        display: -ms-flexbox;
        -js-display: flex;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        font-size: 14px;
      }

      .cal-resize-handle {
        width: 6px;
        height: 100%;
        cursor: col-resize;
        position: absolute;
        top: 0;
      }

      .cal-resize-handle.cal-resize-handle-after-end {
        right: 0;
      }
    }

    .cal-header {
      overflow: hidden;
      -o-text-overflow: ellipsis;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .cal-drag-active {
      pointer-events: none;
      z-index: 1;

      * {
        pointer-events: none;
      }
    }

    .cal-time-events {
      position: relative;
      border: solid 1px;
      border-top: 0;
      display: -webkit-box;
      display: -ms-flexbox;
      -js-display: flex;
      display: flex;
      border-color: #e1e1e1;

      .cal-day-columns {
        display: -webkit-box;
        display: -ms-flexbox;
        -js-display: flex;
        display: flex;
        -webkit-box-flex: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;

        &:not(.cal-resize-active) {
          .cal-hour-segment {
            &:hover {
              background-color: #F5FBFF;
            }
          }
        }
      }

      .cal-day-column {
        position: relative;
      }

      .cal-events-container {
        position: relative;
      }

      .cal-event-container {
        position: absolute;
        z-index: 1;
      }

      .cal-event {
        width: calc(100% - 2px);
        height: calc(100% - 2px);
        margin: 1px;
        padding: 0 5px;
        line-height: 25px;
      }

      .cal-resize-handle {
        width: 100%;
        height: 4px;
        cursor: row-resize;
        position: absolute;
      }

      .cal-resize-handle.cal-resize-handle-after-end {
        bottom: 0;
      }
    }

    .cal-hour-segment {
      position: relative;
      display: block;

      &::after {
        content: '\00a0';
      }
    }

    .cal-event-container {
      &:not(.cal-draggable) {
        cursor: pointer;
      }
    }

    .cal-draggable {
      cursor: move;
    }

    mwl-calendar-week-view-hour-segment {
      display: block;
    }

    .cal-hour {
      &:not(:last-child) {
        .cal-hour-segment {
          border-bottom: thin dashed;
          border-bottom-color: #e1e1e1;
        }
      }

      &:last-child {
        &:not(:last-child) {
          .cal-hour-segment {
            border-bottom: thin dashed;
            border-bottom-color: #e1e1e1;
          }
        }
      }
    }

    .cal-time {
      font-weight: bold;
      padding-top: 5px;
      width: 70px;
      text-align: center;
    }

    .cal-hour-segment.cal-after-hour-start {
      .cal-time {
        display: none;
      }
    }

    .cal-starts-within-day {
      .cal-event {
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        position: absolute;
        top: 18px;
      }
    }

    .cal-ends-within-day {
      .cal-event {
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        position: absolute;
        top: 17px;
      }
    }

    background-color: #fff;
    border-top: solid 1px #e1e1e1;

    .cal-header.cal-today {
      background-color: #e8fde7;
    }

    .cal-header.cal-weekend {
      span {
        color: #8b0000;
      }
    }

    .cal-hour-odd {
      background-color: #fff;
    }

    .cal-drag-over {
      .cal-hour-segment {
        background-color: #ededed;
      }
    }
  }

  .cal-day-view {
    mwl-calendar-week-view-header {
      display: none;
    }

    .cal-events-container {
      margin-left: 70px;
    }

    .cal-day-column {
      border-left: 0;
    }

    .cal-current-time-marker {
      margin-left: 70px;
      width: calc(100% - 70px);
    }
  }
}

.omail-readmenu {
  .mat-menu-content {
    padding-top: 0 !important;
    padding-bottom: 0 !important;

    .mat-menu-item {
      background: var(--white-color);
      border-radius: 6px;
      color: var(--primary-color);
      border: 1px solid var(--primary-color);
      font-size: var(--clamp14);
      font-weight: 400;
      height: 40px;
      line-height: 40px;
    }
  }
}

// omail-calendar-css-end
/*
  *****************************
  * Omail App Common CSS End
  *****************************
*/
.omailapp-mainwrap {
  background: #F3F2FF;
  height: calc(100vh - 76px);
  overflow: hidden;
  margin-right: 32px;

  .was-validated .form-control:invalid,
  .form-control.is-invalid {
    background-image: none;
  }

  input {
    outline: none;

    :focus-visible {
      outline: none;
      border: none;
    }
  }

  .btn:not(:first-child) {
    margin-left: 0;
  }

  .mat-tooltip {
    font-weight: 500;
    font-size: var(--clamp14);
    white-space: pre-wrap;
    color: var(--primary-color) !important;
    background-color: var(--white-color);
    border: 1px solid var(--primary-color);
    border-radius: 8px;
  }
}

/*
  *****************************
  * Omail App Header CSS Start
  *****************************
*/
.omail-app-header {
  padding: 0 0 15px 0px;
  margin: 0;
  position: absolute;
  background-color: #fff;
  z-index: 1;
  width: 240px;
  border-right: 1px solid #DEDEDE;

  @media screen and (max-width: 1024px) {
    position: absolute;
    z-index: 3;
  }

  @media screen and (max-width: 768px) {
    z-index: 4 !important;
  }

  .omail-logo {
    padding: 10px 10px;
    cursor: pointer;
    border-bottom: 1px solid #ECECEC;
    margin-bottom: 18px;

    .omail-main-logo {
      padding-left: 6px;
    }
  }

  .omail-modules-link {
    border-bottom: 1px solid #ECECEC;

    ul {
      display: flex;
      align-items: center;

      li:hover span:first-child {
        transform: translateY(104%);
      }

      li {
        cursor: pointer;
        padding: 0 18px 4px;
        border-bottom: 3px solid transparent;
        overflow: hidden;
        position: relative;

        span {
          transition: transform 0.2s ease-out;

          &:first-child {
            display: inline-block;
          }

          &:last-child {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            transform: translateY(-100%);

            img {
              position: relative;
              top: -2px;
            }
          }
        }

        &:nth-child(3) {
          &:hover {
            span {
              &:first-child {
                transform: translateY(0);
              }

              &:last-child {
                transform: translateY(0);
              }
            }
          }

          &.active {
            span {
              background-image: url(../images/obsomail/app-header/omail-contact-bg-hover.svg);
            }
          }

          .omail-contact-icon {
            margin-top: 3px;
            margin-bottom: 3px;
          }
        }

        &.active {
          border-bottom: 3px solid #006BCE;

          &:hover {
            border-bottom: 3px solid #006BCE;

            img {
              filter: invert(0);
            }
          }

          span:first-child {
            transform: translateY(-100%);
          }

          span:last-child {
            transform: inherit;
          }
        }

        &:last-child:hover {

          span:first-child,
          span:last-child {
            transform: translateY(0);
            transform: rotateZ(720deg);
            opacity: 1;
          }
        }

        &:last-child {
          span:last-child {
            transform: inherit;
            transition-duration: 1s;
            opacity: 0;

            img {
              top: -1px;
            }
          }

          &.active {
            span:last-child {
              opacity: 1;
            }
          }
        }

        &:last-child:hover {
          img {
            position: relative;
            top: 2px;
          }
        }

        &:hover {
          border-bottom: 3px solid #7ECEFE;
        }

        &:nth-child(3) {
          &:hover {
            .omail-contact-icon {
              background-image: url(../images/obsomail/app-header/omail-contact-bg-hover.svg);
              margin-top: 3px;
              margin-bottom: 3px;

              .omail-user-hover {
                transform: translateY(0);
              }

              .omail-user {
                transform: translateY(100%);
                display: none;
                opacity: 0;
              }
            }
          }
        }
      }
    }

    ul li:hover span:last-child,
    ul[data-animation] li:hover span:last-child {
      transform: none;
    }

    ul[data-animation="to-top"] span:last-child {
      transform: translateY(100%);
    }

    ul[data-animation="to-top"] li:hover span:first-child {
      transform: translateY(-100%);
    }

    .omail-contact-icon {
      width: 18px;
      height: 19px;
      background-image: url(../images/obsomail/app-header/omail-contact-bg.svg);
      position: inherit !important;
      transform: translate(0) !important;

      img {
        transition: transform 0.1s ease-out;
        position: absolute !important;
        top: 2px !important;
      }

    }

    .omail-user {
      transform: translateY(0);
      opacity: 1;
      position: absolute;
    }

    .omail-user-hover {
      transform: translateY(100%);
      position: absolute;
    }
  }


  .omail-rightside-links {
    display: flex;
    align-items: center;
    gap: 0;

    @media screen and (max-width:768px) {
      gap: 0;
    }

    @media screen and (max-width:767px) {
      gap: 0;
    }

    .omail-modules-link {
      li {
        opacity: 0.5;
        cursor: pointer;

        &.active {
          opacity: 1;
          cursor: pointer;
        }

        &:hover {
          opacity: 1;
          cursor: pointer;
        }
      }
    }

    .omail-header-user-profile-block {
      text-align: right;
      display: flex;
      align-items: center;
      gap: 10px;

      &::after {
        display: none;
      }

      .omail-user-info {
        .omail-user-name {
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          color: #4136F1;
        }

        .omail-user-mail {
          font-style: normal;
          font-weight: 400;
          font-size: 10px;
          color: #464255;
        }
      }

      .omail-user-img-block {
        width: 36px;
        height: 36px;
        border-radius: 50%;

        img {
          width: 100%;
          height: 100%;
          border-radius: 50%;
          object-fit: cover;
          object-position: center;
        }

        i {
          font-size: 36px;
          color: #4136F1;
        }
      }

      .omail-toggle-arrow {
        i {
          font-size: 26px;
          color: #4136F1;
        }
      }
    }

    span {
      cursor: pointer;
    }
  }
}

.omail-modules-link {
  li {
    cursor: pointer;

    &.active {
      opacity: 1;
      cursor: pointer;
    }

    &:hover {
      opacity: 1;
      cursor: pointer;
    }
  }
}

.omail-notifications-block {
  position: relative;
  border-left: 1px solid #D2CFFF;

  .omail-notifications-icon {
    cursor: pointer;

    &::after {
      display: none;
    }

    img {
      -webkit-animation: ring 4s .7s ease-in-out infinite;
      -webkit-transform-origin: 50% 4px;
      -moz-animation: ring 4s .7s ease-in-out infinite;
      -moz-transform-origin: 50% 4px;
      animation: ring 4s .7s ease-in-out infinite;
      transform-origin: 50% 4px;
    }

    @-webkit-keyframes ring {
      0% {
        -webkit-transform: rotateZ(0);
      }

      1% {
        -webkit-transform: rotateZ(30deg);
      }

      3% {
        -webkit-transform: rotateZ(-28deg);
      }

      5% {
        -webkit-transform: rotateZ(34deg);
      }

      7% {
        -webkit-transform: rotateZ(-32deg);
      }

      9% {
        -webkit-transform: rotateZ(30deg);
      }

      11% {
        -webkit-transform: rotateZ(-28deg);
      }

      13% {
        -webkit-transform: rotateZ(26deg);
      }

      15% {
        -webkit-transform: rotateZ(-24deg);
      }

      17% {
        -webkit-transform: rotateZ(22deg);
      }

      19% {
        -webkit-transform: rotateZ(-20deg);
      }

      21% {
        -webkit-transform: rotateZ(18deg);
      }

      23% {
        -webkit-transform: rotateZ(-16deg);
      }

      25% {
        -webkit-transform: rotateZ(14deg);
      }

      27% {
        -webkit-transform: rotateZ(-12deg);
      }

      29% {
        -webkit-transform: rotateZ(10deg);
      }

      31% {
        -webkit-transform: rotateZ(-8deg);
      }

      33% {
        -webkit-transform: rotateZ(6deg);
      }

      35% {
        -webkit-transform: rotateZ(-4deg);
      }

      37% {
        -webkit-transform: rotateZ(2deg);
      }

      39% {
        -webkit-transform: rotateZ(-1deg);
      }

      41% {
        -webkit-transform: rotateZ(1deg);
      }

      43% {
        -webkit-transform: rotateZ(0);
      }

      100% {
        -webkit-transform: rotateZ(0);
      }
    }

    @-moz-keyframes ring {
      0% {
        -moz-transform: rotate(0);
      }

      1% {
        -moz-transform: rotate(30deg);
      }

      3% {
        -moz-transform: rotate(-28deg);
      }

      5% {
        -moz-transform: rotate(34deg);
      }

      7% {
        -moz-transform: rotate(-32deg);
      }

      9% {
        -moz-transform: rotate(30deg);
      }

      11% {
        -moz-transform: rotate(-28deg);
      }

      13% {
        -moz-transform: rotate(26deg);
      }

      15% {
        -moz-transform: rotate(-24deg);
      }

      17% {
        -moz-transform: rotate(22deg);
      }

      19% {
        -moz-transform: rotate(-20deg);
      }

      21% {
        -moz-transform: rotate(18deg);
      }

      23% {
        -moz-transform: rotate(-16deg);
      }

      25% {
        -moz-transform: rotate(14deg);
      }

      27% {
        -moz-transform: rotate(-12deg);
      }

      29% {
        -moz-transform: rotate(10deg);
      }

      31% {
        -moz-transform: rotate(-8deg);
      }

      33% {
        -moz-transform: rotate(6deg);
      }

      35% {
        -moz-transform: rotate(-4deg);
      }

      37% {
        -moz-transform: rotate(2deg);
      }

      39% {
        -moz-transform: rotate(-1deg);
      }

      41% {
        -moz-transform: rotate(1deg);
      }

      43% {
        -moz-transform: rotate(0);
      }

      100% {
        -moz-transform: rotate(0);
      }
    }

    @keyframes ring {
      0% {
        transform: rotate(0);
      }

      1% {
        transform: rotate(30deg);
      }

      3% {
        transform: rotate(-28deg);
      }

      5% {
        transform: rotate(34deg);
      }

      7% {
        transform: rotate(-32deg);
      }

      9% {
        transform: rotate(30deg);
      }

      11% {
        transform: rotate(-28deg);
      }

      13% {
        transform: rotate(26deg);
      }

      15% {
        transform: rotate(-24deg);
      }

      17% {
        transform: rotate(22deg);
      }

      19% {
        transform: rotate(-20deg);
      }

      21% {
        transform: rotate(18deg);
      }

      23% {
        transform: rotate(-16deg);
      }

      25% {
        transform: rotate(14deg);
      }

      27% {
        transform: rotate(-12deg);
      }

      29% {
        transform: rotate(10deg);
      }

      31% {
        transform: rotate(-8deg);
      }

      33% {
        transform: rotate(6deg);
      }

      35% {
        transform: rotate(-4deg);
      }

      37% {
        transform: rotate(2deg);
      }

      39% {
        transform: rotate(-1deg);
      }

      41% {
        transform: rotate(1deg);
      }

      43% {
        transform: rotate(0);
      }

      100% {
        transform: rotate(0);
      }
    }
  }

  .omail-notifications-circle {
    display: inline-block;
    width: 11px;
    height: 11px;
    border-radius: 50%;
    background-color: #E84C3D;
    position: absolute;
    right: -2px;
    top: 1px;
    border: 2px solid #fff;
    z-index: 1;
  }

  .omail-noti-category-block {
    display: flex;
    width: 711px;
    overflow: hidden;
    box-shadow: 0px 8px 17px rgb(0, 0, 0, 0.3);
    border-radius: 3px;

    @media screen and (max-width: 640px) {
      display: block;
      width: 350px;
    }

    @media screen and (max-width: 360px) {
      width: 310px;
    }

    h5 {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      color: #464255;
      margin: 0 0 9px;
      padding-bottom: 5px;
    }

    .omail-noti-category-links {
      display: flex;
      align-items: center;
      justify-content: space-between;
      min-height: 36px;

      @media screen and (max-width: 640px) {
        min-height: auto;
      }
    }

    .omail-category-mail-list-block {
      max-height: 580px;
      min-height: 580px;
      overflow: hidden;
      overflow-y: auto;
      margin-top: 9px;

      @media screen and (max-width: 640px) {
        max-height: 330px;
        min-height: 70px;
      }

      .omaillist-box {
        background: #FFFFFF;
        border: 1px solid #E6E6E6;
        border-radius: 8px;
        margin: 0 0 4px;
        cursor: pointer;

        .omaillist-inner-one {
          display: flex;
          align-items: center;
          justify-content: start;
          margin: 0 0 6px;
          gap: 10px;
          padding: 10px;
          padding-bottom: 0;

          .box-left {
            .omail-user-icon {
              i {
                font-size: 32px;
                color: #006BCE;
              }
            }

            .omail-user-name {
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              color: #334554;
            }

            .maillist-rounded-block {
              display: block;
              position: relative;
              line-height: 0;
              padding-left: 0px;
              margin-bottom: 0px;
              cursor: pointer;
              -webkit-user-select: none;
              -moz-user-select: none;
              -ms-user-select: none;
              user-select: none;

              .check-img-block {
                cursor: pointer;

                .contact-pic-text {
                  width: 32px;
                  height: 32px;
                  border-radius: 8px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  overflow: hidden;

                  img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                  }

                  span {
                    font-size: var(--clamp14);
                    font-weight: 600;
                    color: var(--white-color);
                    line-height: 15px;
                  }
                }

                input {
                  position: absolute;
                  opacity: 0;
                  cursor: pointer;
                  height: 0;
                  width: 0;
                }

                .check-mark {
                  position: absolute;
                  bottom: -2px;
                  left: 5px;
                  height: 24px;
                  width: 24px;
                  opacity: 0;
                }

                input:checked~.check-mark {
                  background: url(../../../assets/omail/images/obsomailcontacts/checked.svg) no-repeat top left;
                  opacity: 1;
                }
              }
            }
          }

          .box-right {
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            color: #A3A3A3;
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            gap: 3px;
          }

          .alloweduser-text {
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            color: #0A2477;
            white-space: nowrap;
          }

          .timeduration-text {
            white-space: nowrap;
          }
        }

        .omaillist-inner-two {
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 12px;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          color: #A3A3A3;
          padding: 0 10px;

          span {
            i {
              color: #FE7A00;
            }
          }

          .box-left {
            display: flex;
            align-items: center;
            gap: 4px;

            .rightspam-subtext {
              font-style: normal;
              font-weight: 500;
              font-size: 14px;
              color: #E84C3D;

              .right-sub {
                margin-left: 3px;
              }
            }
          }

          .box-right {
            display: flex;
            align-items: center;
            gap: 4px;

            .mail-other-links {
              margin: 0;
              padding: 0;
              cursor: pointer;

              img {
                width: 15px;
              }
            }
          }
        }

        &.activemail {
          background: #006BCE;
          color: #fff;

          .omaillist-inner-one {
            color: #fff;

            .omail-user-name {
              color: #fff;
            }

            .box-left {
              i {
                color: #fff;
              }
            }

            .box-right {
              color: #fff;
            }
          }

          .omaillist-inner-two {
            color: #fff;

            i {
              color: #fff;
            }
          }

          .omaillist-inner-four {
            color: #fff;

            img {
              filter: brightness(0) invert(1);
            }
          }

          &:hover {
            .check_img i {
              color: #006BCE !important;
            }

            .omail-user-name {
              color: #334554 !important;
            }

            .omaillist-inner-two,
            .omaillist-inner-one .box-right {
              color: #A3A3A3 !important;
            }
          }
        }

        &:hover {
          background: #F0EFFF;
          border: 1px solid #006BCE;
        }

        &.newMailText {
          .omaillist-inner-one {
            .omail-user-name {
              font-style: normal;
              font-weight: normal;
              color: #334554;
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;
              width: 95px;
            }
          }

          .omaillist-inner-two {
            font-style: normal;
            font-weight: normal;
            color: #0A2477;
          }
        }
      }

      .omaillist-inner-three {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px;
        padding-top: 0;

        .box-left {
          display: flex;
          align-items: center;
          gap: 4px;
          color: #A3A3A3;
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
        }
      }
    }

    .omail-noti-category-left {
      background: #EAFAFF;
      width: 356px;
      padding: 18px;
      border-right: 1px solid #E6E6E6;
      box-shadow: 0px 3px 3px rgba(80, 56, 244, 0.08);
      border-radius: 8px 0px 0px 8px;

      @media screen and (max-width: 360px) {
        width: 100%;
      }

      h5 {
        border-bottom: 1px solid #B2D3DD;
      }
    }

    .omail-noti-category-right {
      background: #FFF9E8;
      width: 356px;
      padding: 18px;
      border-left: 1px solid #E6E6E6;
      box-shadow: 0px 3px 3px rgba(80, 56, 244, 0.08);
      border-radius: 0px 8px 8px 0px;

      @media screen and (max-width: 360px) {
        width: 100%;
      }

      h5 {
        border-bottom: 1px solid #E4DCC3;
      }
    }

    .notification-deny-btn {
      color: #E84C3D;
      background: #FFEAEA;
      border: 1px solid #FFEAEA;
    }

    .notification-allow-btn {
      color: #0089BE;
      background: #D8F4FF;
      border: 1px solid #D8F4FF;
      margin-left: 3px;
    }

    .notification-discard-btn {
      color: #242539;
      background: #EBEBF0;
      border: 1px solid #EBEBF0;
    }

    .notification-accept-btn {
      color: #277800;
      background: #D3FFD8;
      border: 1px solid #D3FFD8;
      margin-left: 3px;
    }
  }

  .omailcat-link-btn {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #4136F1;
    border: 1px solid #fff;
    padding: 4px 13px;
    margin: 0 0 0 4px;
    text-decoration: none;

    &:hover {
      color: #4136F1 !important;
      border-color: #4136F1 !important;
    }
  }

  .dropdown-menu {
    border: none;
    padding: 0;
  }
}

/*
  *****************************
  * Omail App Header CSS End
  *****************************
*/
/*
  ************************************
  * Omail App Mailbox-Module CSS Start
  ************************************
*/
.omail-mailbox-module {
  display: flex;
  height: calc(100vh - 77px);
  overflow: hidden;
  background-color: #F0F9FF;
  position: relative;

  .omail-mailbox-sidenav-block {
    width: 276px;
    height: calc(100vh - 77px);
    overflow: hidden;
    position: relative;
    padding-top: 123px;
    border-right: 1px solid #DEDEDE;

    @media screen and (max-width: 1440px) {
      width: 293px;
    }

    @media screen and (max-width: 1280px) {
      width: 301px;
    }

    @media screen and (max-width: 1024px) {
      position: absolute;
      width: 240px;
      background: #fff;
      z-index: 1;
      box-shadow: 2px 2px 15px rgb(0, 0, 0, 0.2);
    }

    @media screen and (max-width: 768px) {
      z-index: 3;
    }

    .omail-sub-sidebar {
      background-color: #fff;
      transition: width 0.2s;
      box-shadow: 0px 3px 6px #00000029;
      height: calc(100vh - 77px);
      padding-left: 12px;
      padding-right: 12px;

      .omail-sidebar-toggle {
        position: fixed;
        left: 282px;
        top: 90px;
        z-index: 2;
        color: #A3A3A3;
        cursor: pointer;
        transition: left 0.2s;
        font-size: 20px;

        @media screen and (max-width: 1024px) {
          left: 245px;
        }

        @media screen and (max-width: 992px) {
          left: 170px;
        }

        @media screen and (max-width: 991px) {
          left: 207px;
        }
      }

      .omail-sub-sidebar-layout {
        .omail-categories-list {
          padding: 0;
          margin: 0;

          li {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 10px;
            cursor: pointer;
            background: transparent;
            color: #30353D;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            padding: 10px;
            text-align: left;
            border-radius: 0;
            border-right: 4px solid transparent;
            margin-bottom: 2px;

            &.activefolder-list {
              background: #BFE7FE;
              color: #006BCE;
              border-radius: 6px;

              .list-icon {
                img {
                  filter: invert(22%) sepia(67%) saturate(4594%) hue-rotate(197deg) brightness(96%) contrast(101%);
                }
              }

              .omail-badge {
                color: #ffffff;
                background: #006BCE;
                border: 1px solid #006BCE;
              }

              &:hover {
                background: #BFE7FE;
                color: #006BCE;

                .list-icon {
                  img {
                    filter: invert(22%) sepia(67%) saturate(4594%) hue-rotate(197deg) brightness(96%) contrast(101%) !important;
                  }
                }

                .omail-badge {
                  color: #ffffff;
                  background: #006BCE;
                  border: 1px solid #006BCE;
                }
              }
            }

            .list-icon {
              width: 20px;
              display: inline-block;
              text-align: center;
            }

            &:hover {
              background: #DFF3FF;
              border-radius: 6px;

              span {
                img {
                  filter: none !important;
                }
              }

              .omail-badge {
                color: #ffffff;
                background: #A3A3A3;
              }
            }

            .omail-badge {
              color: #FFFFFF;
              height: 20px;
              min-width: 20px;
              display: block;
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              border-radius: 28px;
              background: #A3A3A3;
              text-align: center;
              line-height: 20px;
            }

            .list-text {
              margin-left: 8px;
              position: relative;
              top: 2px;
            }

            .trash-badge {
              margin-right: 15px;
            }
          }

          .trash-inactive {
            .omail-trash-list {
              li {
                &.activefolder-list {
                  background: #BFE7FE;
                  border-right: 0;
                }
              }
            }
          }

          .omail-trash-list {
            max-height: 200px;
            overflow-y: auto;

            &::-webkit-scrollbar {
              width: 4px !important;
            }

            &::-webkit-scrollbar-thumb {
              background: #DFF3FF;
              box-sizing: border-box;
              box-shadow: inset 0px 10px 10px rgba(255, 255, 255, 0.25);
              border-radius: 7px;
            }

            li {
              opacity: 1;
              justify-content: flex-start;
              position: relative;
              background-color: #F1FAFF;
              border-radius: 6px;
              padding: 0 0 0 38px;

              &:hover {
                background-color: #DFF3FF;
                border-color: transparent;

                .fr-icon,
                span {
                  opacity: 1 !important;
                }

                .folder-all-icons {
                  span {
                    &:hover {
                      img {
                        filter: invert(22%) sepia(67%) saturate(4594%) hue-rotate(197deg) brightness(96%) contrast(101%) !important;
                      }

                      i {
                        color: #006BCE;
                      }
                    }
                  }
                }
              }

              .generate-folder {
                display: flex;
                align-items: center;
                width: 100%;
                padding: 10px 0;
              }

              .folder-all-icons {
                position: absolute;
                right: 12px;

                .fr-icon {
                  position: absolute;
                  top: -2px;
                  right: 22px;
                }

                span {
                  font-size: 17px;
                  color: #b9b8b8;
                }
              }

              .ge-text {
                white-space: nowrap;
                width: 50%;
                overflow: hidden;
                text-overflow: ellipsis;
                text-transform: capitalize;
              }

              .fa-circle {
                margin-right: 6px;
              }

              &:hover {
                .fr-icon {
                  opacity: 1;
                }
              }
            }

            &.active {
              min-height: 0.001px;
              max-height: 0.001px;
              overflow-y: hidden;

              li {
                opacity: 0;
                padding-top: 14px;
                padding-bottom: 14px;
              }
            }

            .trash-folder-img {
              position: relative;
              top: -2px;
            }
          }

          .trash-list-main {
            .list-arrow {
              position: absolute;
              right: 8px;
              top: -34px;
              cursor: pointer;
              width: 16px;
              text-align: center;
              color: #A3A3A3;
            }
          }

          hr {
            position: relative;
            top: 10px;
            margin: 0;
          }
        }

        .omail-categories-list-one {
          .folder-content {
            display: flex;
            align-items: center;
            justify-content: space-between;
            background: #DFF3FF;
            color: #30353D;
            padding: 10px;
            position: relative;
            margin-top: 28px;

            .folder-plush-icon {
              cursor: pointer;
              background-color: #A3A3A3;
              width: 20px;
              height: 20px;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 4px;
            }

            .list-arrow {
              font-size: 18px;
              cursor: pointer;
            }

            .create-folder {
              position: absolute;
              right: 36px;
            }

            .create-folder-icon {
              position: relative;
              top: -3px;
              margin-right: 8px;
            }

            &.folder-inactive {
              background: transparent;

              .folder-txt {
                color: #30353D;
              }

              .create-folder,
              .list-arrow {
                color: #A3A3A3;
              }
            }

            &.folder-active {
              background: #BFE7FE;
              border-radius: 6px;

              .folder-txt,
              .folder-plush-icon,
              .list-arrow {
                color: #006BCE;
              }

              img {
                filter: invert(22%) sepia(67%) saturate(4594%) hue-rotate(197deg) brightness(96%) contrast(101%);
              }

              .folder-plush-icon {
                background-color: #006BCE !important;

                img {
                  filter: none;
                }
              }
            }
          }

          li {
            width: 100%;
            display: flex;
            align-items: center;
            gap: 4px;
            padding: 0 10px;
            cursor: pointer;
            background-color: #F1FAFF;
            margin-bottom: 1px;
            border-radius: 6px;
            position: relative;

            &:hover {
              background: #DFF3FF;

              .folder-all-icons {
                span:hover i {
                  color: #006BCE;
                }
              }
            }

            &.activenewfolder-list {
              background-color: red;
            }

            .generate-folder {
              display: flex;
              align-items: center;
              width: 100%;
              padding: 10px 0;

              i {
                color: #00a4eb;
              }

              .ge-text {
                white-space: nowrap;
                max-width: 70px;
                overflow: hidden;
                text-overflow: ellipsis;
                font-size: 14px;
                font-weight: 400;
                position: relative;
                text-transform: capitalize;
                margin-left: 10px;
                color: #30353D;

                .omail-badge {
                  position: absolute;
                  right: 0;
                  top: 0px;
                  color: #FFFFFF;
                  height: 20px;
                  min-width: 20px;
                  display: block;
                  font-style: normal;
                  font-weight: 400;
                  font-size: 14px;
                  border-radius: 28px;
                  background: #006BCE;
                  text-align: center;
                  line-height: 20px;
                }
              }
            }

            .folder-all-icons {
              position: relative;
              display: flex;

              .omail-badge {
                position: absolute;
                left: -26px;
                top: 0px;
                color: #FFFFFF;
                height: 20px;
                min-width: 20px;
                display: block;
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                border-radius: 28px;
                background: #A3A3A3;
                text-align: center;
                line-height: 20px;
              }

              span {
                img {
                  &:hover {
                    filter: invert(31%) sepia(85%) saturate(1439%) hue-rotate(179deg) brightness(103%) contrast(98%);
                  }
                }
              }
            }

            div {
              .fa-circle {
                font-size: 14px;
              }
            }
          }

          .omail-sub-folder-layout {
            overflow-y: auto;
            max-height: 227px;

            &::-webkit-scrollbar {
              width: 4px !important;
            }

            &::-webkit-scrollbar-thumb {
              background: #DFF3FF;
              box-sizing: border-box;
              box-shadow: inset 0px 10px 10px rgba(255, 255, 255, 0.25);
              border-radius: 7px;
            }

            &.inactive {
              max-height: 342px;
              overflow-y: auto;
            }

            li {
              &.activefolder-list {
                background: #BFE7FE;
                box-shadow: inset 0px 3px 6px #00000029;
                color: #fff;
              }
            }

            &.active {
              li {
                display: none;
              }

              .no-folders-text {
                display: block;
              }
            }

            .no-folders-text {
              font-size: 14px;
              color: #0A2477;
              margin-top: 12px;
              font-weight: 400;
              display: none;
            }
          }
        }

        .omail-leftside-scrollsec {
          max-height: calc(100vh - 300px);
          overflow-y: auto;

          &::-webkit-scrollbar {
            width: 4px !important;
          }

          &::-webkit-scrollbar-thumb {
            background: #DFF3FF;
            box-sizing: border-box;
            box-shadow: inset 0px 10px 10px rgba(255, 255, 255, 0.25);
            border-radius: 7px;
          }

          &.cdk-drop-list-dragging {
            .omaillist-box {
              display: none !important;

              .list-text {
                display: block !important;
              }
            }

            .omail-categories-list-one {
              .omaillist-box {
                display: none;
              }
            }

            .list-icon {
              transform: translate3d(0px, 0px, 0px) !important;
            }

            .omail-drag-active {
              &:hover {
                &:after {
                  content: '';
                  background: url(../../../assets/omail/images/obsomail/mailbox/drag-cross.svg) no-repeat;
                  position: absolute;
                  left: 21px;
                  width: 20px;
                  height: 20px;
                }
              }
            }
          }
        }
      }

      .sidebar-storage {
        position: absolute;
        bottom: 0px;
        left: 0;
        right: 0;
        background-color: #F0F9FF;
        padding: 8px 0;

        h5 {
          font-size: 12px;
          font-weight: 400;
          color: #6D747A;

          strong {
            margin-left: 6px;
            color: #464255;
            font-weight: 600;
          }
        }
      }

      .splitleftblock-partone {
        padding-bottom: 18px;
        border-bottom: 1px solid #DEDEDE;
        margin-bottom: 18px;

        .omail-btn-group .dropdown-toggle {
          padding: 10px 14px 10px 13px;
        }
      }
    }
  }

  .omail-mailbox-split-block {
    width: 100%;
    height: calc(100vh - 77px);
    overflow: hidden;
    position: relative;

    @media screen and (max-width: 1024px) {
      padding-left: 68px;
    }

    .omailmailbox-split-left-block {
      background: #FFFFFF;
      border-right: 2px solid #F3F2FF;
      padding: 16px 0px;

      @media screen and (max-width: 480px) {
        padding: 8px 0px;
      }

      .trash-alert {
        font-size: 12px;
        color: #334554;
        background-color: #FFF3EA;
        border-color: #FFC899;
        padding: 8px 12px;
        border-radius: 6px;
        font-weight: 500;
      }

      .splitleftblock-partone {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0 0 16px;

        @media screen and (max-width: 480px) {
          margin: 0 0 8px;
        }

        .omailsearch-refresh-block {
          display: flex;
          align-items: center;
          gap: 18px;

          @media screen and (max-width: 370px) {
            gap: 10px;
          }

          .search-block {
            input {
              border-right: none;
              min-height: 46px;
              padding-right: 21px;
              border-color: #DEDEDE;

              @media screen and (max-width: 1440px) {
                padding-right: 16px;
              }

              @media screen and (max-width: 370px) {
                min-height: 30px;
                padding-top: 5px;
                padding-bottom: 5px;
              }
            }

            .input-group-text {
              background: transparent;
              border-color: #DEDEDE;

              img {
                cursor: pointer;
              }
            }

            .input-group-text {
              @media screen and (max-width: 1440px) {
                padding-left: 0;
                padding-right: 4px;
              }
            }

            i {
              cursor: pointer;
            }
          }

          .close-button {
            position: absolute;
            right: 50px;
            top: 14px;
            z-index: 3;

            @media screen and (max-width: 1440px) {
              right: 26px;
            }

            @media screen and (max-width: 768px) {
              right: 28px;
            }
          }
        }

        .custom-btn {
          min-width: 133px;

          @media screen and (max-width: 1366px) {
            min-width: 107px;
            padding: 0;
          }

          i {
            @media screen and (max-width: 1366px) {
              margin-right: 4px;
            }
          }
        }
      }

      .splitleftblock-parttwo {
        background: #F5F5F5;
        border-radius: 6px;
        margin: 0 0 5px;
        padding: 6px 12px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border: 1px solid #E4E4E4;

        .left-block {
          .mail_all_check {
            .select-primary {
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              color: #334554;

              .mat-checkbox-layout {
                .mat-checkbox-inner-container {
                  .mat-checkbox-frame {
                    border: 1.5px solid #A3A3A3;
                    border-radius: 4px;
                  }
                }
              }
            }
          }
        }

        .right-block {
          display: flex;
          align-items: center;

          button {
            border: none;
            cursor: pointer;
            background: transparent;

            &:disabled {
              opacity: 0.5;

              &:hover {
                img {
                  filter: invert(0);
                }
              }
            }

            &:hover {
              img {
                filter: invert(54%) sepia(87%) saturate(6221%) hue-rotate(184deg) brightness(99%) contrast(98%);
              }
            }
          }
        }

        @media screen and (max-width:768px) {
          margin: 0;
        }

        @media screen and (max-width:767px) {
          margin: 0;
        }

        .omail-refresh-icon {
          margin-left: 4px;
        }
      }

      .splitleftblock-partthree {
        margin: 0 0 5px;

        @media screen and (max-width:768px) {
          margin: 0;
        }

        @media screen and (max-width:767px) {
          margin: 0;
        }

        button {
          border: none;
          cursor: pointer;
          background: transparent;

          img {
            filter: invert(75%) sepia(15%) saturate(4%) hue-rotate(26deg) brightness(85%) contrast(89%);
          }
        }

        .read-mail-pagenation {
          font-size: 13px;
          color: #A3A3A3;
          margin: 10px 0;
          display: flex;
          align-items: center;
          justify-content: space-between;

          .mail-pagination {
            display: flex;
            align-items: center;
            gap: 4px;

            button {
              filter: invert(34%) sepia(76%) saturate(1948%) hue-rotate(191deg) brightness(80%) contrast(105%);

              &:disabled {
                cursor: no-drop;
                filter: invert(82%) sepia(0%) saturate(515%) hue-rotate(134deg) brightness(82%) contrast(77%);
              }
            }
          }

          .received-block {
            color: #6D747A;
            font-size: 13px;
            font-weight: 400;
            display: flex;
            align-items: center;
            gap: 5px;

            i {
              color: #A3A3A3;
              font-size: 18px;
              cursor: pointer;
            }

            .orderstatus-one {
              i {
                transform: rotate(180deg);
              }
            }

            button {
              display: flex;
              align-items: center;
              padding-top: 0;
              padding-bottom: 0;
              padding-right: 0;

              span {
                display: flex;
                align-items: center;
                color: #6D747A;
              }

              i {
                margin-left: 4px;
              }
            }

            .dropdown-menu {
              box-shadow: 0px 4px 4px 0px rgba(63, 89, 114, 0.20);
              padding: 0;

              .dropdown-item {
                font-size: var(--clamp14);
                color: #0A2477;
                padding: 6px 12px;

                &:hover {
                  background-color: #F0F9FF;
                  border-radius: 3px;
                }

                &.disabled {
                  color: #A3A3A3;
                }
              }
            }

            .dropdown-toggle {
              &:after {
                border-color: transparent;
              }
            }

            &:focus-visible {
              outline: none;
            }
          }
        }
      }

      .splitleftblock-partfour {
        position: relative;

        .omail-mail-listblock {
          height: calc(100vh - 236px);
          overflow: hidden;
          overflow-y: auto;

          &::-webkit-scrollbar {
            width: 4px !important;
          }

          &::-webkit-scrollbar-thumb {
            background: #DFF3FF;
            box-sizing: border-box;
            box-shadow: inset 0px 10px 10px rgba(255, 255, 255, 0.25);
            border-radius: 7px;
          }

          .omail-list-empty-block {
            padding: 0px;
            margin: 0px;
            height: calc(100vh - 237px);
            overflow: hidden;
            overflow-y: auto;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;

            h6 {
              color: #003B93;
              font-size: var(--clamp16);
              font-style: normal;
              font-weight: 400;
            }
          }

          .omail-mailreload-block {
            position: absolute;
            background-color: rgba(248, 240, 240, 0.4);
            width: 100%;
            height: calc(100vh - 237px);
            z-index: 1400;

            .spinner-inner {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
            }
          }

          .omaillist-box {
            background: #ffffff;
            border-top: 1px solid #ECECEC;
            cursor: pointer;
            position: relative;
            border-left: 3px solid transparent;

            &:hover {
              background-color: #F9F9F9;
              border-left: 3px solid #BEE49F;
            }

            .omail-user-block {
              position: absolute;
              top: 10px;
              left: 10px;

              .maillist-rounded-block {
                display: block;
                position: relative;
                line-height: 0;
                padding-left: 0px;
                margin-bottom: 0px;
                cursor: pointer;
                -webkit-user-select: none;
                -moz-user-select: none;
                -ms-user-select: none;
                user-select: none;

                .check-img-block {
                  cursor: pointer;

                  .contact-pic-text {
                    width: 28px;
                    height: 28px;
                    border-radius: 6px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    overflow: hidden;
                    background-color: #DEDEDE;

                    img {
                      width: 100%;
                      height: 100%;
                      object-fit: cover;
                    }

                    span {
                      font-size: var(--clamp14);
                      font-weight: 600;
                      color: var(--white-color);
                      line-height: 15px;
                    }
                  }

                  input {
                    position: absolute;
                    opacity: 0;
                    cursor: pointer;
                    height: 0;
                    width: 0;
                  }

                  .check-mark {
                    position: absolute;
                    bottom: -2px;
                    left: 5px;
                    height: 24px;
                    width: 24px;
                    opacity: 0;
                  }

                  input:checked~.check-mark {
                    background: url(../../../assets/omail/images/obsomailcontacts/checked.svg) no-repeat center center;
                    opacity: 1;
                    background-color: rgba(0, 0, 0, 0.4);
                    left: 0;
                    top: 0;
                    width: 28px;
                    height: 28px;
                    border-radius: 6px;
                  }
                }
              }
            }

            .omail-userinfo-block {
              padding: 10px 10px 10px 50px;
              min-height: 59px;
              max-height: 80px;

              @media screen and (min-width: 769px) and (max-width: 1024px) {
                max-height: initial;
              }

              @media screen and (max-width: 360px) {
                max-height: initial;
              }

              .omaillist-inner-one {
                display: flex;
                align-items: center;
                justify-content: start;

                @media screen and (min-width: 769px) and (max-width: 1024px) {
                  display: block;
                }

                .box-left {
                  .omail-user-icon {
                    i {
                      font-size: 32px;
                      color: #006BCE;
                    }
                  }

                  .omail-user-name {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    color: #334554;
                  }
                }

                .box-right {
                  font-style: normal;
                  font-weight: 400;
                  font-size: 12px;
                  color: #A3A3A3;
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  width: 100%;

                  @media screen and (min-width: 769px) and (max-width: 1024px) {
                    display: block;
                  }

                  @media screen and (max-width: 360px) {
                    display: block;
                  }
                }

                .omail-user-name {
                  max-width: 60%;
                  overflow: hidden;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                  font-size: 14px;
                  color: #30353D;
                  font-weight: 400;

                  @media screen and (max-width: 1600px) {
                    max-width: 50%;
                  }

                  @media screen and (max-width: 1366px) {
                    max-width: 36%;
                  }

                  @media screen and (min-width: 769px) and (max-width: 1024px) {
                    max-width: 98%;
                  }
                }
              }

              .omaillist-inner-two {
                display: flex;
                align-items: center;
                justify-content: space-between;
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                color: #A3A3A3;
                display: block;

                span {
                  margin-right: 4px;

                  i {
                    color: #E84C3D;
                  }
                }

                .box-left {
                  display: flex;
                  align-items: center;

                  .omail-subject-name {
                    max-width: 270px;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    font-size: 14px;
                    color: #6D747A;
                    font-weight: 500;

                    @media screen and (max-width: 1440px) {
                      max-width: 170px
                    }

                    @media screen and (max-width: 1366px) {
                      max-width: 110px
                    }
                  }
                }

                .box-right,
                .omail-bottom-links .box-left {
                  display: flex;
                  align-items: center;
                  gap: 10px;

                  .videomail-icon {
                    i {
                      color: #A3A3A3;
                      line-height: 20px;
                      font-size: 16px;
                    }
                  }

                  .mail-other-links {
                    margin: 0;
                    padding: 0;
                    cursor: pointer;

                    img {
                      width: 15px;
                      filter: invert(75%) sepia(15%) saturate(4%) hue-rotate(26deg) brightness(85%) contrast(89%);

                      &:hover {
                        filter: invert(66%) sepia(93%) saturate(1501%) hue-rotate(1deg) brightness(105%) contrast(87%);
                      }

                      &.important-active {
                        filter: invert(66%) sepia(93%) saturate(1501%) hue-rotate(1deg) brightness(105%) contrast(87%);
                      }
                    }
                  }
                }

                .omail-bottom-links {
                  display: flex;
                  justify-content: space-between;
                  margin-top: 2px;

                  .box-left {
                    gap: 0px;
                  }
                }

                .mail-status-label {
                  font-style: normal;
                  font-weight: 400;
                  font-size: 12px;
                  color: #F87405;
                }
              }

              .omaillist-inner-three {
                margin: 0 0 6px;
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                color: #706D7A;
              }

              .subject-highpriority {
                filter: invert(35%) sepia(54%) saturate(3330%) hue-rotate(342deg) brightness(99%) contrast(84%);

                img {
                  width: 16px;
                }
              }
            }

            &.activemail {
              background: #F3F3F3;
              color: #fff;
              border-left: 3px solid #37AC00;

              &:hover {
                .check_img i {
                  color: #006BCE !important;
                }

                .omail-user-name {
                  color: #334554 !important;
                }

                .omaillist-inner-two,
                .omaillist-inner-one .box-right {
                  color: #A3A3A3 !important;
                }

                .mail-other-links {
                  img {
                    filter: none;
                  }
                }
              }
            }

            &.newMailText {
              .omaillist-inner-two {
                font-style: normal;
                font-weight: 500;

                .omail-subject-name {
                  color: #006BCE !important;
                  font-weight: 500;
                }
              }
            }
          }
        }
      }
    }

    .omailmailbox-split-right-block {
      overflow: auto;
      position: relative;

      .omailmailbox-readmail-mainblock {
        height: calc(100vh - 77px);
        overflow: hidden;
        overflow-y: auto;
        background: #FFFFFF;
        position: relative;

        .omail-readmail-main {
          overflow: hidden;
          height: calc(100vh - 85px);
          position: relative;

          .omail-read-mail-header {
            .omail-read-mail-headerone {
              display: flex;
              align-items: center;
              justify-content: space-between;
              padding: 12px;
              position: relative;
              border-bottom: 1px solid #ECECEC;

              @media screen and (max-width: 480px) {
                display: block;
              }

              .headerone-left-block {
                @media screen and (max-width: 480px) {
                  margin-bottom: 10px;
                }

                .read-mail-user-text {
                  font-style: normal;
                  font-weight: 400;
                  font-size: 14px;
                  color: #30353D;
                }

                .read-mail-usermailid-text {
                  font-style: normal;
                  font-weight: 400;
                  font-size: 12px;
                  color: #A3A3A3;
                }
              }

              .headerone-rigth-block {
                display: flex;
                align-items: center;
                gap: 10px;

                button {
                  background: transparent;
                  outline: none;
                  border: 1px solid transparent;
                  border-radius: 8px;
                  font-size: var(--clamp14);

                  &:hover {
                    filter: invert(60%) sepia(123%) saturate(1855%) hue-rotate(185deg) brightness(69%) contrast(138%);
                  }
                }

                .text-to-speech-block {
                  background: #37AC00;
                  border: 2px solid transparent;
                  border-radius: 4px;
                  font-style: normal;
                  font-weight: 500;
                  font-size: 11px;
                  color: #464255;
                  cursor: pointer;
                  padding: 4px 6px;
                  display: flex;
                  align-items: center;
                  gap: 4px;

                  .omail-text-speech {
                    padding: 2px 4px;
                  }

                  &:hover {
                    border: 2px solid #37AC00;
                    background: #37AC00;

                    .omail-text-speech {
                      background: #2A8300;
                      border-radius: 4px;
                      padding: 2px 4px;
                    }
                  }

                  .textspeech-pauseplay {
                    img {
                      filter: invert(0);
                    }
                  }

                  .text-to-speech-dropdown {
                    .btn-arrow {
                      border: none;
                      padding: 0;
                      vertical-align: middle;

                      &::after {
                        display: none;
                      }

                      img {
                        filter: invert(100%) sepia(4%) saturate(0%) hue-rotate(220deg) brightness(318%) contrast(116%);
                      }
                    }

                    .speech-options-block {
                      border: 1px solid #006BCE;
                      padding: 0;

                      .speech-options {
                        border: none;
                        border-bottom: 1px solid #006BCE;
                        border-radius: 0;
                        font-style: normal;
                        font-weight: 400;
                        font-size: 12px;
                        color: #0A2477;
                        padding-top: 7px;
                        padding-bottom: 7px;

                        &:first-child {
                          border-radius: 8px 8px 0 0;
                        }

                        &:last-child {
                          border-bottom: none;
                          border-radius: 0 0 8px 8px;
                        }

                        &:hover {
                          background: #BFE7FE;
                          filter: inherit;
                        }

                        &.voice-active {
                          background: #BFE7FE;
                        }
                      }
                    }
                  }

                  span {
                    img {
                      filter: invert(100%) sepia(4%) saturate(0%) hue-rotate(220deg) brightness(318%) contrast(116%);
                    }
                  }
                }

                .omail-other-links {
                  display: flex;
                  align-items: center;
                }
              }
            }

            .omail-read-mail-headertwo {
              background: #FFFCF4;
              padding: 12px;
              display: flex;
              align-items: center;
              justify-content: space-between;
              border-bottom: 1px solid #ECECEC;

              .headertwo-left-block {
                display: flex;
                align-items: center;

                .read-mail-subject-text {
                  font-style: normal;
                  font-weight: 500;
                  font-size: 14px;
                  color: #003B93;
                }
              }

              .headertwo-right-block {
                .read-mail-timedate-text {
                  font-style: normal;
                  font-weight: 400;
                  font-size: 12px;
                  color: #A3A3A3;
                }
              }
            }

            .omail-read-mail-headerthree {
              display: flex;
              align-items: center;
              border-bottom: 1px solid #E6E6E6;
              justify-content: space-between;
              padding: 12px;

              .headerthree-left-block {
                display: flex;
                align-items: center;
                gap: 12px;
                width: 100%;
                color: #A3A3A3;
                font-size: 14px;

                .omail-clip-block {
                  .mat-chip-list-wrapper {
                    overflow-y: auto;
                    max-height: 60px;

                    &::-webkit-scrollbar {
                      width: 4px !important;
                    }

                    &::-webkit-scrollbar-thumb {
                      background: #DFF3FF;
                      box-sizing: border-box;
                      box-shadow: inset 0px 10px 10px rgba(255, 255, 255, 0.25);
                      border-radius: 7px;
                    }
                  }
                }
              }

              .headerthree-right-block {
                .email-more-list-block {
                  img {
                    &:hover {
                      filter: invert(38%) sepia(39%) saturate(6593%) hue-rotate(183deg) brightness(99%) contrast(98%);
                    }
                  }
                }
              }
            }

            .omail-read-mail-headerfour {
              display: flex;
              align-items: center;
              justify-content: space-between;
              padding-top: 0;
              position: relative;
              background-color: #FFF3E9;

              .read-mail-scheduled-text {
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                color: #30353D;
                padding: 10px 12px 9px 12px;
              }
            }

            .omail-emailmorelist-dropdown {
              border: none;
              box-shadow: 0px 8px 17px rgb(0 0 0 / 30%);
              padding: 0;

              .omail-morelist-header {
                background: #DFF3FF;
                border-radius: 8px 8px 0px 0px;
                padding: 8px 16px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                color: #30353D;

                h5 {
                  font-size: 14px;
                }

                span {
                  background: #FFFFFF;
                  box-shadow: 0px 4px 4px rgb(63 89 114 / 20%);
                  border-radius: 20px;
                  width: 20px;
                  height: 20px;
                  text-align: center;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  cursor: pointer;

                  &:hover {
                    background-color: #E84C3D;

                    img,
                    img:hover {
                      filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(269deg) brightness(150%) contrast(101%);
                    }
                  }
                }
              }

              .omail-clip-block {
                max-height: 256px;
                padding: 6px;
                overflow-y: auto;

                &::-webkit-scrollbar {
                  width: 4px !important;
                }

                &::-webkit-scrollbar-thumb {
                  background: #DFF3FF;
                  box-sizing: border-box;
                  box-shadow: inset 0px 10px 10px rgba(255, 255, 255, 0.25);
                  border-radius: 7px;
                }
              }
            }
          }

          .omail-read-mail-body {
            height: calc(100vh - 328px);
            overflow: hidden;
            overflow-y: auto;
            padding: 12px;

            .read-mail-body-text {
              font-style: normal;
              font-weight: 400;
              font-size: 12px;
              color: #464255;
              min-height: 500px;
              max-height: 100%;
              overflow: auto;

              &::-webkit-scrollbar {
                width: 4px !important;
                height: 4px !important;
              }

              &::-webkit-scrollbar-thumb {
                background: #DFF3FF;
                box-sizing: border-box;
                box-shadow: inset 0px 10px 10px rgba(255, 255, 255, 0.25);
                border-radius: 7px;
              }

              video {
                width: 100%;
              }

              @media screen and (max-width: 1366px) {
                min-height: 320px;
                max-height: 320px;
                overflow-y: auto;
              }

              dd {
                p span.fr-emoticon-img {
                  background-repeat: no-repeat !important;
                  min-height: 20px;
                  min-width: 20px;
                  display: inline-block;
                }
              }

              .fr-video {
                width: 100%;
                max-height: 509px;
                text-align: left;
                display: flex;

                @media screen and (max-width: 1440px) {
                  max-height: 355px;
                }

                @media screen and (max-width: 1366px) {
                  max-height: 332px;
                }

                @media screen and (max-width: 1280px) {
                  max-height: 303px;
                }

                @media screen and (max-width: 1024px) {
                  max-height: 223px;
                }

                @media screen and (max-width: 991px) {
                  max-height: 289px;
                }

                @media screen and (max-width: 768px) {
                  max-height: 319px;
                }

                @media screen and (max-width: 640px) {
                  max-height: 260px;
                }

                @media screen and (max-width: 575px) {
                  max-height: 231px;
                }

                @media screen and (max-width: 480px) {
                  max-height: 185px;
                }

                @media screen and (max-width: 414px) {
                  max-height: 153px;
                }

                @media screen and (max-width: 360px) {
                  max-height: 131px;
                }

                @media screen and (max-width: 320px) {
                  max-height: 111px;
                }

                video {
                  object-fit: cover;
                }
              }

              p {
                margin-bottom: 0;
              }

              p {
                a {
                  color: #0a58ca;
                }
              }
            }

            .omail-froalabg-block {
              .video-block {
                @media screen and (max-width: 991px) {
                  width: 81%;
                }
              }
            }

            /* text to speech starts here */
            .text-to-speech-block {
              background: #F3F2FF;
              border: 2px solid transparent;
              border-radius: 4px;
              font-style: normal;
              font-weight: 500;
              font-size: 11px;
              color: #464255;
              cursor: pointer;
              padding: 4px 10px;
              display: flex;
              align-items: center;
              gap: 4px;

              &:hover {
                border: 2px solid #F3F2FF;
                background: transparent;
              }

              .text-to-speech-dropdown {
                .btn-arrow {
                  border: none;
                  padding: 0;
                  vertical-align: middle;

                  &::after {
                    display: none;
                  }
                }

                .speech-options-block {
                  border: 1px solid #006BCE;
                  padding: 0;

                  .speech-options {
                    border: none;
                    border-bottom: 1px solid #006BCE;
                    border-radius: 0;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 12px;
                    color: #0A2477;
                    padding-top: 7px;
                    padding-bottom: 7px;

                    &:first-child {
                      border-radius: 8px 8px 0 0;
                    }

                    &:last-child {
                      border-bottom: none;
                      border-radius: 0 0 8px 8px;
                    }

                    &:hover {
                      background: #F3F2FF;
                    }

                    &.voice-active {
                      background: #F3F2FF;
                    }
                  }
                }
              }
            }

            /* text to speech ends here */
          }

          .omail-read-mail-footer {
            padding: 12px;
            width: 100%;

            .readmailattachment-block {
              background: #F8F8F8;
              border-radius: 6px;
              overflow: hidden;
              position: absolute;
              bottom: 0px;
              z-index: 99;
              left: 10px;
              right: 10px;

              .readmailattachment-header {
                padding: 12px;
                display: flex;
                align-items: center;
                justify-content: space-between;

                .allattach-text {
                  font-style: normal;
                  font-weight: 400;
                  font-size: 12px;
                  color: #6D747A;

                  .attach-download-all {
                    color: #4136F1;
                    font-size: 12px;
                    margin-left: 20px;
                  }
                }

                .allattach-download-text {
                  font-style: normal;
                  font-weight: 400;
                  font-size: 12px;
                  color: #006BCE;
                  cursor: pointer;
                }
              }

              .readmailattachment-body {
                margin: 12px;
                min-height: 53px;
                max-height: 53px;
                overflow-y: auto;

                &::-webkit-scrollbar {
                  width: 4px !important;
                  height: 4px !important;
                }

                &::-webkit-scrollbar-thumb {
                  background: #DFF3FF;
                  box-sizing: border-box;
                  box-shadow: inset 0px 10px 10px rgba(255, 255, 255, 0.25);
                  border-radius: 7px;
                }

                @media screen and (max-width: 1800px) {
                  padding-top: 0;
                  padding-bottom: 0;
                }

                .attachment-list {
                  display: flex;

                  li {
                    background: #FFFFFF;
                    border-radius: 6px;
                    padding: 6px 9px;
                    margin-right: 24px;

                    &:last-child {
                      margin-right: 0;
                    }

                    .downloadall-main {
                      display: flex;
                      gap: 10px;

                      .read-attachment-filename {
                        font-style: normal;
                        font-weight: 500;
                        font-size: 12px;
                        color: #30353D;
                        display: inline-block;

                        .file-size-text {
                          color: #A3A3A3;
                          display: block;
                        }
                      }

                      .read-download-icon {
                        cursor: pointer;
                      }
                    }
                  }
                }
              }
            }
          }

          /* Select any mail starts here */
          .select-anymail-main {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;

            .select-anymail-inner {
              display: flex !important;
              flex-direction: column;
              height: 100%;
              justify-content: center;
              margin: 0 20px;
              align-items: center;

              h4 {
                color: #003B93;
                font-size: var(--clamp16);
                font-style: normal;
                font-weight: 400;
                margin-top: 18px;
                margin-bottom: 5px;
              }

              p {
                color: #6D747A;
                font-size: var(--clamp16);
                font-style: normal;
                font-weight: 400;
              }
            }
          }

          /* Select any mail ends here */
        }

        form {
          height: 100%;
        }

        &.readmail-hide {
          display: none;
        }
      }

      .omail-compose-mail-block .omailcomposemail-header .omailcomposemail-headertwo .omail-form-field {
        padding: 8px 12px;
      }
    }

    .omail-search-content {
      padding-left: 12px;
      padding-right: 12px;
    }
  }
}

.omail-compose-mail-block {
  padding: 12px 0 0;
  height: 100%;
  overflow: hidden;

  .omailcomposemail-header {
    .omailcomposemail-headerone {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 0;
      padding: 0 12px 10px;
      border-bottom: 1px solid #E6E6E6;

      h4 {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        color: #334554;
      }

      .close-icon {
        cursor: pointer;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        color: #334554;

        &:hover {
          opacity: 0.5;
        }
      }
    }

    .omailcomposemail-headertwo {
      .omail-form-field {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #E6E6E6;
        padding: 4px 12px;

        .omail-col-one {
          display: flex;
          align-items: center;
          width: 100%;

          h3 {
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            color: #A3A3A3;
            margin: 0 8px 0 0;
            min-width: 46px;
            text-align: center;
          }

          .read-mail-sub {
            width: 100%;
          }

          input {
            border: none !important;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            color: #334554;
            width: 100%;
            outline: none;
            padding-left: 0;
          }

          .attachment-list {
            width: 100%;
            min-height: auto;
            max-height: 60px;
            overflow-y: auto;

            &::-webkit-scrollbar {
              width: 4px !important;
            }

            &::-webkit-scrollbar-thumb {
              background: #DFF3FF;
              box-sizing: border-box;
              box-shadow: inset 0px 10px 10px rgba(255, 255, 255, 0.25);
              border-radius: 7px;
            }

            ul {
              display: inline;
              margin-right: 10px;

              li {
                display: inline-flex;
                align-items: center;
                gap: 6px;
                margin-right: 10px;
                margin-bottom: 10px;
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                color: #30353D;

                i {
                  color: #A3A3A3;
                }

                span {
                  color: #A3A3A3;

                  i {
                    &:hover {
                      opacity: 0.5;
                    }
                  }
                }
              }
            }
          }

          .omail-clip-block {
            position: relative;

            .mat-chip-list-wrapper {
              overflow: auto;
              max-height: 60px;

              &::-webkit-scrollbar {
                width: 4px !important;
              }

              &::-webkit-scrollbar-thumb {
                background: #DFF3FF;
                box-sizing: border-box;
                box-shadow: inset 0px 10px 10px rgba(255, 255, 255, 0.25);
                border-radius: 7px;
              }
            }
          }

          .sub-error-text {
            position: absolute;
            bottom: -14px
          }
        }

        .omail-col-two {
          display: flex;
          align-items: center;
          white-space: pre;
          gap: 4px;
          margin-left: 3px;

          .omail-addgroup-mails {
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            color: #F87405;
            cursor: pointer;
            margin-right: 10px;
          }

          .contacts-user-add {
            cursor: pointer;
          }

          .mail-importance-block {
            cursor: pointer;

            span {
              img {
                &:hover {
                  filter: invert(35%) sepia(54%) saturate(3330%) hue-rotate(342deg) brightness(99%) contrast(84%);
                }
              }
            }
          }

          .minus-square {
            cursor: pointer;
          }

          &.to-col {
            margin-right: 23px;
            padding-left: 0;
            gap: 0;

            .ccbcc-text-links {
              padding-left: 0;
              padding-right: 0;
            }
          }
        }

        &:last-child {
          padding: 0px 12px;
          border-bottom: none;
        }
      }

      .omail-emailmorelist-dropdown {
        border: none;
        box-shadow: 0px 8px 17px rgb(0 0 0 / 30%);
        padding: 0;

        .omail-morelist-header {
          background: #DFF3FF;
          border-radius: 8px 8px 0px 0px;
          padding: 8px 16px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          color: #30353D;

          h5 {
            font-size: 14px;
          }

          span {
            background: #FFFFFF;
            box-shadow: 0px 4px 4px rgb(63 89 114 / 20%);
            border-radius: 20px;
            width: 20px;
            height: 20px;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;

            &:hover {
              background-color: #E84C3D;

              img,
              img:hover {
                filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(269deg) brightness(150%) contrast(101%);
              }
            }
          }
        }

        .event-more-mail-list {
          min-height: 100px;
          max-height: 300px;
          overflow-y: hidden;
          height: auto !important;

          &:hover {
            background-color: transparent !important;
          }

          .omail-clip-block {
            max-height: 256px;
            padding: 6px;
            overflow-y: auto;

            .mail-text {
              margin: 0;
            }

            &::-webkit-scrollbar {
              width: 4px !important;
            }

            &::-webkit-scrollbar-thumb {
              background: #DFF3FF;
              box-sizing: border-box;
              box-shadow: inset 0px 10px 10px rgba(255, 255, 255, 0.25);
              border-radius: 7px;
            }
          }
        }
      }

      .omail-custom-btn-outline {
        padding: 0px 10px;
        border-radius: 4px;
        margin-right: 4px;
        padding: 4px 10px;
      }
    }

    .ccbcc-text-links {
      display: flex;
      justify-content: flex-end;
      width: 100%;
      gap: 10px;
      padding-right: 10px;

      span {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        color: #006BCE;
        cursor: pointer;
      }
    }
  }

  .omailcomposemail-body {
    margin: 0;
    max-height: fit-content;
    overflow-y: hidden;
    height: calc(100vh - 193px);

    .fr-wrapper {
      height: calc(100vh - 323px) !important;

      .fr-video {
        width: 100%;
        height: 481px;
        padding: 0;
        text-align: left;
        display: flex;

        @media screen and (max-width: 1440px) {
          height: 330px;
        }

        @media screen and (max-width: 1366px) {
          height: 308px;
        }

        @media screen and (max-width: 1280px) {
          height: 278px;
        }

        @media screen and (max-width: 1024px) {
          height: 200px;
        }

        @media screen and (max-width: 991px) {
          height: 265px;
        }

        @media screen and (max-width: 768px) {
          height: 294px;
        }

        @media screen and (max-width: 660px) {
          height: 233px;
        }

        @media screen and (max-width: 480px) {
          height: 160px;
        }

        @media screen and (max-width: 414px) {
          height: 131px;
        }

        @media screen and (max-width: 360px) {
          height: 105px;
        }

        @media screen and (max-width: 320px) {
          height: 87px;
        }

        video {
          width: 100%;
          object-fit: cover;
        }
      }
    }

    .listening-txt {
      margin: 0 50px 0 0;
      text-align: right;
      color: #37AC00;
      animation: bounceListen 0.6s ease infinite alternate;
      -webkit-animation: bounceListen 0.6s ease infinite alternate;
    }
  }

  .no-bg-video-block {
    video {
      width: 80% !important;
    }
  }

  .omail-smartreplay-block {
    margin: 10px 0 0;

    @media screen and (min-width: 1025px) {
      position: fixed;
      bottom: 80px;
      z-index: 1;
      background: #fff;
      margin-left: 10px;
    }

    ul {
      display: flex;
      align-items: center;
      gap: 10px;
      justify-content: space-between;

      li {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        color: #006BCE;
        border: 1px solid #4693db;
        border-radius: 4px;
        padding: 6px 12px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        width: 37%;
        cursor: pointer;
      }
    }
  }

  .omailcomposemail-footer {
    padding: 5px 12px;
    border-top: 1px solid #DEDEDE;
    border-bottom: 1px solid #DEDEDE;
    position: absolute;
    left: 0;
    right: 0;
    bottom: -1px;
    z-index: 2;
    background-color: #fff;

    @media screen and (max-width: 1024px) {
      width: 100%;
    }

    @media screen and (max-width: 640px) {
      display: block;
    }

    .omailcomposemail-footer-links-left {
      display: flex;

      .omail-more-dropdown {
        .dropdown-toggle {
          border: none;
          background: no-repeat;
          padding: 0;

          &:disabled {
            cursor: no-drop;
            opacity: 0.5;
          }
        }
      }

      @media screen and (max-width: 414px) {
        margin-bottom: 10px;
      }

      .btn-link {
        padding: 0;
        margin: 0 12px 0 0;

        &:hover {
          filter: invert(49%) sepia(85%) saturate(4431%) hue-rotate(180deg) brightness(93%) contrast(98%);
        }
      }
    }

    .omailcomposemail-footer-links-right {
      display: flex;
      align-items: center;
      gap: 10px;

      @media screen and (max-width: 354px) {
        display: block;
      }

      .sendbtn {
        min-height: 32px;
        min-width: 32px;
        border-radius: 8px;
        padding: 0;
      }

      .resendbtn {
        min-height: 32px;
        min-width: 32px;
        border-radius: 8px;
        padding: 2px 5px;
      }

      .omail-send-btngroup {
        @media screen and (max-width: 640px) {
          margin-right: 0 !important;
        }

        @media screen and (max-width: 320px) {
          margin-top: 10px;
        }
      }

      .schedule-btn {
        @media screen and (max-width: 640px) {
          margin-right: 10px;
          margin-left: 6px;
        }

        @media screen and (max-width: 354px) {
          margin-bottom: 4px;
        }

        img {
          margin-right: 4px;
        }
      }

      .btn-link {
        @media screen and (max-width: 640px) {
          display: block;
          text-align: left;
          padding-left: 5px;
        }
      }

      .omail-custom-btn-outline {
        padding: 9px 24px 8px;
      }
    }

    .composemail-footer-inside {
      display: flex;
      align-items: center;
      justify-content: space-between;

      @media screen and (max-width: 414px) {
        display: block;
      }
    }
  }

  .schedule-text-compose {
    text-align: center;

    span {
      padding: 0;
      font-style: normal;
      font-weight: normal;
      font-size: var(--clamp12);
      text-decoration: none;
      cursor: inherit;
      color: #0d6efd;
    }
  }

  .composemail-form {
    height: 100%;
  }

  &.omail-reply-all-main {
    .omailcomposemail-headertwo {
      .omail-form-field:nth-child(2) {
        .omail-clip-block {
          width: 92.5%;
        }

        .omail-col-two {
          .email-more-list-block {
            right: 24px;
          }
        }

        .omail-emailmorelist-dropdown {
          .omail-clip-block {
            width: 100%;
          }
        }
      }
    }
  }

  /* Contacts send mail starts here */
  &.omail-contact-sendmail-main {
    padding: 0;

    .composemail-form {
      position: inherit !important;

      .omailcomposemail-header {
        .omailcomposemail-headerone {
          display: none;
        }

        .omailcomposemail-headertwo {
          .omail-form-field {
            .omail-col-two {
              .omail-addgroup-mails {
                display: none;
              }
            }
          }
        }
      }
    }
  }

  &.readmail-hide {
    display: none;
  }

  /* Contacts send mail ends here */
}

/*/Mail Clips CSS Start*/
.omail-clip-block,
.omail-email-catagory-types {
  width: 100%;

  .form-control {
    width: 100%;

    &:focus {
      box-shadow: none;
    }
  }

  .mat-chip-list {
    .mat-standard-chip {
      font-style: normal;
      font-weight: normal;
      font-size: var(--clamp12);
      color: #2A3F51;
      min-height: 25px;
      white-space: nowrap;
      background-color: #F5F5F5;

      @media screen and (min-width: 280px) and (max-width: 990px) {
        word-break: break-all;
        white-space: pre-wrap !important;
        height: auto;
      }
    }

    .mat-standard-chip {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      margin: 2px 4px;

      .mat-chip-remove {
        opacity: 1 !important;
        display: flex;

        &:hover {
          img {
            filter: invert(44%) sepia(88%) saturate(4143%) hue-rotate(341deg) brightness(98%) contrast(86%);
          }
        }
      }
    }
  }
}

/* /Mail Clips CSS End */
/* New design changes */
.omail-sidebar-toggle {
  position: fixed;
  left: 282px;
  top: 90px;
  z-index: 2;
  color: #A3A3A3;
  cursor: pointer;
  transition: left 0.2s;
  font-size: 20px;

  @media screen and (max-width: 1024px) {
    position: absolute;
    top: 14px;
    z-index: 5;
  }

  @media screen and (max-width: 991px) {
    left: 210px;
    z-index: 5;
  }
}

.omailapp-mainwrap {
  &.active {
    .omail-mobile-logo {
      display: block;
      margin: 0 auto;
    }

    .omail-main-logo {
      display: none;
    }

    .omail-app-header {
      width: 68px;
    }

    .omail-mailbox-sidenav-block {
      width: 71px;

      @media screen and (max-width: 1024px) {
        width: 68px;
      }
    }

    .omail-sidebar-toggle {
      left: 134px;

      @media screen and (max-width: 991px) {
        left: 55px;
      }
    }

    .list-text,
    .folder-txt,
    .create-folder {
      display: none;
    }

    .folder-content {
      i {
        display: none;
      }
    }

    .generate-folder {
      .ge-text {
        display: none;
      }
    }

    .omail-badge {
      display: none !important;
    }

    .trash-list-main {
      display: none;
    }

    .trash-list-main {
      .list-arrow {
        display: none;
      }

      &.trash-inactive {
        .omail-trash-list {
          .activefolder-list {
            background-color: #D1CDFF;
          }
        }
      }
    }

    .folder-all-icons {
      display: none !important;
    }

    .omail-trash-list li {
      padding-left: 12px !important;
    }

    .trash-list-main {
      display: block;
    }

    .sidebar-storage {
      display: none;
    }

    .omail-modules-link {
      ul {
        li {
          display: none;

          &.active {
            display: block;
          }
        }
      }
    }

    /* Active-collapse calendar starts here */
    .omail-calendar {
      .calendar-left-side {
        width: 68px;
      }
    }

    /* Active-collapse calendar ends here */
    /* Active-collapse contacts starts here */
    .omail-contacts-main-block {
      .contact-left-padding {
        width: 68px;
      }

      .omail-sub-delete-layout {
        display: none;
      }
    }

    .omail-v-tabs .omail-vtabs-view {
      width: calc(100% - 68px);

      @media screen and (max-width: 1024px) {
        width: 100%;
      }
    }

    /* Active-collapse contacts ends here */
    /* Active-collapse settings starts here */
    .omailapp-settings-wrap {
      .calendar-leftside {
        width: 68px;
      }

      .omail-side-settings ul.sidenav-box li.list {
        a img {
          margin-right: 0;
        }

        .align-items-center {
          justify-content: center;
        }
      }
    }

    /* Active-collapse settings ends here */
    /* Active-collapse Mail starts here */
    .new-mail-btn {
      border-radius: 6px !important;
      padding-left: 11px !important;
      min-width: 40px;

      span {
        display: none;
      }
    }

    .omail-sub-sidebar .splitleftblock-partone .btn-group .dropdown-toggle {
      display: none;
    }

    .omail-categories-list li {
      padding-left: 12px !important;
      padding-right: 12px !important;
    }

    /* Active-collapse Mail ends here */
    /* Active-collapse Settings starts here */
    .omailapp-settings-wrap {
      .omail-side-settings {
        width: 68px;
      }

      // .calendar-leftside {
      //   width: 80px;
      // }
      .title {
        display: none;
      }
    }

    /* Active-collapse Settings end here */
    /* Active-collapse contacts starts here */
    .omail-contacts-main-block .omail-v-tabs {
      .omail-vtabs-sidenav {
        width: 68px;

        .cg-btn-action-block .omail-btn-group {

          .create-contact-btn,
          .create-group-btn {
            border-radius: 6px;

            span {
              display: none;
            }
          }

          .dropdown-toggle {
            display: none;
          }
        }

        .cg-list-block {
          li {
            span {
              display: none;
            }
          }
        }

        .omail-delete-list-one {

          .delete-txt,
          .list-arrow {
            display: none;
          }
        }
      }
    }

    /* Active-collapse contacts ends here */
    // .omail-light-guide-block {
    //   display: none;
    // }

    .calendar-left-side {
      .mat-calendar {
        display: none;
      }
    }
  }
}

/* Btn group css starts here */
.omail-btn-group {
  .omail-custom-btn {
    border-radius: 6px 0 0 6px;
    text-align: left;

    &::after {
      display: none;
    }
  }

  .dropdown-toggle {
    border-radius: 0 6px 6px 0;
    border-left: 1px solid #0054A2;
    padding: 10px 14px 10px 13px;

    &:focus-visible {
      outline: none;
    }
  }

  .dropdown-menu {
    width: 216px;
    background: transparent;
    border: none;
    padding: 0;
    margin-top: -1px !important;

    @media only screen and (min-width: 1451px) and (max-width: 1918px) {
      width: 208px;
    }

    button,
    .drop-list {
      background-color: #006BCE;
      border-radius: 6px;
      font-size: 14px;
      color: #ffffff;
      padding: 9px 12px;
      margin-bottom: 1px;
      cursor: pointer;

      img {
        margin-right: 7px;
      }

      &:hover {
        background-color: #0288D9;
      }
    }
  }

  .new-mail-btn {
    padding-left: 10px;
    width: 100% !important;
  }
}

.omail-send-btngroup {
  .dropdown-toggle {
    padding: 10px;
  }

  .dropdown-menu {
    max-width: 116px;
    min-width: 109px;
    text-align: center;

    @media screen and (max-width: 414px) {
      max-width: 165px;
    }

    @media screen and (max-width: 360px) {
      max-width: 123px;
    }

    @media screen and (max-width: 320px) {
      max-width: 178px;
    }

    button {
      text-align: center;
    }
  }
}

.omail-contact-btn-group {
  .dropdown-menu {
    button {
      padding: 9px 16px;
    }
  }
}

/* Btn group css ends here */
/* dropdown starts here */
.omail-more-dropdown {
  .dropdown-toggle {
    &::after {
      border: none;
    }

    &:hover {
      filter: invert(49%) sepia(85%) saturate(4431%) hue-rotate(180deg) brightness(93%) contrast(98%)
    }
  }

  .dropdown-menu {
    border: none;
    box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.3);
    padding: 0;
    border-radius: 0;
    min-width: 129px;

    .dropdown-item {
      color: #0A2477;
      font-size: var(--clamp14);
      font-weight: 400;
      padding-left: 12px;
      padding-right: 12px;

      &:hover {
        background-color: #F0F9FF !important;
        border-radius: 4px !important;
        filter: invert(0) !important;
      }
    }
  }

  .omailreadmail-morelink-block {
    border-radius: 4px;
    padding: 8px;
    min-width: 101px;

    hr {
      margin: 0;
    }
  }

  &.show {
    .dropdown-toggle {
      filter: invert(49%) sepia(85%) saturate(4431%) hue-rotate(180deg) brightness(93%) contrast(98%);
    }
  }

  .btn-link {
    border: none;
    background-color: transparent;
    padding-left: 0;
    padding-right: 0;

    &:disabled {
      cursor: no-drop;
      opacity: 0.5;
    }
  }
}

/* dropdown ends here */
/* New design changes ends here*/
/*
  ************************************
  * Omail App Mailbox-Module CSS End
  ************************************
*/
/* 
  ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
*/
/*
  **************************************
  * Omail App Contacts-Module CSS Start
  **************************************
*/
.omail-contacts-main-block {
  width: 100%;
  height: calc(100vh - 159px);

  .contacts-toggle-btn {
    min-width: auto;
    min-height: auto;
    border-radius: 6px;
    background: #006BCE;

    @media screen and (max-width: 767px) {
      padding: 6px 14px;
      text-align: center;
      margin-top: 9px;
    }

    i {
      margin-right: 0;
    }
  }

  /*/No List in Contacts and Favorites and Group CSS START*/
  .no-list-block {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
    height: calc(100vh - 76px);
    flex-direction: column;

    .list-icon {
      margin: 0 0 26px;
    }

    .list-text {
      font-weight: 400;
      font-size: var(--clamp16);
      color: #003B93;
      margin: 0 0 26px;
    }

    .list-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
    }
  }

  /*/No List in Contacts and Favorites and Group CSS End*/
  .contact-left-padding {
    padding-right: 0;
    width: 13.8%;

    @media screen and (max-width: 1440px) and (min-width: 1090px) {
      width: 32%;
    }

    @media screen and (min-width: 991px) and (max-width: 1024px) {
      width: 36%;
    }

    @media screen and (min-width: 768px) and (max-width: 1023px) {
      width: 38%;
    }

    @media screen and (max-width: 991px) {
      padding-right: 0.75rem;
    }
  }

  .contact-right-padding {
    padding-left: 0;

    @media screen and (max-width: 1440px) and (min-width: 1090px) {
      width: 68%;
    }

    @media screen and (min-width: 991px) and (max-width: 1024px) {
      width: 64%;
    }

    @media screen and (min-width: 768px) and (max-width: 1023px) {
      width: 62%;
    }

    @media screen and (max-width: 767px) {
      padding-left: 0.75rem;
    }
  }

  //Contacts ,Fav and Group Layout Start
  .contacts-left-block {
    background-color: #fff;
    height: calc(100vh - 76px);
    overflow: hidden;
    transition: 0.4s;
  }

  @media screen and (max-width: 767px) {
    .omailcontactsshow {
      visibility: hidden;
      position: absolute;
      width: 0px;
      padding: 0 !important;
    }

    .omailcontactshide {
      visibility: visible;
      position: absolute;
      width: 360px;
      box-shadow: 2px 2px 15px rgb(0, 0, 0, 0.2);
      z-index: 3;
    }
  }

  @media screen and (max-width: 480px) {
    .omailcontactshide {
      width: 65%;
    }
  }

  .contacts-right-block {
    overflow: hidden;
    background-color: #fff;
    border-radius: 0px 8px 8px 0px;
    height: calc(100vh - 76px);

    /*/O-Mail Tabs Right Side design CSS Start*/
    .omail-tab-block {
      margin: 0;

      .nav-tabs {
        padding: 12px;

        .nav-link {
          font-style: normal;
          font-weight: 400;
          font-size: var(--clamp18);
          color: #30353D;
          border: none;
          background-color: #F5F5F5;
          font-size: var(--clamp14);
          border-radius: 6px;

          &:hover {
            background: #F1FAFF;
            border: none;
          }

          &.active {
            font-weight: 500;
            background-color: #9EDAFE;
            border: none;
            color: #30353D;
            border-radius: 6px;
          }
        }

        li {
          padding-right: 2px;

          a {
            &.active {
              .tab-close {
                img {
                  opacity: 0.7;
                }
              }
            }
          }
        }
      }
    }

    /*/O-Mail Tabs Right Side design CSS End*/
    /*/Contacts Right side Header Part CSS Start*/
    .contacts-view-header {
      border-bottom: 1px solid #F3F2FF;
      padding: 10px 24px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: #DFF3FF;

      @media screen and (max-width: 560px) {
        display: block;
        padding: 10px;
      }

      .contact-smart-info {
        display: flex;
        align-items: center;
        gap: 10px;

        .contact-text {
          h4 {
            margin-bottom: 2px;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            color: #30353D;

            @media screen and (max-width: 1440px) {
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              max-width: 340px;
            }

            @media screen and (max-width: 1280px) {
              max-width: 240px;
            }

            @media screen and (max-width: 991px) {
              max-width: 160px;
            }
          }

          p {
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            color: #A3A3A3;
            margin: 0;
            padding: 0;

            @media screen and (max-width: 1440px) {
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              max-width: 340px;
            }

            @media screen and (max-width: 1280px) {
              max-width: 240px;
            }

            @media screen and (max-width: 991px) {
              max-width: 160px;
            }

            @media screen and (max-width: 414px) {
              max-width: 120px;
            }
          }
        }

        .contact-pic-text {
          width: 32px;
          height: 32px;
          border-radius: 8px;
          display: flex;
          align-items: center;
          justify-content: center;
          background: #DEDEDE;
          overflow: hidden;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 8px;
          }

          span {
            font-size: var(--clamp16);
            font-weight: 600;
            color: #fff;
          }
        }

        .contact-text {
          @media screen and (max-width: 767px) {
            text-align: left;
          }

          @media screen and (max-width: 480px) {
            width: 220px;
          }

          @media screen and (max-width: 414px) {
            width: 194px;
          }

          @media screen and (max-width: 360px) {
            width: 120px;
          }

          @media screen and (max-width: 320px) {
            width: 106px;
          }
        }
      }

      .user-view-actions {
        display: flex;
        align-items: center;

        @media screen and (max-width: 640px) {
          margin-left: 0px;
          display: block;

          button {
            margin-top: 8px;
          }
        }

        button {
          color: #0A2477;
          display: flex;
          align-items: center;
          gap: 4px;
          text-decoration: none;

          @media screen and (min-width: 768px) and (max-width: 1023px) {
            font-size: 12px;
          }

          @media screen and (max-width: 480px) {
            font-size: 12px;
          }

          img {
            filter: invert(35%) sepia(99%) saturate(6727%) hue-rotate(199deg) brightness(98%) contrast(100%);
          }

          &.omail-custom-btn {
            background: #FFFFFF;
            color: #006BCE;
            padding: 6px 16px 5px;

            &:hover {
              background-color: #F0F9FF;
            }
          }
        }
      }
    }

    /*/Contacts Right side Header Part CSS End*/
    .contacts-view-block {
      position: relative;

      .contacts-veiw-tab {
        .contacts-view-form-info {
          .form-group {
            margin-bottom: 1rem;

            @media screen and (max-width: 767px) {
              text-align: left;
            }
          }

          background: #FFFFFF;
          height: calc(100vh - 195px);
          overflow: hidden;
          overflow-y: auto;

          @media screen and (max-width: 767px) {
            height: calc(100vh - 286px);
          }

          @media screen and (max-width: 480px) {
            height: calc(100vh - 325px);
          }

          &::-webkit-scrollbar {
            width: 4px !important;

            @media screen and (max-width: 540px) {
              width: 2px !important;
            }
          }

          &::-webkit-scrollbar-thumb {
            background: #DFF3FF;
            box-sizing: border-box;
            box-shadow: inset 0px 10px 10px rgba(255, 255, 255, 0.25);
            border-radius: 7px;
          }

          .action-links {
            text-align: right;

            button {
              margin: 10px;
              outline: none;
            }

            ::ng-deep button:disabled {
              cursor: no-drop !important;
              opacity: 0.5;
              color: #ffffff;

              &:hover {
                color: #ffffff !important;
                background: linear-gradient(180deg, #3B82F6 0%, #1D4ED8 100%);
                border: 1px solid transparent;
              }
            }
          }

          .zip-error-text {
            width: 100%;
            margin-top: 0.25rem;
            font-size: 80%;
            color: #dc3545;
          }

          .contact-group-user-pic-block {
            background: none;
          }
        }

        .create-contact-maincontent {
          .action-links {
            position: relative;
            z-index: 1;

            @media screen and (max-width: 767px) {
              z-index: 0;
            }
          }
        }

        .contact-date-picker {
          .date-input {
            input {
              width: 100%;
              height: 44px;
              border: 1px solid #5EC2FE;
              border-radius: 4px;
              padding: 0.375rem 0.75rem;
              background-color: #ffffff;

              &:disabled {
                border: 1px solid #ECECEC;
                border-radius: 4px;
                color: #6D747A;
                background-color: #F8F8F8;
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                cursor: no-drop;
              }
            }
          }

          mat-form-field {
            width: 100%;
            height: 44px;
          }

          position: relative;

          .date-pic-icon {
            position: absolute;
            top: 2px;
            right: 0;
          }

          .mat-icon {
            vertical-align: inherit;
          }
        }

        label {
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          color: #A3A3A3;
          margin: 0 0 6px;
        }

        .form-control {
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          color: #30353D;
          border: 1px solid #5EC2FE;
          height: 40px;
          background-color: #FFFFFF;
        }

        textarea {
          width: 100%;
          border: 1px solid #F8F8FF;
          border-radius: 4px;
          resize: none;
          min-height: 140px;
          overflow: hidden;
          overflow-y: auto;
          background-color: #F8F8FF;
          padding: 10px;

          &:disabled {
            border: 1px solid #ECECEC;
            border-radius: 4px;
            color: #6D747A;
            background-color: #F8F8F8;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            cursor: no-drop;
          }
        }

        .custom-select {
          background: none;
          border-left: none;
          border-radius: 4px;
          background: url(../../../assets/omail/images/contacts/adressbook-select.png) no-repeat 97% center;
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          color: #2A3F51;
          border: 1px solid #F8F8FF;
          background-color: #F8F8FF;
        }

        .form-control:disabled,
        .form-control[readonly] {
          border: 1px solid #ECECEC;
          border-radius: 4px;
          color: #6D747A;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          cursor: no-drop;
          background-color: #F8F8F8;
        }

        .oesomail-form-control {
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          color: #30353D;
          border: 1px solid #5EC2FE;
          background-color: #ffffff;

          &:focus-visible {
            outline: none;
          }
        }

        .oesomail-date-form-control {
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          color: #30353D;

          &::placeholder {
            color: #30353D;
          }

          &:-ms-input-placeholder {
            color: #30353D;
          }

          &::-ms-input-placeholder {
            color: #30353D;
          }
        }
      }
    }

    /*/Group Contacts Form Start*/
    .create-group-form {
      .form-group {
        margin-bottom: 1rem;
      }

      padding: 24px 24px 100px 24px;
      overflow: hidden;
      overflow-y: auto;
      height: calc(100vh - 90px);

      @media screen and (max-width:1920px) {
        height: calc(100vh - 195px);
      }

      @media screen and (max-width:767px) {
        height: calc(100vh - 180px);
      }

      @media screen and (max-width:480px) {
        height: calc(100vh - 225px);
      }

      &::-webkit-scrollbar {
        width: 4px !important;

        @media screen and (max-width: 540px) {
          width: 2px !important;
        }
      }

      &::-webkit-scrollbar-thumb {
        background: #DFF3FF;
        box-sizing: border-box;
        box-shadow: inset 0px 10px 10px rgba(255, 255, 255, 0.25);
        border-radius: 7px;
      }

      @media screen and (max-width:768px) {
        padding: 0;
      }

      @media screen and (max-width:767px) {
        padding: 0;
      }

      label {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        color: var(--text-color-2);
      }

      .form-control {
        border: 1px solid var(--border-color-2);
        border-radius: 4px;
        background: var(--input-bg-color-1);
      }

      .add-book-btn-block {
        margin: 5px 0 25px;
        display: flex;
        align-items: center;
        gap: 24px;

        @media screen and (max-width:510px) {
          display: block;

          .group-rule-info-text {
            margin-top: 24px;
          }
        }

        .group-rule-info-text {
          background: #FFFBF8;
          border: 1px solid #FFB97E;
          border-radius: 6px;
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          color: #464255;
          padding: 5px 8px;

          .star-icon {
            color: #E84C3D;
          }
        }
      }

      .invalid-feedback {
        position: absolute;
        position: inherit;
        bottom: 0;
      }

      .create-group-form-actions {
        .action-links {
          display: flex;
          align-items: center;
          justify-content: end;
          gap: 20px;
        }
      }

      .group-error-text {
        position: inherit;
      }
    }

    .email-catagory-block {
      display: flex;

      @media screen and (max-width:767px) {
        display: block;
      }

      .email-catagory-inner {
        background: #FFFFFF;
        box-shadow: 0px 4px 4px rgba(63, 89, 114, 0.2);
        border-radius: 8px;
        margin: 0 0 20px;

        @media screen and (max-width:767px) {
          width: 100%;
        }

        .sub-heading {
          background: #F0F9FF;
          border-radius: 12px 12px 0px 0px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 10px 12px;

          h6 {
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            color: #006BCE;
          }

          span {
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            color: #A3A3A3;
          }
        }

        .omail-email-catagory-types {
          min-height: 250px;
          max-height: 250px;
          overflow-x: auto;
          overflow-y: auto;
          padding: 12px;

          &::-webkit-scrollbar {
            width: 4px !important;
          }

          &::-webkit-scrollbar-thumb {
            background: #DFF3FF;
            box-sizing: border-box;
            box-shadow: inset 0px 10px 10px rgba(255, 255, 255, 0.25);
            border-radius: 7px;
          }

          mat-chip-list {
            .mat-chip-list-wrapper {
              display: block;
            }
          }

          @media screen and (max-width: 767px) {
            min-height: 140px;
            max-height: 140px;
          }
        }
      }
    }

    /*/Group Contacts Form End*/
    /*/contacts and Group User Pic right side CSS Start*/
    .contact-group-user-pic-block {
      .form-group {
        margin-bottom: 1rem;
      }

      text-align: center;
      background-color: #F8F8F8;
      padding: 24px 0 10px;
      border-radius: 12px;

      .contact-pic-text-block {
        position: relative;
        text-align: center;
        display: inline-block;
        margin: 0 0 20px;

        .edit-block {
          position: absolute;
          top: 15px;
          right: 0;
          background: #ffffff;
          overflow: hidden;
          width: 32px;
          height: 32px;
          border-radius: 50%;
          cursor: pointer;
          border: none;

          &::after {
            border: transparent;
          }

          img {
            object-fit: none;
          }

          &:hover {
            background: #0288D9;

            img {
              filter: invert(0) sepia(0%) saturate(15%) hue-rotate(0deg) brightness(206%) contrast(100%);
            }
          }
        }

        .delete-block {
          width: 36px;
          height: 36px;
          background-color: #fff;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          bottom: 0;
          right: -14px;
          z-index: 145;
          cursor: pointer;

          button {
            border: none;
            background: none;
          }
        }
      }

      .contact-pic-text {
        width: 94px;
        height: 94px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: right;
        position: relative;
        margin: 0 auto;
        background-color: #DEDEDE;
        background: url(../images/obsomailcontacts/newcon/Lightmode_del-empty-contacts.svg) no-repeat center center;

        .show.dropdown {
          .edit-block {
            background: #0288D9;

            img {
              filter: brightness(0) invert(1);
            }
          }
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 50%;
        }

        span {
          font-size: var(--clamp18);
          font-weight: 600;
          color: var(--white-color);
        }
      }
    }

    /*/contacts and Group User Pic right side CSS End*/
    .create-group-content {
      padding: 24px;
    }

    .create-group-bgcontent {
      padding: 24px 0px 100px 0;
    }

    .create-contact-maincontent {
      padding: 0 !important;

      .contact-group-user-pic-block {
        background-color: #F8F8F8 !important;
        padding: 24px 0 10px;
        margin-bottom: 24px;
      }

      .create-contact-bodycontent {
        padding: 24px;

        @media screen and (max-width: 640px) {
          padding-bottom: 0;
        }

        @media screen and (max-width: 480px) {
          padding: 24px 10px;
        }
      }
    }
  }

  .omail-links-email {
    margin-left: 0 !important;
    margin-right: 0 !important;

    .allow-email-switch {
      display: flex;
      align-items: center;
      margin-left: 20px;
      height: 60px;

      h3 {
        color: #003B93;
        font-weight: 400;
        font-size: var(--clamp14);
        margin-bottom: 6px;
      }

      .mat-slide-toggle-label {
        .mat-slide-toggle-bar {
          background-color: transparent;
          height: 20px;
          width: 36px;
          border-radius: 12px;
          border: 1px solid #A3A3A3;
        }
      }

      .mat-slide-toggle-thumb-container {
        top: 1px;
        left: 1px;

        .mat-slide-toggle-thumb {
          height: 16px;
          width: 16px;
          border-radius: 50%;
          display: block;
          background: #A3A3A3;
        }

        .mat-slide-toggle {
          .mat-checked {
            .mat-slide-toggle-thumb {
              background-color: #0288D9;
            }
          }
        }
      }

      .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
        background-color: #0288D9;
        border: 1px solid #0288D9;
      }

      .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
        background-color: #fff;
      }
    }
  }

  //Contacts ,Fav and Group Layout End
  //Contacts Left Tab CSS Start
  .contacts-tab {
    overflow: hidden;

    .mat-tab-label {
      min-width: auto;
      font-size: 12px !important;
      color: #0A2477;
      opacity: 1;
      padding: 0 14px;
    }

    .mat-tab-group.mat-primary .mat-ink-bar,
    .mat-tab-nav-bar.mat-primary .mat-ink-bar {
      border-bottom: 3px solid #4136F1;
    }

    .mat-tab-label-active {
      .mat-tab-label-content {
        font-style: normal;
        font-weight: 600;
        color: #4136F1;
      }
    }

    .mat-tab-body.mat-tab-body-active {
      overflow: hidden;
    }

    .mat-tab-label,
    .mat-tab-link {
      font-family: "Poppins", sans-serif;
    }
  }

  //Contacts Left Tab CSS End
  //Contacts Left header part CSS Start
  .contacts-header-block {
    .newcontactsadd-btn {
      align-items: center;
      width: 100%;
      gap: 12px;
      padding: 16px 12px;
      background-color: #fff;

      .custom-btn {
        font-size: 14px;
      }

      .create-btn {
        width: 290px;
        margin-right: 15px;

        @media screen and (max-width: 320px) {
          width: 100%;
          margin-bottom: 10px;
        }

        .omail-custom-btn {
          padding: 10px 22px;
        }
      }

      .d-flex {
        @media screen and (max-width: 320px) {
          display: block !important;
        }
      }
    }

    .contacts-search-box {
      width: 100%;
      position: relative;

      .search-box {
        position: relative;

        .search-filter-block {
          position: relative;

          .form-control {
            border: 1px solid #e7e4e4;
            border-radius: 4px !important;
            background-color: #fff;
            height: 44px;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            position: relative;
            width: 100%;
            padding-right: 60px;
          }

          .newcontact-search-btn {
            border: none;
            position: absolute;
            right: 12px;
            top: 12px;
            height: 26px;
            width: 26px;
            background: url(../../../assets/omail/images/obsomailcontacts/contact-search-icon.svg) no-repeat 0 0;
            z-index: 1100;
            cursor: pointer;
          }

          .close-btn {
            border: none;
            background: transparent;
            position: absolute;
            right: 40px;
            top: 10px;
            height: 26px;
            width: 26px;
            cursor: pointer;
            z-index: 1100;
          }
        }
      }
    }

    .contacts-action-links {
      .select-all-block {
        font-style: normal;
        font-weight: 400;
        font-size: var(--clamp16);
        color: #334554;
      }

      background: #f5f5f5;
      border-radius: 4px;
      padding: 8px 16px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 0 12px;
      border: 1px solid #E4E4E4;

      span {
        cursor: pointer;
        border-color: #A3A3A3;
        font-size: var(--clamp14);

        &:last-child {
          margin: 0;
        }

        img {
          filter: invert(74%) sepia(0%) saturate(0%) hue-rotate(160deg) brightness(89%) contrast(89%);

          &:hover {
            filter: invert(36%) sepia(86%) saturate(5024%) hue-rotate(185deg) brightness(104%) contrast(98%);
          }
        }
      }

      label {
        margin-bottom: 0 !important;
      }

      .mat-checkbox {
        font-family: "Poppins", sans-serif;
      }
    }

    @media screen and (min-width: 768px) and (max-width: 1023px) {
      .d-flex {
        display: block !important;

        .create-btn {
          width: 100%;

          .custom-btn {
            margin-bottom: 10px;
          }
        }
      }
    }
  }

  //Contacts Left header part CSS End
}

/*/ ||||| Contacts Module START |||||||||*/
//Contacts Left List CSS Start
.omail-list-of-contacts-block {
  margin: 0;
  height: calc(100vh - 194px);
  overflow: hidden;

  //Contacts Left List User CSS Start
  .pannel-main {
    padding-top: 12px;
    height: calc(100vh - 194px);
    overflow: hidden;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 4px !important;

      @media screen and (max-width: 540px) {
        width: 2px !important;
      }
    }

    &::-webkit-scrollbar-thumb {
      background: #DFF3FF;
      box-sizing: border-box;
      box-shadow: inset 0px 10px 10px rgba(255, 255, 255, 0.25);
      border-radius: 7px;
    }

    h6 {
      text-align: center;
      font-style: normal;
      font-weight: 600;
      font-size: var(--clamp16);
      color: var(--main-color);
    }

    .panner-content {
      padding: 12px;
      position: relative;
      cursor: pointer;
      border-top: 1px solid #ECECEC;
      border-left: 3px solid transparent;

      &.active {
        background: #F3F3F3;
        border-left: 3px solid #37AC00;

        .contacts-lable-block {
          span {
            color: #fff;
          }
        }

        &:hover {
          .favorites-icons {
            span {
              img:hover {
                filter: invert(64%) sepia(63%) saturate(454%) hue-rotate(330deg) brightness(135%) contrast(106%);
              }
            }
          }

          .contacts-lable-block {
            span {
              color: #464255;
            }
          }
        }
      }

      &:hover {
        background: #ECECEC;
        border-left: 3px solid #BEE49F;

        h4 {
          color: #334554 !important;
        }
      }

      .contact-img {
        width: 3.125vw;

        @media screen and (max-width:1200px) {
          width: 40px;
        }
      }

      .contact-text {
        padding: 0px 25px 0px 12px;
        width: 100vw;
        text-align: left;

        @media screen and (max-width: 1440px) {
          width: 80%;
        }

        @media screen and (max-width: 991px) {
          width: 75%;
        }

        @media screen and (max-width:1000px) {
          padding: 0px 0px 0px 12px;
        }

        h4 {
          margin-bottom: 5px;
          font-size: var(--clamp14);
          color: #30353D;
          font-weight: 400;
          word-break: break-all;
          font-family: "Poppins", sans-serif;
          max-width: 290px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;

          @media screen and (max-width: 1440px) {
            width: 100%;
          }
        }

        p {
          margin-bottom: 0px;
          font-size: var(--clamp14);
          color: #0A2477;
          font-weight: 400;
          word-break: break-all;
          max-width: 290px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;

          @media screen and (max-width: 1440px) {
            width: 100%;
          }
        }
      }

      .contactmail-text {
        p {
          margin-bottom: 0px;
          font-size: var(--clamp14);
          color: #006BCE;
          font-weight: 500;
          word-break: break-all;
          font-family: "Poppins", sans-serif;
        }
      }
    }
  }

  //Contacts Left List User CSS End
  //Contacts Left List User image with Checkmark CSS Start
  .contacts-rounded-block {
    display: block;
    position: relative;
    padding-left: 0px;
    margin-bottom: 0px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    .check-img-block {
      .contact-pic-text {
        width: 28px;
        height: 28px;
        border-radius: 6px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #DEDEDE;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        span {
          font-size: var(--clamp14);
          font-weight: 600;
          color: var(--white-color);
        }
      }

      input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
      }

      .check-mark {
        position: absolute;
        bottom: -2px;
        left: 5px;
        height: 24px;
        width: 24px;
        opacity: 0;
      }

      input:checked~.check-mark {
        background: url(../../../assets/omail/images/obsomailcontacts/checked.svg) no-repeat center center;
        opacity: 1;
        background-color: rgba(0, 0, 0, 0.4);
        left: 0;
        top: 0;
        width: 28px;
        height: 28px;
        border-radius: 6px;
      }
    }
  }

  //Contacts Left List User image CSS with Checkmark End
  .favorites-icons {
    display: flex;
    align-items: center;
    justify-content: end;
    width: 100%;
    gap: 8px;

    .contacts-lable-block {
      font-family: "Poppins", sans-serif;

      span {
        font-size: var(--clamp12);
        color: #464255;
      }
    }

    span {
      img {
        filter: invert(61%) sepia(84%) saturate(0%) hue-rotate(207deg) brightness(95%) contrast(77%);

        &:hover {
          filter: invert(64%) sepia(63%) saturate(454%) hue-rotate(330deg) brightness(135%) contrast(106%);
        }

        &.fav-active {
          filter: invert(64%) sepia(63%) saturate(454%) hue-rotate(330deg) brightness(135%) contrast(106%);
        }
      }
    }
  }
}

//Contacts Left List CSS End
//Contacts Right side Selected Multiple Contacts page CSS Start
.omail-selected-list-block {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 48px);
  background-color: #ffffff;

  .selected-list-inner {
    display: block;
    text-align: center;
    gap: 40px;

    h5 {
      font-style: normal;
      font-weight: 400;
      font-size: var(--clamp16);
      color: #003B93;
      margin: 18px 0 48px;
    }

    button {
      font-style: normal;
      font-weight: 400;
      font-size: var(--clamp14);
      color: #30353D;
      display: flex;
      align-items: center;
      margin: 0;
      padding: 0;
      margin-bottom: 16px;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }

      span {
        min-width: 30px;
      }

      img {
        padding: 0 5px 0 0;
      }
    }

    .omail-selected-list-main {
      height: 94px;
      background: url(../../../assets/omail/images/contacts/selected-listcontacts-icon.svg) no-repeat center center;

      &.omail-mulselected-group {
        height: 94px;
        background: url(../images/obsomailcontacts/newcon/Lightmode_del-empty-group.svg) no-repeat center center;
      }
    }
  }

  &.right-mailadd {
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
  }
}

.omail-selected-maillist-main {
  width: 95px;
  height: 94px;
  background: url(../../../assets/omail/images/mailbox/mail-circle-icon.svg) no-repeat center center;
  margin: 0 auto;
}

//Contacts Right side Selected Multiple Contacts page CSS End
.omail-restore-block {
  span {
    font-style: normal;
    font-weight: 600;
    font-size: var(--clamp12);
    color: #0A2477;
  }
}

/*/ ||||| Contacts Module END |||||||||*/
/*
  ***********************************
  * Omail App Contacts-Module CSS End
  ***********************************
*/
/*
  *************************************
  * Omail App Calendar-Module CSS Start
  **************************************
*/
/*/ ||||| Calendar Module START |||||||||*/
.omail-calendar {
  overflow: hidden;
  width: 100%;
  display: block;

  .calendar-left {
    border-radius: 8px 0px 0px 8px;
    padding: 12px;
    height: calc(100vh - 78px);
    overflow: hidden;
    overflow-y: hidden;
    background: var(--white-color);

    .custom-btn {
      border-radius: 6px;

      &.omail-create-calbtn-main {
        @media screen and (max-width: 767px) {
          display: none;
        }
      }

      &.omail-create-calbtn-mobile {
        display: none;

        @media screen and (max-width: 767px) {
          display: block;
        }
      }
    }

    .event-list-block {
      padding: 0;

      h3 {
        font-style: normal;
        font-weight: 400;
        font-size: var(--clamp14);
        margin-bottom: 12px;
        margin-top: 24px;
        color: #464255;

        @media screen and (max-width: 767px) {
          margin-top: 5px;
        }
      }

      .event-list {
        overflow: hidden;
        overflow-y: auto;
        height: calc(100vh - 450px);

        @media screen and (max-width: 991px) {
          height: auto;
          max-height: 300px;
          overflow-x: scroll;
          display: flex;
        }

        &::-webkit-scrollbar {
          width: 4px !important;

          @media screen and (max-width: 540px) {
            width: 2px !important;
          }
        }

        &::-webkit-scrollbar-thumb {
          background: #DFF3FF;
          box-sizing: border-box;
          box-shadow: inset 0px 10px 10px rgba(255, 255, 255, 0.25);
          border-radius: 7px;
        }

        li {
          .event-info-block {
            display: flex;
            gap: 6px;
            border-radius: 8px;
            padding: 12px;
            margin: 0 0 12px;

            @media screen and (max-width:991px) {
              margin: 0 5px 0px;
            }

            &.newevent-status {
              background: #E8F6F0;
            }

            &.cancelevent-status {
              background: #FDF3F3;
            }

            &.saveevent-status {
              background: #E8F3FF;
            }

            .event-status {
              margin: 0;
            }

            .event-action-links {
              margin: 0;
              cursor: pointer;
            }

            .event-more-info {
              width: 100%;

              .event-title-links {
                .event-title {
                  font-style: normal;
                  font-weight: 400;
                  font-size: var(--clamp14);
                  width: auto;
                  text-overflow: ellipsis;
                  overflow: hidden;
                  white-space: nowrap;
                  color: #0A2477;
                  white-space: nowrap;
                  width: auto;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  max-width: 344px;

                  @media screen and (max-width: 1440px) {
                    max-width: 220px;
                  }

                  @media screen and (max-width: 1366px) {
                    max-width: 200px;
                  }

                  @media screen and (max-width: 1280px) {
                    max-width: 140px;
                  }

                  @media screen and (max-width: 1024px) {
                    max-width: 120px;
                  }
                }
              }

              .event-locaion {
                font-weight: 400;
                font-size: var(--clamp12);
                text-transform: capitalize;
                width: auto;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                color: #464255;
                max-width: 344px;

                @media screen and (max-width: 1440px) {
                  max-width: 220px;
                }

                @media screen and (max-width: 1366px) {
                  max-width: 200px;
                }

                @media screen and (max-width: 1280px) {
                  max-width: 140px;
                }

                @media screen and (max-width: 1024px) {
                  max-width: 120px;
                }
              }

              .event-date-time {
                span {
                  img {
                    width: 12px;
                  }
                }

                font-style: normal;
                font-weight: 400;
                font-size: var(--clamp12);
                text-transform: capitalize;
                width: auto;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                color: #464255;

                @media screen and (min-width: 992px) and (max-width: 1024px) {
                  white-space: break-spaces;
                }
              }
            }
          }
        }
      }

      .upcomingevnts-mobile {
        @media screen and (max-width: 767px) {
          display: flex;
          align-items: center;
        }

        .omail-create-calbtn-mobile {
          @media screen and (max-width: 767px) {
            min-width: 40px;
            margin-right: 10px;
          }
        }
      }
    }

    .no-list-block {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      width: 100%;
      height: calc(100vh - 108px);
      flex-direction: column;

      @media screen and (max-width: 991px) {
        justify-content: flex-start;
      }

      .list-icon {
        margin: 0 0 26px;

        @media screen and (max-width: 991px) {
          display: none;
        }
      }

      .list-text {
        font-weight: 400;
        font-size: var(--clamp18);
        color: #2A3F51;
        margin: 0 0 26px;

        @media screen and (max-width: 991px) {
          margin-bottom: 10px;
        }
      }
    }

    .mat-calendar-body-label {
      opacity: 0;
      height: 0px;
      padding-top: 0 !important;
      padding-bottom: 0 !important;
    }

    .mat-calendar-content {
      min-height: 212px;

      tr {
        border-top: 1px solid transparent;
      }
    }
  }

  .calendar-right {
    border-radius: 0;
    height: calc(100vh - 78px);
    overflow: hidden;
    overflow-y: auto;
    padding: 0px 0;
    background: #fff;

    .day-view-block {
      .cal-day-view {
        .cal-week-view {
          background-color: var(--fill-color-4);
          border: none;

          .cal-time-events {
            border: 1px solid #FFFFFF;

            .cal-day-columns {
              .cal-day-column {
                .cal-hour {
                  background-color: #FFFFFF;
                  margin: 0;
                  border-top: 1px solid #ECECEC;
                  border-radius: 0;

                  &:last-child {
                    border-bottom: 1px solid #ECECEC;
                  }

                  &.cal-hour-odd {
                    background: #fff;
                    border-radius: 4px;
                  }

                  mwl-calendar-week-view-hour-segment {
                    margin-left: 60px;

                    &:first-child {
                      border-bottom: 1px dashed #ECECEC;
                    }
                  }
                }
              }
            }
          }

          .cal-time {
            font-style: normal;
            font-weight: 400;
            font-size: var(--clamp14);
            color: #A3A3A3;
            text-transform: capitalize;
            position: relative;
            left: -60px;
            top: 12px;
          }
        }

        .cal-week-view .cal-current-time-marker {
          background-color: transparent;
          background-color: #ea4334;
        }
      }

      .cal-week-view .cal-hour:not(:last-child) .cal-hour-segment,
      .cal-week-view .cal-hour:last-child :not(:last-child) .cal-hour-segment {
        border-bottom: transparent;
      }
    }

    .show-day-strip {
      background: #DFF3FF;
      color: #30353D;
      border-radius: 6px;
      padding: 7px 8px;
      position: sticky;
      top: 0;
      z-index: 1;
      margin-bottom: 16px;

      h3 {
        font-weight: 400;
        font-size: var(--clamp14);
        color: #30353D;
      }
    }

    .omail-tab-block {
      margin: 0;

      .tab-event {
        padding: 12px;

        .nav-tabs {
          border-bottom: none;

          .nav-link {
            font-weight: 400;
            font-size: var(--clamp14);
            color: #30353D;
            background: #F5F5F5;
            border: none;
            border-bottom: none;
            padding: 8px;
            border-radius: 6px;

            &:hover {
              background: #F1FAFF;
              border: none;
              border-bottom: none;
            }

            &.active {
              font-weight: 500;
              border: none;
              border-bottom: none;
              background: #9EDAFE;
              border-radius: 6px;
              color: #30353D;
              font-size: var(--clamp14);
              padding: 8px;

              span {
                img {
                  opacity: 0.7;
                }
              }
            }
          }

          li {
            padding-right: 2px;
          }
        }
      }

      .omail-appointment {
        &.calendar-hide {
          display: none;
        }

        .event-block {
          width: 100%;
          height: calc(100vh - 100px);
          overflow: hidden;
          padding: 0;

          .event-form-block {
            .pad-t {
              padding: 0;
            }

            width: 100%;
            height: calc(100vh - 100px);
            overflow: hidden;
            overflow-y: auto;

            @media screen and (max-width: 1440px) {
              height: calc(100vh - 175px);
            }

            &::-webkit-scrollbar {
              width: 4px !important;
              height: 4px !important;

              @media screen and (max-width: 540px) {
                width: 2px !important;
              }
            }

            &::-webkit-scrollbar-thumb {
              background: #DFF3FF;
              box-sizing: border-box;
              box-shadow: inset 0px 10px 10px rgba(255, 255, 255, 0.25);
              border-radius: 7px;
            }

            .event-info-textarea {
              padding: 0px 5px 0px 0px;

              @media screen and (max-width:1440px) {
                margin-bottom: 0;
              }

              textarea {
                background: #FFFFFF;
                border: 1px solid rgba(140, 130, 255, 0.2);
                border-radius: 8px;
                resize: none;
                min-height: 236px;
                margin: 12px 0px;
                font-style: normal;
                font-weight: 400;
                font-size: var(--clamp16);
                color: #334554;

                &::placeholder {
                  color: #9CA9B4;
                  font-size: var(--clamp16);
                }

                &:focus {
                  box-shadow: none;
                  border: none;
                }
              }

              .invalid-feedback {
                padding-left: 12px;
              }

              .fr-toolbar {
                .fr-top {
                  background: #F3F2FF;
                  border-radius: 8px 8px 0px 0px;
                }
              }

              .omail-customtheam-froala-block {
                .fr-basic {
                  .fr-toolbar {
                    border: none;
                    border-radius: 0;
                    padding: 0 6px;
                  }
                }
              }

              .omail-customtheam-froala-block {
                .fr-second-toolbar {
                  border: none;
                }

                .fr-box.fr-basic .fr-element {
                  overflow-y: auto;
                  height: calc(100vh - 550px);
                }
              }
            }

            .event-form-action-links {
              padding: 7px 12px;
              gap: 24px;
              bottom: 16px;
              z-index: 2;
              background-color: #ffffff;
              border-top: 1px solid #ECECEC;
              position: absolute;
              left: 0;
              right: 0;
              bottom: 33px;

              @media screen and (max-width: 991px) {
                width: 100%;
                position: inherit;
              }

              .custom-btn {
                background: #4136F1;
                border-radius: 8px;
                min-width: 32px;
                min-height: 32px;
                padding: 0;

                i {
                  margin: 0;
                }
              }

              .custom-btn-outline {
                min-width: 32px;
                min-height: 32px;
                height: 32px;
              }

              .custom-btn-link {
                border: none;
                background-color: transparent;
                margin-right: 12px;
                padding: 0;

                &:hover {
                  filter: invert(49%) sepia(85%) saturate(4431%) hue-rotate(180deg) brightness(93%) contrast(98%);
                }
              }

              .custom-btn-outline {
                min-height: auto;
                padding: 4px 14px 4px;
                border-radius: 8px;
                font-size: 14px;
                font-weight: 400;

                &.cancel-event-btn {
                  margin-left: 12px;
                  font-size: var(--clamp14);
                }
              }

              .cal-event-form {
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;

                .omail-cal-rght-btns {
                  button {
                    padding: 8px 24px 9px;
                  }
                }
              }
            }

            .mat-form-field-outline {
              background: #FFFFFF;
              border-radius: 8px;
              color: rgba(230, 227, 254, 1);
            }

            .mat-form-field-appearance-outline .mat-form-field-infix {
              padding: 6px 0;
              border-top: 4px solid transparent;
            }

            .mat-select-arrow {
              color: #fff;
            }

            .mat-form-field-appearance-legacy .mat-form-field-underline {
              background-color: transparent;
            }

            .mat-form-field-appearance-outline .mat-form-field-wrapper {
              margin: 0;
              padding: 0;
            }

            .mat-form-field-appearance-outline {
              .mat-form-field-flex {
                color: #30353D;
                font-size: var(--clamp14);
                width: 140px;
              }
            }

            .mail-field {
              display: flex;
              align-items: center;
              margin: 0;
              padding: 0 50px 0 12px;
              background: var(--white-color);
              border-top: solid 1px #E6E6E6;

              @media screen and (max-width: 480px) {
                padding-left: 0;
                padding-right: 4px;
              }

              .field-1 {
                h3 {
                  font-style: normal;
                  font-weight: 400;
                  font-size: var(--clamp14);
                  color: #A3A3A3;
                  margin: 0;
                }
              }

              .field-2 {
                display: flex;
                align-items: center;
                width: 100%;

                img {
                  cursor: pointer;
                }

                .event-to-block {
                  display: flex;
                  width: 100%;
                  align-items: center;

                  .mat-chip-list-wrapper {
                    margin: 0px;
                  }

                  .mat-form-field-appearance-legacy .mat-form-field-infix {
                    border: 0;
                    padding: 0;
                  }

                  .mat-form-field-appearance-legacy .mat-form-field-wrapper {
                    padding: 0;
                  }

                  input.mat-input-element {
                    height: 40px;
                    margin: 0 !important;
                    padding-left: 6px;
                    -webkit-appearance: none;
                  }

                  .mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
                  .mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
                    background-color: transparent;
                  }

                  .mat-form-field.mat-focused .mat-form-field-ripple {
                    background-color: transparent;
                    opacity: 0;
                  }

                  .omail-clip-block {
                    overflow: hidden;
                    width: 100%;
                    min-height: 40px;
                    max-height: 60px;
                    overflow-y: auto;
                  }

                  .omail-emailmorelist-dropdown {
                    border: none;
                    box-shadow: 0px 8px 17px rgb(0 0 0 / 30%);
                    padding: 0;
                    left: -223px !important;

                    .omail-morelist-header {
                      background: #DFF3FF;
                      border-radius: 8px 8px 0px 0px;
                      padding: 8px 16px;
                      display: flex;
                      align-items: center;
                      justify-content: space-between;
                      color: #30353D;

                      h5 {
                        font-size: 14px;
                      }

                      span {
                        background: #FFFFFF;
                        box-shadow: 0px 4px 4px rgb(63 89 114 / 20%);
                        border-radius: 20px;
                        width: 20px;
                        height: 20px;
                        text-align: center;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        cursor: pointer;

                        &:hover {
                          background-color: #E84C3D;

                          img,
                          img:hover {
                            filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(269deg) brightness(150%) contrast(101%);
                          }
                        }
                      }
                    }

                    .event-more-mail-list {
                      min-height: 100px;
                      max-height: 300px;
                      overflow-y: hidden;
                      height: auto !important;

                      &:hover {
                        background-color: transparent !important;
                      }

                      .omail-clip-block {
                        max-height: 256px;
                        padding-left: 6px;
                        padding-right: 6px;

                        .mail-text {
                          white-space: nowrap;
                          max-width: 150px;
                          overflow: hidden;
                          text-overflow: ellipsis;
                        }
                      }
                    }
                  }
                }

                .location-block {
                  width: 100%;
                  position: relative;

                  input {
                    width: 100%;
                    border: none;
                    font-weight: 400;
                    font-size: var(--clamp14);
                    color: #334554;
                    padding: 0 0 0 6px;
                    outline: 0;
                    height: 40px;

                    &::placeholder {
                      font-weight: 400;
                      font-size: var(--clamp14);
                      color: #334554;
                    }
                  }

                  .invalid-feedback {
                    padding-left: 6px;
                    position: absolute;
                    bottom: -5px;
                  }
                }

                &.reminder-field {
                  padding: 0 0 0 12px;
                  margin-top: 10px;
                  margin-bottom: 10px;

                  .custom-select {
                    background: url(../../omail/images/contacts/adressbook-select.png) no-repeat 94% center;
                    font-style: normal;
                    font-weight: 400;
                    font-size: var(--clamp14);
                    border: 1px solid #DEDEDE;
                    border-radius: 4px;
                    height: 32px;
                    width: 124px;
                    color: #0A2477;
                  }
                }
              }

              .field-3 {
                img {
                  cursor: pointer;
                }
              }

              .subject-block {
                input {
                  border: none;
                  font-style: normal;
                  font-weight: 400;
                  font-size: var(--clamp14);
                  padding: 0;
                  outline: 0;
                  color: #334554;
                  height: 40px;
                }

                .invalid-feedback {
                  padding-left: 0px;
                  position: absolute;
                  bottom: -5px;

                  @media screen and (max-width: 414px) {
                    font-size: 10px;
                    bottom: -3px;
                  }

                  @media screen and (max-width: 330px) {
                    font-size: 9px;
                  }
                }
              }
            }

            hr {
              border-top: 1px solid #D7DDE1;
              margin: 10px 0;
            }

            .to-hr {
              margin: 0 0 10px 0;
            }

            .omail-form-field {
              display: flex;
              align-items: center;
              justify-content: space-between;
              border-top: 1px solid #E6E6E6;
              padding-left: 12px;

              .omail-col-one {
                display: flex;
                align-items: center;
                width: 100%;

                h3 {
                  font-style: normal;
                  font-weight: 400;
                  font-size: 14px;
                  color: #A3A3A3;
                  margin: 0 8px 0 0;
                  padding: 4px 10px;
                  min-width: 46px;
                  text-align: center;
                }

                input {
                  border: none !important;
                  font-style: normal;
                  font-weight: 400;
                  font-size: 14px;
                  color: #30353D;
                  width: 100%;
                  outline: none;
                  padding-left: 0;
                }

                .mat-chip-list-wrapper {
                  overflow: auto;
                  max-height: 60px;
                  position: relative;
                  top: 6px;

                  &::-webkit-scrollbar {
                    width: 4px !important;
                  }

                  &::-webkit-scrollbar-thumb {
                    background: #DFF3FF;
                    box-sizing: border-box;
                    box-shadow: inset 0px 10px 10px rgba(255, 255, 255, 0.25);
                    border-radius: 7px;
                  }
                }
              }

              .omail-col-two {
                display: flex;
                align-items: center;
                white-space: pre;
                gap: 4px;
                margin-left: 3px;
                padding-right: 12px;

                .contacts-user-add {
                  cursor: pointer;
                }
              }

              h3 {
                font-size: 14px;
                color: #a3a3a3;
              }
            }

            form {
              height: 100%;
            }
          }

          .event-duration-block {
            padding: 12px 12px;
            border-top: 1px solid #E6E6E6;
            border-bottom: 1px solid #E6E6E6;

            @media screen and (max-width: 650px) {
              overflow: hidden;
              overflow-x: auto;
              width: 96%;

              &::-webkit-scrollbar {
                width: 4px !important;
                height: 4px !important;

                @media screen and (max-width: 540px) {
                  width: 2px !important;
                }
              }

              &::-webkit-scrollbar-thumb {
                background: #DFF3FF;
                box-sizing: border-box;
                box-shadow: inset 0px 10px 10px rgba(255, 255, 255, 0.25);
                border-radius: 7px;
              }
            }

            .event-duration-fields {
              display: flex;
              align-items: center;
              gap: 4px;
              margin: 0 0 7px;

              h3 {
                font-style: normal;
                font-weight: 400;
                font-size: var(--clamp14);
                color: #A3A3A3;
                margin: 0;
                width: 40px
              }

              .ed-f-1 {
                width: 80px;
              }

              .ed-f-2 {
                display: flex;
                align-items: center;
                gap: 4px;

                .mat-form-field-infix {
                  width: 135px;

                  .event-icon {
                    img {
                      opacity: 0.6;
                    }
                  }
                }

                .mat-form-field {
                  @media screen and (max-width: 375px) {
                    width: 100%;
                  }
                }

                .mat-form-field-infix {
                  input {
                    margin-top: 2px;
                  }
                }
              }

              .ed-f-3 {
                .mat-form-field-infix {
                  width: 135px;
                }

                .timer-field {
                  padding: 0 0 0 12px;
                  position: relative;

                  @media screen and (max-width: 480px) {
                    padding-left: 0;
                    display: inline-block;
                  }

                  .custom-select {
                    font-weight: 400;
                    font-size: var(--clamp14);
                    border-radius: 4px;
                    height: 32px;
                    width: 118px;
                    color: #30353D;
                    border: 1px solid #E6E3FE;
                    background: var(--white-color);
                    z-index: 1;
                    position: relative;
                    background: none;
                  }

                  .cal-time-icon {
                    position: absolute;
                    left: 103px;
                    top: 4px;
                    z-index: 0;
                  }
                }
              }

              .ed-f-4 {
                width: 100%;

                .omail-all-days-block {
                  .mat-slide-toggle-label {
                    .mat-slide-toggle-bar {
                      background-color: transparent;
                      height: 20px;
                      width: 32px;
                      border-radius: 12px;
                      border: 1px solid #A3A3A3;
                    }
                  }

                  .mat-slide-toggle-thumb-container {
                    top: 1px;
                    left: 2px;

                    .mat-slide-toggle-thumb {
                      height: 16px;
                      width: 16px;
                      border-radius: 50%;
                      display: block;
                      background: #A3A3A3;
                    }
                  }

                  .all-day {
                    color: #464255;
                    font-size: var(--clamp14);
                    font-weight: 400;
                    margin-left: 12px;
                    margin-right: 10px;
                    white-space: nowrap;

                    @media screen and (max-width: 375px) {
                      margin-left: 0;
                    }
                  }

                  .mat-slide-toggle.mat-checked {
                    .mat-slide-toggle-bar {
                      background-color: #0288D9;
                      border: 1px solid #0288D9;
                    }
                  }

                  .mat-slide-toggle-thumb-container {
                    .mat-slide-toggle-thumb {
                      background-color: #A3A3A3;
                    }
                  }

                  .mat-slide-toggle.mat-checked {
                    .mat-slide-toggle-thumb {
                      background-color: #fff;
                    }
                  }

                  .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb-container {
                    top: 1px;
                    left: -3px;
                  }
                }
              }

              .end-date-main {
                .mat-error {
                  position: absolute;
                  bottom: -8px;

                  .mat-error {
                    font-size: 0.875em;
                    color: #dc3545;
                  }
                }
              }
            }
          }

          .week-days {
            margin-left: 12px;

            @media screen and (max-width: 375px) {
              margin-left: 0;
            }

            ul {
              display: flex;
              align-items: center;
              gap: 4px;

              li {
                position: relative;

                input {
                  display: none;
                  width: 32px;
                  height: 32px;
                  border-radius: 4px;

                  &:checked+label {
                    background: #9EDAFE;
                  }
                }

                label {
                  width: 29px;
                  height: 29px;
                  border-radius: 4px;
                  color: #30353D;
                  font-style: normal;
                  font-weight: 400;
                  font-size: var(--clamp12);
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  cursor: pointer;
                  background: none;
                  border: 1px solid #9EDAFE;

                  @media screen and (max-width: 640px) {
                    width: 26px;
                    height: 26px;
                  }
                }
              }
            }
          }
        }

        .event-attachment-block {
          border-top: solid 1px #E6E6E6;

          ul {
            padding: 0 50px 0 10px !important;

            &::-webkit-scrollbar {
              width: 4px !important;
              height: 4px !important;

              @media screen and (max-width: 540px) {
                width: 2px !important;
              }
            }

            &::-webkit-scrollbar-thumb {
              background: #DFF3FF;
              box-sizing: border-box;
              box-shadow: inset 0px 10px 10px rgba(255, 255, 255, 0.25);
              border-radius: 7px;
            }

            li {
              display: inline-flex;
              align-items: center;
              cursor: pointer;
              margin: 0 16px 0 0;
              line-height: 2rem;

              .attach-file-info {
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                color: #30353D;
              }

              button {
                padding: 0;
                border: none;
                background: transparent;
              }

              .attach-icon {
                img {
                  width: 70%;
                }
              }
            }
          }
        }

        .subject-block {
          width: 100%;
          position: relative;

          input {
            width: 100%;
            border: none;
            font-style: normal;
            font-weight: 600;
            font-size: var(--clamp18);
            color: #0A2477;
            padding: 0 0 0 6px !important;
          }

          .invalid-feedback {
            padding-left: 6px;
          }
        }

        .omail-clip-block {
          overflow: hidden;
          width: 100%;
          min-height: 41px;
          max-height: 60px;
          overflow-y: auto;

          &::-webkit-scrollbar {
            width: 4px !important;
            height: 4px !important;

            @media screen and (max-width: 540px) {
              width: 2px !important;
            }
          }

          &::-webkit-scrollbar-thumb {
            background: #DFF3FF;
            box-sizing: border-box;
            box-shadow: inset 0px 10px 10px rgba(255, 255, 255, 0.25);
            border-radius: 7px;
          }

          .mat-form-field-infix {
            margin: 0;
            padding: 0;
          }

          .mat-form-field-wrapper {
            padding-bottom: 0;
          }

          .mat-form-field-underline {
            .mat-form-field-ripple {
              height: 0;
            }
          }
        }

        .calemail-more-list-block {
          .omail-emailmorelist-dropdown {
            border: none;
            box-shadow: 0px 8px 17px rgb(0 0 0 / 30%);
            padding: 0;

            .omail-morelist-header {
              background: #DFF3FF;
              border-radius: 8px 8px 0px 0px;
              padding: 8px 16px;
              display: flex;
              align-items: center;
              justify-content: space-between;
              color: #30353D;

              h5 {
                font-size: 14px;
              }

              span {
                background: #FFFFFF;
                box-shadow: 0px 4px 4px rgb(63 89 114 / 20%);
                border-radius: 20px;
                width: 20px;
                height: 20px;
                text-align: center;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;

                &:hover {
                  background-color: #E84C3D;

                  img,
                  img:hover {
                    filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(269deg) brightness(150%) contrast(101%);
                  }
                }
              }
            }

            .omail-clip-block {
              max-height: 256px;
              padding: 6px;
              overflow-y: auto;

              .mail-text {
                margin: 0;
              }

              &::-webkit-scrollbar {
                width: 4px !important;
              }

              &::-webkit-scrollbar-thumb {
                background: #DFF3FF;
                box-sizing: border-box;
                box-shadow: inset 0px 10px 10px rgba(255, 255, 255, 0.25);
                border-radius: 7px;
              }
            }
          }
        }
      }
    }
  }

  .pad-right {
    padding-right: 0;

    @media screen and (max-width:992px) {
      padding-right: calc(var(--bs-gutter-x) * .5);
      margin-top: 0;
    }
  }

  .pad-left {
    padding-left: 0;

    @media screen and (max-width:992px) {
      padding-left: calc(var(--bs-gutter-x) * .5);
    }
  }

  .calendar-left-side {
    width: 13.3%;
  }

  .mat-calendar {
    .mat-calendar-header {
      background-color: #DFF3FF;
      padding-top: 0;

      .mat-calendar-controls {
        margin-top: 0;
      }

      .mat-calendar-controls {
        color: #003B93;
        font-size: var(--clamp14);

        svg {
          fill: #003B93;
        }
      }
    }
  }

  .mat-calendar-table {
    th {
      color: #003B93;
      font-size: 12px;
      font-weight: 500;
    }

    .mat-calendar-table-header-divider {
      display: none;
    }

    .mat-calendar-body-cell-content {
      color: #30353D;
    }

    .mat-calendar-body-today {
      background-color: #F0F9FF !important;
      border-radius: 4px;
      color: #006BCE;
      font-size: 12px;
      border-color: transparent;
      font-weight: 400;
    }
  }
}

// event-calendar-start//
.omail-event-calendar {
  padding: 0 16px 0 16px;
  background-color: transparent;

  .omail-month-view {
    .event-list {
      li {
        .event-info-block {
          margin: 0;
          line-height: 1.2rem;
          display: flex;

          .event-status {
            margin-right: 10px;

            @media screen and (max-width: 1440px) {
              margin-right: 0;
            }

            .calendar-dot {
              width: 6px;
              height: 6px;
              border-radius: 50%;
              display: none;

              @media screen and (max-width: 640px) {
                display: block;
              }

              &.green-dot-mobile {
                background-color: #37AC00;
              }

              &.red-dot-mobile {
                background-color: #E84C3D;
              }

              &.blue-dot-mobile {
                background-color: #009CD9;
              }
            }

            img {
              @media screen and (max-width: 640px) {
                display: none;
              }
            }
          }

          .event-more-info {
            @media screen and (max-width: 640px) {
              display: none;
            }
          }

          .event-action-links {
            @media screen and (max-width: 640px) {
              display: none;
            }
          }

          .event-title-links {
            margin-bottom: 2px;

            @media screen and (max-width: 991px) {
              margin-bottom: 0;
            }
          }

          .event-title {
            color: #0A2477;
            font-weight: 400;
            font-size: var(--clamp14);
            max-width: 112px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;

            @media screen and (max-width: 1440px) {
              max-width: 72px;
            }

            @media screen and (max-width: 991px) {
              max-width: 48px;
            }
          }

          .event-locaion {
            color: #464255;
            font-weight: 400;
            font-size: var(--clamp14);
            margin-bottom: 7px;
            max-width: 112px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;

            @media screen and (max-width: 1440px) {
              max-width: 72px;
            }

            @media screen and (max-width: 991px) {
              font-size: 10px;
              margin-bottom: 0;
              line-height: 16px;
              max-width: 49px;
            }
          }

          .event-date-time {
            color: #A3A3A3;
            font-weight: 400;
            font-size: var(--clamp12);
            line-height: 16px;
          }
        }
      }
    }

    .cal-month-view {
      background-color: #fff;
      border: none;

      .cal-header {
        gap: 4px;

        .cal-cell {
          border-radius: 6px;
          font-size: var(--clamp14);
          font-weight: 400;
          max-width: auto;
          min-width: auto;
          background: #F5F5F5;
          color: #30353D;
          padding: 7px 8px;

          &.cal-today {
            background: #DFF3FF;
          }

          &:hover {
            background: #DFF3FF;
          }

          @media screen and (max-width: 1024px) {
            max-width: initial;
            min-width: auto;
          }

          @media screen and (max-width: 682px) {
            max-width: 64px;
            min-width: 64px;
          }
        }
      }

      .cal-days {
        border: none;

        .cal-cell-row {
          .cal-day-cell {
            border: none;
            border-radius: 0;
            min-height: 150px;
            max-height: 150px;
            max-width: auto;
            min-width: auto;
            position: relative;
            margin: 2px;

            &:hover {
              background-color: #F5FBFF;
            }

            @media screen and (max-width: 1024px) {
              max-width: initial;
              min-width: auto;
            }

            @media screen and (max-width: 682px) {
              max-width: 64px;
              min-width: 64px;
            }

            .cal-cell-top {
              .cal-day-number {
                float: left;
                margin: 15px 0 15px 5px;
                font-weight: 400;
                font-size: 12px;
                color: #30353D;
                opacity: 1;
                font-style: normal;
                font-weight: 400;
                font-size: 14px;

                @media screen and (max-width: 320px) {
                  margin: 15px 0;
                }
              }
            }

            &.cal-today {
              .cal-day-number {
                color: #30353D !important;
                font-style: normal;
                font-weight: 500 !important;
                font-size: 20px;

                @media screen and (max-width: 320px) {
                  margin: 15px 0;
                }
              }
            }
          }
        }
      }

      .cal-day-badge {
        visibility: hidden;

        @media screen and (max-width: 480px) {
          margin: 0;
        }
      }
    }
  }

  .d-w-m-tab {
    display: flex;
    align-items: center;
    gap: 10px;
    margin: 20px 0;
  }

  .custom-btn-outline {
    &.active {
      border: none;
      color: var(--white-color);
      background: var(--primary-btn-bg);
    }
  }

  .short-view-cal {
    background: transparent;
    display: flex;
    align-items: center;

    @media screen and (max-width: 640px) {
      justify-content: center;
      margin-bottom: 10px;
    }

    @media screen and (max-width: 460px) {
      display: block;
    }

    .s-v-cal-text {
      font-weight: 500;
      font-size: var(--clamp18);
      color: #30353D;
      margin-left: 10px;

      ngb-datepicker {
        top: 28px !important;
        left: -58px !important;
        background: #FFFFFF !important;
        border-radius: 8px !important;
        border: none !important;
        box-shadow: 0 3px 10px rgb(0 0 0 / 0.2) !important;

        .ngb-dp-header {
          .custom-select {
            background: none;
            border-left: none;
            border-radius: 4px;
            background: url(../../../assets/omail/images/contacts/adressbook-select.png) no-repeat 88% center;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            color: var(--text-color-2);
            border: none;
            text-transform: capitalize;
          }

          .ngb-dp-arrow-btn {
            span {
              border-width: 2px 2px 0 0 !important;
              color: #334272 !important;
            }
          }
        }

        .ngb-dp-months {
          .ngb-dp-month {
            ngb-datepicker-month {
              .ngb-dp-weekdays {
                border: none !important;
                margin: 10px 0;

                .ngb-dp-weekday {
                  font-style: normal !important;
                  font-weight: 600 !important;
                  font-size: var(--clamp14) !important;
                  color: #0A2477 !important;
                  background: #E8F3FF !important;
                }
              }

              .ngb-dp-week {
                .text-muted {
                  font-style: normal !important;
                  font-weight: 400 !important;
                  font-size: var(--clamp14) !important;
                  color: #000000 !important;
                }

                .btn-light {
                  font-style: normal !important;
                  font-weight: 400 !important;
                  font-size: var(--clamp14) !important;
                  color: #19181A !important;

                  &:hover {
                    background: #E8F3FF !important;
                  }
                }

                .bg-primary {
                  background: linear-gradient(180deg, #3B82F6 0%, #1D4ED8 100%) !important;
                  border-radius: 4px !important;
                  font-style: normal !important;
                  font-weight: 600 !important;
                  font-size: var(--clamp14) !important;
                  color: #FFFFFF !important;
                  outline: none !important;
                }
              }
            }
          }
        }
      }
    }

    .arrows {
      .btn-outline {
        padding: 5px 12px 4px;
        background: #006BCE;
        border-radius: 6px;
        border: 1px solid transparent;
        color: #ffffff;
        font-weight: 400;
        font-size: var(--clamp14);
        margin-right: 15px;

        &:hover {
          background: #0288D9;
          border: 1px solid transparent;
        }
      }

      .btn {
        img {
          filter: invert(65%) sepia(99%) saturate(0%) hue-rotate(45deg) brightness(80%) contrast(87%);

          &:hover {
            filter: invert(32%) sepia(90%) saturate(1424%) hue-rotate(179deg) brightness(97%) contrast(101%);
          }
        }

        &:hover {
          border-color: transparent;
        }
      }
    }
  }

  .dwm-block {
    width: 100%;
    overflow: auto;

    &::-webkit-scrollbar {
      width: 4px !important;
      height: 4px !important;

      @media screen and (max-width: 540px) {
        width: 2px !important;
      }
    }

    &::-webkit-scrollbar-thumb {
      background: #DFF3FF;
      box-sizing: border-box;
      box-shadow: inset 0px 10px 10px rgba(255, 255, 255, 0.25);
      border-radius: 7px;
    }

    @media screen and (max-width:992px) {
      overflow: auto;
      overflow-x: scroll;
    }

    @media screen and (max-width:640px) {
      height: 100%;
    }
  }

  .week-view-block {
    .cal-week-view {
      background-color: #fff;
      border: none;

      .cal-day-headers {
        border: none;
        background: transparent;
        gap: 4px;
        position: sticky;
        top: 0;
        z-index: 1;

        .cal-header {
          border: none;
          background: #F5F5F5;
          border-radius: 6px;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-wrap: wrap;

          &.cal-today {
            background: #DFF3FF;
          }

          b {
            font-weight: 400;
            font-size: var(--clamp14);
            color: #30353D;

            @media screen and (max-width:630px) {
              white-space: nowrap;
              width: 13px;
              overflow: hidden;
              letter-spacing: 8px;
              text-align: center;
            }
          }

          span {
            display: none;
            font-weight: 400;
            font-size: var(--clamp14);
            opacity: 1;
            padding-left: 5px;
            color: #30353D;

            @media screen and (max-width:630px) {
              display: none;
            }
          }

          &.cal-weekend {
            span {
              color: #30353D;
              opacity: 1;
            }
          }
        }
      }
    }

    .cal-time {
      font-style: normal;
      font-weight: 400;
      font-size: var(--clamp14);
      color: #A3A3A3;
      text-transform: capitalize;
    }

    .cal-week-view .cal-hour:not(:last-child) .cal-hour-segment,
    .cal-week-view .cal-hour:last-child :not(:last-child) .cal-hour-segment {
      border: 0.5vh;
    }

    .cal-week-view .cal-day-column {
      border: 1px solid #ECECEC;
    }

    .cal-week-view .cal-time-events {
      border: none;
      margin-top: 16px;
    }

    .cal-week-view .cal-current-time-marker {
      background-color: transparent;
      background-color: #ea4334;
    }

    .cal-day-columns {
      border: 1px solid #ECECEC;
      border-top: none;
      border-bottom: none;
    }

    .cal-hour {
      border-top: 1px solid #ECECEC;

      &:last-child {
        border-bottom: 1px solid #ECECEC;
      }

      mwl-calendar-week-view-hour-segment {
        &:first-child {
          border-bottom: 1px dashed #ECECEC;
        }
      }
    }

    .cal-time {
      padding-top: 20px;
    }

    .cal-time-events {
      .cal-time-label-column {
        .cal-hour {
          mwl-calendar-week-view-hour-segment {
            &:first-child {
              border-bottom: none;
            }
          }
        }
      }
    }
  }

  .omail-date-week-month {
    @media screen and (max-width: 640px) {
      display: block !important;
    }
  }

  button {
    @media screen and (max-width: 360px) {
      min-width: 76px;
    }
  }
}

.omail-event-list {
  .event-info-block {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;

    .left-event {
      display: flex;

      .event-more-info {
        .event-title-links {
          .event-title {
            color: #0A2477;
            font-weight: 400;
            font-size: var(--clamp14);
          }
        }

        .event-locaion {
          color: #464255;
          font-weight: 400;
          font-size: var(--clamp14);
        }

        .event-date-time {
          color: #A3A3A3;
          font-weight: 400;
          font-size: var(--clamp12);
          line-height: 12px;
        }
      }
    }
  }
}

.omail-showmore-event-link {
  justify-content: end;
  flex: 1;
  align-items: center;
  margin: 0 0;
  flex-wrap: wrap;
  display: flex;
  margin-right: 0;

  .showmore {
    display: flex;
    font-style: normal;
    font-weight: 400;
    font-size: var(--clamp14);
    color: #1877F2;
    cursor: pointer;

    @media screen and (max-width: 640px) {
      font-size: 10px;
    }

    @media screen and (max-width: 480px) {
      display: block;
    }

    &:hover {
      color: #1877F2;
      opacity: 0.5;
    }

    .more-no {
      margin-right: 7px;
    }

    .show-arrow {
      img {
        margin-top: -3px;

        @media screen and (max-width: 640px) {
          width: 5px;
        }
      }
    }

    .more-show {
      margin-left: 7px;
      margin-right: 7px;
    }
  }
}

.omail-drop-down-menu {
  padding: 0;
  background: transparent;
  border: none;
  min-width: auto;

  .dropdown-item {
    background: var(--white-color);
    border-radius: 6px;
    color: var(--primary-color);
    border: 1px solid var(--primary-color);
    font-size: var(--clamp14);
    font-weight: 400;
    height: 40px;
  }
}

// end //
.omail-event-modal-link {
  span {
    &::after {
      display: none;
    }
  }
}

.omail-event-list-one {
  height: auto;
  width: 100%;

  @media screen and (max-width: 480px) {
    margin-top: 14px !important;
  }

  li {
    margin: 0 0 4px;
    cursor: pointer;

    @media screen and (max-width: 991px) {
      margin-bottom: 0;
    }

    .event-info-block {
      gap: 2px;
      padding: 0;
      width: 99%;
    }
  }
}

/* / ||||| Calendar Module END |||||||||*/
/*
  ***********************************
  * Omail App Calendar-Module CSS End
  ***********************************
*/
/*
  **************************************
  * Omail App Settings-Module CSS Start
  *************************************
*/
.omailapp-settings-wrap {
  overflow: hidden;
  width: 100%;
  display: flex;
  padding: 0 0px 18px 0;

  .omail-side-settings {
    border-radius: 8px 0px 0px 8px;
    border-right: 1px solid #DEDEDE;
    padding: 12px;
    height: calc(100vh - 77px);
    overflow: hidden;
    overflow-y: auto;
    background: #fff;
    transition: 0.4s;
    z-index: 999;
    padding-top: 124px;
    width: 240px;

    @media screen and (max-width: 1024px) {
      position: relative;
      z-index: 2;
    }

    &::-webkit-scrollbar {
      width: 4px !important;
    }

    &::-webkit-scrollbar-thumb {
      background: #DFF3FF;
      box-sizing: border-box;
      box-shadow: inset 0px 10px 10px rgba(255, 255, 255, 0.25);
      border-radius: 7px;
    }

    @media screen and (max-width: 767px) {
      border-radius: 8px;
      margin-top: 15px;
      box-shadow: 2px 2px 15px rgb(0, 0, 0, 0.2);
      z-index: 3;
      padding-top: 75px;
    }

    ul.sidenav-box {
      li {
        &.active {
          border-radius: 6px;
          font-size: var(--clamp14);
          font-weight: 400;
          cursor: pointer;
        }
      }

      li.list {
        margin-bottom: 4px;
        cursor: pointer;

        a {
          display: block;
          color: #30353D;
          font-weight: 400;
          font-size: var(--clamp14);
          padding: 10px 12px;
          border-radius: 6px;

          .settings-side-img {
            width: 20px;
            text-align: center;
            margin-right: 8px;
          }

          &:hover {
            background: #DFF3FF;
            color: #30353D;
          }

          @media screen and (max-width:960px) {
            padding: 10px 10px;
          }
        }
      }

      li {
        &.active {
          a {
            font-weight: 500;
            color: #006BCE;
            background: #BFE7FE;

            img {
              filter: invert(22%) sepia(67%) saturate(4594%) hue-rotate(186deg) brightness(102%) contrast(82%);
            }

            &:hover {
              background: #BFE7FE;
              color: #006BCE;
            }
          }
        }
      }
    }

    .sidenav-box {
      li {
        display: block;
      }
    }
  }

  .omail-settings-tab-content {
    border-radius: 0;
    height: calc(100vh - 77px);
    overflow: hidden;
    overflow-y: auto;
    padding: 24px 24px 0 24px;
    background: #fff;
    position: relative;

    @media screen and (max-width: 1024px) {
      padding-left: 90px;
    }

    @media screen and (max-width: 640px) {
      padding-left: 80px;
    }

    @media screen and (max-width: 383px) {
      padding-left: 70px;
      padding-right: 15px;
    }

    &::-webkit-scrollbar {
      width: 4px !important;

      @media screen and (max-width: 540px) {
        width: 2px !important;
      }
    }

    &::-webkit-scrollbar-thumb {
      background: #DFF3FF;
      box-sizing: border-box;
      box-shadow: inset 0px 10px 10px rgba(255, 255, 255, 0.25);
      border-radius: 7px;
    }

    .edit-user {
      background: #F8F8F8;
      border-radius: 12px;
      padding: 24px 0;
      margin-top: 4px;
      margin-left: 4px;
      margin-right: 4px;

      .setting-pic-block {
        .pic-edit {
          width: 94px;
          height: 94px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          margin: 0 auto;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }

          .pic-block {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            width: 100%;
            height: 100%;
            border-radius: 50%;
            transition: 0.3s ease;
            cursor: pointer;

            .pic-icon {
              width: 40px;
              height: auto;
              object-fit: inherit;
              border-radius: 0;
              position: absolute;
              bottom: -32px;
              transform: translate(85%, -62%);
              text-align: center;
              border-radius: 50%;
              padding: 8.5px 6.5px;
              overflow: hidden;
              background: #F3F2FF;
              right: 25px;
            }
          }
        }
      }
    }

    .slide-up,
    .slide-down {
      overflow: hidden;
    }

    .slide-up form,
    .slide-down form {
      transform: translateY(-100%);
      transition: .4s ease-in-out;
      opacity: 0;
    }

    .slide-down form {
      transform: translateY(0);
      opacity: 1;
    }

    .omail-setting-form {
      margin: 10px 0 0;

      @media screen and (max-width: 767px) {
        margin-bottom: 30px;
      }

      .form-group {
        margin-bottom: 1rem;
      }

      label {
        font-style: normal;
        font-weight: 400;
        font-size: var(--clamp12);
        color: #6D747A;
        margin-bottom: 2px;
      }

      .form-control {
        background: #FFFFFF;
        box-shadow: 0px 1px 2px rgba(77, 64, 85, 0.1);
        border-radius: 4px;
        height: 40px;
        padding: 9.5px 12px;
        font-size: var(--clamp14);
        font-weight: 400;
        color: #30353D;
        border: none;

        &::placeholder {
          color: #0A2477;
          font-size: var(--clamp14);
          font-weight: 400;
        }

        &:disabled {
          border: 1px solid #5EC2FE;
          cursor: no-drop;
        }
      }

      .text-muted {
        position: absolute;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        color: #9CA9B4;

        @media screen and (max-width:767px) {
          font-size: 10px;
        }
      }

      textarea {
        resize: none;
        border: 1px solid #A7C0F5;
        border-radius: 4px;
        padding: 10px;

        &:focus-visible {
          outline: none;
        }
      }

      @media screen and (max-width:767px) {
        .setting-info-text {
          margin-bottom: 1rem;
        }
      }

      .omail-custom-select {
        background: url(../../../assets/omail/images/obssettings/down-arrow.svg) no-repeat 98% center;
        background-color: #fff;
        border: 1px solid #5EC2FE;
      }

      .omail-sign-btn {
        .custom-btn-outline {
          background: transparent;
          cursor: pointer;
          min-width: auto;

          &:hover {
            border: 1px solid #615ADB;
          }
        }
      }

      .omail-auto-followup-actions {
        display: flex;
        align-items: center;
        gap: 24px
      }

      .omail-form-field {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 12px;

        h3 {
          margin-right: 6px;
        }

        .omail-col-one {
          display: flex;
          align-items: center;
          width: 100%;

          input {
            background-color: transparent;
            border: none;
          }

          .omail-custom-btn-outline {
            padding: 1px 10px 2px;
            min-width: 46px;
            text-align: center;
          }
        }

        .omail-col-two {
          display: flex;
          align-items: center;
          white-space: pre;
          gap: 4px;
          margin-left: 3px;
        }

        .omail-clip-block {
          input {
            padding: 0 10px;
            border: none !important;
          }
        }
      }

      .ccbcc-text-links {
        display: flex;
        justify-content: flex-end;
        width: 100%;
        gap: 8px;
        position: relative;
        right: 16px;

        span {
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          color: #006BCE;
          cursor: pointer;
        }
      }

      .mat-form-field-appearance-outline {
        .mat-form-field-flex {
          min-height: 40px !important;

          .settings-msg {
            position: absolute;
            bottom: -20px;
            left: -11px;
            top: inherit;
            white-space: nowrap;
          }
        }

        .mat-form-field-wrapper {
          @media screen and (max-width: 767px) {
            padding-bottom: 0 !important;
          }
        }
      }

      .event-more-mail-list {
        .omail-morelist-header {
          background: #DFF3FF;
          border-radius: 8px 8px 0px 0px;
          padding: 8px 16px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          color: #30353D;

          h5 {
            font-size: 14px;
          }

          span {
            background: #FFFFFF;
            box-shadow: 0px 4px 4px rgb(63 89 114 / 20%);
            border-radius: 20px;
            width: 20px;
            height: 20px;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;

            &:hover {
              background-color: #E84C3D;

              img,
              img:hover {
                filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(269deg) brightness(150%) contrast(101%);
              }
            }
          }
        }
      }

      .omail-emailmorelist-dropdown {
        padding-top: 0;
        border: none;
        box-shadow: 0px 8px 17px rgb(0, 0, 0, 0.3);

        .omail-clip-block {
          max-height: 256px;
          padding: 6px;
          overflow-y: auto;

          &::-webkit-scrollbar {
            width: 4px !important;
          }

          &::-webkit-scrollbar-thumb {
            background: #DFF3FF;
            box-sizing: border-box;
            box-shadow: inset 0px 10px 10px rgba(255, 255, 255, 0.25);
            border-radius: 7px;
          }
        }
      }

      .allow-limitvalidation {
        position: absolute;
        bottom: -18px;
        left: 9px;
      }

      .allow-subject-validation {
        .allow-limitvalidation {
          position: absolute;
          bottom: -12px;
          left: 9px;
        }
      }

      .auto-replies-footer {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .actions {
          display: flex;
          align-items: center;
          gap: 24px
        }

        .omail-sign-btn {
          cursor: pointer;
        }
      }

      .omail-customtheam-froala-block {
        .fr-box.fr-basic {
          box-shadow: 0px 3px 4px rgb(63, 89, 100, 0.2);
        }
      }

      .omail-customtheam-froala-block {
        @media screen and (max-width: 1024px) {
          position: relative;
          z-index: 1;
        }
      }

      .omail-customtheam-froala-block {
        .fr-box.fr-basic .fr-toolbar {
          position: inherit;
        }

        .fr-sticky-dummy {
          display: none !important;
        }
      }

      .omail-customtheam-froala-block {
        .fr-wrapper {
          max-height: 460px;
        }
      }

      .omail-mat-input .mat-form-field-outline {
        color: #DEDEDE;
      }

      .omail-timer-field .timecustom-select {
        border-color: #DEDEDE !important;
      }

      .omail-autoreplay-date {
        mat-icon {
          &:hover {
            img {
              filter: invert(52%) sepia(53%) saturate(7282%) hue-rotate(181deg) brightness(94%) contrast(98%);
            }
          }
        }
      }
    }

    /** Auto Followup css starts here **/
    .auto-replie {
      padding: 0px;

      h4 {
        color: #6D747A;
        font-weight: 400;
        font-size: var(--clamp14);
        margin-bottom: 8px;
      }

      h3 {
        color: #003B93;
        font-weight: 400;
        font-size: var(--clamp14);
        margin-bottom: 8px;
      }

      p {
        color: #30353D;
        font-size: var(--clamp14);
        font-weight: 400;
        margin-bottom: 24px;
      }

      .autofollowuplist-table {
        min-height: 160px;
        margin-bottom: 30px;

        th,
        td {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 300px;
          vertical-align: middle;

          @media screen and (max-width: 575px) {
            max-width: 200px;
          }

          @media screen and (max-width: 375px) {
            max-width: 140px;
            font-size: 12px;
          }

          &:first-child {
            width: 40%;
            padding-left: 15px;
          }

          &:nth-child(2) {
            width: 20%;
            text-align: center;
          }

          &:last-child {
            width: 40%;
            text-align: end;
            padding-right: 15px;
          }
        }

        tbody {
          tr {
            &:nth-child(even) {
              background-color: #FCFCFF !important;
            }

            &:nth-child(odd) {
              background-color: #FAF9FF !important;
            }
          }
        }
      }

      .omail-autofollowup-form {
        .omail-col-one {
          h3 {
            margin-bottom: 0;
            color: #A3A3A3;

            &.schedulemail-heading {
              width: 165px;
            }

            &.followup-text {
              width: auto;
              margin: 0;
              white-space: nowrap;
            }
          }

          .omail-clip-block {
            overflow: hidden;
            width: 100%;
            min-height: 40px;
            max-height: 60px;
            overflow-y: auto;
            position: relative;

            &::-webkit-scrollbar {
              width: 4px !important;
            }

            &::-webkit-scrollbar-thumb {
              background: #DFF3FF;
              box-sizing: border-box;
              box-shadow: inset 0px 10px 10px rgba(255, 255, 255, 0.25);
              border-radius: 7px;
            }
          }
        }

        .omail-col-two {
          .contacts-user-add {
            cursor: pointer;
          }

          .minus-square {
            cursor: pointer;
          }

          .mail-text {
            margin: 0;
          }
        }

        .allow-from-group {
          margin-bottom: 0 !important;
        }

        .omail-form-field {
          padding: 0 12px;
          border-top: 1px solid #E6E6E6;
          border-bottom: 1px solid #E6E6E6;
          margin-top: -1px;

          .omail-clip-block {
            input {
              &::placeholder {
                color: #A3A3A3;
                opacity: 1;
              }

              &:-ms-input-placeholder {
                color: #A3A3A3;
              }

              &::-ms-input-placeholder {
                color: #A3A3A3;
              }
            }
          }

          &:nth-child(3) {
            .omail-clip-block {
              width: 95%;
            }

            .email-more-list-block {
              right: 23px;
            }
          }
        }

        .week-days {
          margin-left: 12px;
          margin-top: 28px;

          ul {
            display: flex;
            align-items: center;
            gap: 4px;

            li {
              position: relative;

              input {
                display: none;
                width: 32px;
                height: 32px;
                border-radius: 4px;

                &:checked+label {
                  background: #fff;
                  color: #006BCE;
                }
              }

              label {
                width: 32px;
                height: 32px;
                border-radius: 8px;
                color: var(--white-color);
                font-style: normal;
                font-weight: 400;
                font-size: var(--clamp14);
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                background: #006BCE;
              }

              &:first-child {
                label {
                  background: #E6E3FE;
                  border-radius: 8px;
                  color: #006BCE;
                }
              }

              &:last-child {
                label {
                  background: #E6E3FE;
                  color: #006BCE;
                }
              }
            }
          }
        }

        .mat-button-toggle-group-appearance-standard {
          border: none;
          border-radius: initial;

          .mat-button-toggle {
            border-left: none;
            margin: 0 4px;

            &.mat-button-toggle-checked {
              background-color: transparent;

              .mat-button-toggle-button {
                background-color: #9EDAFE;
                color: #30353D;
              }
            }
          }

          .mat-button-toggle-button {
            background: transparent;
            color: #30353D;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            border: 1px solid #9EDAFE;
            border-radius: 4px;

            .mat-button-toggle-label-content {
              line-height: 30px;
              font-size: 12px;
              padding: 0 8px;
              width: 26px;

              @media screen and (min-width: 992px) and (max-width: 1280px) {
                padding: 0 5px;
                width: 22px;
              }
            }
          }
        }

        .omail-sign-btn {
          cursor: pointer;
          margin-right: 12px;

          span {
            cursor: pointer;
          }
        }

        .form-group {
          margin-bottom: 1rem;

          @media screen and (max-width:767px) {
            margin-bottom: 1rem;
          }

          .mat-icon-button.cdk-program-focused .mat-button-focus-overlay {
            opacity: 0 !important;
          }

          .omail-mat-input img {
            &:hover {
              filter: invert(22%) sepia(67%) saturate(4594%) hue-rotate(186deg) brightness(102%) contrast(82%);
            }
          }
        }

        .invalid-feedback {
          white-space: nowrap;
          font-size: 11px;
          position: absolute;
          bottom: -20px;
          left: 0;
          top: inherit;
        }

        .auto-followup-sendbtn {
          padding-top: 7px;
          padding-bottom: 7px;
          border: none;
          margin-bottom: 30px;
        }

        .omail-customtheam-froala-block {
          .fr-box.fr-basic {
            box-shadow: 0px 3px 4px rgb(63, 89, 100, 0.2);
          }
        }

        input {
          background: transparent;
          box-shadow: none;
        }

        .omail-autofollo-upclipblock {
          overflow: hidden !important;

          input {
            background: transparent;
            box-shadow: none;
            border: none;
          }

          .allow-limitvalidation {
            position: absolute;
            bottom: -2px;
            left: 10px;
          }
        }
      }

      .auto-reply-heading {
        margin-bottom: 5px;
      }
    }

    /** Auto Followup css ends here **/
    .omail-cmn-pad {
      padding: 0;

      @media screen and (max-width: 375px) {
        padding: 0;
      }

      .omail-line {
        padding: 24px 0px;

        @media screen and (max-width: 767px) {
          padding-top: 0;
        }

        hr {
          border-color: #ECECEC;
          opacity: 1;
          margin: 0px;
        }
      }

      .omail-em-sign {
        h3 {
          color: #464255;
          font-weight: 400;
          font-size: var(--clamp14);
          margin-bottom: 12px;
        }

        .form-group {
          margin-bottom: 25px;
        }

        .omail-customtheam-froala-block {
          margin-bottom: 4px;
        }

        .sig-action-links {
          margin-top: 20px;
        }
      }

      .email-tl {
        h3 {
          color: #6D747A;
          font-weight: 400;
          font-size: var(--clamp14);
        }
      }
    }

    .oesuser-profile-block {
      text-align: center;
      width: 100%;

      .show-pic {
        .profile-pic {
          width: 100%;
          overflow: hidden;
          height: 100%;
          object-fit: cover;
          object-position: center;
          border-radius: 50%;
        }

        .omail-settings-profile-pic {
          height: 94px;
          background: url(../images/obssettings/lightmode_SettingsProfilepic.svg) no-repeat center center;
        }
      }

      .setting-user-info {
        h3 {
          font-weight: 400;
          font-size: var(--clamp14);
          color: #30353D;
          margin-top: 12px;
          text-transform: capitalize;
        }
      }
    }

    .emailruleslist-table-fixed {
      overflow-y: auto;
      max-height: 350px;
      margin-bottom: 24px;
      padding: 10px;

      &::-webkit-scrollbar {
        width: 4px !important;

        @media screen and (max-width: 540px) {
          width: 2px !important;
        }
      }

      &::-webkit-scrollbar-thumb {
        background: #DFF3FF;
        box-sizing: border-box;
        box-shadow: inset 0px 10px 10px rgba(255, 255, 255, 0.25);
        border-radius: 7px;
      }

      thead {
        th {
          position: sticky;
          top: 0;
          background: #DFF3FF;
          border-radius: 0px;
        }
      }

      .emailruleslist-table {
        width: 100%;
        overflow: hidden;
        filter: drop-shadow(0px 4px 4px rgba(63, 89, 114, 0.2));
        border-radius: 6px;

        .result-empty-msg {
          text-align: center;
          font-style: normal;
          font-weight: 600;
          font-size: var(--clamp14);
          color: var(--text-color-2);
        }

        .thead-color {
          background: #E7E5FF;
          border-radius: 4px;
          height: 40px;
          line-height: 24px;

          tr {
            th {
              font-style: normal;
              font-weight: 500;
              font-size: var(--clamp12);
              color: #30353D;
              border: none;
              z-index: 2;

              &:first-child {
                padding-left: 24px;
              }

              &:last-child {
                padding-right: 24px;
              }
            }
          }
        }

        .tbody-color {
          tr {
            td {
              border-bottom: 1px solid #ECECEC;
              vertical-align: middle;
              background: transparent;
              font-size: var(--clamp14);
              background-color: #ffffff;

              &:first-child {
                padding-left: 24px;
              }

              &:last-child {
                padding-right: 18px;
              }
            }
          }

          tr {
            &.active {
              font-style: normal;
              font-weight: 400;
              font-size: var(--clamp-14);
              color: #003B93;
              background: #FCFCFF;
            }

            &:hover {
              background: #FAF9FF;
            }
          }

          .omail-emauto-switch {
            display: flex;
            align-items: center;
            gap: 8px;

            .mat-slide-toggle-label {
              .mat-slide-toggle-bar {
                background-color: transparent;
                height: 20px;
                width: 32px;
                border-radius: 12px;
                border: 1px solid #A3A3A3;
              }
            }

            .mat-slide-toggle-thumb-container {
              top: 1px;
              left: 2px;

              .mat-slide-toggle-thumb {
                height: 16px;
                width: 16px;
                border-radius: 50%;
                display: block;
                background: #A3A3A3;
              }

              .mat-slide-toggle {
                .mat-checked {
                  .mat-slide-toggle-thumb {
                    background-color: #0288D9;
                  }
                }
              }
            }

            .mat-slide-toggle.mat-checked {
              .mat-slide-toggle-bar {
                background-color: #0288D9;
                border: 1px solid #0288D9;
              }
            }

            .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
              background-color: #fff;
            }

            .mat-slide-toggle.mat-checked {
              .mat-slide-toggle-thumb-container {
                top: 1px;
                left: -2px;
              }
            }
          }

          .em-active {
            color: #37AC00;
            font-size: var(--clamp14);
            font-weight: 400;
          }

          .em-inactive {
            color: #A3A3A3;
            font-size: var(--clamp14);
            font-weight: 400;
          }
        }

        button {
          color: var(--main-color);
          padding-top: 0;
          padding-bottom: 0;
          padding-left: 0px;
          padding-right: 2px;

          &:focus {
            box-shadow: none;
          }
        }

        .email-rule-name {
          @media screen and (max-width: 14400px) {
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            max-width: 580px;
          }

          @media screen and (max-width: 1280px) {
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            max-width: 200px
          }

          @media screen and (max-width: 375px) {
            max-width: 110px;
            font-size: 12px;
          }
        }
      }
    }

    .email-rule-form-block {
      margin-bottom: 24px;

      .rule-title {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        color: #003B93;
        margin-bottom: 12px;
      }

      .email-rule-form {
        .rule-title-text {
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          color: #003B93;
          margin: 24px 0 8px;
        }

        .rule-title-sub-text {
          color: #30353D;
          font-size: 12px;
        }

        .settngs-signature-block .rule-title-sub-text {
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          color: #464255;
          margin: 0 0 6px;
        }

        .form-group {
          label {
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            color: #003B93;
          }

          .form-control {
            background: #FFFFFF;
            border: 1px solid #DEDEDE;
            box-shadow: 0px 1px 2px rgba(77, 64, 85, 0.1);
            border-radius: 4px;
            padding: 10px 12px;
            font-size: var(--clamp14);
            color: #A3A3A3;

            &::placeholder {
              color: #A3A3A3;
            }
          }

          .omail-custom-select {
            background: url(../../../assets/omail/images/obssettings/down-arrow.svg) no-repeat 98% center;
            background-color: #fff;
          }
        }

        .omail-custom-select {
          background: url(../../../assets/omail/images/obssettings/new-down-arrow.svg) no-repeat 98% center;
          background-color: #F8F8FF;

          @media screen and (max-width: 767px) {
            margin-bottom: 10px;
          }
        }
      }

      .emr-action-links {
        margin-top: 20px;
        display: flex;
        align-items: center;
        justify-content: right;
        gap: 10px;
      }

      .listof-contacts {
        margin: 10px 0 0;
        overflow-y: auto;
        max-height: 110px;
        border: 1px solid #E6E6E6;
        border-radius: 4px;
        padding: 4px;

        .total-list {
          resize: none;
          min-height: 70px;
          max-height: 70px;
        }
      }

      .custom-select {
        background-color: #F8F8FF;
        border: none;
        border-radius: 4px;
        background: url(../../../assets/omail/images/contacts/adressbook-select.png) no-repeat 96% center;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        color: var(--text-color-2);
        border: 1px solid #E6E3FE;
        text-transform: capitalize;
        appearance: none;
        width: 100%;
        vertical-align: middle;
        padding: 10px 15px;
        display: inline-block;

        &::placeholder {
          color: #A3A3A3;
        }
      }
    }

    .showTab {
      display: block;
    }

    .hideTab {
      display: none;
    }

    .settngs-signature-block {
      .omailsignature-modal {
        .modal-body {
          @media screen and (max-width: 767px) {
            padding: 0;
          }
        }
      }
    }
  }

  .block-user {
    padding: 36px 0 24px;

    @media screen and (max-width: 767px) {
      padding: 24px 0 24px;
    }

    @media screen and (max-width: 640px) {
      padding: 15px 0 15px;
    }
  }

  .common-view {
    h3 {
      color: #6D747A;
      font-weight: 400;
      font-size: var(--clamp14);
      margin-bottom: 2px;
    }

    .omail-auto-switch {
      padding-top: 22px;

      .readrecept-block {
        display: flex;
        align-items: center;
        gap: 8px;

        .slide-toggle-block {
          margin-top: 6px;
        }
      }
    }
  }

  .pad-right {
    padding-right: 0px;
  }

  .pad-left {
    padding-left: 0px;

    @media screen and (max-width: 767px) {
      padding-left: 10px;
    }
  }

  .settings-toggle-btn {
    min-width: auto;
    min-height: auto;
    border-radius: 6px;
    background: #006BCE;
    border: none;
    padding: 5px 11px;
    outline: none;

    i {
      margin-right: 0;
      color: #fff;
    }
  }

  .calendar-leftside {
    width: 240px;

    @media screen and (max-width: 1440px) {
      width: 252px;
    }

    @media screen and (max-width: 1280px) {
      width: 260px;
    }

    @media screen and (max-width: 1024px) {
      width: 240px;
      position: absolute;
    }
  }

  .calendar-rightside {
    width: 100%;

    @media screen and (max-width: 1024px) {
      width: 100%;
    }
  }
}

.omail-mat-input {
  .mat-form-field-flex {
    height: 40px;
  }

  .mat-form-field-infix {
    padding: 0px;
    line-height: 0;
  }

  .mat-form-field-outline {
    color: #5EC2FE;
    background: transparent;
    border-radius: 6px;
  }

  img {
    vertical-align: baseline;
  }

  .mat-form-field-suffix {
    top: -3px !important;
    right: -13px;
  }
}

.omail-settinslistof-folders {
  .notfolder-list {
    text-align: center;
    font-style: normal;
    font-weight: 400;
    font-size: var(--clamp14);
    color: #6D747A;
    border: 1px solid #E6E6E6;
    border-left: none;
    border-right: none;
    padding: 3px 0;

    p {
      margin: 0;
      padding: 0;
      font-style: normal;
      font-weight: 400;
      font-size: var(--clamp14);
      color: #6D747A;
    }
  }

  li {
    .settinslistof-folders-inner {
      width: 100%;
      display: flex;
      align-items: center;
      gap: 10px;
      margin-bottom: 24px;

      .folder-reset-block {
        padding: 16px 17px;
        width: 44%;
        background: #F0F9FF;
        border-radius: 6px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media screen and (max-width: 1440px) {
          width: 60%;
        }

        @media screen and (max-width: 1280px) {
          width: 70%;
        }

        @media screen and (max-width: 1024px) {
          width: 100%;
        }

        @media screen and (max-width: 640px) {
          display: block;
          padding: 5px 17px 10px;
        }

        @media screen and (max-width: 375px) {
          min-width: 100%;
          max-width: 100%;
          padding: 16px 10px;
        }

        .folder-name-text {
          font-weight: 400;
          font-size: 14px;
          color: #0A2477;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          min-width: 30%;
          max-width: 30%;

          @media screen and (max-width: 1280px) {
            max-width: 23%;
          }

          @media screen and (max-width: 640px) {
            max-width: 100%;
            margin-bottom: 3px;
          }
        }

        .folder-btns {
          display: flex;

          @media screen and (max-width: 476px) {
            display: block;
          }

          .omail-custom-btn {
            padding: 6px 24px;
            text-wrap: nowrap;
            max-width: 170px;
            min-width: 170px;
            text-align: center;

            @media screen and (max-width: 476px) {
              display: block;
              margin-bottom: 15px;
              padding: 6px 15px
            }
          }

          .omail-custom-btn-outline {
            padding: 5px 24px;
          }
        }

        .folder-resetpasscode-input {
          @media screen and (max-width: 640px) {
            float: left;
          }

          @media screen and (max-width: 567px) {
            float: none;
            margin-bottom: 10px;
          }

          .form-control {
            border: 1px solid #006BCE;
            border-radius: 6px;
            max-width: 75px;
            min-width: 75px;
            min-height: 31px;
            line-height: 18px;
          }
        }
      }

      .text-danger {
        font-size: 12px;

        @media screen and (max-width:567px) {
          position: relative !important;
        }
      }
    }
  }
}

/*  Analytics CSS Start */
.omail-user-analytics-block {
  .omail-user-analytics-box {
    padding: 18px;
    margin: 0 0 20px;
    background: #FFFFFF;
    border: 1px solid #F3F2FF;
    border-radius: 8px;

    h3 {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      color: #334272;
      margin: 0 0 20px;
    }

    ul {
      margin: 0 auto;
      display: grid;
      gap: 1rem;

      @media (min-width: 1441px) {
        grid-template-columns: repeat(8, 1fr);
      }

      @media (min-width: 1367px) and (max-width: 1440px) {
        grid-template-columns: repeat(6, 1fr);
      }

      @media (min-width: 1280px) and (max-width: 1366px) {
        grid-template-columns: repeat(5, 1fr);
      }

      @media (min-width: 1025px) and (max-width: 1289px) {
        grid-template-columns: repeat(4, 1fr);
      }

      @media (min-width: 769px) and (max-width: 1024px) {
        grid-template-columns: repeat(3, 1fr);
      }

      @media (min-width: 640px) and (max-width: 768px) {
        grid-template-columns: repeat(2, 1fr);
      }

      @media (max-width: 600px) {
        grid-template-columns: repeat(1, 1fr);
      }

      li {
        border-radius: 12px;
        margin: 0 0 20px;
        padding: 12px;
        display: flex;
        align-items: center;
        vertical-align: middle;
        flex-direction: column;
        word-break: break-word;

        &:nth-child(9n+1) {
          background: linear-gradient(0deg, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), #E6F7FF;
          border: 1px solid #D5F1FF;

          .list-icon {
            background: #79D1FB;
          }
        }

        &:nth-child(9n+2) {
          background: linear-gradient(0deg, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), #E6FFFB;
          border: 1px solid #C2FFF5;

          .list-icon {
            background: #37DFC5;
          }
        }

        &:nth-child(9n+3) {
          background: linear-gradient(0deg, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), #EFFFDE;
          border: 1px solid #E5F9CF;

          .list-icon {
            background: #97D77A;
          }
        }

        &:nth-child(9n+4) {
          background: linear-gradient(0deg, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), #FFF2E8;
          border: 1px solid #FFECDD;

          .list-icon {
            background: #FCAC6E;
          }
        }

        &:nth-child(9n+5) {
          background: linear-gradient(0deg, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), #FFF0F6;
          border: 1px solid #FFEAF2;

          .list-icon {
            background: #FF87B5;
          }
        }

        &:nth-child(9n+6) {
          background: linear-gradient(0deg, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), #F9F0FF;
          border: 1px solid #F7EAFF;

          .list-icon {
            background: #D68AF2;
          }
        }

        &:nth-child(9n+7) {
          background: linear-gradient(0deg, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), #FFF1F0;
          border: 1px solid #FFEBEA;

          .list-icon {
            background: #F4817B;
          }
        }

        &:nth-child(9n+8) {
          background: linear-gradient(0deg, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), #FEFAE4;
          border: 1px solid #FDF7D3;

          .list-icon {
            background: #EFD643;
          }
        }

        &:nth-child(9n+9) {
          background: linear-gradient(0deg, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), #F1F4FF;
          border: 1px solid #EBEFFF;

          .list-icon {
            background: #879BEE;
          }
        }

        .list-icon {
          width: 50px;
          height: 50px;
          border-radius: 50%;
          background-color: #655ae6;
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            filter: invert(0) sepia(0%) saturate(15%) hue-rotate(0deg) brightness(165%) contrast(100%);
          }

          .c_temail {
            filter: inherit;
          }
        }

        .list-cout {
          font-style: normal;
          font-weight: 600;
          font-size: 20px;
          color: #0A2477;
          margin: 0 0 4px;
        }

        .list-text {
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          color: #4B576D;
          text-transform: capitalize;
          text-align: center;
          word-break: break-all;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }
      }
    }

    &.storage-box {
      border: none;
      padding: 0;

      ul {
        grid-template-columns: repeat(4, 1fr);

        @media screen and (max-width: 1280px) {
          grid-template-columns: repeat(3, 1fr);
        }

        @media screen and (max-width: 1024px) {
          grid-template-columns: repeat(2, 1fr);
        }

        @media screen and (max-width: 480px) {
          grid-template-columns: repeat(1, 1fr);
        }

        .list-icon {
          margin-right: 24px;
          background-color: #576CC1;

          @media screen and (max-width: 1366px) {
            margin-right: 14px;
          }

          img {
            filter: brightness(0) invert(1);
            width: 60%;
          }
        }

        .list-cout {
          margin-bottom: 0;
        }

        li {
          padding-top: 18px;
          padding-bottom: 18px;
          padding-left: 24px;
          display: block;
          background-color: #FAFDFF;
          border-color: #C1E0FF;
        }
      }

      .storage-usage {
        display: flex;
        align-items: center;
      }
    }
  }
}

/*  Analytics CSS End */
.omail-storage-block {
  .storage-title {
    font-style: normal;
    font-weight: 400;
    font-size: var(--clamp-14);
    color: #6D747A;
    margin-bottom: 24px;
  }

  .omail-storage-box {
    display: inline-flex;
    align-items: center;
    gap: 26px;
    background: #F0F9FF;
    border: 1px solid #C1E0FF;
    border-radius: 12px;
    padding: 29px 70px 29px 20px;

    .storage-info {
      .storage-cout {
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        color: #003B93;
      }

      .storage-text {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        color: #30353D;
      }
    }
  }
}

/*
  ***********************************
  * Omail App Settings-Module CSS End
  ***********************************
*/
/*
  ********************************
  * Omail App All Modal CSS Start
  ********************************
*/
/*/omail-one Modal Structure Start*/
.omail-modal-one {
  .modal-content {
    border-radius: 8px;
    border: none;

    .modal-header {
      background: #DFF3FF;
      border-radius: 8px 8px 0px 0px;
      padding: 11px 16px;

      h5 {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        color: #30353D;
      }

      .btn-close {
        height: 20px;
        width: 20px;
        border-radius: 50%;
        background: #FFFFFF;
        box-shadow: 0px 4px 4px rgba(63, 89, 114, 0.2);
        line-height: 8px;
        padding: 0;
        margin: 0;

        img {
          transition: transform .7s ease-in-out;
        }

        &:hover {
          background-color: #E84C3D;

          img {
            filter: invert(100%) sepia(75%) saturate(0%) hue-rotate(70deg) brightness(160%) contrast(101%);
            transform: rotate(90deg);
          }
        }
      }
    }

    .modal-body {
      background: #FFFFFF;

      &::-webkit-scrollbar {
        width: 4px !important;

        @media screen and (max-width: 540px) {
          width: 2px !important;
        }
      }

      &::-webkit-scrollbar-thumb {
        background: #DFF3FF;
        box-sizing: border-box;
        box-shadow: inset 0px 10px 10px rgba(255, 255, 255, 0.25);
        border-radius: 7px;
      }
    }

    .modal-footer {
      border: none;
      gap: 24px;
    }
  }
}

/*/omail-one Modal Structure End*/
// omail-calendar-list-of-event-modal//
.omail-moreEventList-modal {
  .omail-event-list {
    overflow-y: auto;
    min-height: 100px;
    max-height: 296px;

    &::-webkit-scrollbar {
      width: 4px !important;
    }

    &::-webkit-scrollbar-thumb {
      background: #DFF3FF;
      box-sizing: border-box;
      box-shadow: inset 0px 10px 10px rgba(255, 255, 255, 0.25);
      border-radius: 7px;
    }

    .event-action-links {
      span {
        &:after {
          display: none;
        }
      }
    }

    li {
      .event-info-block {
        padding: 4px;
        border-radius: 8px;

        &.newevent-status {
          background: #E8F6F0;
        }

        &.cancelevent-status {
          background: #FDF3F3;
        }

        &.saveevent-status {
          background: #E8F3FF;
        }
      }
    }
  }

  .omail-event-list-one .viewevent-box .evnt-box .p-title {
    @media screen and (max-width: 1024px) {
      max-width: 200px;
      display: block;
    }
  }
}

// end//
/*/Contacts Delete Modal  CSS Start*/
.omail-delete-contact-modal {
  .modal-body {
    p {
      font-style: normal;
      font-weight: 400;
      font-size: var(--clamp16);
      color: var(--text-color-2);
    }
  }
}

/*/Contacts Delete Modal  CSS End */
/*/Addres book Contacts Modal CSS Start*/
.omail-list-group-modal {
  .modal-body {
    padding: 24px;

    @media screen and (max-width: 360px) {
      padding: 12px;
    }
  }

  .modal-footer {
    padding: 24px;

    .custom-btn-outline {
      cursor: pointer;
      margin-right: 24px;
    }

    .custom-btn {
      cursor: pointer;

      &:disabled {
        cursor: no-drop;
        opacity: 0.5;
      }
    }
  }

  .addressbook-table-fixed {
    overflow: auto;
    max-height: 350px;
    margin-top: 20px;

    &::-webkit-scrollbar {
      width: 4px !important;
      height: 4px !important;

      @media screen and (max-width: 540px) {
        width: 2px !important;
        height: 2px !important;
      }
    }

    &::-webkit-scrollbar-thumb {
      background: #DFF3FF;
      box-sizing: border-box;
      box-shadow: inset 0px 10px 10px rgba(255, 255, 255, 0.25);
      border-radius: 7px;
    }

    thead {
      th {
        position: sticky;
        top: -1px;
        background: #ffffff;
        border-radius: 4px;
        border-top: 1px solid #ECECEC;
        border-bottom: 1px solid #ECECEC;
      }
    }

    .addressbook-table {
      width: 100%;
      vertical-align: middle;

      .result-empty-msg {
        text-align: center;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        color: var(--text-color-2);
      }

      .thead-color {
        background: var(--fill-color-3);
        border-radius: 4px;

        tr {
          th {
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            color: #30353D;
            z-index: 1;
            height: 40px;
          }
        }

        tr {
          td {
            border-bottom: 1px solid var(--border-color-3);
          }
        }
      }

      .user-name {
        display: flex;
        align-items: center;
        gap: 5px;
        font-style: normal;
        font-weight: 400;
        font-size: var(--clamp14);
        color: #464255;

        img {
          width: 32px;
          height: 32px;
          border: 1px solid transparent;
          border-radius: 8px;
        }
      }

      .user-email {
        font-style: normal;
        font-weight: 400;
        font-size: var(--clamp14);
        color: #464255;
      }

      .user-choose-cat-block {
        display: flex;
        align-items: center;
        gap: 20px;
      }
    }
  }

  .invaliedsearch-text {
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    color: #E84C3D;
  }

  .user-block {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .oesomailcustom-select {
    background: none;
    border-left: none;
    border-radius: 4px;
    background: url(../../../assets/omail/images/contacts/adressbook-select.png) no-repeat 88% center;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #30353D;
    border: 1px solid #5EC2FE;
    text-transform: capitalize;
    appearance: none;
    width: 100%;
    vertical-align: middle;
    padding: 5px 28px 5px 10px;
    display: inline-block;

    option {
      text-transform: capitalize;
    }
  }

  table {
    width: 100%;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #2A3F51;

    input[type=checkbox] {
      position: relative;
      cursor: pointer;
      width: 20px;
      height: 20px;
    }

    input[type=checkbox]:before {
      content: "";
      display: block;
      position: absolute;
      width: 20px;
      height: 20px;
      top: 0;
      left: 0;
      border: 1.5px solid #A3A3A3;
      border-radius: 3px;
    }

    input[type=checkbox]:checked:before {
      content: "";
      display: block;
      position: absolute;
      width: 20px;
      height: 20px;
      top: 0;
      left: 0;
      background: #006BCE;
      border: 1.5px solid #006BCE;
      border-radius: 4px;
    }

    input[type=checkbox]:checked:after {
      content: "";
      display: block;
      width: 5px;
      height: 10px;
      border: solid white;
      border-width: 0 2px 2px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
      position: absolute;
      top: 4px;
      left: 8px;
    }
  }

  .usercheckmark-block {
    display: flex;
    align-items: center;
    gap: 4px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #2A3F51;
  }

  .contacts-limit-text {
    background: #FDF3F3;
    border: 1px solid #FFA6A6;
    border-radius: 6px;
    display: flex;
    align-items: center;
    padding: 4px 10px;
    gap: 8px;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #464255;

    i {
      color: #E84C3D;
    }
  }

  .contact-search-block {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10%;

    .contacts-selected-text {
      text-align: right;
      width: 40%;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      color: #0A2477;
    }

    .oesobs-search {
      position: relative;
      width: 50%;
      border: 1px solid #DEDEDE;
      border-radius: 6px;

      &:hover {
        border-color: #A3A3A3;
      }

      .form-control {
        padding-left: 12px;
        padding-right: 0px;
        border: none;
      }

      .search-icon {
        background-color: transparent;
        cursor: pointer;
        border: none;
      }

      .close-icon {
        cursor: pointer;
        background-color: transparent;
        border: transparent;
        position: absolute;
        right: 3px;
        top: 5px;
        z-index: 3;
      }
    }

    @media screen and (max-width:767px) {
      display: block;

      .oesobs-search {
        width: 100%;
      }

      .contacts-selected-text {
        width: 100%;
      }
    }

    @media screen and (max-width:500px) {
      display: block;

      .oesobs-search {
        width: 100%;
      }

      .contacts-selected-text {
        width: 100%;
      }
    }
  }
}

.omail-groups-addressbook-table {
  .custom-select {
    border: 1px solid #006BCE !important;
    background-color: transparent !important;
  }
}

/*/Addres book Contacts Modal CSS End*/
/* MoveToFolder Mails Modal Start*/
.omail-movetofolder-modal {
  .selected-mail-list-block {
    padding: 0 15px;

    h4 {
      font-style: normal;
      font-weight: normal;
      font-size: var(--clamp16);
      color: #1D284E;
      margin: 16px 0 0;

      span {
        font-weight: bold;
      }
    }
  }

  .modal-body {
    background-color: #F4F3FF;

    .omail-move-list {
      background-color: #fff;
      overflow: hidden;
      overflow-y: auto;
      min-height: 200px;
      max-height: 200px;

      &::-webkit-scrollbar {
        width: 4px !important;
      }

      &::-webkit-scrollbar-thumb {
        background: #DFF3FF;
        box-sizing: border-box;
        box-shadow: inset 0px 10px 10px rgba(255, 255, 255, 0.25);
        border-radius: 7px;
      }

      .omail-sub-sidebar {
        .omail-sub-sidebar-layout {
          ul {
            padding: 0;
            margin: 0;

            li {
              display: flex;
              align-items: center;
              gap: 10px;
              padding: 10px;
              cursor: pointer;
              background-color: #F0F9FF;
              border-radius: 4px;
              margin-bottom: 1px;

              &:last-child {
                border: none;
              }

              &:hover {
                background: #DFF3FF;

                .mat-tooltip {
                  display: none;
                }
              }

              &.activetab {
                background: #BFE7FE;

                img {
                  filter: invert(96%) sepia(100%) saturate(0%) hue-rotate(111deg) brightness(130%) contrast(104%);
                }
              }

              .generate-folder {
                display: flex;

                .ge-text {
                  margin-left: 6px;
                }
              }
            }
          }
        }
      }
    }

    .omail-move-create-folder {
      background-color: #fff;
      padding: 12px 10px;
      margin-bottom: 18px;
      cursor: pointer;
      border: 1px solid #9EDAFE;
      border-radius: 6px;

      h5 {
        font-size: 14px;
        color: #0A2477;
        display: flex;
        align-items: center;
        font-weight: 400;
      }

      img {
        margin-right: 7px;
      }
    }

    .omail-sub-folder-layout {
      &.active {
        .activefolder-list {
          display: none !important;
        }
      }
    }
  }
}

/* MoveToFolder Mails Modal End*/
/* create-subfolder Modal Start*/
.omail-create-subfolder-modal {
  .create-subfolder-form-block {
    padding: 0 15px;
  }

  .form-group {
    position: relative;

    label {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      color: #0A2477;
      margin: 0 0 5px;
    }

    .form-control {
      border: 1px solid #A7C0F5;
      border-radius: 4px;
      height: 42px;
      box-shadow: none;
      margin: 0 0 20px;
    }
  }

  .craete-sub-folder-btn {
    text-align: right;
    margin: 0 0 15px;

    button {
      align-items: center;
      gap: 10px;

      &:hover {
        text-decoration: none;
        opacity: 0.5;
      }
    }
  }

  .invalid-feedback {
    position: absolute;
    bottom: -16px;
  }

  .listof-folders {
    padding: 16px;
    border-radius: 8px;
    background-color: var(--modal-header-bgcolor);
    overflow: hidden;
    overflow-y: auto;
    min-height: 200px;
    max-height: 200px;

    &::-webkit-scrollbar {
      width: 4px !important;

      @media screen and (max-width: 540px) {
        width: 2px !important;
      }
    }

    &::-webkit-scrollbar-thumb {
      background: #DFF3FF;
      box-sizing: border-box;
      box-shadow: inset 0px 10px 10px rgba(255, 255, 255, 0.25);
      border-radius: 7px;
    }

    .list-button {
      display: flex;
      align-items: center;
      font-style: normal;
      font-weight: normal;
      font-size: var(--clamp16);
      color: #0A2477;
      border: none;
      background: transparent;
      padding: 10px 20px;
      cursor: pointer;

      &:hover {
        background: #E6F5FF;
        border-radius: 0 !important;
      }

      &.activeTab {
        background: #E6F5FF;
        border-radius: 0 !important;
      }

      .icon-circle {
        width: 30px;
      }

      &:hover {
        text-decoration: none;
      }
    }
  }
}

/* create-subfolder Modal End*/
/* Email-Rules Modal Start*/
.omail-email-rules-modal {
  .modal-content {
    .modal-header {
      h5 {
        font-weight: 400;
        font-size: var(--clamp14);
        color: #fff;
      }
    }

    .modal-body {
      padding: 24px;

      @media screen and (max-width: 375px) {
        padding: 12px;
      }

      .email-rule-form {
        .rule-title-text {
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          color: #0A2477;
          margin: 24px 0 8px;
        }

        .settngs-signature-block .rule-title-sub-text {
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          color: #464255;
          margin: 0 0 6px;
        }

        .form-group {
          label {
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            color: #334554;
          }

          .form-control {
            background-color: #F8F8FF;
            border-radius: 4px;
            border: none;
            padding: 10px 12px;
            font-size: var(--clamp14);
            color: #0A2477;

            &::placeholder {
              color: #0A2477;
            }
          }
        }

        .omail-custom-select {
          background: url(../../../assets/omail/images/obssettings/new-down-arrow.svg) no-repeat 98% center;
          background-color: #F8F8FF;

          @media screen and (max-width: 767px) {
            margin-bottom: 10px;
          }
        }
      }

      .listof-contacts {
        margin: 10px 0 0;
        overflow-y: auto;
        max-height: 110px;
        border: 1px solid #E6E6E6;
        border-radius: 4px;
        padding: 4px;

        .total-list {
          resize: none;
          min-height: 70px;
          max-height: 70px;
        }
      }

      .custom-select {
        background-color: #F8F8FF;
        border: none;
        border-radius: 4px;
        background: url(../../../assets/omail/images/contacts/adressbook-select.png) no-repeat 96% center;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        color: var(--text-color-2);
        border: 1px solid #E6E3FE;
        text-transform: capitalize;
        appearance: none;
        width: 100%;
        vertical-align: middle;
        padding: 10px 15px;
        display: inline-block;

        &::placeholder {
          color: #A3A3A3;
        }
      }
    }

    .modal-footer {
      .custom-btn-outline {
        margin-right: 24px;
      }
    }
  }
}

/*  Email-Rules Modal End*/
/* Create Group Modal for compose mail Start */
.omail-compose-group-modal {
  @media screen and (max-width: 991px) {
    max-width: 90%;
    margin: auto;
  }

  .modal-body {
    .create-group-form {
      height: inherit;
      overflow: hidden;
      padding: 0;
    }

    .add-book-btn-block {
      margin: 24px 0 25px;
      display: flex;
      align-items: center;
      gap: 24px;

      @media screen and (max-width:510px) {
        display: block;

        .group-rule-info-text {
          margin-top: 24px;
        }
      }

      .group-rule-info-text {
        background: #FFFBF8;
        border: 1px solid #FFB97E;
        border-radius: 6px;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        color: #464255;
        padding: 5px 8px;

        .star-icon {
          color: #E84C3D;
        }
      }
    }
  }
}

/* Create Group Modal for compose mail End */
/* omailsettings-password Modal for Start */
.omailsettings-password-block {
  .modal-content {
    .modal-header {
      h5 {
        .reg-info {
          img {
            filter: invert(100%) sepia(75%) saturate(0%) hue-rotate(70deg) brightness(105%) contrast(101%);
          }
        }
      }
    }

    .modal-body {
      padding: 24px;

      .form-group {
        margin-bottom: 24px;

        label {
          color: #A3A3A3;
          font-size: var(--clamp-14);
          font-weight: 400;
          margin-bottom: 2px;
        }

        .input-group {
          background: #F8F8FF;
          border-radius: 4px;

          .form-control {
            padding: 10px 12px;
            border: none;
          }

          .input-group-text {
            background: transparent;
            border-radius: 0px;
            border: none;
            padding: 12px;
          }
        }
      }
    }

    .modal-footer {
      .custom-btn-outline {
        margin-right: 24px;
      }

      .custom-btn {
        cursor: pointer;
      }
    }
  }
}

/* omailsettings-password Modal for End */
/* Omail-Confirm  Modal Start */
.omail-confirm-modal {
  .modal-content {
    .modal-body {
      padding: 24px;
    }

    .modal-footer {
      padding: 24px;

      .custom-btn-outline {
        margin-right: 10px;
        font-size: var(--clamp14);
      }

      .custom-btn {
        font-size: var(--clamp14);
      }
    }
  }
}

/* Omail-Confirm  Modal End */
/* omail-sendwithoutfields-modal Start*/
.omail-sendwithoutfields-modal {
  .modal-content {
    .modal-body {
      padding: 24px;
    }

    .modal-footer {
      padding: 24px;
    }
  }
}

/* omail-sendwithoutfields-modal End*/
/* omail-compAddGroup-modal Start*/
.omail-addgroupincomp-Modal {
  .modal-content {
    overflow: hidden;
    overflow-y: auto;
  }

  .create-group-form {
    .form-group {
      margin-bottom: 1rem;

      .form-control {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        color: #0A2477;
        border: 1px solid #F8F8FF;
        height: 44px;
        background-color: #F8F8FF;
      }
    }

    &::-webkit-scrollbar {
      width: 4px !important;

      @media screen and (max-width: 540px) {
        width: 2px !important;
      }
    }

    &::-webkit-scrollbar-thumb {
      background: #DFF3FF;
      box-sizing: border-box;
      box-shadow: inset 0px 10px 10px rgba(255, 255, 255, 0.25);
      border-radius: 7px;
    }

    @media screen and (max-width:768px) {
      padding: 0;
    }

    @media screen and (max-width:767px) {
      padding: 0;
    }

    label {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      color: var(--text-color-2);
    }

    .form-control {
      border: 1px solid var(--border-color-2);
      border-radius: 4px;
      background: var(--input-bg-color-1);
    }

    .add-book-btn-block {
      margin: 5px 0 25px;
      display: flex;
      align-items: center;
      gap: 24px;

      @media screen and (max-width:510px) {
        display: block;

        .group-rule-info-text {
          margin-top: 24px;
        }
      }

      .group-rule-info-text {
        background: #FFFBF8;
        border: 1px solid #FFB97E;
        border-radius: 6px;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        color: #464255;
        padding: 5px 8px;

        .star-icon {
          color: #E84C3D;
        }
      }
    }

    .invalid-feedback {
      position: absolute;
      position: inherit;
      bottom: 0;
    }

    .create-group-form-actions {
      .action-links {
        display: flex;
        align-items: center;
        justify-content: end;
        gap: 20px;
      }
    }

    .group-error-text {
      position: inherit;
    }
  }

  .email-catagory-block {
    margin: 20px 0 0;

    @media screen and (max-width: 991px) {
      margin-bottom: 30px;
    }

    @media screen and (max-width:767px) {
      display: block;
    }

    .email-catagory-inner {
      background: #FFFFFF;
      border: 1px solid #E6E6E6;
      border-radius: 12px;
      margin: 0 0 20px;

      @media screen and (max-width:767px) {
        width: 100%;
      }

      .sub-heading {
        background: #F0F9FF;
        border-radius: 12px 12px 0px 0px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 12px;

        h6 {
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          color: #0A2477;
        }

        span {
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          color: #706D7A;
        }
      }

      .omail-email-catagory-types {
        min-height: 295px;
        max-height: 295px;
        overflow-x: auto;
        overflow-y: auto;
        padding: 12px;

        &::-webkit-scrollbar {
          width: 4px !important;
        }

        &::-webkit-scrollbar-thumb {
          background: #DFF3FF;
          box-sizing: border-box;
          box-shadow: inset 0px 10px 10px rgba(255, 255, 255, 0.25);
          border-radius: 7px;
        }

        mat-chip-list {
          .mat-chip-list-wrapper {
            display: block;
          }
        }
      }
    }
  }

  .contact-group-user-pic-block {
    margin: 0 0 20px;

    .form-group {
      margin-bottom: 1rem;
    }

    text-align: center;
    background-color: #F8F8F8;
    padding: 24px 0 10px;

    .contact-pic-text-block .edit-block {
      position: absolute;
      top: 15px;
      right: 0;
      background: #F3F2FF;
      overflow: hidden;
      width: 32px;
      height: 32px;
      border-radius: 50%;
      cursor: pointer;
      border: none;
    }

    .contact-pic-text-block {
      position: relative;
      text-align: center;
      display: inline-block;
      margin: 0 0 20px;

      .edit-block {
        position: absolute;
        top: 15px;
        right: 0;
        background: #F3F2FF;
        overflow: hidden;
        width: 32px;
        height: 32px;
        border-radius: 50%;
        cursor: pointer;
        border: none;

        &::after {
          border: transparent;
        }

        img {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          text-align: center;
          object-fit: none;
        }
      }

      .delete-block {
        width: 36px;
        height: 36px;
        background-color: #fff;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        bottom: 0;
        right: -14px;
        z-index: 145;
        cursor: pointer;

        button {
          border: none;
          background: none;
        }
      }
    }

    .contact-pic-text {
      width: 94px;
      height: 94px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      margin: 0 auto;
      background: url(../images/obsomailcontacts/newcon/Lightmode_del-empty-contacts.svg) no-repeat center center;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 50%;
      }

      span {
        font-size: var(--clamp18);
        font-weight: 600;
        color: var(--white-color);
      }
    }
  }

  form {
    @media screen and (max-width: 1440px) {
      max-height: 500px;
      overflow-y: auto;
    }
  }
}

/* omail-compAddGroup-modal End*/
/* omail Signature-modal End*/
.omail-mydialog-signature {
  width: 70%;
  min-width: 70%;
  margin: 0 auto;
}

.omailsignature-modal {
  .modal-content {
    .modal-body {
      min-height: 50vh;
      overflow: auto;
      max-height: 50vh;

      &::-webkit-scrollbar {
        width: 4px !important;
      }

      &::-webkit-scrollbar-thumb {
        background: #DFF3FF;
        box-sizing: border-box;
        box-shadow: inset 0px 10px 10px rgba(255, 255, 255, 0.25);
        border-radius: 7px;
      }

      @media screen and (max-width: 1700px) {
        max-height: 500px;
        overflow-y: auto;
      }

      @media screen and (max-width: 767px) {
        padding: 12px;
      }

      .signature-info {
        margin-bottom: 24px;

        @media screen and (max-width: 375px) {
          margin-bottom: 0px;
        }

        .form-group {
          margin-bottom: 1rem;

          label {
            font-weight: 400;
            font-size: var(--clamp14);
            color: #A3A3A3;
            margin-bottom: 2px;
          }

          input {
            font-size: var(--clamp14);
            background: #F8F8FF;
            border-radius: 0;
            max-height: 40px;
            min-height: 40px;
            border: none;
            color: #0A2477;

            &:focus {
              box-shadow: none;
            }
          }
        }

        .custom-select {
          font-size: var(--clamp14);
          background: url(../../../assets/omail/images/contacts/adressbook-select.png) no-repeat 94% center;
          border-radius: 0;
          max-height: 40px;
          min-height: 40px;
          border: none;
          color: #0A2477;
          background-color: #F8F8FF;
          appearance: none;
          padding: 10px 12px;

          &:focus {
            box-shadow: none;
            border: none;
            outline: 0;
          }
        }
      }

      .signature-info-status {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 24px;

        @media screen and (max-width: 480px) {
          display: block;
        }

        @media screen and (max-width: 375px) {
          flex-direction: column;
        }

        label {
          input {
            margin: 0 6px 0 0;
            vertical-align: middle;
          }

          font-size: var(--clamp14);
          color: var(--text-color1);
          margin: 0 10px 0 0;

          @media screen and (max-width: 480px) {
            margin-bottom: 10px;
            display: block;
          }
        }

        .delete {
          background-color: #006BCE;
          border-radius: 50px;
          color: #fff;
          font-size: 14px;
        }

        .omail-auto-switch {
          font-size: var(--clamp14);
          display: flex;
          align-items: center;

          label {
            @media screen and (max-width: 480px) {
              display: flex;
            }
          }
        }
      }

      .radio-inline,
      .signature_txt {
        font-family: var(--font-family) !important;
        font-style: normal;
        font-weight: normal;
        font-size: var(--clamp16);
        color: var(--main-color) !important;
        margin-left: 5px;
      }

      .signature_name_input::placeholder {
        color: var(--main-color) !important;
      }
    }

    .modal-footer {
      padding: 24px;

      @media screen and (max-width: 375px) {
        padding: 24px 16px;
      }

      @media screen and (max-width: 320px) {
        padding: 24px 8px;
      }

      .custom-btn-outline {
        font-size: var(--clamp14);
        margin-right: 24px;

        @media screen and (max-width: 320px) {
          margin-right: 4px;
        }
      }

      .custom-btn {
        font-size: var(--clamp14);
      }

      @media screen and (max-width:500px) {
        .custom-btn-outline {
          margin-right: 10px;
          min-width: auto;
        }

        .custom-btn {
          min-width: auto;
        }

        padding: 12px;
      }
    }
  }
}

/* omail Signature-modal-modal End*/
/* omail-passCodeModal-modal-modal Start*/
.omail-passCodeModal-modal {
  .modal-content {
    .modal-body {
      .create-folder-modal {
        .omail-folder-form {
          display: flex;
          align-items: center;
          margin-bottom: 30px;

          .folder-text {
            margin-right: 5px;
          }

          .folder-input {
            position: relative;

            input {
              max-width: 100px;
              min-width: 100px;

              &.is-invalid {
                background-image: none;
              }
            }

            .foldericon {
              position: absolute;
              top: 11px;
              left: 69px;
            }
          }

          .invalid-feedback {
            position: fixed;
            font-size: 10px;
          }
        }
      }
    }
  }
}

/* omail-passCodeModal-modal-modal End*/
/* Create Folder-modal Start*/
.omail-create-folder-modal {
  .modal-content {
    .modal-body {
      overflow-y: hidden;
      position: inherit;

      .create-folder-modal {

        // position: relative;
        .form-group {
          label {
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            color: #6D747A;
            margin: 0 0 2px;
          }

          .form-control {
            border-color: #5EC2FE;
            border-radius: 4px;
            height: 40px;
            box-shadow: none;
            color: #0A2477;
            font-size: 14px;
            background-color: transparent;
          }
        }

        .create-folder-btn {
          background: #E9F0FF;
          text-align: center;
          padding: 10px;
          display: flex;
          justify-content: center;

          button {
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            color: #0A2477;
            display: flex;
            align-items: center;
            gap: 10px;

            &:hover {
              text-decoration: none;
              opacity: 0.5;
            }
          }
        }

        .omail-folder-form {
          display: flex;
          gap: 11px;
          margin-top: 65px;

          .folder-input {
            position: relative;

            input {
              max-width: 100px;
              min-width: 100px;
              background: none;
              border: 1px solid #ccc;

              &.is-invalid {
                background-image: none;
              }
            }

            .foldericon {
              position: absolute;
              top: 11px;
              left: 69px;
            }
          }

          .folder-text {
            .passcode-checkbox {
              margin-top: 10px;

              .passcode-text {
                margin-left: 4px;
                font-size: 14px;
                color: #334554;
              }
            }
          }
        }

        select {
          font-size: 14px;
          color: #0A2477;
          height: 40px;
          background-color: #F8F8FF;
          border: none;

          .select-option {
            width: 20px;
            height: 20px;
            background-color: red;
            display: block;
            border-radius: 50%;
          }
        }

        .folder-color {
          width: 14px;
          height: 14px;
          display: inline-block;
          margin-right: 10px;
          border-radius: 50%;
        }

        .ng-select-container {
          background-color: transparent;
          border-color: #5EC2FE;
          height: 40px;
          background: none;
          border-radius: 4px;
        }

        .ng-dropdown-panel.ng-select-bottom {
          border: 1px solid #5EC2FE;
        }

        .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
          background-color: #F0F9FF;
          color: #30353D;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
        }

        .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
          color: #30353D;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          border-bottom: 1px solid #5EC2FE;

          &:last-child {
            border-bottom: none;
          }
        }

        .ng-dropdown-panel .scroll-host {
          overflow: hidden;
          overflow-y: auto;
          position: relative;
          display: block;
          max-height: 120px;
        }

        ng-select {
          position: absolute;
          left: 15px;
          right: 15px;

          .ng-arrow-wrapper .ng-arrow {
            background: url(../../../assets/omail/images/contacts/adressbook-select.png) no-repeat 97% center;
            width: 12px;
            height: 12px;
            border: none;
            opacity: 0.6;
          }

          &.ng-select-opened>.ng-select-container .ng-arrow {
            transform: rotate(180deg);
            top: 0;
          }
        }
      }

      .folder-colorpicker {
        position: relative;

        input[type=text] {
          float: left;
          padding-left: 45px;
        }

        button {
          float: left;
          padding: 11px 0 0 10px;
          color: white;
          border: none;
          border-left: none;
          cursor: pointer;
          position: absolute;
          left: 0;
          background: transparent;

          span {
            width: 14px;
            height: 14px;
            padding: 10px;
            float: left;
          }
        }
      }
    }
  }
}

.omail-color-picker-main {
  max-width: 90px;
  float: left;

  div {
    float: left;

    span {
      width: 14px;
      height: 14px;
      padding: 10px;
      float: left;
      margin: 0 5px 10px;
      cursor: pointer;
    }
  }
}

.omail-folder-edit-modal {
  .modal-footer {
    padding-top: 68px;
  }
}

/* Create Folder-modal  End*/
/* User Detais and send mail Modal Start */
.omail-userdetails-modal {
  .modal-body {
    p {
      padding: 0;
      margin: 0;
    }
  }
}

/* User Detais and send mail Modal End */
/* Import Contacts  Modal  Start*/
.omail-contactimports-modal {
  .modal-body {
    .omail-import-contacts-block {
      .omail-import-contacts-text {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        color: #464255;

        span {
          a {
            color: #4136F1;
            text-decoration: underline;
          }
        }
      }

      .omail-import-contacts-file {
        margin: 0 0 9px;
        position: relative;

        label {
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          color: #A3A3A3;
        }

        .input-group {
          .input-group-text {
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            color: #FFFFFF;
            cursor: pointer;
          }
        }

        .file-error-text {
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          color: #E84C3D;
        }

        input[type=file]::-webkit-file-upload-button {
          width: 0;
          padding: 0;
          margin: 0;
          -webkit-appearance: none;
          border: none;
          border: 0px;
        }

        input {
          line-height: 32px;
          border-radius: 4px !important;
          background: #F8F8FF;
          border: none;
        }

        .input-group {
          label {
            margin-left: 10px !important;
            border-radius: 6px !important;
          }
        }
      }
    }
  }

  .modal-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;

    button {
      cursor: pointer;

      img {
        margin-right: 5px;
      }
    }
  }
}

/* Import Contacts  Modal  End*/
/* Video mail css starts here */
.omail-videomail-modal {
  .modal-content {
    .modal-header {
      background: transparent;
      border: none;
      position: absolute;
      top: 0;
      right: 0;
      z-index: 1;

      .btn-close {
        width: 24px;
        height: 24px;
        background-color: #8C82FF;
        border-radius: 50%;
        padding: 0;
        position: relative;
        top: 5px;
        transition: transform .7s ease-in-out;

        &:hover {
          background-color: #E84C3D;
          transform: rotate(90deg);
        }

        i {
          color: var(--white-color);
        }
      }
    }

    .modal-body {
      min-height: 360px;
      padding: 0;
      width: 100%;

      @media screen and (max-width: 1440px) {
        max-height: 560px;
        overflow-y: hidden;
      }

      video {
        width: 100%;
        display: block;
        margin-top: -3px;
      }
    }

    .modal-footer {
      background-color: rgba(255, 255, 255, 0.6);
      position: absolute;
      bottom: 0;
      width: 100%;
      justify-content: flex-start;
      padding-top: 6px;
      padding-bottom: 6px;

      @media screen and (max-width: 640px) {
        font-size: 12px;
      }

      @media screen and (max-width: 400px) {
        gap: 10px;
        padding: 4px;
      }

      .start-record {
        background: #00A542;
        font-weight: 400;

        @media screen and (max-width: 640px) {
          font-size: 12px;
          min-width: 94px;
          padding: 0px 2px;
          min-height: 32px;
        }
      }

      .end-record {
        background: #E84C3D;
        font-weight: 400;

        @media screen and (max-width: 640px) {
          font-size: 12px;
          min-width: 94px;
          padding: 0px 2px;
          min-height: 32px;
        }
      }

      .video-pause-icon,
      .video-play-icon {
        border: none;
        background-color: transparent;
        margin-left: 15px;

        @media screen and (max-width: 320px) {
          margin-left: 0;
        }
      }

      .center {
        margin: 0;
      }

      div {
        @media screen and (max-width: 320px) {
          margin: 0;
        }
      }
    }
  }
}

.omail-videomailone-modal {
  .modal-content {
    .modal-body {
      position: relative;
      padding: 0;

      .bg-img {
        width: 100%;
        height: 100%;
        z-index: 999;

        img {
          object-fit: cover;
          width: 100%;
          height: 100%;
          text-align: center;
        }
      }

      .video-block {
        position: absolute;
        top: 48%;
        left: 50%;
        width: 81%;
        z-index: 1000;
        transform: translate(-50%, -50%);
      }
    }

    .changebg-icon {
      position: absolute;
      right: 16px;
      bottom: 16px;

      @media screen and (max-width: 480px) {
        right: 6px;
        bottom: 3px;
      }

      span {
        cursor: pointer;
      }

      .close-btn {
        width: 24px;
        height: 24px;
        background-color: #8C82FF;
        border-radius: 50%;
        padding: 0 6px;
        position: relative;
        top: 0;
        transition: transform 0.7s ease-in-out;
        cursor: pointer;

        &:hover {
          background-color: #E84C3D;
          transform: rotate(90deg);
        }

        i {
          color: #fff;
        }
      }
    }

    .omail-videomail-modal .modal-content .modal-body {
      min-height: auto;
      max-height: 520px;
      overflow-y: hidden;

      @media screen and (max-width: 1024px) {
        max-height: 360px;
      }

      @media screen and (max-width: 991px) {
        max-height: 226px;
      }

      @media screen and (max-width: 480px) {
        max-height: 210px;
      }

      @media screen and (max-width: 414px) {
        max-height: 180px;
      }

      @media screen and (max-width: 360px) {
        max-height: 156px;
      }

      @media screen and (max-width: 320px) {
        max-height: 138px;
      }
    }
  }
}

.omail-withoutbg-content {
  .no-bg {
    img {
      display: none;
    }
  }

  .changebg-icon {
    position: absolute;
    bottom: 14px;
    right: 14px;
  }

  .omail-videomail-modal .modal-content .modal-body {
    overflow-y: auto !important;

    @media screen and (max-width: 1440px) {
      max-height: 650px !important;
      overflow-y: hidden !important;
    }
  }
}

/* Video mail css ends here */
/* Alert Modal CSS Start */
.omail-mydialog-allowed-files {
  .omailalert-modal {
    .modal-content {
      .modal-body {
        padding: 20px;

        p {
          @media screen and (max-width: 640px) {
            word-break: break-word;
          }
        }
      }

      .modal-footer {
        padding: 20px;
      }
    }
  }
}

/* Alert Modal CSS End */
.omail-save-to-drafts-modal {
  .modal-content {
    .modal-body {
      text-align: center;

      p {
        margin: 0;
      }
    }
  }
}

.omail-viewcontactpic-modal {
  .modal-content {
    .modal-body {
      padding: 0;

      p {
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }
}

.omail-viewgrouppic-modal {
  .modal-content {
    .modal-body {
      padding: 0;

      p {
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }
}

/*
  ********************************
  * Omail App All Modal CSS  End
  ********************************
*/
/*-----O-MailFroala CSS Start -----*/
.omail-customtheam-froala-block {
  .fr-box {
    &.fr-basic {
      .fr-toolbar {
        border: 1px solid rgba(140, 130, 255, 0.2);
        background: #F0F9FF;
        border-radius: 10px 10px 0 0;

        .fr-command {
          &.fr-btn {
            background: #BFE7FE;
            border-radius: 6px;

            svg {
              path {
                fill: #003B93;
              }
            }
          }

          &.fr-active {
            background: #2c97d4;
          }

          &[id*="videoMail"] {
            background: #37AC00;
            fill: #ffffff;

            &.fr-btn {
              border-radius: 50%;
            }

            svg {
              path {
                fill: #ffffff;
              }
            }
          }

          &[id*="speechToText"] {
            background: #37AC00;
            border-radius: 4px;
            padding: 0 11px;

            img {
              margin: 0;
              width: auto;
              filter: brightness(0) invert(1);
            }
          }
        }

        .fr-btn-grp {
          margin: 0 4px;
        }
      }

      .fr-wrapper {
        border: 1px solid rgba(140, 130, 255, 0.2);
        border-top: 1px solid transparent;
        overflow-x: hidden !important;
        padding-bottom: 40px;

        &::-webkit-scrollbar {
          width: 4px !important;
        }

        &::-webkit-scrollbar-thumb {
          background: #DFF3FF;
          box-sizing: border-box;
          box-shadow: inset 0px 10px 10px rgba(255, 255, 255, 0.25);
          border-radius: 7px;
        }

        @media screen and (max-width: 1024px) {
          margin-bottom: 0px;
        }

        p {
          margin-bottom: 0;
        }

        .fr-element {
          min-height: 400px;
          margin-bottom: 40px;

          a {
            color: #0a58ca;
          }
        }
      }
    }

    .fr-second-toolbar {
      border: 1px solid rgba(140, 130, 255, 0.2);
      border-top: 0;
    }
  }

  .fr-toolbar .fr-command.fr-btn.fr-open:not(:hover):not(:focus):not(:active) {
    background: #aea9eb;
  }

  .fr-desktop .fr-command.fr-selected:not(.fr-table-cell),
  .omail-customtheam-froala-block .fr-desktop .fr-command:active {
    background: #f00 !important;

    svg {
      path {
        fill: #fff !important;
      }
    }
  }

  .fr-toolbar .fr-command.fr-btn.fr-dropdown.fr-options,
  .fr-popup .fr-command.fr-btn.fr-dropdown.fr-options,
  .fr-modal .fr-command.fr-btn.fr-dropdown.fr-options {
    background: transparent;
  }

  &.fr-fullscreen-wrapper {
    .fr-box.fr-basic .fr-toolbar {
      top: 1px !important;
    }

    .fr-wrapper {
      max-height: inherit !important;
    }
  }

  &.omail-composemail-froala,
  &.omail-replymail-froala {
    margin-bottom: 60px;

    .fr-box.fr-basic .fr-toolbar {
      border: none;
      border-radius: 0;
      padding: 0 7px;
    }

    .fr-wrapper {
      border: none;
    }

    .fr-second-toolbar {
      border: none;
      border-radius: 0;
      background: transparent;
    }
  }
}

.omail-read-mail-body {
  .fr-fic.fr-dib {
    margin: 5px auto;
    display: list-item;
    float: none;
    vertical-align: top;

    &.fr-fir {
      margin-right: 0;
      text-align: right;
    }

    &.fr-fil {
      margin-left: 0;
      text-align: left;
    }
  }

  img.fr-fic.fr-rounded {
    border-radius: 10px;
  }

  img.fr-fic.fr-bordered {
    border: solid 5px #CCC;
  }

  img.fr-fic.fr-shadow {
    box-shadow: 10px 10px 5px 0px #cccccc;
  }
}

/*-----//O-MailFroala CSS End -----*/
@keyframes bounceListen {
  0% {
    text-shadow:
      0 5px 0 #ccc,
      0 2px 3px rgba(0, 0, 0, 0.3);
  }

  100% {
    transform: translateY(-5px);
  }
}

@-webkit-keyframes bounceListen {
  0% {
    text-shadow:
      0 5px 0 #ccc,
      0 2px 3px rgba(0, 0, 0, 0.3);
  }

  100% {
    transform: translateY(-5px);
  }
}

.omail-welcontact-pic-text {
  width: 32px;
  height: 32px;
  border: 1px solid #E6E6E6;
  border-radius: 8px;
  background-color: #E6E6E6;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.omail-froalabg-block {
  position: relative;
  padding: 0;

  .bg-img {
    width: 100%;
    height: 100%;
    z-index: 999;

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
      text-align: center;
    }
  }

  .video-block {
    position: absolute;
    top: 49%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 81%;

    @media screen and (max-width: 991px) {
      width: 79%;
    }

    &.no-bg-video-block {
      position: inherit;
      transform: none;
      width: 100%;
      left: 0;
      top: 0;
    }
  }
}

.omail-guide-block {
  button {
    background: transparent;
    border: 1px solid #4136F1;
    background-color: #4136F1;
    color: #fff;
    font-size: 12px;
    font-weight: 400;
    border-radius: 8px;
    padding: 2px 10px;
    margin-right: 18px;

    &:hover {
      opacity: 0.5;
    }

    i {
      font-size: 10px;
      margin-left: 4px;
    }
  }

  @media screen and (max-width: 780px) {
    display: none;
  }

  @media screen and (max-width:767px) {
    display: none;
  }
}

.introjs-tooltip.omail-tour-guide {
  background-color: transparent;
  box-shadow: none;

  .introjs-tooltiptext {
    color: #fff;
    font-size: var(--clamp16);
    text-shadow: 2px 2px 6px #000;
    font-family: cursive;
    padding: 15px 0px;
    line-height: 28px;
  }

  .introjs-tooltipbuttons {
    border-top: none;
    padding: 0 0 10px;

    .introjs-button {
      background: var(--primary-btn-bg);
      border-radius: var(--border-radius2);
      color: var(--white-color);
      font-size: var(--clamp14);
      font-weight: 500;
      text-align: center;
    }

    .introjs-prevbutton {
      margin-right: 5px;
    }
  }

  .introjs-skipbutton i {
    font-family: "Font Awesome 5 Pro" !important;
    color: #f00;
    background-color: var(--white-color);
    width: 25px;
    height: 25px;
    border-radius: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: var(--clamp16);
  }

  .introjs-arrow {
    display: none !important;
  }
}

.omail-mobile-logo {
  display: none;

  @media screen and (max-width: 580px) {
    display: none;
  }
}

.omail-pre-loader-pleasewait {
  flex-direction: column;
  top: 76px;
  left: 76px;
  z-index: 5;
  background-color: rgb(256, 256, 256, 1);

  @media screen and (max-width: 1024px) {
    align-items: center;
  }

  @media screen and (max-width:768px) {
    left: 0;
  }

  @media screen and (max-width:767px) {
    left: 0;
  }

  @media screen and (max-width:500px) {
    left: 0;
  }

  .pre-loader-box {
    flex-direction: column;
    width: 170px;
    height: 124px;

    @media screen and (max-width: 1024px) {
      left: auto;
      top: auto;
    }

    .spinner-border {
      margin-bottom: 4px;
    }

    h5 {
      font-size: 16px;
    }
  }
}

/* -- omail-mat-autocomplete CSS Start -- */
.omail-mat-autocomplete {
  max-height: 336px !important;

  &.mat-autocomplete-panel.mat-autocomplete-visible {
    display: block;
  }

  &:not([class*=mat-elevation-z]) {
    box-shadow: 0px 4px 8px rgba(68, 100, 157, 0.25) !important;
    display: none;
  }

  border: 1px solid #006BCE !important;
  border-radius: 6px !important;
  padding: 16px !important;

  &::-webkit-scrollbar {
    width: 4px !important;
  }

  &::-webkit-scrollbar-thumb {
    background: #DFF3FF;
    box-sizing: border-box;
    box-shadow: inset 0px 10px 10px rgba(255, 255, 255, 0.25);
    border-radius: 7px;
  }

  .mat-option {
    padding: 7px 13px;
    height: 36px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #0A2477;
    background: #F1FAFF;
    border-radius: 4px;
    margin-bottom: 4px;

    &:hover {
      background: #DFF3FF;
    }

    &.mat-active {
      background: rgba(0, 0, 0, .04);
    }
  }
}

/*-- //omail-mat-autocomplete CSS End -- */
/* -----Contact and Group pic dropdown CSS Start------*/
.cg-pic-dw-block {
  border: 1px solid transparent;
  padding: 0;
  min-width: 140px;
  border-radius: 0;
  filter: drop-shadow(0px 4px 4px rgba(63, 89, 114, 0.2));

  .dropdown-item {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #0A2477;
    cursor: pointer;
    border-bottom: 1px solid transparent;

    &:last-child {
      border-bottom: none;
    }

    &:hover {
      background: #F0F9FF;
    }
  }
}

/*--------//Contact and Group pic dropdown CSS Start-------*/
/*
  ********************************
  ********************************
  * Omail App Mailbox,Calendar,
  * Contacts and Settings  End
  ********************************
  ********************************
*/

.omail-darktheam-block {
  position: absolute;
  right: 0;
  height: calc(100vh - 76px);
  top: 0;
  background-color: #ECECEC;
  padding: 14px 4px 0 4px;

  img {
    cursor: pointer;
  }

  .light-icon {
    opacity: 0.5;

    &:hover {
      opacity: 1;
    }
  }
}

.omail-contacts-main-block {
  .omail-v-tabs {
    display: flex;

    .omail-vtabs-sidenav {
      width: 240px;
      background-color: #fff;
      height: calc(100vh - 76px);
      border-right: 1px solid #DEDEDE;
      padding: 12px;

      @media screen and (max-width: 1024px) {
        position: absolute;
        background: #fff;
        z-index: 2;
        box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.3);
      }

      .cg-btn-action-block {
        margin-top: 111px;

        .omail-btn-group {
          border-top: 1px solid #DEDEDE;
          margin-bottom: 18px;

          span {
            margin-left: 10px;
          }

          .create-contact-btn,
          .create-group-btn {
            padding-left: 3px;
            padding-right: 3px;
            text-align: center;
          }
        }

        .dropdown-menu {
          transform: translate(-175px, 43px) !important;

          @media screen and (max-width: 413px) {
            transform: translate(0px, 43px) !important;
          }

          .omail-custom-btn {
            span {
              margin-left: 5px;
            }
          }

          .create-contact-btn,
          .create-group-btn {
            text-align: left;
            padding: 9px 18px !important;

            span,
            img {
              margin-left: 5px;
            }
          }
        }
      }

      /* Deleted contact and group starts here */
      .cg-list-block {
        padding-top: 18px;
        border-top: 1px solid #DEDEDE;

        li {
          background: #fff;
          border-radius: 6px;
          padding: 10px 12px;
          color: #30353D;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          cursor: pointer;
          display: flex;
          align-items: center;
          margin-bottom: 2px;

          &:hover {
            background: #DFF3FF;
          }

          &.active {
            background: #000;
          }

          &.activetab {
            background-color: #BFE7FE;

            span {
              color: #006BCE;
              font-weight: 500;
            }

            img {
              filter: invert(25%) sepia(34%) saturate(6744%) hue-rotate(196deg) brightness(93%) contrast(100%);
            }
          }
        }
      }

      .delete-content {
        display: flex;
        width: 100%;
        padding: 10px 12px;
        align-items: center;
        justify-content: space-between;
        position: relative;

        &.delete-active {
          background-color: #BFE7FE;
          border-radius: 6px;

          .delete-txt {
            font-weight: 500;
            color: #006BCE;
          }

          .create-delete-icon {
            img {
              filter: invert(25%) sepia(34%) saturate(6744%) hue-rotate(196deg) brightness(93%) contrast(100%);
            }
          }
        }
      }

      .omail-delete-list-one {
        &:hover {
          background-color: #DFF3FF;
          border-radius: 6px;
        }

        .delete-txt {
          color: #30353D;
          font-weight: 400;
          font-size: 14px;
          margin-left: 5px;
        }
      }

      .omail-sub-delete-layout {
        li {
          width: 100%;
          display: flex;
          align-items: center;
          gap: 4px;
          padding: 10px 10px 10px 42px;
          cursor: pointer;
          background-color: #F1FAFF;
          margin-bottom: 1px;
          border-radius: 6px;
          font-size: 14px;

          &:hover {
            background-color: #DFF3FF;
          }

          &.activetab {
            background-color: #DFF3FF;
            font-weight: 400;
          }
        }

        &.active {
          li {
            display: none;
          }
        }
      }

      /* Deleted contact and group ends here */
    }

    .omail-vtabs-view {
      background-color: #fff;
      width: calc(100% - 240px);
      height: calc(100vh - 76px);

      @media screen and (max-width: 1024px) {
        width: 100%;
      }

      .omail-vtabs-view-block {
        display: flex;

        @media screen and (max-width: 1024px) {
          padding-left: 68px;
        }

        @media screen and (max-width: 767px) {
          text-align: center;
          margin-top: 9px;
        }

        .omail-vtabs-view-left {
          width: 30%;
          height: calc(100vh - 76px);
          border-right: 1px solid #DEDEDE;

          @media screen and (max-width: 767px) {
            width: 60px;
          }
        }

        .omail-vtabs-view-right {
          width: 70%;
          height: calc(100vh - 76px);
          position: relative;

          @media screen and (max-width: 767px) {
            width: 90%;
          }
        }
      }
    }
  }

  .omail-contacts-v-tabs {
    color: #000;

    .mat-tab-labels {
      display: none;
    }
  }
}

.omail-sigsettings-block {
  .signature-info {
    label {
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      color: #30353D;
      margin-bottom: 2px;
    }

    .signature_name_input {
      background: #FFFFFF;
      border: 1px solid #DEDEDE;
      box-shadow: 0px 1px 2px rgba(77, 64, 85, 0.1);
      border-radius: 4px;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      color: #A3A3A3;
      height: 40px;
    }
  }

  .omail-customtheam-froala-block {
    margin: 26px 0 24px;
  }

  .sig-action-links {
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 24px;
  }

  .sig-checkbox-block {
    display: flex;
    align-items: center;
    gap: 24px;

    label {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      color: #30353D;

      input {
        width: 18px;
        height: 18px;
        vertical-align: middle;
        border: 1.5px solid #A3A3A3;
      }
    }
  }

  .omail-customtheam-froala-block {
    .fr-box.fr-basic {
      box-shadow: 0px 3px 4px rgb(63, 89, 100, 0.2);
    }
  }
}

.omail-mn-block {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;

  .omail-mn-1 {
    width: 80px;

    input {
      text-align: center;
    }
  }

  .omail-mn-2 {
    width: 100%;
  }
}

.omail-more-lineksmail+* .cdk-overlay-pane {
  .mat-menu-panel {
    min-height: auto !important;
    border-radius: 0 !important;
    min-width: 95px;
    max-width: 95px;
    padding: 8px;
    border-radius: 4px !important;
    box-shadow: 0px 4px 4px 0px rgba(63, 89, 114, 0.20);

    .mat-menu-content:not(:empty) {
      padding: 0;

      button.mat-menu-item {
        color: #0A2477;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        height: 36px;
        line-height: 36px;

        &:hover {
          background: #F0F9FF;
        }
      }
    }
  }

  hr {
    margin: 0;
  }
}

.omail-pre-loader-mail-list {
  top: 0;
  left: 0;
  background-color: white;
  width: 100%;
  z-index: 5;
  height: calc(100vh - 77px);
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 1024px) {
    align-items: center;
  }

  .omail-pre-loader-box {
    width: 170px;
    height: 124px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #006BCE;

    @media screen and (max-width: 1024px) {
      left: auto;
      top: auto;
    }

    .spinner-border {
      margin-bottom: 4px;
    }

    h5 {
      font-size: 16px;
    }
  }
}

.omail-contacts-loader {
  background: rgb(63, 89, 100, 0.2);
}

.omail-contactsgroup-sendmail-loader {
  background-color: #ffffff;
}

.omail-rfrall-remail-loader {
  top: 0;
  left: 15%;
  width: 100%;
  z-index: 998;
  z-index: 1;
  height: calc(100vh - 77px);
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 1024px) {
    align-items: center;
  }

  .omail-pre-loader-box {
    width: 170px;
    height: 124px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #006BCE;
    gap: 10px;

    @media screen and (max-width: 1024px) {
      left: auto;
      top: auto;
    }

    .spinner-border {
      margin-bottom: 4px;
    }

    h5 {
      font-size: 16px;
    }
  }
}

.omail-readmailchain-block {
  border-left: 2px solid #7ECEFE;
  background-color: #F0F9FF;
  padding: 6px;

  ul {
    li {
      margin-left: 25px;
    }
  }
}

.omail-folder-error {
  font-size: 10px;
}

.omail-timer-field-block {
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 0 0 22px;

  @media screen and (max-width: 450px) {
    overflow: hidden;
    overflow-x: auto;
    width: 96%;

    &::-webkit-scrollbar {
      width: 4px !important;
      height: 4px !important;

      @media screen and (max-width: 540px) {
        width: 2px !important;
      }
    }

    &::-webkit-scrollbar-thumb {
      background: #DFF3FF;
      box-sizing: border-box;
      box-shadow: inset 0px 10px 10px rgba(255, 255, 255, 0.25);
      border-radius: 7px;
    }
  }

  .mat-form-field-wrapper {
    padding-bottom: 0;
  }

  .mat-form-field-appearance-outline .mat-form-field-wrapper {
    margin: 0;
  }

  .mat-form-field {
    display: inline-flex;
  }
}

.omail-timer-field {
  padding: 0 0 0 12px;
  position: relative;

  @media screen and (max-width: 480px) {
    padding-left: 0;
    display: inline-block;
  }

  .timecustom-select {
    width: 122px;
    height: 34px !important;
    border: 1px solid #DEDEDE !important;
    border: 1px solid #5EC2FE !important;
    padding: 0 27px 0 10px !important;
    color: #30353D !important;
    cursor: pointer;
  }

  .time-icon {
    position: absolute;
    left: 103px;
    top: 4px;
  }
}

.omail-setting-form {
  .timelabel-text {
    margin: 0;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #A3A3A3;
    width: 50px;
    display: inline-block;
  }
}

.omail-autoreply-froala-block {
  margin-top: 20px;
}

.omail-select-contact-errortext {
  font-size: 0.875em;
  color: #dc3545;
}

.omail-autotimer-field {
  position: relative;

  .form-control {
    height: 36px !important;
    padding: 6.5px 12px !important;
  }

  .cal-time-icon {
    position: absolute;
    right: 6px;
    top: 5px;
  }
}

.omail-calendar .calendar-right .day-view-block .cal-day-view .cal-week-view .cal-current-time-marker {
  background-color: #0175BA;
}

.omail-event-calendar .week-view-block .cal-week-view .cal-current-time-marker {
  background-color: #0175BA;
}

.omail-calendar {
  .calendar-left-side {
    .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
      background-color: #DFF3FF !important;
      border-color: #9EDAFE;
    }

    .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
      background-color: #DFF3FF;
      border-radius: 4px;
    }

    .mat-calendar-body-selected {
      background-color: #9EDAFE;
      border-radius: 4px;
    }
  }
}

.omail-c-trash {
  height: 94px;
  width: 94px;
  background: url(../images/obsomailcontacts/newcon/Lightmode_del-empty-contacts.svg) no-repeat center center;
}

.omail-c-gtrash {
  height: 94px;
  width: 94px;
  background: url(../images/obsomailcontacts/newcon/Lightmode_del-empty-group.svg) no-repeat center center;
}

.omail-c-fav {
  height: 94px;
  width: 94px;
  background: url(../images/obsomailcontacts/newcon/Lightmode-empty-favorites.svg) no-repeat center center;
}

.omail-drag-selected-mails {
  background-color: #F8F8F8;
  border: 1px solid #A3A3A3;
  border-radius: 4px;
  padding: 4px 6px 4px 17px;
  font-size: 12px;
  color: #30353D;
  font-weight: 400;
}

.omail-autoreplay-date {
  width: 155px;

  .mat-form-field-appearance-outline .mat-form-field-flex {
    width: 155px;
  }
}

.omail-date-autofup {
  border-bottom: 1px solid #E6E6E6;
  margin-bottom: 12px;
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 12px 0;

  @media screen and (max-width: 1024px) {
    overflow: hidden;
    overflow-x: auto;
    width: 96%;

    &::-webkit-scrollbar {
      width: 4px !important;
      height: 4px !important;

      @media screen and (max-width: 540px) {
        width: 2px !important;
      }
    }

    &::-webkit-scrollbar-thumb {
      background: #DFF3FF;
      box-sizing: border-box;
      box-shadow: inset 0px 10px 10px rgba(255, 255, 255, 0.25);
      border-radius: 7px;
    }
  }

  .mat-form-field-wrapper {
    padding: 0;
  }

  .timeselect-autore {
    border: 1px solid #DEDEDE !important;
    width: 114px;
  }

  .mat-form-field-flex {
    width: 155px;
  }

  .date-lable {
    color: #A3A3A3;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
  }
}

/* Mail dragging css starts here */
.omail-leftside-scrollsec.cdk-drop-list-dragging {
  .omail-drag-active {
    &:hover {
      &:after {
        content: '';
        background: url(../../../assets/omail/images/obsomail/mailbox/drag-cross.svg) no-repeat;
        position: absolute;
        // left: 0px;
        width: 20px;
        height: 20px;
      }
    }
  }

  .omail-sub-folder-layout {
    .omail-drag-active {
      &:hover {
        &:after {
          left: 8px !important;
        }
      }
    }
  }
}

/* Mail dragging css ends here */
/* Tab Blinking starts here */
.omail-blink-btn {
  -webkit-animation: pulse 400ms infinite alternate;
  animation: pulse 400ms infinite alternate;
}

@-webkit-keyframes pulse {
  0% {
    background-color: #559ecb;
  }

  100% {
    background-color: #9EDAFE;
  }
}

@keyframes pulse {
  0% {
    background-color: #559ecb;
  }

  100% {
    background-color: #9EDAFE;
  }
}

/* Tab Blinking ends here */

/*
	* 300+ count Mail list  CSS Start
 */
.omail-listofmailids-count {
  border-radius: 28px;
  background: #006BCE;
  padding: 1px 5px;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  color: #fff;
  margin-left: 3px;
  cursor: pointer;

  &::after {
    display: none
  }
}

.omail-listofmailids-count-block {
  position: sticky;
  left: 0;
  top: -6px;
  background-color: #fff;
  z-index: 1;

  .omail-listofmailids-count-inner {
    border-radius: 4px;
    padding: 3px 10px;
    border: 1px solid #F87405;
    background: #FFF2E8;
    color: #30353D;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    text-align: left;
    display: inline;
  }
}

.omail-listofmailids-count-list {
  margin-top: 7px;
}

/*
	* 300+ count Mail list  CSS End
 */
/*
	* Manuval input time  CSS Start
*/
.omail-inputtimer-field {
  position: relative;

  .omail-input-time {
    font-weight: 400;
    font-size: 14px;
    border-radius: 4px;
    height: 32px;
    width: 118px;
    color: #30353D;
    border: 1px solid #E6E3FE;
    z-index: 1;
    position: relative;
    background: none;
    padding-right: 27px;
    padding-left: 11px;
  }

  .omail-input-time-icon {
    position: absolute;
    right: 5px;
    top: 3px;
    cursor: pointer;
    z-index: 4;

    button {
      border: none;
      background: transparent;
      cursor: pointer;

      &::after {
        display: none;
      }
    }

    &:hover {
      filter: invert(40%) sepia(52%) saturate(5057%) hue-rotate(182deg) brightness(96%) contrast(98%);
    }
  }
}

.omail-light-mode {
  .omail-timeicon-list {
    position: relative !important;
    left: -81px;
    border: 1px solid #ccc;
    background: #ffffff;
    padding: 8px;
    max-height: 289px;
    min-height: 40px;
    overflow-y: auto;
    min-width: 99px;
    box-shadow: 0px 0px 0px 0px rgba(27, 63, 80, 0.20), 0px 1px 3px 0px rgba(27, 63, 80, 0.20), 0px 6px 6px 0px rgba(27, 63, 80, 0.17), 0px 12px 7px 0px rgba(27, 63, 80, 0.10), 0px 22px 9px 0px rgba(27, 63, 80, 0.03), 0px 35px 10px 0px rgba(27, 63, 80, 0.00);

    &::-webkit-scrollbar {
      width: 4px !important;
    }

    &::-webkit-scrollbar-thumb {
      background: #DFF3FF;
      box-sizing: border-box;
      box-shadow: inset 0px 10px 10px rgba(255, 255, 255, 0.25);
      border-radius: 7px;
    }

    .dropdown-item {
      cursor: pointer;

      &:hover {
        background-color: #DFF3FF;
      }

      span {
        color: #003B93;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
      }
    }
  }
}

/*
	* Manuval input time  CSS End
*/

.omail-reschedule-btn {
  color: #006BCE;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  display: flex;
  align-items: center;
  border-radius: 6px;
  padding: 4px 10px 5px;

  &:hover {
    background-color: #F0F9FF !important;
    filter: none !important;
  }

  img {
    margin-right: 6px;
  }
}

.omail-appointment {
  .event-duration-fields {
    .event-icon {
      &:hover {
        img {
          filter: invert(52%) sepia(53%) saturate(7282%) hue-rotate(181deg) brightness(94%) contrast(98%);
          opacity: 1 !important;
        }
      }
    }
  }
}

.omail-date-autofup {
  mat-icon {
    &:hover {
      img {
        filter: invert(52%) sepia(53%) saturate(7282%) hue-rotate(181deg) brightness(94%) contrast(98%);
      }
    }
  }
}

.omail-future-date {
  font-size: 11px;
  position: absolute;
}

/*
	* Main Layout adding inactive class start
 */
.omailapp-mainwrap.active {
  @media screen and (max-width: 767px) {
    margin: auto;

    .omail-mailbox-module .omail-mailbox-split-block {
      width: 97%;
    }
  }
}

.omailapp-mainwrap.inactive {
  @media screen and (max-width: 767px) {
    margin: auto;

    .omail-mailbox-module .omail-mailbox-split-block {
      width: 97%;
    }
  }
}

/*
	* Main Layout adding inactive class End
 */

/* Please Don't write the styles Below 
   Please Don't write the styles Below 
   Please Don't write the styles Below 
*/
/*
	* Mobile features Hide CSS Start
 */
.omail-mobilehide-features {
  @media screen and (max-width: 767px) {
    display: none;
  }
}

.omailapp-settings-wrap .omail-side-settings ul.sidenav-box li.omail-mobilehide-features4 {
  @media screen and (max-width: 767px) {
    display: none;
  }
}

.omailapp-settings-wrap .omail-side-settings ul.sidenav-box li.omail-mobilehide-features6 {
  @media screen and (max-width: 767px) {
    display: none;
  }
}

.omailapp-settings-wrap .omail-side-settings ul.sidenav-box li.omail-mobilehide-features8 {
  @media screen and (max-width: 767px) {
    display: none;
  }
}

.omailapp-settings-wrap .omail-side-settings ul.sidenav-box li.omail-mobilehide-features9 {
  @media screen and (max-width: 767px) {
    display: none;
  }
}

.omail-contacts-main-block .omail-v-tabs .omail-vtabs-sidenav .cg-list-block li.omail-mobilehide-features {
  @media screen and (max-width: 767px) {
    display: none;
  }
}

.omail-contacts-main-block .contacts-right-block .contacts-view-header .user-view-actions button.omail-mobilehide-features {
  @media screen and (max-width: 767px) {
    display: none;
  }
}

.omail-darktheam-block .light-icon {
  @media screen and (max-width: 767px) {
    display: none;
  }
}

.omail-darktheam-block .dark-icon {
  @media screen and (max-width: 767px) {
    display: none;
  }
}

.omail-mailbox-module .omail-mailbox-split-block .omailmailbox-split-right-block .omailmailbox-readmail-mainblock .omail-readmail-main .omail-read-mail-header .omail-read-mail-headerone .headerone-rigth-block .text-to-speech-block {
  @media screen and (max-width: 767px) {
    display: none;
  }
}

.omail-customtheam-froala-block .fr-box.fr-basic .fr-toolbar .fr-command[id*=speechToText] {
  @media screen and (max-width: 767px) {
    display: none;
  }
}

.omail-customtheam-froala-block .fr-box.fr-basic .fr-toolbar .fr-command[id*=videoMail] {
  @media screen and (max-width: 767px) {
    display: none;
  }
}

.omail-mobilehide-features-btn {
  .omail-custom-btn {
    @media screen and (max-width: 767px) {
      border-radius: 6px;
    }
  }

  .omail-mobilehide-features-btn-group {
    @media screen and (max-width: 767px) {
      display: none;
    }
  }
}

.omail-mobilehide-features-sendbtn {
  @media screen and (max-width: 767px) {
    margin: 0 !important;
  }

  .msend-btn {
    @media screen and (max-width: 767px) {
      width: 100% !important;
    }
  }
}

/*
	* Mobile features Hide CSS End
 */
@import 'omail-flatpicker-calendar.scss';
@import 'omail-dark-mode.scss';
/* Please Don't write the styles Below 
   Please Don't write the styles Below 
   Please Don't write the styles Below 
*/
